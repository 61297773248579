import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const tourPersonnelQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourPersonnel", tourId],
    queryFn: async () => {
      const response = await API.tours.toursControllerFindAllPersonnel(tourId);
      return response.data;
    },
  });

export const useTourPersonnel = (id: string) => {
  return useQuery(tourPersonnelQueryOptions(id));
};

export const useTourPersonnelSuspense = (id: string) => {
  return useSuspenseQuery(tourPersonnelQueryOptions(id));
};
