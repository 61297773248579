import { fromZonedTime } from "date-fns-tz";
import { FieldValues, SubmitHandler } from "react-hook-form";
import {
  getExtendedSharedTravelSchema,
  getGenericFormSchema,
  getHotelFormSchema,
  getLoadInFormSchema,
  getPersonalFormSchema,
  getPromoFormSchema,
} from "@/modules/Tours/Events/eventsSchemas";
import { z } from "zod";
import { TFunction } from "i18next";
import { connectDateWithTime as connectDate } from "@/lib/dates";
import { PersonalEventName, TourEventType } from "@articulate/shared";
import { subDays } from "date-fns";

const connectDateWithTime = (date: Date, time: string, timezoneId?: string) => {
  const connectedDate = connectDate(date, time);

  if (timezoneId) {
    return fromZonedTime(connectedDate, timezoneId);
  }

  return connectedDate;
};

interface Props {
  eventType: TourEventType | PersonalEventName;
  eventTypeId: string;
  form: FieldValues;
  t: TFunction<["common", "dashboard", "forms"]>;
  tourId: string;
}

export const formFields: SubmitHandler<FieldValues & Props> = ({
  form,
  eventType,
  eventTypeId,
  tourId,
  t,
}: Props) => {
  const timezoneId = form.timezoneId;
  const nextDay = form.nextDay || false;

  switch (eventType) {
    case "HOTEL_STAY": {
      const hotelSchema = getHotelFormSchema(t);
      const hotelEvent = form as z.infer<typeof hotelSchema>;

      // If hotel stay last more than one day, we want to display one day less
      const showOneDayLess =
        hotelEvent.checkIn.toISOString() !== hotelEvent.checkOut.toISOString();
      const start = connectDateWithTime(
        hotelEvent.checkIn,
        "12:00",
        timezoneId
      );
      const end = connectDateWithTime(
        showOneDayLess ? subDays(hotelEvent.checkOut, 1) : hotelEvent.checkOut,
        "12:00",
        timezoneId
      );
      const phoneNumber =
        hotelEvent.phoneNumber.areaCode + hotelEvent.phoneNumber.phoneNumber;

      if (hotelEvent.id) {
        return {
          membersAndGroups: hotelEvent.membersAndGroups,
          spectators: hotelEvent.spectators,
          id: hotelEvent.id,
          data: {
            countryId: hotelEvent.countryId,
            endDate: end.toISOString(),
            eventTypeId,
            startDate: start.toISOString(),
            city: hotelEvent.city,
            state: hotelEvent.state || "",
            notes: hotelEvent.notes,
            zipCode: hotelEvent.postalCode,
            streetAddress: hotelEvent.address,
            visibleToAll: hotelEvent.visibility === "true",
            eventDetails: {
              description: "",
              name: hotelEvent.eventName || hotelEvent.name,
            },
            timezoneId: timezoneId,
            additionalInformation: {
              loadIn: null,
              promo: null,
              travel: null,
              hotel: {
                hotelName: hotelEvent.name,
                email: hotelEvent.email || null,
                keyContacts: hotelEvent.keyContacts || null,
                phoneNumber,
                website: hotelEvent.website || null,
                facilities: hotelEvent.facilities,
              },
            },
          },
        };
      }

      return {
        membersAndGroups: hotelEvent.membersAndGroups,
        spectators: hotelEvent.spectators,
        tourId,
        data: {
          city: hotelEvent.city,
          countryId: hotelEvent.countryId,
          endDate: end.toISOString(),
          eventTypeId,
          startDate: start.toISOString(),
          state: hotelEvent.state || "",
          notes: hotelEvent.notes,
          zipCode: hotelEvent.postalCode,
          streetAddress: hotelEvent.address,
          visibleToAll: hotelEvent.visibility === "true",
          eventDetails: {
            description: "",
            name: hotelEvent.eventName || hotelEvent.name,
          },
          timezoneId: timezoneId,
          additionalInformation: {
            loadIn: null,
            promo: null,
            travel: null,
            hotel: {
              hotelName: hotelEvent.name,
              email: hotelEvent.email || null,
              keyContacts: hotelEvent.keyContacts || null,
              phoneNumber,
              website: hotelEvent.website || null,
              facilities: hotelEvent.facilities,
            },
          },
        },
      };
    }
    case "LOAD_IN": {
      const loadInSchema = getLoadInFormSchema(t);
      const loadInEvent = form as z.infer<typeof loadInSchema>;

      const departureDate = connectDateWithTime(
        loadInEvent.startDate,
        loadInEvent.startTime,
        timezoneId
      );
      const arrivalDate = connectDateWithTime(
        loadInEvent.endDate,
        loadInEvent.endTime,
        timezoneId
      );

      if (loadInEvent.id) {
        return {
          membersAndGroups: loadInEvent.membersAndGroups,
          spectators: loadInEvent.spectators,
          id: loadInEvent.id,
          data: {
            countryId: loadInEvent.countryId,
            endDate: arrivalDate.toISOString(),
            eventTypeId,
            startDate: departureDate.toISOString(),
            eventDetails: {
              description: "",
              name: loadInEvent.eventName,
            },
            nextDay,
            timezoneId: timezoneId,
            visibleToAll: loadInEvent.visibility === "true",
            city: loadInEvent.city || "",
            state: loadInEvent.state || "",
            zipCode: loadInEvent.postalCode,
            streetAddress: loadInEvent.address,
            notes: loadInEvent.notes,
            additionalInformation: {
              hotel: null,
              loadIn: {
                requiredEquipment: loadInEvent.requiredEquipment,
              },
              promo: null,
              travel: null,
            },
          },
        };
      }

      return {
        membersAndGroups: loadInEvent.membersAndGroups,
        spectators: loadInEvent.spectators,
        tourId,
        data: {
          countryId: loadInEvent.countryId,
          endDate: arrivalDate.toISOString(),
          eventTypeId,
          startDate: departureDate.toISOString(),
          eventDetails: {
            description: "",
            name: loadInEvent.eventName,
          },
          nextDay,
          timezoneId: timezoneId,
          visibleToAll: loadInEvent.visibility === "true",
          city: loadInEvent.city || "",
          state: loadInEvent.state || "",
          zipCode: loadInEvent.postalCode,
          streetAddress: loadInEvent.address,
          notes: loadInEvent.notes,
          additionalInformation: {
            hotel: null,
            loadIn: {
              requiredEquipment: loadInEvent.requiredEquipment,
            },
            promo: null,
            travel: null,
          },
        },
      };
    }
    case "TRAVEL": {
      const travelSchema = getExtendedSharedTravelSchema(t);
      const travelEvent = form as z.infer<typeof travelSchema>;

      const departureDate = connectDateWithTime(
        travelEvent.departureDate,
        travelEvent.departureTime,
        timezoneId
      );
      const arrivalDate = connectDateWithTime(
        travelEvent.arrivalDate,
        travelEvent.arrivalTime,
        travelEvent.travelType === "air"
          ? travelEvent.arrivalTimezoneId
          : travelEvent.destinationTimezone
      );
      const flight = travelEvent.flightInfo;
      const scheduledOut = flight?.scheduledOut || "";
      const scheduledIn = flight?.scheduledIn || "";
      const flightInfo = {
        identIcao: flight?.identIcao || "",
        identIata: flight?.identIata || "",
        scheduledIn,
        scheduledOut,
        origin: {
          codeIcao: flight?.origin.codeIcao || "",
          timezone: flight?.origin.timezone || "",
          name: flight?.origin.name || "",
          city: flight?.origin.city || "",
        },
        destination: {
          codeIcao: flight?.destination.codeIcao || "",
          timezone: flight?.destination.timezone || "",
          name: flight?.destination.name || "",
          city: flight?.destination.city || "",
        },
      };

      if (travelEvent.id) {
        return {
          membersAndGroups: travelEvent.membersAndGroups,
          spectators: travelEvent.spectators,
          id: travelEvent.id,
          data: {
            countryId: travelEvent.countryId,
            endDate:
              travelEvent.travelType === "air"
                ? scheduledIn
                : arrivalDate.toISOString(),
            startDate:
              travelEvent.travelType === "air"
                ? scheduledOut
                : departureDate.toISOString(),
            eventTypeId,
            eventDetails: {
              description: "",
              name: travelEvent.eventName,
            },
            nextDay,
            timezoneId: timezoneId,
            city: travelEvent.city || "",
            state: travelEvent.state || "",
            zipCode: travelEvent.postalCode,
            streetAddress: travelEvent.address,
            notes: "",
            visibleToAll: travelEvent.visibility === "true",
            additionalInformation: {
              hotel: null,
              loadIn: null,
              promo: null,
              travel: {
                arrivalTime: arrivalDate.toISOString(),
                departureTime: departureDate.toISOString(),
                destination: travelEvent.destination,
                destinationTimezone: travelEvent.destinationTimezone,
                arrivalOrigin: travelEvent.destination,
                arrivalOriginAirportCode:
                  travelEvent.arrivalAirportCode || null,
                departureOrigin: travelEvent.departureOrigin,
                departureOriginAirportCode:
                  travelEvent.departureAirportCode || null,
                flightNumber: travelEvent.flightNumber?.toString() || "",
                sealNumber: travelEvent.sealNumber || null,
                trainNumber: travelEvent.trainNumber || null,
                travelType: travelEvent.travelType,
                flightInfo,
              },
            },
          },
        };
      }

      return {
        membersAndGroups: travelEvent.membersAndGroups,
        spectators: travelEvent.spectators,
        tourId,
        data: {
          countryId: travelEvent.countryId,
          endDate:
            travelEvent.travelType === "air"
              ? scheduledIn
              : arrivalDate.toISOString(),
          startDate:
            travelEvent.travelType === "air"
              ? scheduledOut
              : departureDate.toISOString(),
          eventTypeId,
          eventDetails: {
            description: "",
            name: travelEvent.eventName,
          },
          nextDay,
          timezoneId: timezoneId,
          city: travelEvent.city || "",
          state: travelEvent.state || "",
          zipCode: travelEvent.postalCode,
          streetAddress: travelEvent.address,
          notes: "",
          visibleToAll: travelEvent.visibility === "true",
          additionalInformation: {
            hotel: null,
            loadIn: null,
            promo: null,
            travel: {
              arrivalTime: arrivalDate.toISOString(),
              departureTime: departureDate.toISOString(),
              destination: travelEvent.destination,
              destinationTimezone: travelEvent.destinationTimezone,
              arrivalOrigin: travelEvent.destination,
              arrivalOriginAirportCode: travelEvent.arrivalAirportCode || null,
              departureOrigin: travelEvent.departureOrigin,
              departureOriginAirportCode:
                travelEvent.departureAirportCode || null,
              flightNumber: travelEvent.flightNumber?.toString() || "",
              sealNumber: travelEvent.sealNumber || null,
              trainNumber: travelEvent.trainNumber || null,
              travelType: travelEvent.travelType,
              flightInfo,
            },
          },
        },
      };
    }
    case "PROMO": {
      const promoSchema = getPromoFormSchema(t);
      const promoEvent = form as z.infer<typeof promoSchema>;

      const startDate = connectDateWithTime(
        promoEvent.startDate,
        promoEvent.startTime,
        timezoneId
      );
      const endDate = connectDateWithTime(
        promoEvent.endDate,
        promoEvent.endTime,
        timezoneId
      );

      if (promoEvent.id) {
        return {
          membersAndGroups: promoEvent.membersAndGroups,
          spectators: promoEvent.spectators,
          id: promoEvent.id,
          data: {
            countryId: promoEvent.countryId,
            endDate: endDate.toISOString(),
            eventTypeId,
            startDate: startDate.toISOString(),
            eventDetails: {
              description: "",
              name: promoEvent.eventName,
            },
            nextDay,
            timezoneId: timezoneId,
            visibleToAll: promoEvent.visibility === "true",
            city: promoEvent.city || "",
            state: promoEvent.state || "",
            zipCode: promoEvent.postalCode,
            streetAddress: promoEvent.address,
            notes: promoEvent.notes,
            additionalInformation: {
              hotel: null,
              loadIn: null,
              promo: {
                interviewerName: promoEvent.interviewerName,
                mediaOutlet: promoEvent.mediaOutlet,
              },
              travel: null,
            },
          },
        };
      }

      return {
        membersAndGroups: promoEvent.membersAndGroups,
        spectators: promoEvent.spectators,
        tourId,
        data: {
          countryId: promoEvent.countryId,
          endDate: endDate.toISOString(),
          eventTypeId,
          startDate: startDate.toISOString(),
          eventDetails: {
            description: "",
            name: promoEvent.eventName,
          },
          nextDay,
          timezoneId: timezoneId,
          visibleToAll: promoEvent.visibility === "true",
          city: promoEvent.city || "",
          state: promoEvent.state || "",
          zipCode: promoEvent.postalCode,
          streetAddress: promoEvent.address,
          notes: promoEvent.notes,
          additionalInformation: {
            hotel: null,
            loadIn: null,
            promo: {
              interviewerName: promoEvent.interviewerName,
              mediaOutlet: promoEvent.mediaOutlet,
            },
            travel: null,
          },
        },
      };
    }
    case "PERSONAL": {
      const personalSchema = getPersonalFormSchema(t);
      const personalEvent = form as z.infer<typeof personalSchema>;

      const startDate = connectDateWithTime(
        personalEvent.startDate,
        personalEvent.startTime,
        timezoneId
      );
      const endDate = connectDateWithTime(
        personalEvent.endDate,
        personalEvent.endTime,
        timezoneId
      );

      if (personalEvent.id) {
        return {
          id: personalEvent.id,
          data: {
            countryId: personalEvent.countryId,
            endDate: endDate.toISOString(),
            startDate: startDate.toISOString(),
            timezoneId: timezoneId,
            city: personalEvent.city || "",
            state: personalEvent.state || "",
            streetAddress: personalEvent.address,
            notes: personalEvent.notes,
            name: personalEvent.eventName,
          },
        };
      }

      return {
        tourId,
        data: {
          countryId: personalEvent.countryId,
          endDate: endDate.toISOString(),
          startDate: startDate.toISOString(),
          timezoneId: timezoneId,
          city: personalEvent.city || "",
          state: personalEvent.state || "",
          streetAddress: personalEvent.address,
          notes: personalEvent.notes,
          name: personalEvent.eventName,
        },
      };
    }
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SCHEDULE_ITEM":
    case "SHOW": {
      const genericSchema = getGenericFormSchema(t);
      const genericEvent = form as z.infer<typeof genericSchema>;

      const startDate = connectDateWithTime(
        genericEvent.startDate,
        genericEvent.startTime,
        timezoneId
      );
      const endDate = connectDateWithTime(
        genericEvent.endDate,
        genericEvent.endTime,
        timezoneId
      );

      if (genericEvent.id) {
        return {
          membersAndGroups: genericEvent.membersAndGroups,
          spectators: genericEvent.spectators,
          id: genericEvent.id,
          data: {
            countryId: genericEvent.countryId,
            endDate: endDate.toISOString(),
            eventTypeId,
            startDate: startDate.toISOString(),
            visibleToAll: genericEvent.visibility === "true",
            eventDetails: {
              description: "",
              name: genericEvent.eventName,
            },
            nextDay,
            timezoneId: timezoneId,
            city: genericEvent.city || "",
            state: genericEvent.state || "",
            zipCode: genericEvent.postalCode,
            streetAddress: genericEvent.address,
            notes: genericEvent.notes,
          },
        };
      }

      return {
        membersAndGroups: genericEvent.membersAndGroups,
        spectators: genericEvent.spectators,
        tourId,
        data: {
          countryId: genericEvent.countryId,
          endDate: endDate.toISOString(),
          eventTypeId,
          startDate: startDate.toISOString(),
          visibleToAll: genericEvent.visibility === "true",
          eventDetails: {
            description: "",
            name: genericEvent.eventName,
          },
          nextDay,
          timezoneId: timezoneId,
          city: genericEvent.city || "",
          state: genericEvent.state || "",
          zipCode: genericEvent.postalCode,
          streetAddress: genericEvent.address,
          notes: genericEvent.notes,
        },
      };
    }
  }
};
