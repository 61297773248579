import { useTranslation } from "react-i18next";
import { getGenericFormSchema, getVisibilityOptions } from "./eventsSchemas";
import { useFormContext } from "react-hook-form";
import { FormTextArea } from "@/components/Form/FormTextarea";
import { EventFormProps } from "./useEventForms";
import { PersonnelPicker } from "./PersonnelPicker";
import { useParams } from "react-router-dom";
import { FormRadioField } from "@/components/Form/FormRadio";
import { TourEventRoles } from "@articulate/shared";
import { GooglePlacesSearchbox } from "@/components/GooglePlacesSearchbox/AsyncCombobox";
import { z } from "zod";
import { DatePickersForm } from "@/components/DatePicker/DatePickersForm";
import { FormInput } from "@/components/Form/FormInput";
import { OvernightEventCheckbox } from "./OvernightEventCheckbox";

export function GenericEventForm({ tourInterval }: EventFormProps) {
  const { tourId } = useParams();
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const genericSchema = getGenericFormSchema(t);
  const form = useFormContext<z.infer<typeof genericSchema>>();

  return (
    <>
      <FormInput
        control={form.control}
        name={"eventName"}
        label={t("forms:editEvent.name")}
      />
      <PersonnelPicker tourId={tourId!} role={TourEventRoles.CREW} />
      <div className="flex flex-col gap-4">
        <GooglePlacesSearchbox label="Address" />
        <DatePickersForm
          control={form.control}
          names={{
            start: { day: "startDate", time: "startTime" },
            end: { day: "endDate", time: "endTime" },
          }}
          label={t("forms:genericEvent.duration") + " *"}
          fromDate={tourInterval.startsAt}
          toDate={tourInterval.endsAt}
        />
        <OvernightEventCheckbox />
        <FormTextArea
          control={form.control}
          name={"notes"}
          label={t("forms:hotelStayEvent.notes")}
          placeholder={t("forms:hotelStayEvent.addNotesHere")}
        />
        <FormRadioField
          control={form.control}
          onChange={() => form.trigger("visibility")}
          name={"visibility"}
          label={t("forms:travelEvent.visibility")}
          items={getVisibilityOptions(t)}
        />
        {form.getValues("visibility") === "false" && (
          <PersonnelPicker tourId={tourId!} role={TourEventRoles.SPECTATOR} />
        )}
      </div>
    </>
  );
}
