import { useCallback, useEffect, useState } from "react";
import { Control, FieldValues, Path, useFormContext } from "react-hook-form";
import { ClassValue } from "clsx";
import { addDays } from "date-fns";

import { cn } from "@/lib/utils";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { DatePickerHtml } from "@/components/DatePicker/DatePickerHtml";
import { FormInput } from "@/components/Form/FormInput";
import { useTourDayState } from "@/modules/Tours/TourDayContext";
import { FormLabel } from "@/components/ui/form";
import { useTranslation } from "react-i18next";

type PathT = Path<FieldValues | Date>;

type Props<T extends FieldValues> = {
  containerClassName?: ClassValue;
  control: Control<T>;
  fromDate?: Date;
  label?: string;
  names: {
    start: { day: PathT; time: Path<T> };
    end: { day: PathT; time: Path<T> };
  };
  toDate?: Date;
};

const options = {
  one: "option-one",
  two: "option-two",
};

type Options = typeof options;
type OptionValues = Options[keyof Options];

export function DatePickersForm<T extends FieldValues>({
  containerClassName,
  control,
  fromDate,
  label,
  names,
  toDate,
}: Props<T>) {
  const { t } = useTranslation(["forms"]);
  const [selectedOption, setSelectedOption] = useState<OptionValues>(
    options.one
  );
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean>(false);
  const { setValue } = useFormContext();
  const { selectedDate } = useTourDayState();

  const setDates = useCallback(
    (type: OptionValues) => {
      if (selectedDate) {
        setValue(names.start.day, selectedDate, {
          shouldValidate: false,
        });

        setValue(
          names.end.day,
          type === options.one ? selectedDate : addDays(selectedDate, 1),
          {
            shouldValidate: false,
          }
        );
      }
    },
    [selectedDate, setValue, names.start.day, names.end.day]
  );

  const onRadioChange = (value: OptionValues) => {
    if (value === options.one) {
      setSelectedCheckbox(false);
    }
    setSelectedOption(value);
  };

  useEffect(() => {
    setDates(selectedOption);
  }, [selectedOption, setDates]);

  useEffect(() => {
    setDates(selectedCheckbox ? options.two : options.one);
  }, [selectedCheckbox, setDates]);

  return (
    <div
      className={cn("relative flex w-full grow flex-col", containerClassName)}>
      {label && <FormLabel className="mb-2">{label}</FormLabel>}

      <RadioGroup
        className="gap-0"
        defaultValue={options.two}
        onValueChange={onRadioChange}
        value={selectedOption}>
        <div className="flex flex-row justify-between">
          <div className="flex">
            <RadioGroupItem value={options.one} id={options.one} />
            <Label htmlFor={options.one} className="ml-2">
              {selectedOption === options.one
                ? t("forms:genericEvent.oneDay")
                : t("forms:genericEvent.thisDay")}
            </Label>
          </div>

          {selectedOption === options.one && (
            <div className="flex">
              <Checkbox
                id="two-days"
                checked={selectedCheckbox}
                onCheckedChange={(value: boolean) => setSelectedCheckbox(value)}
              />
              <Label className="ml-2" htmlFor="two-days">
                {t("forms:genericEvent.twoDays")}
              </Label>
            </div>
          )}
        </div>

        {selectedOption === options.one && (
          <div className="mt-2 flex flex-row gap-4">
            <div className="w-full">
              <FormInput
                className="w-full"
                control={control}
                label=""
                name={names.start.time}
                placeholder="05:00"
                type="time"
              />
            </div>
            <div className="w-full">
              <FormInput
                className="w-full"
                control={control}
                label=""
                name={names.end.time}
                placeholder="05:00"
                type="time"
              />
            </div>
          </div>
        )}

        <div className="mt-4 flex">
          <RadioGroupItem value={options.two} id={options.two} />
          <Label className="ml-2" htmlFor={options.two}>
            {t("forms:genericEvent.custom")}
          </Label>
        </div>
      </RadioGroup>

      {selectedOption === options.two && (
        <div className="mt-2">
          <div className="flex flex-row gap-4">
            <div className="w-full">
              <DatePickerHtml
                control={control}
                fromDate={fromDate}
                name={names.start.day as Path<T>}
                toDate={toDate}
              />
            </div>
            <div className="w-full">
              <FormInput
                control={control}
                label=""
                name={names.start.time}
                placeholder="05:00"
                type="time"
              />
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-full">
              <DatePickerHtml
                control={control}
                name={names.end.day as Path<T>}
                fromDate={fromDate}
                toDate={toDate}
              />
            </div>
            <div className="w-full">
              <FormInput
                control={control}
                name={names.end.time}
                label=""
                type="time"
                placeholder="05:00"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
