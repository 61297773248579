import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const tourQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tours", tourId],
    queryFn: async () => {
      const response = await API.tours.toursControllerFindOne(tourId);
      return response.data;
    },
    enabled: !!tourId,
  });

export const useTour = (tourId: string) => {
  return useQuery(tourQueryOptions(tourId));
};

export const useTourSuspense = (tourId: string) => {
  return useSuspenseQuery(tourQueryOptions(tourId));
};
