import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

export type UpdateEventBodyT = {
  id: string;
  data: Parameters<typeof API.events.eventsControllerUpdate>[1];
};

export const useUpdateEvent = () => {
  return useMutation({
    mutationKey: ["updateEvent"],
    mutationFn: async ({ id, data }: UpdateEventBodyT) => {
      const response = await API.events.eventsControllerUpdate(id, data);

      return response.data;
    },
  });
};
