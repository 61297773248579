import image from "@/assets/sprites/articulate.png";

export const STORAGE_URL = import.meta.env.VITE_STORAGE_URL;

export const getArtistImageWithFallback = (logoKey: string | null) =>
  logoKey ? `${STORAGE_URL}/${logoKey}` : image;

export const getTourImageWithFallback = (logoKey?: string | null) =>
  logoKey
    ? `${STORAGE_URL}/${logoKey}`
    : "https://picsum.photos/300.webp?blur=1";

export const getImageWithFallback = (logoKey: string | null) =>
  logoKey ? `${STORAGE_URL}/${logoKey}` : image;

export const getImage = (logoKey?: string | null) =>
  logoKey ? `${STORAGE_URL}/${logoKey}` : "";
