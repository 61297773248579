import { zString } from "@/lib/zod";
import { PhoneNumberUtil } from "google-libphonenumber";
import i18next from "i18next";
import { z } from "zod";

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const phoneSchema = z
  .object({
    phoneNumber: zString(),
    areaCode: zString(),
    countryCode: zString(),
  })
  .refine((data) => isPhoneValid(data.areaCode + data.phoneNumber), {
    message: i18next.t("common:error.invalidPhoneNumber"),
  });
