// these variables are for default input values
export var unitedStatesId = "c38adb63-661f-4dca-b265-40c78bf7fc20";
export var unitedStatesItem = {
    uuid: unitedStatesId,
    code: "US",
    name: "United States",
};
export var unitedStatesOption = {
    label: unitedStatesItem.name,
    value: unitedStatesItem.uuid,
};
export var countriesList = [
    unitedStatesItem,
    {
        uuid: "1e4f8f82-22f2-4593-9c1d-0d22582641e7",
        code: "AD",
        name: "Andorra",
    },
    {
        uuid: "a8da2fbf-4e85-4166-b055-787be829b7e6",
        code: "AE",
        name: "United Arab Emirates",
    },
    {
        uuid: "4f5cb5f4-7649-464d-85f0-13ff815843ed",
        code: "AF",
        name: "Afghanistan",
    },
    {
        uuid: "482bd4aa-79dd-4fc8-82de-cba1cc22aaaf",
        code: "AG",
        name: "Antigua and Barbuda",
    },
    {
        uuid: "6d1641e7-3ea2-442d-a39a-3eeb3d902d8a",
        code: "AI",
        name: "Anguilla",
    },
    {
        uuid: "7cd4d4ec-ac56-4b25-88c5-2de1a448d576",
        code: "AL",
        name: "Albania",
    },
    {
        uuid: "b3f3d4f2-a3d9-4c05-9f91-261bdd43d4b3",
        code: "AM",
        name: "Armenia",
    },
    {
        uuid: "e3819f79-32e9-49b2-93e9-1f6e5652f21b",
        code: "AN",
        name: "Netherlands Antilles",
    },
    {
        uuid: "b7dc128a-7c4e-4379-8678-ff285d3a9b8e",
        code: "AO",
        name: "Angola",
    },
    {
        uuid: "ebb3cc77-fe3b-4787-b4d6-b5f67c30c796",
        code: "AQ",
        name: "Antarctica",
    },
    {
        uuid: "23384da6-3251-40b9-a6e8-af173b8f83cf",
        code: "AR",
        name: "Argentina",
    },
    {
        uuid: "d75aa88f-c204-47ce-ad35-852bcb24431a",
        code: "AS",
        name: "American Samoa",
    },
    {
        uuid: "91ce8e82-8523-4286-9289-a34eba2ac70f",
        code: "AT",
        name: "Austria",
    },
    {
        uuid: "4f6429fb-bd96-48ef-9448-06107c595365",
        code: "AU",
        name: "Australia",
    },
    {
        uuid: "102e2506-da5b-4297-bde9-57e35db53ecd",
        code: "AW",
        name: "Aruba",
    },
    {
        uuid: "39ef6a1e-162e-40f1-9ddc-ddc75ec7d1fd",
        code: "AZ",
        name: "Azerbaijan",
    },
    {
        uuid: "ab83fc00-07ca-4e41-b894-0d79ae626aad",
        code: "BA",
        name: "Bosnia and Herzegovina",
    },
    {
        uuid: "5c95a531-258d-4784-afa9-0bb6158ab455",
        code: "BB",
        name: "Barbados",
    },
    {
        uuid: "5170e6dc-7a45-4325-af6c-2e9e90810caa",
        code: "BD",
        name: "Bangladesh",
    },
    {
        uuid: "43ad8cb3-b7ec-4c7e-9e18-cf4704450ebc",
        code: "BE",
        name: "Belgium",
    },
    {
        uuid: "71cbab52-a484-4c90-bd37-e5fdbcd48cd1",
        code: "BF",
        name: "Burkina Faso",
    },
    {
        uuid: "a7866153-4701-4ff0-928c-b36e92080acc",
        code: "BG",
        name: "Bulgaria",
    },
    {
        uuid: "fdab54e3-b285-4798-aec9-0e9b3bd338e6",
        code: "BH",
        name: "Bahrain",
    },
    {
        uuid: "4075f24f-f90f-4a84-bbd9-39d12689645a",
        code: "BI",
        name: "Burundi",
    },
    {
        uuid: "27b3d303-40de-477a-b5cb-473444875efb",
        code: "BJ",
        name: "Benin",
    },
    {
        uuid: "02fd57b0-6257-489d-a1b4-69e5e7fcec95",
        code: "BM",
        name: "Bermuda",
    },
    {
        uuid: "f4b32798-34b7-4e90-b6eb-3667b8dcd73f",
        code: "BN",
        name: "Brunei",
    },
    {
        uuid: "27e36461-4192-4d34-93d4-420d56a93efc",
        code: "BO",
        name: "Bolivia",
    },
    {
        uuid: "d4440993-ba1d-4026-9b45-ad54213473f8",
        code: "BR",
        name: "Brazil",
    },
    {
        uuid: "a3294835-fdd8-4c36-8abd-72433e5822b5",
        code: "BS",
        name: "Bahamas",
    },
    {
        uuid: "25f418d4-7708-4f03-a81d-f577372fbe2b",
        code: "BT",
        name: "Bhutan",
    },
    {
        uuid: "f88111d4-b2d3-446d-a823-adbca38c996e",
        code: "BV",
        name: "Bouvet Island",
    },
    {
        uuid: "23306546-902e-4ed3-9fd9-f6d6c7eaf909",
        code: "BW",
        name: "Botswana",
    },
    {
        uuid: "85dfd18d-83ec-438d-8ee8-51feb0fd0d62",
        code: "BY",
        name: "Belarus",
    },
    {
        uuid: "5a9664d1-f474-4eb5-9e8e-65da2d7f90d4",
        code: "BZ",
        name: "Belize",
    },
    {
        uuid: "008ea6c4-14d9-4131-873c-3d4ec769f8f2",
        code: "CA",
        name: "Canada",
    },
    {
        uuid: "3cac7316-daf4-4bab-a545-15c60745008b",
        code: "CC",
        name: "Cocos [Keeling] Islands",
    },
    {
        uuid: "fe32c910-0ce1-4c6c-807f-7aafc2238ae2",
        code: "CD",
        name: "Congo [DRC]",
    },
    {
        uuid: "dc44b31a-da44-4426-a6fa-90d6a4bba4b4",
        code: "CF",
        name: "Central African Republic",
    },
    {
        uuid: "4f138aa3-7243-4fda-8bf6-8b14ea50581b",
        code: "CG",
        name: "Congo [Republic]",
    },
    {
        uuid: "1aaae37d-8cea-4c06-b811-dab7cf3abcca",
        code: "CH",
        name: "Switzerland",
    },
    {
        uuid: "6e56024b-8c3d-4a9e-abe6-8037b1f92618",
        code: "CI",
        name: "Côte d'Ivoire",
    },
    {
        uuid: "d378689c-81bc-430e-b1e4-2128cc9aa62b",
        code: "CK",
        name: "Cook Islands",
    },
    {
        uuid: "98254b1c-abce-44ed-a1aa-14482ec91cd1",
        code: "CL",
        name: "Chile",
    },
    {
        uuid: "d641f4ff-8462-41ac-a31b-b6424900e5b2",
        code: "CM",
        name: "Cameroon",
    },
    {
        uuid: "3cfc16c6-0bb1-49dc-ac8b-a11210c2e055",
        code: "CN",
        name: "China",
    },
    {
        uuid: "594bdbeb-6446-4437-aab0-0518a6fcc708",
        code: "CO",
        name: "Colombia",
    },
    {
        uuid: "ec54794a-c871-4ece-b437-c037d9e80a5f",
        code: "CR",
        name: "Costa Rica",
    },
    {
        uuid: "8d3eb4d8-bd93-49c0-82a7-3f952bafebe8",
        code: "CU",
        name: "Cuba",
    },
    {
        uuid: "32458958-7390-47ef-8e61-0d4799409870",
        code: "CV",
        name: "Cape Verde",
    },
    {
        uuid: "7441fabf-50a8-4272-ba2a-556fa425d9ac",
        code: "CX",
        name: "Christmas Island",
    },
    {
        uuid: "67089094-1b26-4727-84b6-1ef475a08e89",
        code: "CY",
        name: "Cyprus",
    },
    {
        uuid: "7e80e7b8-9f24-467e-ae85-235430d0c3da",
        code: "CZ",
        name: "Czech Republic",
    },
    {
        uuid: "9e227440-a065-49bc-9bac-b02b4ffabb15",
        code: "DE",
        name: "Germany",
    },
    {
        uuid: "eba8f1c6-6f2d-42a4-a579-4bac9468b46e",
        code: "DJ",
        name: "Djibouti",
    },
    {
        uuid: "87df60c5-f117-4d54-9d9d-7499f52c37ba",
        code: "DK",
        name: "Denmark",
    },
    {
        uuid: "7e97a19c-61bc-46cf-8257-26b83c071bf9",
        code: "DM",
        name: "Dominica",
    },
    {
        uuid: "d253b841-fac0-4fe3-8073-10d79070c9a6",
        code: "DO",
        name: "Dominican Republic",
    },
    {
        uuid: "1a0cc096-51ed-4835-8c05-e1d667f3d88c",
        code: "DZ",
        name: "Algeria",
    },
    {
        uuid: "a0746b7f-9e45-4db7-a249-e04fa014fee5",
        code: "EC",
        name: "Ecuador",
    },
    {
        uuid: "0e036387-a8bb-48b5-aa7e-9538c262219f",
        code: "EE",
        name: "Estonia",
    },
    {
        uuid: "fe879944-6746-425c-b8aa-70b19ceea6d2",
        code: "EG",
        name: "Egypt",
    },
    {
        uuid: "6dfcd8f8-453f-46d1-ab56-7d5f93ba5243",
        code: "EH",
        name: "Western Sahara",
    },
    {
        uuid: "fdd85cc7-c15b-4586-826d-a6f17fc76b89",
        code: "ER",
        name: "Eritrea",
    },
    {
        uuid: "f359d07e-44eb-4589-af55-f95fd7f7daad",
        code: "ES",
        name: "Spain",
    },
    {
        uuid: "f694abe7-163a-4ef7-adf1-49255fd9020b",
        code: "ET",
        name: "Ethiopia",
    },
    {
        uuid: "6bbec499-2453-40e0-bba8-8bfa28fe2c95",
        code: "FI",
        name: "Finland",
    },
    {
        uuid: "eaa1ea60-0994-4b2f-aa5d-4000092e63f3",
        code: "FJ",
        name: "Fiji",
    },
    {
        uuid: "817dcad5-0ff0-4c44-b102-3e4ca8edfec0",
        code: "FK",
        name: "Falkland Islands [Islas Malvinas]",
    },
    {
        uuid: "f65c7515-5974-42f3-b15e-32d4f2bb6c06",
        code: "FM",
        name: "Micronesia",
    },
    {
        uuid: "1d4a8fc8-1fbe-44f9-86fc-47aae0a83e1b",
        code: "FO",
        name: "Faroe Islands",
    },
    {
        uuid: "d7678fa6-506d-499d-99d9-1410c3e486e3",
        code: "FR",
        name: "France",
    },
    {
        uuid: "22d47870-6077-4c66-bbbb-0ad1b45758c7",
        code: "GA",
        name: "Gabon",
    },
    {
        uuid: "db37fa0a-f97e-487b-83e6-cd5f46313b2f",
        code: "GB",
        name: "United Kingdom",
    },
    {
        uuid: "f43ec5c6-634a-483f-b5f5-a0ff8f47cd6e",
        code: "GD",
        name: "Grenada",
    },
    {
        uuid: "af054276-82af-43c5-bc2b-ff2d3f2d5b72",
        code: "GE",
        name: "Georgia",
    },
    {
        uuid: "2f08cd26-5ef6-4db4-ae4e-14d16c244855",
        code: "GF",
        name: "French Guiana",
    },
    {
        uuid: "02434396-ef1b-454c-9b23-d4b971850130",
        code: "GG",
        name: "Guernsey",
    },
    {
        uuid: "fc63e5df-71bd-4258-8e32-db76e9cee37b",
        code: "GH",
        name: "Ghana",
    },
    {
        uuid: "60c90715-1e01-4b92-b713-c4dcfe96d127",
        code: "GI",
        name: "Gibraltar",
    },
    {
        uuid: "1874ee25-cc17-4124-bbe4-e3b5efcc4330",
        code: "GL",
        name: "Greenland",
    },
    {
        uuid: "dd58ff71-995d-469a-895c-da04653257cd",
        code: "GM",
        name: "Gambia",
    },
    {
        uuid: "c9add13e-a321-48ae-8578-616c93919b96",
        code: "GN",
        name: "Guinea",
    },
    {
        uuid: "f6263cd0-0539-4ef7-81c4-fff02d71bc8a",
        code: "GP",
        name: "Guadeloupe",
    },
    {
        uuid: "a036718a-c1ad-4b22-8f22-616c6b09806f",
        code: "GQ",
        name: "Equatorial Guinea",
    },
    {
        uuid: "2e156e16-9608-4924-b473-5941dbcd0a56",
        code: "GR",
        name: "Greece",
    },
    {
        uuid: "6e782e12-fa9b-424c-bd7d-b26159c4c102",
        code: "GS",
        name: "South Georgia and the South Sandwich Islands",
    },
    {
        uuid: "025f34ae-9cd6-4511-a036-f164f2901d47",
        code: "GT",
        name: "Guatemala",
    },
    {
        uuid: "0bcc9851-a3de-46c7-9616-f472b9b3b9c4",
        code: "GU",
        name: "Guam",
    },
    {
        uuid: "970aa241-e570-448f-8e5e-063e8887a77d",
        code: "GW",
        name: "Guinea-Bissau",
    },
    {
        uuid: "e79ca224-974e-45d7-bf34-563ff2fbc95f",
        code: "GY",
        name: "Guyana",
    },
    {
        uuid: "f22ab26f-fcbf-4cd7-b206-cde94b3a7e1b",
        code: "GZ",
        name: "Gaza Strip",
    },
    {
        uuid: "011f7fb0-44f5-4f8f-a60a-358d8cf60102",
        code: "HK",
        name: "Hong Kong",
    },
    {
        uuid: "d07e6d89-2144-4595-b70b-63565309f21a",
        code: "HM",
        name: "Heard Island and McDonald Islands",
    },
    {
        uuid: "30a6e0fc-39e4-40e5-9286-63d3bbfcd52c",
        code: "HN",
        name: "Honduras",
    },
    {
        uuid: "7e59e1e8-5466-4dbe-8810-8318b374fddc",
        code: "HR",
        name: "Croatia",
    },
    {
        uuid: "992ca337-6279-455f-bcbe-95cc9f47045c",
        code: "HT",
        name: "Haiti",
    },
    {
        uuid: "7b60435f-a69a-4ad5-8446-35133b8424c1",
        code: "HU",
        name: "Hungary",
    },
    {
        uuid: "06fd7d6e-702c-44c4-8d0d-8196265bf0e5",
        code: "ID",
        name: "Indonesia",
    },
    {
        uuid: "6cf498bb-f249-4839-839f-ad7a2db50b8b",
        code: "IE",
        name: "Ireland",
    },
    {
        uuid: "58971a5f-fe3b-46c2-a451-2c5fd2da374f",
        code: "IL",
        name: "Israel",
    },
    {
        uuid: "a7d16e99-2d40-4bb0-8bf2-d9f85bc55336",
        code: "IM",
        name: "Isle of Man",
    },
    {
        uuid: "3c2daa23-1c37-4279-a413-e3d693d3b6dc",
        code: "IN",
        name: "India",
    },
    {
        uuid: "2acee30a-f2c5-4ac1-9e9c-cac8df702c60",
        code: "IO",
        name: "British Indian Ocean Territory",
    },
    {
        uuid: "cb7bf511-eb31-4bb8-ba90-d11b577333dc",
        code: "IQ",
        name: "Iraq",
    },
    {
        uuid: "f34d6eb2-2930-4e12-b83c-7415d7f2cb9f",
        code: "IR",
        name: "Iran",
    },
    {
        uuid: "5401c204-7f98-4b67-9c6e-a91afacad25b",
        code: "IS",
        name: "Iceland",
    },
    {
        uuid: "dabf68c8-5586-495a-bd6f-5d394a272e24",
        code: "IT",
        name: "Italy",
    },
    {
        uuid: "b3df2861-3f2a-45f4-900d-384cef68d313",
        code: "JE",
        name: "Jersey",
    },
    {
        uuid: "fcd1e919-44a8-4b28-b5a5-df1e10bd3693",
        code: "JM",
        name: "Jamaica",
    },
    {
        uuid: "7efb5110-716f-4ac4-869d-65260f471e83",
        code: "JO",
        name: "Jordan",
    },
    {
        uuid: "c55fdf22-b5fc-4523-8683-985a859ac014",
        code: "JP",
        name: "Japan",
    },
    {
        uuid: "834bb98a-5066-4a9d-99b5-f12d26993405",
        code: "KE",
        name: "Kenya",
    },
    {
        uuid: "027bf99c-f80d-42cd-9190-15c5861d5c3f",
        code: "KG",
        name: "Kyrgyzstan",
    },
    {
        uuid: "175a234d-0a04-48ca-a93b-bbc0c4cee951",
        code: "KH",
        name: "Cambodia",
    },
    {
        uuid: "5fd5cdf4-e079-46da-b1c5-9d0f04c1b778",
        code: "KI",
        name: "Kiribati",
    },
    {
        uuid: "39660311-1d77-45c4-b311-6f716df7846a",
        code: "KM",
        name: "Comoros",
    },
    {
        uuid: "1c81bc78-14a7-4e08-a8fc-86b7b6fd4613",
        code: "KN",
        name: "Saint Kitts and Nevis",
    },
    {
        uuid: "c5b3edc2-97a9-4884-b877-eee8d9d04a1c",
        code: "KP",
        name: "North Korea",
    },
    {
        uuid: "d68e8beb-49a8-4a18-b87c-68628403fa43",
        code: "KR",
        name: "South Korea",
    },
    {
        uuid: "e6dfbb18-235b-4cca-89fd-53fb1a82491d",
        code: "KW",
        name: "Kuwait",
    },
    {
        uuid: "599d28dc-efb0-4938-8d6b-f1803ef68963",
        code: "KY",
        name: "Cayman Islands",
    },
    {
        uuid: "20caf719-e2fe-418e-bc65-45bd9b117791",
        code: "KZ",
        name: "Kazakhstan",
    },
    {
        uuid: "cff4756f-fab0-4097-a2d5-9c2d3c977be9",
        code: "LA",
        name: "Laos",
    },
    {
        uuid: "a66db9ba-851a-4a7d-8c81-5b23275b3fad",
        code: "LB",
        name: "Lebanon",
    },
    {
        uuid: "e4a68e2f-a560-41a9-b52b-c86152e83856",
        code: "LC",
        name: "Saint Lucia",
    },
    {
        uuid: "60f63b8c-8e8a-4851-972b-d2beb28cf361",
        code: "LI",
        name: "Liechtenstein",
    },
    {
        uuid: "a6df97ce-8ac0-424f-b3b6-efd1357ee2ad",
        code: "LK",
        name: "Sri Lanka",
    },
    {
        uuid: "8dd189f4-c877-43ea-b094-272103f6b0f8",
        code: "LR",
        name: "Liberia",
    },
    {
        uuid: "c6513bce-0788-4e29-b9ed-96c29604d4bd",
        code: "LS",
        name: "Lesotho",
    },
    {
        uuid: "e5ec5eee-6506-4ec4-975d-29fdaa39ab39",
        code: "LT",
        name: "Lithuania",
    },
    {
        uuid: "489db4a8-9701-4320-bbe4-531429c06d68",
        code: "LU",
        name: "Luxembourg",
    },
    {
        uuid: "7a344374-a10d-44b2-bf25-4ffd7e1e7106",
        code: "LV",
        name: "Latvia",
    },
    {
        uuid: "82d53cc6-1fde-4c14-9f41-ce678f4bb87a",
        code: "LY",
        name: "Libya",
    },
    {
        uuid: "003782b0-9460-4358-a9d8-09eefa243b36",
        code: "MA",
        name: "Morocco",
    },
    {
        uuid: "619fe592-fc9b-4d47-8bcc-85a6e6a69ee9",
        code: "MC",
        name: "Monaco",
    },
    {
        uuid: "204ea061-4441-421a-a70c-5159802ec9bd",
        code: "MD",
        name: "Moldova",
    },
    {
        uuid: "8636081a-5cdf-4ec5-a0df-c6a3b20cb61d",
        code: "ME",
        name: "Montenegro",
    },
    {
        uuid: "0a8e91d7-d664-41a3-8082-8322d8aaa3e4",
        code: "MG",
        name: "Madagascar",
    },
    {
        uuid: "b51bafa2-5b2f-4a97-a78c-1747cf00508b",
        code: "MH",
        name: "Marshall Islands",
    },
    {
        uuid: "c5eed16f-5cfa-45c9-b9e5-eeb8bc3e5655",
        code: "MK",
        name: "Macedonia [FYROM]",
    },
    {
        uuid: "521e3658-9926-4198-b4ef-51728ee52a70",
        code: "ML",
        name: "Mali",
    },
    {
        uuid: "a91e6273-3a40-4b8a-b560-6ad9c08af635",
        code: "MM",
        name: "Myanmar [Burma]",
    },
    {
        uuid: "89e997bb-8b62-42c0-b5fb-ac63e46ada9a",
        code: "MN",
        name: "Mongolia",
    },
    {
        uuid: "60faaa76-c3f9-49b8-a2b9-45b77bd56155",
        code: "MO",
        name: "Macau",
    },
    {
        uuid: "c4676d76-f374-4a2f-8b86-2cdd73423bea",
        code: "MP",
        name: "Northern Mariana Islands",
    },
    {
        uuid: "649d6551-ed47-485b-aae0-e339c6b3b9d2",
        code: "MQ",
        name: "Martinique",
    },
    {
        uuid: "56be5a5b-d7bf-4af6-b0cf-4c36c220e49c",
        code: "MR",
        name: "Mauritania",
    },
    {
        uuid: "91324f9c-9730-48e5-91f4-a93a1eb0de4a",
        code: "MS",
        name: "Montserrat",
    },
    {
        uuid: "a12eff46-437d-4bb6-be36-9e85ff6df6cc",
        code: "MT",
        name: "Malta",
    },
    {
        uuid: "f286ce1e-0940-413f-b097-c6805bb51e67",
        code: "MU",
        name: "Mauritius",
    },
    {
        uuid: "34f88e44-83bd-4ac0-963e-4ab2a5c4088f",
        code: "MV",
        name: "Maldives",
    },
    {
        uuid: "34394ade-d91b-4574-b71b-9e85018f4938",
        code: "MW",
        name: "Malawi",
    },
    {
        uuid: "5590ce19-e20d-4aeb-b180-b24a7b82a237",
        code: "MX",
        name: "Mexico",
    },
    {
        uuid: "3b5f332b-17a8-4e3a-88d5-50e2107c5705",
        code: "MY",
        name: "Malaysia",
    },
    {
        uuid: "b0de0479-1c19-4675-b3fe-9da64c7860b4",
        code: "MZ",
        name: "Mozambique",
    },
    {
        uuid: "d8c9cda8-1a63-448b-a10e-143df44734e8",
        code: "NA",
        name: "Namibia",
    },
    {
        uuid: "a6985dd3-1ef4-4059-aefa-9949c4baec9c",
        code: "NC",
        name: "New Caledonia",
    },
    {
        uuid: "eb7c365c-2e60-4baa-82bb-c088e8c83eee",
        code: "NE",
        name: "Niger",
    },
    {
        uuid: "44e9fecb-892e-42d8-96e9-c44695b12a7d",
        code: "NF",
        name: "Norfolk Island",
    },
    {
        uuid: "686bacc3-4a69-4514-bd04-413d24a3e6e4",
        code: "NG",
        name: "Nigeria",
    },
    {
        uuid: "a85ab5cf-9c42-402b-b8ce-8b1b75fa5a01",
        code: "NI",
        name: "Nicaragua",
    },
    {
        uuid: "b11404a8-65ec-42fc-b9da-9c0f63fe2bf2",
        code: "NL",
        name: "Netherlands",
    },
    {
        uuid: "43b9477a-6fc0-49ff-8163-04d325e01337",
        code: "NO",
        name: "Norway",
    },
    {
        uuid: "339a5ae8-9708-4fc9-b322-a347fead6fbc",
        code: "NP",
        name: "Nepal",
    },
    {
        uuid: "9ec8871e-c862-4a56-aac0-ff22cb468d4b",
        code: "NR",
        name: "Nauru",
    },
    {
        uuid: "0af179b1-94a0-415d-bc67-758c9a395293",
        code: "NU",
        name: "Niue",
    },
    {
        uuid: "1342e618-8599-49c5-9255-b93cb6d8a939",
        code: "NZ",
        name: "New Zealand",
    },
    {
        uuid: "2564e3f2-3002-4daf-9fab-b4a112876dba",
        code: "OM",
        name: "Oman",
    },
    {
        uuid: "c577e428-e370-4508-8e04-c09f157aac07",
        code: "PA",
        name: "Panama",
    },
    {
        uuid: "31dd3fba-3410-456e-a14b-b74cbd46ac31",
        code: "PE",
        name: "Peru",
    },
    {
        uuid: "b594ee12-194c-4649-9449-7fa19cc7eec6",
        code: "PF",
        name: "French Polynesia",
    },
    {
        uuid: "120e0c7b-8e3e-4b7a-be35-5c83938180e6",
        code: "PG",
        name: "Papua New Guinea",
    },
    {
        uuid: "713f60b6-e029-4c1f-9fdb-93f944715a72",
        code: "PH",
        name: "Philippines",
    },
    {
        uuid: "dd110a6c-55fc-48f4-837a-318f1b0e1643",
        code: "PK",
        name: "Pakistan",
    },
    {
        uuid: "72030c6e-0298-4c86-95ff-8746f410fec9",
        code: "PL",
        name: "Poland",
    },
    {
        uuid: "6bf5391b-d673-48e7-9df7-04d59c3d9166",
        code: "PM",
        name: "Saint Pierre and Miquelon",
    },
    {
        uuid: "e5d8c2c1-bc42-4265-8955-f3bb98cf71da",
        code: "PN",
        name: "Pitcairn Islands",
    },
    {
        uuid: "b92117e8-5f0f-48ba-94e3-076002be9b6f",
        code: "PR",
        name: "Puerto Rico",
    },
    {
        uuid: "9ee248a0-c73d-4af5-bc0a-4df1151b88ff",
        code: "PS",
        name: "Palestinian Territories",
    },
    {
        uuid: "18a3c33b-5e3f-4bb2-b6e7-c9d65becabb9",
        code: "PT",
        name: "Portugal",
    },
    {
        uuid: "cb1c894a-cb66-4af6-965a-528dcabbc7f4",
        code: "PW",
        name: "Palau",
    },
    {
        uuid: "b08d796c-0945-43a7-b4ec-e8c74aa36dbe",
        code: "PY",
        name: "Paraguay",
    },
    {
        uuid: "8249adcf-8555-4e7d-a848-17c49b390e99",
        code: "QA",
        name: "Qatar",
    },
    {
        uuid: "057c989b-cc4f-4efc-8827-fca4c3584651",
        code: "RE",
        name: "Réunion",
    },
    {
        uuid: "33082340-f99a-4d99-a07e-9d741b817670",
        code: "RO",
        name: "Romania",
    },
    {
        uuid: "0e27beb8-bd72-46d4-851a-cfecda9b43a4",
        code: "RS",
        name: "Serbia",
    },
    {
        uuid: "3c03bcd7-293e-414f-ac75-7d30ff15abf4",
        code: "RU",
        name: "Russia",
    },
    {
        uuid: "c0407976-62e0-42ce-9729-c971db9794d3",
        code: "RW",
        name: "Rwanda",
    },
    {
        uuid: "7620d6f7-4986-47e0-a033-6bb7d5c920b4",
        code: "SA",
        name: "Saudi Arabia",
    },
    {
        uuid: "295d7d03-cfd1-42aa-8c8d-8138573de898",
        code: "SB",
        name: "Solomon Islands",
    },
    {
        uuid: "7b7762f0-b330-4ba6-a6b7-1dbb465c4669",
        code: "SC",
        name: "Seychelles",
    },
    {
        uuid: "be6ac64c-af33-4312-8253-b44de15cb0a4",
        code: "SD",
        name: "Sudan",
    },
    {
        uuid: "f1a1868a-3718-4c9f-ad7c-b6762e9181d2",
        code: "SE",
        name: "Sweden",
    },
    {
        uuid: "a2fc46ec-a8d1-450c-b427-dbf9f4bae1d8",
        code: "SG",
        name: "Singapore",
    },
    {
        uuid: "a51aa51c-219b-4898-8033-6b1000f2fbf9",
        code: "SH",
        name: "Saint Helena",
    },
    {
        uuid: "82d8decd-d6be-423d-bbe1-eba980be72c3",
        code: "SI",
        name: "Slovenia",
    },
    {
        uuid: "abf9095d-e818-4798-93e9-f5c6d49b1eaf",
        code: "SJ",
        name: "Svalbard and Jan Mayen",
    },
    {
        uuid: "d74d8e22-591d-4337-b5d9-bbaed964a5b1",
        code: "SK",
        name: "Slovakia",
    },
    {
        uuid: "004b024c-d8c3-40ab-8cf5-750b42bdcaa6",
        code: "SL",
        name: "Sierra Leone",
    },
    {
        uuid: "8ed8f534-745f-4f79-a40c-9ca3f88ebb33",
        code: "SM",
        name: "San Marino",
    },
    {
        uuid: "8da428be-fbca-4814-90c7-0a34f75693ca",
        code: "SN",
        name: "Senegal",
    },
    {
        uuid: "86d1d966-c6fa-4f60-93be-48df722cb4bc",
        code: "SO",
        name: "Somalia",
    },
    {
        uuid: "24e1dec8-8c31-426d-b3e4-538a58117426",
        code: "SR",
        name: "Suriname",
    },
    {
        uuid: "0cbc4628-2de6-4006-bb04-872a62198eab",
        code: "ST",
        name: "São Tomé and Príncipe",
    },
    {
        uuid: "88947f8b-af1b-452e-860e-7907a4a9c2cb",
        code: "SV",
        name: "El Salvador",
    },
    {
        uuid: "fda9172f-4678-4414-ba09-f6fd74e56453",
        code: "SY",
        name: "Syria",
    },
    {
        uuid: "c02fc23f-b7f9-40ac-9fef-3739d1e562b3",
        code: "SZ",
        name: "Swaziland",
    },
    {
        uuid: "b837493c-9372-49d3-ab71-74c0f6a68a6c",
        code: "TC",
        name: "Turks and Caicos Islands",
    },
    {
        uuid: "b0b4aa73-c65b-40a6-8b94-f4109f9b5601",
        code: "TD",
        name: "Chad",
    },
    {
        uuid: "39c77db7-1924-410a-b892-f72b94feeaab",
        code: "TF",
        name: "French Southern Territories",
    },
    {
        uuid: "74d73409-1334-40d9-9c71-f678f2ee4ec7",
        code: "TG",
        name: "Togo",
    },
    {
        uuid: "7968a084-e612-4c5f-8ca3-8aaa0e5f2393",
        code: "TH",
        name: "Thailand",
    },
    {
        uuid: "f1dff0fe-d0bb-4ae4-bb23-9cd8a9b20070",
        code: "TJ",
        name: "Tajikistan",
    },
    {
        uuid: "3b0d0c63-c53c-40df-8ad5-3b2607327c8f",
        code: "TK",
        name: "Tokelau",
    },
    {
        uuid: "d0390d3e-64bc-46ce-9789-ebe0c1a85bed",
        code: "TL",
        name: "Timor-Leste",
    },
    {
        uuid: "333ac3b1-2b2f-4a04-b003-0c91964531c4",
        code: "TM",
        name: "Turkmenistan",
    },
    {
        uuid: "582e2e4d-33b3-4546-881c-5f3cea47a966",
        code: "TN",
        name: "Tunisia",
    },
    {
        uuid: "2ffe8297-6278-490b-8d37-72798e8960aa",
        code: "TO",
        name: "Tonga",
    },
    {
        uuid: "7b387077-9589-4000-a468-df3b127bc99c",
        code: "TR",
        name: "Turkey",
    },
    {
        uuid: "0ccff9bf-e9f4-430a-9c3b-c7eee6a0df56",
        code: "TT",
        name: "Trinidad and Tobago",
    },
    {
        uuid: "2f01ba0f-d369-46d6-82ab-cc2502d58906",
        code: "TV",
        name: "Tuvalu",
    },
    {
        uuid: "2c53c90e-f0fe-4903-ad14-f19e9bdd77da",
        code: "TW",
        name: "Taiwan",
    },
    {
        uuid: "07fc38a9-8181-4da0-b4a0-cb1dea16dfb3",
        code: "TZ",
        name: "Tanzania",
    },
    {
        uuid: "78d8c621-c497-4c2b-b2b0-0606b3dfd6c4",
        code: "UA",
        name: "Ukraine",
    },
    {
        uuid: "05321bbf-088a-4f27-bd5e-3c15c0a9d5ca",
        code: "UG",
        name: "Uganda",
    },
    {
        uuid: "6eaf0a84-08b8-436a-922f-c8a8db23c475",
        code: "UM",
        name: "U.S. Minor Outlying Islands",
    },
    {
        uuid: "ff15b3f6-ce51-4558-856f-c8bf996b0577",
        code: "UY",
        name: "Uruguay",
    },
    {
        uuid: "90b69296-22f1-43d8-98e9-bfcc2f10e22c",
        code: "UZ",
        name: "Uzbekistan",
    },
    {
        uuid: "6f7db696-a7ef-40d2-806c-afab149eb1f4",
        code: "VA",
        name: "Vatican City",
    },
    {
        uuid: "64067324-3a63-43ea-b670-9baab170426d",
        code: "VC",
        name: "Saint Vincent and the Grenadines",
    },
    {
        uuid: "b873429f-0c80-451c-a076-0948497ae5d1",
        code: "VE",
        name: "Venezuela",
    },
    {
        uuid: "a51bae5d-094e-4cba-b9a0-7f18889aa2d4",
        code: "VG",
        name: "British Virgin Islands",
    },
    {
        uuid: "a8003754-9e84-4ba1-aa13-5237f8308c90",
        code: "VI",
        name: "U.S. Virgin Islands",
    },
    {
        uuid: "4cdfdbc3-9bc1-4217-94d9-71cc87621319",
        code: "VN",
        name: "Vietnam",
    },
    {
        uuid: "824effe3-b762-444a-b9ea-d4afc20ed118",
        code: "VU",
        name: "Vanuatu",
    },
    {
        uuid: "16499727-ace3-484d-86c5-dba811e14e2e",
        code: "WF",
        name: "Wallis and Futuna",
    },
    {
        uuid: "8f89d58e-670d-4ef2-9183-676c52ab8ef3",
        code: "WS",
        name: "Samoa",
    },
    {
        uuid: "5d4f2508-36ab-4256-93c2-c3d9df7787d6",
        code: "XK",
        name: "Kosovo",
    },
    {
        uuid: "5ff85d20-9176-4ba7-ba0b-50d8fa7916d2",
        code: "YE",
        name: "Yemen",
    },
    {
        uuid: "72c120cc-6e2b-4d42-b937-0b9a6b85d938",
        code: "YT",
        name: "Mayotte",
    },
    {
        uuid: "09d78070-2702-4a7b-9f42-96978374baa2",
        code: "ZA",
        name: "South Africa",
    },
    {
        uuid: "a1582240-f2b2-4053-b09c-e0fa895a3c3c",
        code: "ZM",
        name: "Zambia",
    },
    {
        uuid: "3863989d-c844-440d-856d-f8700b9e6239",
        code: "ZW",
        name: "Zimbabwe",
    },
];
