import {
  personalEventName,
  PersonalEventName,
  TourEventType,
  tourEventTypes,
} from "@articulate/shared";
import { TFunction } from "i18next";
import { z, ZodType, ZodTypeAny } from "zod";
import { TravelType } from "./TravelEventForm";
import { format } from "date-fns";
import { countriesOptions } from "@/lib/countries";
import { phoneSchema } from "@/modules/Profile/schemas/phoneSchema";

type TranslationArg = TFunction<["forms"] | ["common", "dashboard", "forms"]>;

export type CreateFormSchemaT = z.ZodObject<
  { eventName: z.ZodLiteral<TourEventType>; form: z.ZodTypeAny },
  "strip",
  z.ZodTypeAny,
  object,
  object
>;

export type FormsSchemaT = z.ZodObject<
  { forms: z.ZodArray<CreateFormSchemaT, "many"> },
  "strip",
  z.ZodTypeAny,
  { forms: ZodType & { eventName: string; form: string } }
>;

export const getVisibilityOptions = (t: TranslationArg) => [
  {
    value: "true",
    label: t("forms:visibleToAll"),
  },
  {
    value: "false",
    label: t("forms:assignedOnly"),
  },
];

export const getVisibilityOptionsByValue = (t: TranslationArg) => {
  const optionsArray = getVisibilityOptions(t);
  return optionsArray.reduce(
    (acc, option) => {
      acc[option.value] = option.label;
      return acc;
    },
    {} as Record<string, string>
  );
};

export const getFormattedEventType = (
  eventType: TourEventType | PersonalEventName
) => {
  switch (eventType) {
    case "TRAVEL":
      return "Travel";
    case "HOTEL_STAY":
      return "Hotel stay";
    case "GLAM":
      return "Glam";
    case "LOAD_IN":
      return "Load-in";
    case "PRODUCTION":
      return "Production";
    case "PROMO":
      return "Promo";
    case "REHEARSAL":
      return "Rehearsal";
    case "SCHEDULE_ITEM":
      return "Schedule item";
    case "SHOW":
      return "Show";
    case "PERSONAL":
      return "Personal";
  }
};

const getDateSchema = (t: TranslationArg) =>
  z.date({
    errorMap: () => ({
      message: t("common:thisFieldIsRequired"),
    }),
  });

export const MemberOrGroup = z.object({
  id: z.string(),
  userId: z.string(),
  roleId: z.string(),
  email: z.string(),
});

const EventTypes = z.enum([...tourEventTypes, personalEventName]);

const getSharedTravelFormSchema = (t: TranslationArg) =>
  z.object({
    eventName: z.string().min(1),
    eventType: EventTypes,
    departureOrigin: z.string().min(1),
    departureDate: getDateSchema(t),
    departureTime: z.string().min(1),
    arrivalDate: getDateSchema(t),
    arrivalTime: z.string().min(1),
    destination: z.string().min(1),
    destinationTimezone: z.string().optional(),
    countryId: z.string().min(1),
    id: z.string().optional(),
    membersAndGroups: z.array(MemberOrGroup),
    visibility: z.string().optional(),
    spectators: z.array(MemberOrGroup),
    city: z.string().optional(),
    state: z.string().optional(),
    postalCode: z.string().optional(),
    address: z.string().optional(),
    timezoneId: z.string(),
    nextDay: z.boolean().optional(),
  });

export const getHotelFormSchema = (t: TranslationArg) =>
  z.object({
    eventName: z.string().min(1),
    eventType: EventTypes,
    name: z.string().min(1),
    address: z.string().min(1),
    city: z.string().min(1),
    state: z.string().min(1),
    postalCode: z.string().min(1),
    countryId: z.string().min(1),
    checkIn: getDateSchema(t),
    checkOut: getDateSchema(t),
    visibility: z.string().optional(),
    phoneNumber: phoneSchema,
    email: z.string().email().or(z.string().optional()),
    website: z.string().optional(),
    notes: z.string().optional(),
    keyContacts: z.string().optional(),
    facilities: z.array(z.string()),
    id: z.string().optional(),
    membersAndGroups: z.array(MemberOrGroup),
    spectators: z.array(MemberOrGroup),
    timezoneId: z.string(),
  });

const flightPlaceSchema = z.object({
  codeIcao: z.string(),
  timezone: z.string(),
  name: z.string(),
  city: z.string(),
});

export const getExtendedSharedTravelSchema = (t: TranslationArg) =>
  getSharedTravelFormSchema(t).merge(
    z.object({
      travelType: z.enum(["ground", "air", "rail", "sea"]),
      // air
      departureAirportCode: z.string().min(1).optional(),
      flightNumber: z.number().min(1).optional(),
      arrivalAirportCode: z.string().min(1).optional(),
      airline: z.string().optional(),
      departureTimezoneId: z.string().optional(),
      arrivalTimezoneId: z.string().optional(),
      flightInfo: z
        .object({
          identIcao: z.string(),
          identIata: z.string(),
          scheduledIn: z.string(),
          scheduledOut: z.string(),
          origin: flightPlaceSchema,
          destination: flightPlaceSchema,
        })
        .nullable()
        .optional(),
      // train
      trainNumber: z.string().min(1).optional(),
      // sea
      sealNumber: z.string().min(1).optional(),
    })
  );

export const getGenericFormSchema = (t: TranslationArg) =>
  z.object({
    eventName: z.string().min(1),
    eventType: EventTypes,
    address: z.string().min(1),
    countryId: z.string().min(1),
    startDate: getDateSchema(t),
    startTime: z.string().min(1),
    endDate: getDateSchema(t),
    endTime: z.string().min(1),
    notes: z.string().optional(),
    visibility: z.string().optional(),
    id: z.string().optional(),
    membersAndGroups: z.array(MemberOrGroup),
    spectators: z.array(MemberOrGroup),
    city: z.string().optional(),
    state: z.string().optional(),
    postalCode: z.string().optional(),
    timezoneId: z.string(),
    nextDay: z.boolean().optional(),
  });

export const getPersonalFormSchema = (t: TranslationArg) =>
  getGenericFormSchema(t);

export const getLoadInFormSchema = (t: TranslationArg) =>
  getGenericFormSchema(t).merge(
    z.object({
      requiredEquipment: z.string().min(1),
    })
  );

export const getPromoFormSchema = (t: TranslationArg) =>
  getGenericFormSchema(t).merge(
    z.object({
      mediaOutlet: z.string().min(1),
      interviewerName: z.string().min(1),
    })
  );

const getFormSchema = (schemaForm: () => ZodTypeAny) => {
  if (!schemaForm) return {};

  return schemaForm();
};

export const getEventFormSchemas = (
  t: TranslationArg,
  eventType?: TourEventType | PersonalEventName
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
): FormsSchemaT | object => {
  switch (eventType) {
    case "TRAVEL": {
      return getFormSchema(() =>
        getExtendedSharedTravelSchema(t)
      ) as FormsSchemaT;
    }
    case "HOTEL_STAY": {
      return getFormSchema(() => getHotelFormSchema(t)) as FormsSchemaT;
    }
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SCHEDULE_ITEM":
    case "SHOW": {
      return getFormSchema(() => getGenericFormSchema(t)) as FormsSchemaT;
    }
    case "PROMO": {
      return getFormSchema(() => getPromoFormSchema(t)) as FormsSchemaT;
    }
    case "PERSONAL": {
      return getFormSchema(() => getPersonalFormSchema(t)) as FormsSchemaT;
    }
    case "LOAD_IN": {
      return getFormSchema(() => getLoadInFormSchema(t)) as FormsSchemaT;
    }
  }
};

export const getDefaultTravelFields = (
  travelType: TravelType,
  selectedDate: Date
) => {
  const dayAfterSelectedDate = new Date(selectedDate);
  dayAfterSelectedDate.setDate(selectedDate.getDate() + 1);
  dayAfterSelectedDate.setHours(23, 59);

  const formDRY = {
    address: "",
    arrivalDate: dayAfterSelectedDate,
    arrivalTime: format(dayAfterSelectedDate, "hh:mm"),
    city: "",
    countryId: countriesOptions[0].value,
    departureDate: selectedDate,
    departureOrigin: "",
    departureTime: format(selectedDate, "hh:mm"),
    destination: "",
    destinationTimezone: "",
    eventName: "Travel",
    eventType: "TRAVEL",
    postalCode: "",
    spectators: [],
    state: "",
    timezoneId: "",
    visibility: "true",
    nextDay: false,
  };

  switch (travelType) {
    case "ground":
      return {
        travelType: "ground" as const,
        ...formDRY,
        membersAndGroups: [],
      };
    case "air":
      return {
        travelType: "air" as const,
        ...formDRY,
        departureAirportCode: "",
        flightNumber: undefined,
        arrivalAirportCode: "",
        membersAndGroups: [],
      };
    case "rail":
      return {
        travelType: "rail" as const,
        ...formDRY,
        trainNumber: "",
        membersAndGroups: [],
      };
    case "sea":
      return {
        travelType: "sea" as const,
        ...formDRY,
        departureAirportCode: "",
        sealNumber: "",
        membersAndGroups: [],
      };
  }
};

export const getDefaultFormFields = (
  eventType: Exclude<TourEventType | PersonalEventName, "TRAVEL">,
  tourStartsAt: Date,
  tourEndsAt: Date
) => {
  const formatedEventType = getFormattedEventType(eventType);
  const formBase = {
    address: "",
    countryId: "",
    eventName: formatedEventType,
    eventType: eventType,
    membersAndGroups: [],
    notes: "",
    spectators: [],
    visibility: "true",
    nextDay: false,
  };
  const formDRY = {
    ...formBase,
    endDate: tourEndsAt,
    endTime: "06:00",
    startDate: tourStartsAt,
    startTime: "05:00",
  };

  switch (eventType) {
    case "HOTEL_STAY":
      return {
        ...formBase,
        checkIn: tourStartsAt,
        checkOut: tourEndsAt,
        city: "",
        email: "",
        facilities: [],
        keyContacts: "",
        name: "",
        phoneNumber: "",
        postalCode: "",
        state: "",
        website: "",
      };
    case "LOAD_IN":
      return {
        ...formDRY,
        requiredEquipment: "",
      };
    case "PROMO":
      return {
        ...formDRY,
        mediaOutlet: "",
        interviewerName: "",
      };
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SCHEDULE_ITEM":
    case "SHOW":
    case "PERSONAL":
      return {
        ...formDRY,
      };
  }
};
