import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

type QueryOptions = {
  enabled?: boolean;
  filter?: {
    artistId?: string;
  };
};

export const workspaceArchivedToursQueryOptions = ({
  enabled,
  filter,
}: QueryOptions) =>
  queryOptions({
    queryKey: ["workspaceArchivedTours", filter?.artistId],
    queryFn: async () => {
      const response = await API.tours.toursControllerFindAll({
        archived: true,
        artistId: filter?.artistId,
      });
      return response.data;
    },
    enabled,
  });

export const useArchivedTours = (args: QueryOptions) => {
  return useQuery(workspaceArchivedToursQueryOptions(args));
};

export const useArchivedToursSuspense = (args: QueryOptions) => {
  return useSuspenseQuery(workspaceArchivedToursQueryOptions(args));
};
