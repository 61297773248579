import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

const SIXTY_SECONDS = 60000;

export const personalEventsQueryOptions = queryOptions({
  queryKey: ["personalEvents"],
  queryFn: async () => {
    const response = await API.personalEvents.personalEventsControllerFindAll();
    return response.data;
  },
  refetchInterval: SIXTY_SECONDS,
});

export const usePersonalEvents = () => {
  return useQuery(personalEventsQueryOptions);
};

export const usePersonalEventsSuspense = () => {
  return useSuspenseQuery(personalEventsQueryOptions);
};
