import { useMemo } from "react";
import { useTour as useTourRequest } from "@/api/queries/useTour";
import { useTourDays } from "@/api/queries/useTourDays";
import { useTourEvents } from "@/api/queries/useTourEvents";
import {
  getDaysForIntervalIgnoringTimezone,
  toApiDateFormat,
} from "@/lib/dates";

export const useTour = (tourId: string) => {
  const { data: tour } = useTourRequest(tourId);
  const { data } = useTourDays(tourId);
  const { data: tourEvents } = useTourEvents(tourId);
  const tourDaysJSON = JSON.stringify(data?.tourDays);

  const tourDays = useMemo(
    () =>
      getDaysForIntervalIgnoringTimezone(
        tour?.startsAt || "",
        tour?.endsAt || ""
      ).map((day) => {
        const tourDay = data?.tourDays?.find(
          (tourDay) => tourDay.date === toApiDateFormat(day)
        );

        return {
          date: day,
          tourDay: tourDay,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tour?.startsAt, tour?.endsAt, tourDaysJSON, data?.tourDays?.length]
  );

  return { tourDays, tourEvents, tour };
};
