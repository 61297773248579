import {
  PhoneInput as PhoneInputComp,
  PhoneInputProps,
} from "react-international-phone";
import { Control, FieldValues, Path } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { LabelText } from "../Typography/Typography";
import { Checkbox } from "../ui/checkbox";

type Props<T extends FieldValues> = PhoneInputProps & {
  control: Control<T>;
  name: Path<T>;
  label: string;
  withNotApplicable?: boolean;
};

export function PhoneInput<T extends FieldValues>({
  control,
  label,
  name,
  withNotApplicable,
  ...rest
}: Props<T>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <span className="group">
          <FormItem>
            {label && (
              <FormLabel className="cursor-pointer">
                <LabelText>{label}</LabelText>
              </FormLabel>
            )}
            <FormControl>
              <PhoneInputComp
                onChange={(phone, { country }) => {
                  const areaCode = `+${country.dialCode}`;
                  const phoneNumberCopy = phone;
                  const phoneNumber = phoneNumberCopy.replace(areaCode, "");

                  field.onChange({
                    phoneNumber: phoneNumber,
                    countryCode: country.iso2,
                    areaCode,
                  });
                }}
                value={field.value.areaCode + field.value.phoneNumber}
                defaultCountry={field.value.countryCode || "us"}
                countrySelectorStyleProps={{
                  flagClassName: "bg-surface-one w-4 h-3 ml-2",
                  dropdownStyleProps: {
                    listItemFlagClassName: "w-4 h-3 mr-1",
                    listItemCountryNameClassName: "mr-1",
                    listItemClassName: "hover:text-black",
                    className:
                      "rounded outline-0 bg-surface-one gap-1 text-foreground-one",
                  },
                  buttonClassName:
                    "bg-surface-one border-r-0 !rounded-bl-xl border-none !rounded-tl-xl hover:bg-surface-two",
                }}
                inputProps={{ id: label }}
                inputClassName="dark:!bg-surface-one !bg-surface-two !text-foreground-two w-full !border-none !rounded-tr-xl !rounded-br-xl !pl-1 !pr-2"
                className="hover:!border-border-hover border-border group-hover:border-border-hover rounded-xl border"
                {...rest}
              />
            </FormControl>
            {withNotApplicable && (
              <div className="flex items-center gap-2 pt-2">
                <Checkbox
                  checked={field.value === "N/A"}
                  onCheckedChange={(checked) => {
                    field.onChange(checked ? "N/A" : "");
                  }}
                />
                <FormLabel className="w-full cursor-pointer">N/A</FormLabel>
              </div>
            )}
            <FormMessage />
          </FormItem>
        </span>
      )}
    />
  );
}
