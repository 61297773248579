import { useCalendar } from "@/hooks/useCalendar";
import { SheetClose, SheetContent } from "../ui/sheet";
import { useTranslation } from "react-i18next";
import { Body, Header, LabelText } from "../Typography/Typography";
import { Icon } from "../Icon/Icon";
import { Separator } from "../ui/separator";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { Calendar } from "../ui/calendar";
import { Button } from "../ui/button";
import { PopoverContent } from "../ui/popover";
import { PopoverClose } from "@radix-ui/react-popover";
import { FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import React from "react";
import { formatDate } from "@/lib/dates";
import { Input } from "../ui/input";
import { Control, FieldValues, Path } from "react-hook-form";
import { cn } from "@/lib/utils";
import { ClassValue } from "clsx";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  onDateChange: (name: Path<T>, newDate: Date) => void;
  selectedDate?: Date;
  fromDate?: Date;
  toDate?: Date;
  label?: string;
  containerClassName?: ClassValue;
};

export function DatePicker<T extends FieldValues>({
  control,
  name,
  onDateChange,
  label,
  selectedDate,
  fromDate,
  toDate,
  containerClassName,
}: Props<T>) {
  const { t } = useTranslation(["dashboard", "common"]);
  const {
    Trigger,
    Wrapper,
    isMobile,
    calendarPosition,
    daysInWeek,
    months,
    loadMoreMonths,
  } = useCalendar();

  const formatedDate = selectedDate && formatDate(selectedDate);

  return (
    <div className={cn("relative flex grow gap-4", containerClassName)}>
      <Wrapper>
        {isMobile ? (
          <SheetContent
            side="bottom"
            className="bg-surface-two borde-border flex h-[90vh] flex-col justify-between gap-2 rounded-2xl border p-4">
            <div className="flex items-center justify-between">
              <Header size="h3">{t("createTour.pickDate")} *</Header>
              <SheetClose className="border-border hover:bg-surface-one flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border">
                <Icon name="CloseIcon" />
              </SheetClose>
            </div>
            <div className="flex justify-center">
              {daysInWeek.map((day) => (
                <div
                  className="flex h-10 w-10 items-center justify-center"
                  key={day}>
                  <LabelText className="text-foreground-two">{day}</LabelText>
                </div>
              ))}
            </div>
            <Separator />
            <ScrollArea>
              <Calendar
                mode="single"
                numberOfMonths={months}
                fromDate={fromDate}
                toDate={toDate}
                classNames={{
                  head: "hidden",
                }}
                selected={selectedDate}
                onSelect={(date) => {
                  if (!date) return;
                  onDateChange(name, date);
                }}
                initialFocus
              />
              <div className="flex justify-center">
                <Button
                  variant="secondary"
                  className="mb-28 w-44"
                  onClick={loadMoreMonths}>
                  {t("common:loadMore")}
                </Button>
              </div>
              <ScrollBar orientation="vertical" />
            </ScrollArea>
            <div className="bg-surface-two absolute bottom-0 left-4 right-4 flex flex-col space-y-4 pb-4">
              <Separator />
              <Body className="text-center">
                {selectedDate ? formatedDate : t("createTour.pickDate")}
              </Body>
              <SheetClose asChild>
                <Button
                  variant="primary"
                  className="basis-full lg:basis-36"
                  disabled={!formatedDate}>
                  {t("common:apply")}
                </Button>
              </SheetClose>
            </div>
          </SheetContent>
        ) : (
          <PopoverContent
            align="end"
            side="bottom"
            className="w-auto rounded-2xl p-6 md:w-full lg:w-[calc(448px-64px)]"
            sideOffset={calendarPosition}>
            <Calendar
              mode="single"
              numberOfMonths={1}
              fromDate={fromDate}
              toDate={toDate}
              selected={selectedDate}
              onSelect={(date) => {
                if (!date) return;
                onDateChange(name, date);
              }}
              initialFocus
            />
            <div>
              <Separator className="mb-4 mt-2" />
              <div className="flex flex-col gap-4 sm:flex-row-reverse">
                <PopoverClose asChild>
                  <Button
                    variant="primary"
                    className="basis-full lg:basis-36"
                    disabled={!formatedDate}>
                    {t("common:apply")}
                  </Button>
                </PopoverClose>
                <PopoverClose asChild>
                  <Button
                    variant="secondary"
                    className="basis-full lg:basis-36">
                    {t("common:cancel")}
                  </Button>
                </PopoverClose>
              </div>
            </div>
          </PopoverContent>
        )}
        <FormField
          control={control}
          name={name}
          render={({ field, fieldState: { error } }) => (
            <FormItem className="w-full">
              {label && <FormLabel>{label}</FormLabel>}
              <div className="relative">
                <Trigger asChild>
                  <FormControl>
                    {React.cloneElement(<Input />, {
                      ...field,
                      value: field.value ? formatDate(field.value) : "",
                    })}
                  </FormControl>
                </Trigger>
                <Icon
                  name="CalendarIcon"
                  className="pointer-events-none absolute right-2 top-1/2 h-4 w-4 -translate-y-2"
                />
              </div>
              <p
                className={cn("text-error text-sm opacity-0", {
                  "opacity-100": error?.message,
                })}>
                {error?.message}
              </p>
            </FormItem>
          )}
        />
      </Wrapper>
    </div>
  );
}
