import { Control, FieldValues, Path } from "react-hook-form";
import { FormFieldController } from "./FormFieldController";
import { Input, InputProps } from "../ui/input";

export type FormInputProps<T extends FieldValues> = InputProps & {
  control: Control<T>;
  name: Path<T>;
  label: string;
  withNotApplicable?: boolean;
};

export function FormInput<T extends FieldValues>({
  control,
  label,
  name,
  placeholder,
  withNotApplicable,
  ...rest
}: FormInputProps<T>) {
  return (
    <FormFieldController
      withNotApplicable={withNotApplicable}
      control={control}
      label={label}
      name={name}>
      <Input placeholder={placeholder} {...rest} />
    </FormFieldController>
  );
}
