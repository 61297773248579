import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

const SIXTY_SECONDS = 60000;

export const tourDaysQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourDays", tourId],
    queryFn: async () => {
      const response = await API.tourDays.tourDaysControllerFindAll(tourId);
      return response.data;
    },
    enabled: !!tourId,
    refetchInterval: SIXTY_SECONDS,
  });

export const useTourDays = (tourId: string) => {
  return useQuery(tourDaysQueryOptions(tourId));
};

export const useTourDaysSuspense = (tourId: string) => {
  return useSuspenseQuery(tourDaysQueryOptions(tourId));
};
