import * as React from "react";

import { cn } from "@/lib/utils";
import { Label } from "./label";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, error, ...props }, ref) => {
    return (
      <div className="flex flex-col">
        {label ? (
          <Label htmlFor={label} className="mb-2">
            {label}
          </Label>
        ) : null}
        <textarea
          className={cn(
            "text-foreground-two border-border disabled:placeholder-border disabled:bg-border-hover disabled:border-foreground-two focus:border-foreground-one focus:text-foreground-one bg-surface-one ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring hover:border-border-hover flex h-10 min-h-24 w-full rounded-xl border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            { "border-error hover:border-error focus:border-error": error },
            className
          )}
          ref={ref}
          {...props}
        />
        {error ? (
          <p className={cn("text-error mt-2 text-sm")}>{error}</p>
        ) : null}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
