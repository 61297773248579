import { cn } from "@/lib/utils";
import { Icon } from "../Icon/Icon";
import { LabelText } from "../Typography/Typography";
import { ClassValue } from "clsx";

type Props = {
  value: string;
  onDelete: () => void;
  className?: ClassValue;
};

export function SelectedBadge({ value, onDelete, className }: Props) {
  return (
    <li
      className={cn(
        "bg-surface-two border-border flex items-center gap-1 rounded-lg border px-2 py-1",
        className
      )}>
      <LabelText className="text-foreground-one">{value}</LabelText>
      <Icon
        name="CloseIcon"
        className="h-4 w-4 cursor-pointer"
        onClick={onDelete}
      />
    </li>
  );
}
