import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { UpdatePersonalEventDto } from "../base-api";

export type UpdatePersonalEventArgs = {
  eventId: string;
  data: UpdatePersonalEventDto;
};

export const useUpdatePersonalEvent = () => {
  return useMutation({
    mutationKey: ["updatePersonalEvent"],
    mutationFn: async ({ eventId, data }: UpdatePersonalEventArgs) => {
      const response = await API.personalEvents.personalEventsControllerUpdate(
        eventId,
        data
      );

      return response.data;
    },
  });
};
