import { ArtiSelect } from "../ArtiSelect/ArtiSelect";
import { useFormContext } from "react-hook-form";
import { countriesList } from "@articulate/shared";
import { useTranslation } from "react-i18next";
import { useGooglePlaces } from "@/api/queries/useGooglePlaces";
import { useTimezone } from "@/api/mutations/useTimezone";
import { useState } from "react";

export function GooglePlacesSearchbox({
  label,
  mainField,
}: {
  label: string;
  mainField?: string;
}) {
  const { t } = useTranslation(["dashboard"]);
  const [query, setQuery] = useState("");
  const { data, isLoading } = useGooglePlaces(query);
  const { mutateAsync: getTimezone } = useTimezone();
  const { setValue, getValues, formState } = useFormContext();

  const formErrors = formState.errors;
  const formErrorKeys = Object.keys(formErrors || {});

  const errors: Record<string, string> = {};
  const handledFields = [
    "address",
    "city",
    "state",
    "countryId",
    "name",
    "postalCode",
    "timezoneId",
  ];

  formErrorKeys.forEach((key) => {
    if (handledFields.includes(key)) {
      errors[key] = formErrors?.[key]?.message as string;
    }
  });

  const options =
    data?.map((place) => ({
      label:
        place.types?.includes("point_of_interest") ||
        place.types?.includes("establishment")
          ? `${place.displayName} - ${place.name}`
          : place.name,
      value: JSON.stringify({
        latitude: place.latitude,
        longitude: place.longitude,
        id: place.id,
      }),
    })) || [];

  const placeholder =
    mainField || !getValues("state")
      ? getValues(`${mainField}`)
      : `${getValues("state")}, ${getValues("address")}`;

  return (
    <>
      <ArtiSelect
        items={options}
        label={label}
        placeholder={placeholder || "Search for a place"}
        isLoading={isLoading}
        onChange={async (value) => {
          const { latitude, longitude, id } = JSON.parse(value);
          const found = data?.find((place) => {
            return (
              place.latitude === latitude &&
              place.longitude === longitude &&
              place.id === id
            );
          });

          let timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

          try {
            const resp = await getTimezone({ latitude, longitude });
            timezoneId = resp.timeZoneId;
          } catch (e) {
            console.error(e);
          }

          setValue("timezoneId", timezoneId);
          setValue("city", found?.city || t("events.cityNotFound"));

          const country = countriesList.find(
            (country) => country.code === found?.country
          );

          if (country) {
            setValue("countryId", country.uuid);
          }

          if (found?.state || found?.displayName) {
            setValue(
              "state",
              `${found.displayName && found.displayName + ", "}${found.state}`
            );
          }

          if (found?.postalCode) {
            setValue("postalCode", found.postalCode);
          }

          if (found?.streetAddress) {
            setValue("address", found.streetAddress);
          }

          if (found?.types.includes("hotel")) {
            setValue("name", found.displayName);
          }
        }}
        async={true}
        onAsyncChange={(query) => {
          setQuery(query);
        }}
      />
      {Object.keys(errors).length > 0 && (
        <p className="text-red-500">
          Missing Properties
          <br />
          {Object.keys(errors).map((key) => (
            <>
              <span key={key}>
                {key}: {errors[key]}
              </span>
              <br />
            </>
          ))}
        </p>
      )}
    </>
  );
}

export function GooglePlacesTravelSearchbox({
  label,
  type,
}: {
  label: string;
  type: "departure" | "arrival";
}) {
  const [query, setQuery] = useState("");
  const { data, isLoading } = useGooglePlaces(query);
  const { setValue, getValues, formState } = useFormContext();
  const { mutateAsync: getTimezone } = useTimezone();

  const options =
    data?.map((place) => ({
      label:
        place.types?.includes("point_of_interest") ||
        place.types?.includes("establishment")
          ? `${place.displayName} - ${place.name}`
          : place.name,
      value: JSON.stringify({
        latitude: place.latitude,
        longitude: place.longitude,
        id: place.id,
      }),
    })) || [];

  const defaultValue =
    type === "arrival"
      ? getValues("destination")
      : getValues("departureOrigin");

  const formErrors = formState.errors;
  const formErrorKeys = Object.keys(formErrors || {});

  const errors: Record<string, string> = {};
  const handledFields = [
    "address",
    "city",
    "state",
    "countryId",
    "name",
    "postalCode",
    "timezoneId",
    "destinationTimezone",
    type === "departure" ? "departureOrigin" : "arrivalOrigin",
    type === "arrival" && "destination",
  ].filter(Boolean);

  formErrorKeys.forEach((key) => {
    if (handledFields.includes(key)) {
      errors[key] = formErrors?.[key]?.message as string;
    }
  });

  return (
    <>
      <ArtiSelect
        items={options}
        label={label}
        placeholder={defaultValue || "Search for a place"}
        isLoading={isLoading}
        onChange={async (value) => {
          const { latitude, longitude, id } = JSON.parse(value);
          const found = data?.find((place) => {
            return (
              place.latitude === latitude &&
              place.longitude === longitude &&
              place.id === id
            );
          });

          if (type === "departure") {
            let timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

            try {
              const resp = await getTimezone({ latitude, longitude });
              timezoneId = resp.timeZoneId;
            } catch (e) {
              console.error(e);
            }

            setValue("timezoneId", timezoneId);
          }

          if (type === "arrival") {
            const resp = await getTimezone({ latitude, longitude });
            setValue("destinationTimezone", resp.timeZoneId);
          }

          const country = countriesList.find(
            (country) => country.code === found?.country
          );

          if (country && type === "departure") {
            setValue("countryId", country.uuid);
          }

          if (found?.displayName && type === "departure") {
            setValue("departureOrigin", found.displayName);
          }

          if (found?.displayName && type === "arrival") {
            setValue("destination", found.displayName);
            setValue("arrivalOrigin", found.displayName);
          }

          if (found?.city && type === "departure") {
            setValue("city", found.city);
          }

          if (found?.state && type === "departure") {
            setValue("state", found.state);
          }

          if (found?.postalCode && type === "departure") {
            setValue("postalCode", found.postalCode);
          }

          if (found?.streetAddress && type === "departure") {
            setValue("address", found.streetAddress);
          }
        }}
        async={true}
        onAsyncChange={(query) => {
          setQuery(query);
        }}
      />
      {Object.keys(errors).length > 0 && (
        <p className="text-red-500">
          Missing Properties
          <br />
          {Object.keys(errors).map((key) => (
            <>
              <span key={key}>
                {key}: {errors[key]}
              </span>
              <br />
            </>
          ))}
        </p>
      )}
    </>
  );
}
