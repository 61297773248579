import { Control, FieldValues, Path } from "react-hook-form";
import { FormFieldController } from "./FormFieldController";
import { Textarea, TextareaProps } from "../ui/textarea";

type FormTextAreaProps<T extends FieldValues> = TextareaProps & {
  control: Control<T>;
  name: Path<T>;
  label: string;
  withNotApplicable?: boolean;
};

export function FormTextArea<T extends FieldValues>({
  control,
  label,
  name,
  withNotApplicable,
  ...rest
}: FormTextAreaProps<T>) {
  return (
    <FormFieldController
      withNotApplicable={withNotApplicable}
      control={control}
      label={label}
      name={name}>
      <Textarea {...rest} />
    </FormFieldController>
  );
}
