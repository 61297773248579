import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

export type RemoveEventPersonnelArgs = {
  eventId: string;
  personnelId: string;
};

export const useRemoveEventPersonnel = () => {
  return useMutation({
    mutationKey: ["removeEventPersonnel"],
    mutationFn: async ({ eventId, personnelId }: RemoveEventPersonnelArgs) => {
      const response = await API.events.eventsControllerRemovePersonnel(
        eventId,
        personnelId
      );

      return response.data;
    },
  });
};
