import { countriesList } from "@articulate/shared";

export const countriesOptions = countriesList.map((country) => {
  return {
    label: country.name,
    value: country.uuid,
  };
});

export const countriesMap = countriesList.reduce(
  (acc, country) => {
    acc[country.uuid] = {
      id: country.uuid,
      name: country.name,
      code: country.code,
    };
    return acc;
  },
  {} as Record<string, { id: string; name: string; code: string }>
);

export function findCountryById(id: string | undefined | null) {
  if (!id) {
    return undefined;
  }

  return countriesMap[id];
}
