import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Body } from "@/components/Typography/Typography";
import { useTourSuspense } from "@/api/queries/useTour";
import { ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import {
  personalEventName,
  PersonalEventName,
  TourEventType,
  tourEventTypes,
} from "@articulate/shared";
import { useEventFormsForCreate } from "./useEventForms";
import EventFormFields from "./EventFormFields";
import { EventDto } from "@/api/base-api";

type Props = {
  dayEvents: EventDto[] | undefined;
  eventType: TourEventType | PersonalEventName;
  onHide: () => void;
  tourId: string;
  withScroll?: boolean;
};

export const CreateEvent = ({
  dayEvents,
  eventType,
  onHide,
  tourId,
  withScroll = false,
}: Props) => {
  const { data: event } = useTourSuspense(tourId!);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (withScroll && formRef?.current?.parentElement) {
      formRef.current.parentElement.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onSubmit, form } = useEventFormsForCreate({
    tourId: tourId!,
    dayEvents: dayEvents,
    onSuccess: onHide,
    eventType,
    event,
  });

  return (
    <EventFormFields
      eventType={eventType}
      form={form}
      formRef={formRef}
      onHide={onHide}
      onSubmit={onSubmit}
      tour={{ startsAt: event.startsAt || "", endsAt: event.endsAt || "" }}
    />
  );
};

export const EventCreateForm = ({
  dayEvents,
  onHide,
  tourId,
  eventTypes,
}: Pick<Props, "tourId" | "onHide" | "dayEvents"> & {
  eventTypes: { label: string; value: string }[];
}) => {
  const { t } = useTranslation(["common", "dashboard", "forms"]);

  const [getValue, setValue] = useState<
    TourEventType | PersonalEventName | null
  >(null);

  const addEvent = (value: TourEventType | PersonalEventName) => {
    if (value === personalEventName || tourEventTypes.includes(value)) {
      setValue(value);
    }
  };

  return (
    <div className="flex w-full grow flex-col">
      <Body size="lg" className="text-foreground-one p-2">
        {t("dashboard:events.createNewEvent")}
      </Body>

      <ArtiSelect
        items={eventTypes}
        className="w-32"
        placeholder={t("dashboard:events.addEvent")}
        onChange={addEvent}
      />

      {getValue && (
        <CreateEvent
          eventType={getValue}
          onHide={onHide}
          tourId={tourId}
          dayEvents={dayEvents}
        />
      )}
    </div>
  );
};
