import EventFormFields from "@/modules/Tours/Events/EventFormFields";
import { useEventForms } from "@/modules/Tours/Events/useEventForms";
import { PersonalEventName, TourEventType } from "@articulate/shared";
import { EventDto, TourWithRoleAndArtistDto } from "@/api/base-api";

export const EventForm = ({
  event,
  eventType,
  onHide,
  tour,
}: {
  event: EventDto;
  eventType: TourEventType | PersonalEventName;
  onHide: () => void;
  tour: TourWithRoleAndArtistDto;
}) => {
  const { form, onSubmit } = useEventForms(tour.id, event, onHide);

  return (
    <EventFormFields
      event={event}
      form={form}
      onHide={onHide}
      onSubmit={onSubmit}
      tour={tour}
      eventType={eventType}
    />
  );
};
