import * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { Icon } from "../Icon/Icon";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  allowDatesInThePast?: boolean;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  allowDatesInThePast = false,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("pb-4 sm:pb-0", className)}
      fromDate={allowDatesInThePast ? undefined : new Date()}
      classNames={{
        months:
          "flex flex-col sm:flex-row space-y-4 sm:space-x-8 sm:space-y-2 justify-center items-center",
        month: "sm:space-y-5",
        caption: "flex pt-1 relative justify-start sm:justify-center",
        caption_label: "text-base font-medium text-foreground-one",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "bg-transparent p-2 hover:opacity-100 border-border rounded-full h-10 w-10 hover:bg-surface-one hidden sm:block"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-2",
        head_row: "flex",
        head_cell:
          "rounded-md w-10 h-10 flex items-center justify-center font-normal text-sm text-foreground-one",
        row: "flex w-full mt-2",
        cell: "h-10 w-10 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected])]:bg-background [&:has([aria-selected].day-outside)]:bg-transparent first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20 overflow-hidden",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-10 w-10 p-0 font-normal aria-selected:opacity-100 text-foreground-two text-sm hover:bg-surface-two hover: hover:text-foreground-one hover:border hover:border-hover rounded-full"
        ),
        day_range_end: "day-range-end",
        day_range_start: "day-range-start",
        day_selected: "bg-primary text-foreground-one",
        day_outside: "hidden day-outside",
        day_disabled: "opacity-50 text-disabled",
        day_range_middle:
          "aria-selected:bg-background aria-selected:text-foreground-two",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <Icon
            name="ChevronLeftIcon"
            className="bg-foreground-one"
            {...props}
          />
        ),
        IconRight: ({ ...props }) => (
          <Icon
            name="ChevronRightIcon"
            className="bg-foreground-one"
            {...props}
          />
        ),
      }}
      weekStartsOn={1}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
