import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { UpdateEventPersonnelDto } from "../base-api";

export type UpdateEventPersonnelArgs = {
  id: string;
  personnelId: string;
  data: UpdateEventPersonnelDto;
};

export const useUpdateEventPersonnel = () => {
  return useMutation({
    mutationKey: ["updateEventPersonnel"],
    mutationFn: async ({ id, personnelId, data }: UpdateEventPersonnelArgs) => {
      const response = await API.events.eventsControllerUpdatePersonnel(
        id,
        personnelId,
        data
      );

      return response.data;
    },
  });
};
