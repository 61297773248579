import { z } from "zod";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getHotelFormSchema, getVisibilityOptions } from "./eventsSchemas";
import { FormInput } from "@/components/Form/FormInput";
import { FormHeading } from "./FormHeading";
import { PhoneInput } from "@/components/PhoneInput/PhoneInput";
import { FormTextArea } from "@/components/Form/FormTextarea";
import { TFunction } from "i18next";
import { EventFormProps } from "./useEventForms";
import { PersonnelPicker } from "./PersonnelPicker";
import { useParams } from "react-router-dom";
import { FormRadioField } from "@/components/Form/FormRadio";
import { TourEventRoles } from "@articulate/shared";
import { GooglePlacesSearchbox } from "@/components/GooglePlacesSearchbox/AsyncCombobox";
import { DatePickerHtml } from "@/components/DatePicker/DatePickerHtml";
import { FacilityItem } from "./FacilityItem";

const getFacilities = (t: TFunction<["common", "dashboard", "forms"]>) => [
  t("forms:hotelStayEvent.internet"),
  t("forms:hotelStayEvent.laundry"),
  t("forms:hotelStayEvent.privateParking"),
  t("forms:hotelStayEvent.restaurant"),
  t("forms:hotelStayEvent.fitnessCenter"),
  t("forms:hotelStayEvent.pool"),
  t("forms:hotelStayEvent.breakfast"),
];

export function HotelStayEventForm({ tourInterval }: EventFormProps) {
  const { tourId } = useParams();
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const hotelSchema = getHotelFormSchema(t);
  const form = useFormContext<z.infer<typeof hotelSchema>>();

  return (
    <>
      <FormInput
        control={form.control}
        name={"eventName"}
        label={t("forms:editEvent.name")}
      />
      <PersonnelPicker tourId={tourId!} role={TourEventRoles.CREW} />
      <div className="flex flex-col gap-4">
        <GooglePlacesSearchbox label="Hotel name" mainField="name" />
        <div className="flex gap-4">
          <DatePickerHtml
            control={form.control}
            name={"checkIn"}
            label={t("forms:hotelStayEvent.checkIn") + " *"}
            fromDate={tourInterval.startsAt}
            toDate={tourInterval.endsAt}
          />
          <DatePickerHtml
            control={form.control}
            name={"checkOut"}
            label={t("forms:hotelStayEvent.checkOut") + " *"}
            fromDate={tourInterval.startsAt}
            toDate={tourInterval.endsAt}
          />
        </div>
        <FormRadioField
          control={form.control}
          onChange={() => form.trigger("visibility")}
          name={"visibility"}
          label={t("forms:travelEvent.visibility")}
          items={getVisibilityOptions(t)}
        />
        {form.getValues("visibility") === "false" && (
          <PersonnelPicker tourId={tourId!} role={TourEventRoles.SPECTATOR} />
        )}
        <FormHeading text={t("forms:hotelStayEvent.additionalInfo")} />
        <PhoneInput
          control={form.control}
          name={"phoneNumber"}
          label={t("forms:personalDetailsForm.phoneNumber")}
        />
        <FormInput
          control={form.control}
          name={"email"}
          label={t("common:email")}
        />
        <FormInput
          control={form.control}
          name={"website"}
          label={t("forms:hotelStayEvent.website")}
        />
        <FormTextArea
          control={form.control}
          name={"notes"}
          label={t("forms:hotelStayEvent.notes")}
          placeholder={t("forms:hotelStayEvent.addNotesHere")}
        />
        <FormTextArea
          control={form.control}
          name={"keyContacts"}
          label={t("forms:hotelStayEvent.keyContacts")}
          placeholder={t("forms:hotelStayEvent.addKeyContactsHere")}
        />
        <FormHeading text={t("forms:hotelStayEvent.facilities")} />
        <FacilitiesList />
      </div>
    </>
  );
}

function FacilitiesList() {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const facilities = getFacilities(t);
  const hotelSchema = getHotelFormSchema(t);
  const { getValues, setValue } = useFormContext<z.infer<typeof hotelSchema>>();

  const facilitiesList = facilities.map((facility) => {
    const prevFavilities = getValues("facilities");
    const isSelected = prevFavilities?.includes(facility);

    const onClick = () => {
      if (isSelected) {
        setValue(
          "facilities",
          prevFavilities.filter((value) => value !== facility),
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
        return;
      }
      setValue("facilities", [...(prevFavilities || []), facility], {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    return (
      <FacilityItem
        value={facility}
        isSelected={isSelected}
        onClick={onClick}
        key={facility}
      />
    );
  });

  return <div className="flex flex-wrap gap-2">{facilitiesList}</div>;
}
