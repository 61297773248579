import { Control, FieldValues, Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";

export type RadioFieldItem = {
  label: string;
  value: string;
};

export function FormRadioField<T extends FieldValues>({
  items,
  control,
  name,
  label,
  className,
  onChange,
}: {
  items: RadioFieldItem[];
  control: Control<T>;
  name: Path<T>;
  label?: string;
  className?: string;
  onChange?: () => Promise<boolean>;
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <div className="mb-4">
              <FormLabel className="text-base">{label}</FormLabel>
            </div>
          )}

          <RadioGroup
            onValueChange={async (val) => {
              field.onChange(val);
              await onChange?.();
            }}
            defaultValue={field.value}>
            {items.map((item) => (
              <FormItem
                key={item.value}
                className={cn(
                  "flex flex-row items-center space-x-3 space-y-0",
                  className
                )}>
                <FormControl>
                  <RadioGroupItem value={item.value} id={item.value}>
                    {item.label}
                  </RadioGroupItem>
                </FormControl>
                <FormLabel
                  className="w-full cursor-pointer text-sm font-normal"
                  htmlFor={item.value}>
                  {item.label}
                </FormLabel>
              </FormItem>
            ))}
          </RadioGroup>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
