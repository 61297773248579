import { EventTravelAdditionalInformation } from "@/api/base-api";
import { IconName, IconProps } from "@/components/Icon/Icon";
import { PersonalEventName, TourEventType } from "@articulate/shared";

const travelIcons: Record<string, IconName> = {
  air: "AirIcon",
  ground: "GroundIcon",
  sea: "SeaIcon",
  rail: "RailIcon",
};

export const getEventIconName = (
  event: TourEventType | PersonalEventName,
  travelType?: EventTravelAdditionalInformation["travelType"]
): IconProps["name"] => {
  switch (event) {
    case "LOAD_IN":
      return "LoadInDayIcon";
    case "HOTEL_STAY":
      return "OffDayIcon";
    case "PRODUCTION":
      return "ProductionDayIcon";
    case "PROMO":
      return "PromoIcon";
    case "REHEARSAL":
      return "RehearsalIcon";
    case "SHOW":
      return "ShowIcon";
    case "GLAM":
      return "GlamIcon";
    case "TRAVEL":
      return (
        travelIcons[
          travelType as Exclude<
            EventTravelAdditionalInformation["travelType"],
            null
          >
        ] || "TravelIcon"
      );
    case "SCHEDULE_ITEM":
      return "ScheduleItemIcon";
    case "PERSONAL":
      return "ProfileIcon";
    default:
      return "ScheduleItemIcon";
  }
};
