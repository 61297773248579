import { useTranslation } from "react-i18next";
import { getPersonalFormSchema } from "./eventsSchemas";
import { useFormContext } from "react-hook-form";
import { FormTextArea } from "@/components/Form/FormTextarea";
import { GooglePlacesSearchbox } from "@/components/GooglePlacesSearchbox/AsyncCombobox";
import { z } from "zod";
import { DatePickersForm } from "@/components/DatePicker/DatePickersForm";
import { EventFormProps } from "./useEventForms";
import { FormInput } from "@/components/Form/FormInput";

export function PersonalEventForm({ tourInterval }: EventFormProps) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const personalFormSchema = getPersonalFormSchema(t);
  const form = useFormContext<z.infer<typeof personalFormSchema>>();

  return (
    <>
      <div className="flex flex-col gap-4">
        <FormInput
          control={form.control}
          name={"eventName"}
          label={t("forms:editEvent.name")}
        />
        <DatePickersForm
          control={form.control}
          names={{
            start: { day: "startDate", time: "startTime" },
            end: { day: "endDate", time: "endTime" },
          }}
          label={t("forms:genericEvent.duration") + " *"}
          fromDate={tourInterval.startsAt}
          toDate={tourInterval.endsAt}
        />
        <GooglePlacesSearchbox label={t("forms:loadInEvent.address")} />
        <FormTextArea
          control={form.control}
          name={"notes"}
          label={t("forms:hotelStayEvent.notes")}
          placeholder={t("forms:hotelStayEvent.addNotesHere")}
        />
      </div>
    </>
  );
}
