import { useCurrentUser } from "@/api/queries/useCurrentUser";
import { useUserSubscriptionPlan } from "@/api/queries/useUserSubscriptionPlan";

export function useUserSubscriptions() {
  const { data: user } = useCurrentUser();
  const { data: userSubscriptionPlans } = useUserSubscriptionPlan(
    user?.id || ""
  );

  const isSubscriptionActive = user
    ? !!userSubscriptionPlans?.subscriptions?.find(
        (subs) => subs.status === "active"
      ) || !!user.grantedPremiumAccess
    : false;

  return { isSubscriptionActive };
}
