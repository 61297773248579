import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateDayTagDto } from "../base-api";

export const useCreateDayTag = () => {
  return useMutation({
    mutationKey: ["createDayTag"],
    mutationFn: async (values: CreateDayTagDto) => {
      const response = await API.dayTags.dayTagsControllerCreate(values);

      return response.data;
    },
  });
};
