import { ReactNode } from "react";

import { Header } from "@/components/Typography/Typography";
import { TabsContent } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";

const TourTabWrapper = ({
  children,
  extraButton,
  id,
  title,
  isExists,
  emptyMessage,
}: {
  children: ReactNode;
  emptyMessage: () => ReactNode;
  extraButton?: ReactNode;
  id: string;
  isExists: boolean;
  title: string;
}) => (
  <TabsContent
    value={id}
    className={cn("border-none", { "h-full": !isExists })}>
    <div className="flex min-h-full basis-2/3 flex-col justify-center gap-2 ">
      {isExists && title && (
        <div className="flex min-h-10 items-center justify-between">
          <div>
            <Header size="h2" className="text-foreground-one font-normal">
              {title}
            </Header>
          </div>
          {extraButton}
        </div>
      )}
      {isExists ? children : emptyMessage()}
    </div>
  </TabsContent>
);

export default TourTabWrapper;
