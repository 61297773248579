import { useCallback, useEffect, useState } from "react";

const smBreakpoint = 640;
const mdBreakpoint = 768;

export function useViewport() {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width < smBreakpoint;
  const isMdAtLeast = width >= mdBreakpoint;

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { isMobile, isMdAtLeast };
}
