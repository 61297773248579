import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { Button } from "../ui/button";
import { Icon } from "../Icon/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function PageWrapper({
  children,
  withoutPadding,
  withBackButton,
  className,
  customBackFn,
}: {
  children: ReactNode;
  className?: string;
  withoutPadding?: boolean;
  withBackButton?: boolean;
  customBackFn?: () => void;
}) {
  const location = useLocation();

  const isBackNeeded = location.key !== "default" && withBackButton;

  return (
    <div
      className={cn(
        "flex h-full flex-col gap-4 lg:gap-6",
        {
          "p-4 md:p-6 lg:p-8": !withoutPadding,
        },
        className
      )}>
      {isBackNeeded && (
        <nav>
          <BackButton onBack={customBackFn} />
        </nav>
      )}
      {children}
    </div>
  );
}

export function BackButton({
  onBack,
  text,
}: {
  onBack?: () => void;
  text?: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  return (
    <Button
      className="mr-auto flex gap-1 pl-0"
      variant={"link"}
      type="button"
      onClick={() => {
        if (onBack) {
          onBack();
          return;
        }
        navigate(-1);
      }}>
      <Icon name="ChevronLeftIcon" className="h-6 w-6" />
      <span>{text || t("back")}</span>
    </Button>
  );
}
