import { ReactNode } from "react";
import { useTourSuspense } from "@/api/queries/useTour";
import Circle from "@/components/Circle/Circle";
import { Icon } from "@/components/Icon/Icon";
import { Body, Header } from "@/components/Typography/Typography";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  getDateFromIsoTimestampIgnoringTimezone,
  formatDate,
} from "@/lib/dates";
import { getTourImageWithFallback } from "@/lib/storage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import image from "@/assets/sprites/articulate.png";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { useTourRole } from "./tourAccess";
import { ArtiTooltip } from "@/components/ArtiTooltip";
import { useTourPersonnel } from "@/api/queries/useTourPersonnel";
import { useUserSubscriptions } from "@/hooks/useUserSubscriptions";
import { toast } from "@/components/ui/use-toast";
import { useArchiveTour } from "@/api/mutations/useArchiveTour";
import { queryClient } from "@/api/client";
import { toursQueryOptions } from "@/api/queries/useTours";
import { workspaceArchivedToursQueryOptions } from "@/api/queries/useArchivedTours";
import { TourPersonnelDto } from "@/api/base-api";
import { getUserName } from "@/lib/getUserName";

const SHOWN_PERSONNEL = 3;

export type Person = TourPersonnelDto;
type Props = { tourId: string };

export function TourHeader({ tourId }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "dashboard"]);

  const { data: tour } = useTourSuspense(tourId);
  const { isManager } = useTourRole(tourId!);
  const { data: tourPersonnel } = useTourPersonnel(tourId!);
  const { isSubscriptionActive } = useUserSubscriptions();
  const { mutateAsync: archiveTour } = useArchiveTour();

  const startsAt =
    tour.startsAt &&
    formatDate(getDateFromIsoTimestampIgnoringTimezone(tour.startsAt));
  const endsAt =
    tour.endsAt &&
    formatDate(getDateFromIsoTimestampIgnoringTimezone(tour.endsAt));

  const goInvitePersonnel = () => {
    if (!isManager) return;
    navigate(`/dashboard/tours/${tourId}/personnel`);
  };
  const goToMessages = () => {
    navigate("messages");
  };
  const handleArchiveTour = async () => {
    await archiveTour(
      {
        tourId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(toursQueryOptions());
          await queryClient.invalidateQueries(
            toursQueryOptions({ filter: { artistId: tour.artistId } })
          );
          await queryClient.invalidateQueries({
            queryKey: workspaceArchivedToursQueryOptions({ enabled: true })
              .queryKey,
          });
          toast({
            variant: "success",
            title: t("success"),
            description: t("dashboard:tour.tourHasBeenArchived", {
              tourName: tour.name,
            }),
          });
        },
      }
    );
  };

  return (
    <header className="relative flex items-center justify-between px-4 md:px-6 lg:px-8">
      <div className="flex flex-col gap-4 overflow-scroll sm:overflow-auto lg:flex-row">
        <img
          src={getTourImageWithFallback(tour.posterKey)}
          alt="tour-image"
          className="h-20 w-20 rounded-lg object-cover"
          onError={(e) => {
            e.currentTarget.src = image;
          }}
        />
        <div className="flex flex-col gap-2">
          <div className="w-auto">
            <Badge variant="default">{tour.artist.name}</Badge>
          </div>
          <Header size="h1" className="text-foreground-one">
            {tour.name}
          </Header>
          <ScrollArea>
            <div className="flex flex-col items-start gap-2 pr-4 sm:flex-row sm:items-center">
              <div className="flex items-center gap-1">
                <Icon name="CalendarIcon" className="h-4 w-4" />
                <Body className="text-foreground-two whitespace-nowrap">
                  {startsAt} - {endsAt}
                </Body>
              </div>
              <Separator
                orientation="vertical"
                className="bg-surface-two hidden h-8 lg:block"
              />
              {/* TODO: take country and flag from API */}
              <div className="min-w-12 flex items-center gap-1">
                <Icon name="FlagIcon" className="text-foreground-one h-4 w-4" />
                <Body className="text-foreground-two">{t("none")}</Body>
                {/* <img
                  src={`${storageURL}/public/countries/us.png`}
                  className="h-4 w-4"
                  alt="us flag"
                />
                <Body>USA</Body> */}
              </div>
              <Separator
                orientation="vertical"
                className="bg-surface-two hidden h-8 lg:block"
              />
              <div className="flex items-center">
                <PersonnelList
                  data={tourPersonnel?.tourPersonnel}
                  limitedAmount={SHOWN_PERSONNEL}
                  goInvitePersonnel={goInvitePersonnel}
                  withTooltip
                />
                {isManager && (
                  <Button
                    variant="secondary"
                    className="h-8"
                    onClick={goInvitePersonnel}>
                    {t("invite")}
                  </Button>
                )}
              </div>
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
      <div className="absolute right-4 top-0 flex gap-4 sm:top-1/3 md:right-6 lg:right-8">
        <Circle onClick={goToMessages}>
          <Icon name="MessagesIcon" />
        </Circle>
        {isManager && isSubscriptionActive && (
          <DropdownMenu>
            <DropdownMenuTrigger className="rounded-full">
              <Circle>
                <Icon name="MoreVerticalIcon" />
              </Circle>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-surface-one border-border min-w-36 rounded border py-1">
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() =>
                  navigate(
                    `/dashboard/tours/update/${tour.artistId}/${tour.id}`
                  )
                }>
                {t("dashboard:sidebar.artistMenu.edit")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleArchiveTour}>
                {t("dashboard:sidebar.artistMenu.archiveTour")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </header>
  );
}

const ContentWrapper = ({
  children,
  fullName,
  withTooltip,
}: {
  children: ReactNode;
  fullName: string;
  withTooltip: boolean;
}) =>
  withTooltip ? (
    <ArtiTooltip content={fullName}>
      <div>{children}</div>
    </ArtiTooltip>
  ) : (
    children
  );

export function PersonnelList({
  data,
  limitedAmount,
  goInvitePersonnel,
  withTooltip = false,
}: {
  data?: Person[];
  limitedAmount?: number;
  goInvitePersonnel: () => void;
  withTooltip?: boolean;
}) {
  if (!data?.length) {
    return null;
  }

  let peopleLeft = 0;
  const limitedPeople = [...data];
  if (limitedAmount) {
    peopleLeft = data.length - limitedAmount;

    if (limitedPeople.length > limitedAmount) {
      limitedPeople.splice(limitedAmount, data.length);
    }
  }

  const people = limitedPeople.map((person, index) => {
    const name = getUserName(
      person.user.firstName,
      person.user.lastName,
      person.user.email
    );
    const shortName =
      person.user.firstName && person.user.lastName
        ? `${person.user.firstName[0]}${person.user.lastName[0]}`
        : person.user.email[0]?.toUpperCase();

    return (
      <ContentWrapper withTooltip={withTooltip} fullName={name} key={person.id}>
        <Circle
          className="-mr-[5px] h-8 w-8"
          onClick={goInvitePersonnel}
          key={index}>
          <Body>{shortName}</Body>
        </Circle>
      </ContentWrapper>
    );
  });

  return (
    <ul
      className={cn("flex", {
        "mr-2": limitedPeople.length,
      })}>
      {people}
      {peopleLeft > 0 && (
        <Circle className="h-8 w-8" onClick={goInvitePersonnel}>
          <Body>+{peopleLeft}</Body>
        </Circle>
      )}
    </ul>
  );
}
