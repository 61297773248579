import { useTranslation } from "react-i18next";
import { PageWrapper } from "@/components/PageWrapper/PageWrapper";
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import {
  Calendar,
  EventProps,
  View,
  dateFnsLocalizer,
} from "react-big-calendar";
import { zhTW } from "date-fns/locale";
import { formatDate, getDaysForInterval, getFormatedTime } from "@/lib/dates";
import { Header, LabelText } from "@/components/Typography/Typography";
import { cn } from "@/lib/utils";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { EventDto, TourWithArtistDto } from "@/api/base-api";
import { useEventTypes } from "@/api/queries/useEventTypes";
import { getPlaceTimezone } from "@/modules/Tours/Events/EventExpandItem";
import { useSearchParams } from "react-router-dom";
import { ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import Circle from "@/components/Circle/Circle";
import { Icon } from "@/components/Icon/Icon";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { EventCreateForm } from "@/modules/Tours/Events/EventCreateForm";
import { useTour } from "@/hooks/useTours";
import { getFormattedEventType } from "@/modules/Tours/Events/eventsSchemas";
import {
  personalEventName,
  TourEventType,
  TourRoles,
} from "@articulate/shared";
import { useTourRole } from "@/modules/Tours/tourAccess";
import { useTours } from "@/api/queries/useTours";
import TourChangeDays from "@/modules/Tours/TourChangeDays";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import {
  combinedCalendarQueryOptions,
  useCombinedCalendar,
} from "@/api/queries/useCombinedCalendar";
import { useCurrentUser } from "@/api/queries/useCurrentUser";
import { toZonedTime } from "date-fns-tz";
import { getEventTimezones } from "@/modules/Tours/TourEvents";
import { queryClient } from "@/api/client";
import { tourQueryOptions } from "@/api/queries/useTour";
import { useUserSubscriptions } from "@/hooks/useUserSubscriptions";
import { usePersonalEvents } from "@/api/queries/usePersonalEvents";
import { useArtists } from "@/api/queries/useArtists";

const locales = {
  "zh-TW": zhTW,
};
const ALL_TOURS_ID = "all-tours";
const ALL_ARTISTS_ID = "all-artists";

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type DateRange =
  | {
      min: Date;
      max: Date;
    }
  | undefined;

type DateContextType = {
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  days: Date[];
  setDays: Dispatch<SetStateAction<Date[]>>;
  view: View;
  setView: Dispatch<SetStateAction<View>>;
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
};

export const DateContext = React.createContext<DateContextType>({
  date: null,
  setDate: () => {},
  days: [],
  setDays: () => {},
  view: "day",
  setView: () => {},
  dateRange: undefined,
  setDateRange: () => {},
});

function CalendarPageWithContext() {
  const [date, setDate] = useState<Date | null>(null);
  const [days, setDays] = useState<Date[]>([]);
  const [view, setView] = useState<View>("day");
  const [dateRange, setDateRange] = useState<DateRange>(
    getDateRangeBasedOnView(view, date!)
  );

  return (
    <DateContext.Provider
      value={{
        date,
        setDate,
        days,
        setDays,
        dateRange,
        setDateRange,
        view,
        setView,
      }}>
      <CalendarPage />
    </DateContext.Provider>
  );
}

const MemoizedDaysNavigation = memo(DaysNavigation);

const getDateRangeBasedOnView = (view: View, date: Date) => {
  if (view === "day") {
    return {
      min: date
        ? new Date(new Date(date).setUTCHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000)
        : date,
      max: date
        ? new Date(
            new Date(date).setUTCHours(23, 59, 59, 999) + 24 * 60 * 60 * 1000
          )
        : date,
    };
  }

  if (view === "week") {
    return {
      min: new Date(startOfWeek(date).setUTCHours(0, 0, 0, 0)),
      max: new Date(endOfWeek(date).setUTCHours(23, 59, 59, 999)),
    };
  }

  if (view === "month") {
    return {
      min: new Date(startOfMonth(date).setUTCHours(0, 0, 0, 0)),
      max: new Date(endOfMonth(date).setUTCHours(23, 59, 59, 999)),
    };
  }
};

function CalendarPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const artistId = searchParams.get("artistId") || "";
  const tourId = searchParams.get("tourId") || "";
  const { date, setDate, setDays, view, setView, dateRange, setDateRange } =
    useContext(DateContext);

  const { data: currentUser } = useCurrentUser();
  const { data: tours } = useTours({ filter: { artistId } });
  const filteredToursIds = useMemo(() => {
    const toursIds = tours?.tours.map((tour) => tour.id) || [];
    const filteredToursIds = tourId
      ? [toursIds.find((id) => id === tourId) || ""].filter(Boolean)
      : toursIds;

    return filteredToursIds;
  }, [tours, tourId]);
  const { data: artists } = useArtists();
  const { data: personalEventsData } = usePersonalEvents();
  const { data: combinedCalendar } = useCombinedCalendar({
    from: dateRange?.min ? dateRange.min.toISOString() : "",
    to: dateRange?.max ? dateRange.max.toISOString() : "",
    tourIds: filteredToursIds,
    workspaceId: currentUser?.personalWorkspaceId || "",
  });

  const toursOptions = useMemo(() => {
    return [
      {
        label: "All tours",
        value: ALL_TOURS_ID,
      },
      ...(tours?.tours.map((tour) => ({
        label: tour.name,
        value: tour.id,
      })) || []),
    ];
  }, [tours]);
  const artistsOptions = useMemo(() => {
    return [
      {
        label: "All artists",
        value: ALL_ARTISTS_ID,
      },
      ...(artists?.artists.map((artist) => ({
        label: artist.name,
        value: artist.id,
      })) || []),
    ];
  }, [artists]);
  const colors = useMemo(
    () => [
      "#FF69B4",
      "#33CC33",
      "#6666CC",
      "#CC6633",
      "#33CCCC",
      "#4285F4",
      "#0F9D58",
      "#F4B400",
    ],
    []
  );
  const coloredEvents = useMemo(() => {
    return Object.entries(combinedCalendar?.events || {}).reduce(
      (acc, [key, value]) => {
        const uniqueColor =
          colors[
            Object.keys(combinedCalendar?.events || {}).indexOf(key) %
              colors.length
          ];
        acc[key] = value.map((event: EventDto) => ({
          ...event,
          color: uniqueColor,
        }));
        return acc;
      },
      {}
    );
  }, [colors, combinedCalendar?.events]);
  const personalEvents = useMemo(() => {
    const events: (EventDto & { color: string })[] =
      personalEventsData?.personalEvents?.map((event) => ({
        additionalInformation: null,
        city: event.city || "",
        countryId: event.countryId || "",
        createdAt: event.createdAt,
        deletedAt: "",
        endDate: event.endTime,
        eventDetails: {
          description: "",
          name: event.name,
        },
        eventTypeId: "",
        id: event.id,
        nextDay: false,
        startDate: event.startTime,
        state: event.state || "",
        tourId: "",
        visibleToAll: false,
        streetAddress: event.streetAddress,
        notes: event.notes,
        timezoneId: event.timezone,
        updatedAt: event.updatedAt,
        zipCode: "",
        eventPersonnel: [],
        eventProvider: null,
        groups: [],
        color: "#4D4D4D",
      })) || [];

    return events;
  }, [personalEventsData]);

  const { defaultDate, views, components, currentDate, events } = useMemo(
    () => ({
      components: {
        ...(view === "day"
          ? {
              timeGutterHeader: MemoizedDaysNavigation,
            }
          : {}),
        dateCellWrapper: () => null,
        event: (props: EventProps<EventDto>) => Event({ ...props, view }),
      },
      defaultDate: new Date(),
      currentDate: date,
      events: [...Object.values(coloredEvents).flat(), ...personalEvents].map(
        (event: EventDto) => {
          const { startTimezone, endTimezone } = getEventTimezones({
            isTravel: !!event?.additionalInformation?.travel?.travelType,
            event,
          });
          // handle next day events
          const startDate = event.nextDay
            ? new Date(
                new Date(event.startDate!).getTime() + 24 * 60 * 60 * 1000
              )
            : event.startDate;
          const endDate = event.nextDay
            ? new Date(new Date(event.endDate!).getTime() + 24 * 60 * 60 * 1000)
            : event.endDate;

          return {
            start: toZonedTime(startDate || "", startTimezone!),
            end: toZonedTime(endDate || "", endTimezone!),
            title: event.eventDetails?.name,
            ...event,
          };
        }
      ),
      views: ["day", "week", "month"],
    }),
    [coloredEvents, date, personalEvents, view]
  );

  const onFiltersChange = (
    resetFilter: typeof ALL_ARTISTS_ID | typeof ALL_TOURS_ID,
    value: string
  ) => {
    const filter = resetFilter === ALL_ARTISTS_ID ? "artistId" : "tourId";
    if (value === resetFilter) {
      searchParams.delete(filter);
    } else {
      searchParams.set(filter, value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const dateRange = getDateRangeBasedOnView("month", new Date());

    setDays(
      getDaysForInterval(
        dateRange?.min.toISOString() || "",
        dateRange?.max.toISOString() || ""
      )
    );
    setDateRange(dateRange);
    setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper withBackButton className="h-screen min-h-screen">
      <div className="flex flex-row justify-end gap-4">
        <ArtiSelect
          items={artistsOptions}
          onChange={(artistId) => onFiltersChange(ALL_ARTISTS_ID, artistId)}
          value={artistId || ALL_ARTISTS_ID}
        />
        <ArtiSelect
          items={toursOptions}
          onChange={(tourId) => onFiltersChange(ALL_TOURS_ID, tourId)}
          value={tourId || ALL_TOURS_ID}
        />
        <EventCreationView />
      </div>
      <Calendar
        formats={{
          dayHeaderFormat: (date) => formatDate(date),
        }}
        components={components}
        defaultDate={defaultDate}
        onNavigate={(date) => {
          setDate(date);
          const monthDateRange = getDateRangeBasedOnView("month", date);
          setDays(
            getDaysForInterval(
              monthDateRange?.min.toISOString() || "",
              monthDateRange?.max.toISOString() || ""
            )
          );
          setDateRange(getDateRangeBasedOnView(view, date));
        }}
        view={view}
        onView={(view) => {
          setView(view);
          setDateRange(getDateRangeBasedOnView(view, date!));
        }}
        date={currentDate}
        events={events}
        localizer={localizer}
        showMultiDayTimes
        step={60}
        views={views}
        defaultView="day"
        eventPropGetter={(event) => {
          return {
            style: {
              backgroundColor: event.color,
              borderColor: "#16161866",
            },
          };
        }}
      />
    </PageWrapper>
  );
}

function EventCreationView() {
  const { t } = useTranslation(["dashboard", "common"]);
  const [_, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [tourId, setTourId] = useState<string | null>(null);
  const [managerTours, setManagerTours] = useState<TourWithArtistDto[]>([]);
  const { setDate, date, setDateRange, view } = useContext(DateContext);

  const { tourDays } = useTour(tourId!);
  const { data: tours } = useTours();
  const { data: eventTypes } = useEventTypes();
  const { isManager, isOwner } = useTourRole(tourId!);
  const { data: currentUser } = useCurrentUser();
  const { isSubscriptionActive } = useUserSubscriptions();

  const availableEventTypes = eventTypes
    ? [
        ...eventTypes.eventTypes
          .filter(() => {
            if (isManager || isOwner) return true;
            return false;
          })
          .map((eventType) => ({
            label: getFormattedEventType(eventType.name as TourEventType),
            value: eventType.name,
          })),
        {
          label: getFormattedEventType(personalEventName),
          value: personalEventName,
        },
      ]
    : [];

  useEffect(() => {
    const fetchTourData = async () => {
      if (!tours) {
        setManagerTours([]);
        return;
      }

      const tourPromises = tours.tours.map(async (tour) => {
        try {
          const tourData = await queryClient.fetchQuery(
            tourQueryOptions(tour.id)
          );
          return {
            tour,
            isManager: [TourRoles.TOUR_MANAGER, TourRoles.TOUR_OWNER].includes(
              tourData.role
            ),
          };
        } catch (error) {
          console.error(`Error fetching tour ${tour.id}:`, error);
          return { tour, isManager: false };
        }
      });

      const tourResults = await Promise.all(tourPromises);
      const filteredTours = tourResults
        .filter((result) => result.isManager && isSubscriptionActive)
        .map((result) => result.tour);

      setManagerTours(filteredTours);
    };

    fetchTourData();
  }, [tours, isSubscriptionActive]);

  if (!managerTours.length) return null;

  return (
    <>
      <div className="flex justify-end">
        <Circle
          className="bg-primary border-primary"
          onClick={() => {
            setOpen(true);
          }}>
          <Icon name="AddIcon" />
        </Circle>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-auto min-h-2/3 flex h-2/3 flex-col justify-between overflow-hidden rounded-2xl p-6 md:max-w-full lg:max-w-[calc(896px-64px)]">
          <ArtiSelect
            items={
              managerTours.map((tour) => ({
                label: tour.name,
                value: tour.id,
              })) || []
            }
            onChange={(tourId) => {
              setTourId(tourId);
              setSearchParams({ tourId });
              setDate(
                new Date(
                  managerTours?.find((tour) => tour.id === tourId)?.startsAt ||
                    ""
                )
              );
            }}
            value={tourId!}
            placeholder={t("dashboard:createTour.tourName")}
            label={t("dashboard:messaging.selectTour")}
          />

          {tourId && (
            <>
              <TourChangeDays
                selectedDay={date!}
                setSelectedDay={(date) => {
                  setDate(date);
                }}
                tourDays={tourDays}
              />
              <ScrollArea className="h-full">
                <EventCreateForm
                  tourId={tourId}
                  onHide={() => {
                    const changedDay = getDateRangeBasedOnView(view, date!);
                    if (!changedDay) return;
                    setDateRange({
                      min: changedDay.min,
                      max: changedDay.max,
                    });
                    queryClient.invalidateQueries(
                      combinedCalendarQueryOptions({
                        from: changedDay.min
                          ? changedDay.min.toISOString()
                          : "",
                        to: changedDay.max ? changedDay.max.toISOString() : "",
                        tourIds: tours?.tours.map((tour) => tour.id),
                        workspaceId: currentUser?.personalWorkspaceId,
                      })
                    );
                    setOpen(false);
                  }}
                  dayEvents={[]}
                  eventTypes={availableEventTypes}
                />
              </ScrollArea>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

function Event(props: EventProps<EventDto> & { view: View }) {
  const { data: eventTypes } = useEventTypes();
  const { tour } = useTour(props.event.tourId);

  const { startTimezone } = getEventTimezones({
    isTravel: !!props.event.additionalInformation?.travel?.travelType,
    event: props.event,
  });
  const startDate = toZonedTime(props.event.startDate || "", startTimezone!);

  const renderSpecificEvent = () => {
    const travelType = props.event.additionalInformation?.travel?.travelType;

    return (
      <>
        <LabelText className="text-wrap text-black">
          {tour?.name}
          <br />
          {props.event.eventDetails?.name}
          <br />
          {travelType && (
            <>
              {travelType === "air"
                ? "Flight"
                : travelType[0].toUpperCase() + travelType.slice(1)}
              <br />
            </>
          )}

          {travelType ? (
            <>
              Dep {getFormatedTime(startDate)}{" "}
              {getPlaceTimezone(
                props.event.additionalInformation?.travel?.departureTime || "",
                props.event,
                "origin",
                eventTypes
              )}
            </>
          ) : (
            <>
              Start: {getFormatedTime(startDate)}{" "}
              {getPlaceTimezone(
                props.event.startDate || "",
                props.event,
                "origin",
                eventTypes
              )}
            </>
          )}
        </LabelText>
      </>
    );
  };

  return (
    <div style={{ backgroundColor: props.event.color }}>
      {renderSpecificEvent()}
    </div>
  );
}

function DaysNavigation() {
  const { date, setDate, days, setDateRange } = useContext(DateContext);

  return (
    <ScrollArea key="scroll-area-1">
      <div className="flex gap-2 pb-2.5">
        {days.map((day) => (
          <button
            className={cn(
              "border-border min-w-20 flex h-20 w-20 flex-col items-center justify-center gap-1 rounded-2xl border bg-transparent",
              {
                "bg-primary border-0": date
                  ? formatDate(date) === formatDate(day)
                  : false,
              }
            )}
            onClick={() => {
              setDate(day);
              setDateRange(getDateRangeBasedOnView("day", day));
            }}
            key={day.toISOString()}>
            <LabelText>
              {day.toLocaleDateString("en-US", { weekday: "short" })}
            </LabelText>
            <Header size={"h4"}>
              {day.getDate()}-
              {day.toLocaleDateString("en-US", {
                month: "short",
              })}
            </Header>
          </button>
        ))}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}

export const Component = CalendarPageWithContext;
