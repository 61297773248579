import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

export const useRemoveDayTag = () => {
  return useMutation({
    mutationKey: ["removeDayTag"],
    mutationFn: async ({ tagId }: { tagId: string }) => {
      const response = await API.dayTags.dayTagsControllerRemove(tagId);

      return response.data;
    },
  });
};
