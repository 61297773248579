import { Body } from "@/components/Typography/Typography";
import { Separator } from "@/components/ui/separator";

type Props = { text: string };

export function FormHeading({ text }: Props) {
  return (
    <div className="flex items-center gap-2 overflow-hidden">
      <Body size="lg" className="text-foreground-two text-nowrap">
        {text}
      </Body>
      <Separator className="bg-surface-one" />
    </div>
  );
}
