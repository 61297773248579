import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

type Args = { tourId: string };

export const useArchiveTour = () => {
  return useMutation({
    mutationKey: ["archiveTour"],
    mutationFn: async ({ tourId }: Args) => {
      const response = await API.tours.toursControllerArchive(tourId);

      return response.data;
    },
  });
};
