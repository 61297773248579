import { FieldValues, useFormContext } from "react-hook-form";
import {
  getExtendedSharedTravelSchema,
  getVisibilityOptions,
} from "./eventsSchemas";
import { Icon, IconProps } from "@/components/Icon/Icon";
import { Body, Header } from "@/components/Typography/Typography";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { FormInput } from "@/components/Form/FormInput";
import { FormHeading } from "./FormHeading";
import { EventFormProps } from "./useEventForms";
import { PersonnelPicker } from "./PersonnelPicker";
import { useParams } from "react-router-dom";
import { FormRadioField } from "@/components/Form/FormRadio";
import { TourEventRoles } from "@articulate/shared";
import { GooglePlacesTravelSearchbox } from "@/components/GooglePlacesSearchbox/AsyncCombobox";
import { z } from "zod";
import { DatePickerHtml } from "@/components/DatePicker/DatePickerHtml";
import { ArtiFormSelect, ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import { airlinesOptions } from "@/lib/airlines";
import { useState } from "react";
import { formatDateAndTime, toApiDateFormat } from "@/lib/dates";
import { useFlights } from "@/api/queries/useFlights";
import { FlightInfoDto } from "@/api/base-api";
import { useDebounce } from "@uidotdev/usehooks";
import { Input } from "@/components/ui/input";
import { toZonedTime } from "date-fns-tz";
import { OvernightEventCheckbox } from "./OvernightEventCheckbox";

export type TravelType = "ground" | "air" | "rail" | "sea";
type TravelTypeItem = {
  iconName: IconProps["name"];
  type: TravelType;
};

const travelTypesData: TravelTypeItem[] = [
  {
    iconName: "GroundIcon",
    type: "ground",
  },
  {
    iconName: "AirIcon",
    type: "air",
  },
  {
    iconName: "RailIcon",
    type: "rail",
  },
  {
    iconName: "SeaIcon",
    type: "sea",
  },
];

export function TravelEventForm({ tourInterval }: EventFormProps) {
  const { tourId } = useParams();
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const travelSchema = getExtendedSharedTravelSchema(t);
  const { getValues, setValue, trigger, control } =
    useFormContext<z.infer<typeof travelSchema>>();

  const travelType = getValues("travelType");

  const onTravelTypeChange = (type: TravelType) => {
    setValue("travelType", type, {
      shouldTouch: true,
      shouldDirty: true,
    });
    trigger("travelType");
  };

  const renderSelectedTravelForm = () => {
    switch (travelType) {
      case "ground":
        return <GroundForm tourInterval={tourInterval} />;
      case "air":
        return <AirForm />;
      case "rail":
        return <RailForm tourInterval={tourInterval} />;
      case "sea":
        return <SeaForm tourInterval={tourInterval} />;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <FormInput
        control={control}
        name={"eventName"}
        label={t("forms:editEvent.name")}
      />
      <PersonnelPicker tourId={tourId!} role={TourEventRoles.CREW} />
      <ScrollArea>
        <ul className="flex gap-4">
          {travelTypesData.map(({ iconName, type }) => (
            <li
              className={cn(
                "bg-background border-border min-w-44 flex flex-1 cursor-pointer justify-center gap-2 rounded-2xl border py-4",
                {
                  "bg-primary": travelType === type,
                }
              )}
              onClick={() => onTravelTypeChange(type)}
              key={type}>
              <Icon name={iconName} />
              <Header size="h3" className="text-foreground-one">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </Header>
            </li>
          ))}
        </ul>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      {renderSelectedTravelForm()}
      <FormRadioField
        control={control}
        onChange={() => trigger("visibility")}
        name={"visibility"}
        label={t("forms:travelEvent.visibility")}
        items={getVisibilityOptions(t)}
      />
      {getValues("visibility") === "false" && (
        <PersonnelPicker tourId={tourId!} role={TourEventRoles.SPECTATOR} />
      )}
    </div>
  );
}

function GroundForm({ tourInterval }: EventFormProps) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const travelSchema = getExtendedSharedTravelSchema(t);
  const form = useFormContext<z.infer<typeof travelSchema>>();

  return (
    <div className="flex flex-col gap-4">
      <FormHeading text={t("forms:travelEvent.departure")} />
      <GooglePlacesTravelSearchbox label="Origin" type="departure" />
      <div className="flex items-end gap-4">
        <div className="flex-1">
          <DatePickerHtml
            control={form.control}
            name={"departureDate"}
            label={t("forms:travelEvent.departureTime") + "*"}
            fromDate={tourInterval.startsAt}
            toDate={tourInterval.endsAt}
          />
        </div>
        <div className="mb-2 flex-1 ">
          <FormInput
            control={form.control}
            name={"departureTime"}
            label=""
            type="time"
            placeholder={"05:00"}
          />
        </div>
      </div>
      <FormHeading text={t("forms:travelEvent.arrival")} />
      <GooglePlacesTravelSearchbox label="Destination" type="arrival" />
      <div className="flex items-end gap-4">
        <div className="flex-1">
          <DatePickerHtml
            control={form.control}
            name={"arrivalDate"}
            label={t("forms:travelEvent.arrivalTime") + "*"}
            fromDate={tourInterval.startsAt}
            toDate={tourInterval.endsAt}
          />
        </div>
        <div className="mb-2 flex-1 ">
          <FormInput
            control={form.control}
            name={"arrivalTime"}
            label=""
            type="time"
            placeholder={"05:00"}
          />
        </div>
      </div>
      <OvernightEventCheckbox />
    </div>
  );
}

function AirForm() {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const travelSchema = getExtendedSharedTravelSchema(t);
  const form = useFormContext<z.infer<typeof travelSchema>>();
  const formValues = form.getValues();
  const [flightNumber, setFlightNumber] = useState(formValues.flightNumber);
  const [query, setQuery] = useState("");
  const debouncedFlightNumber = useDebounce(flightNumber, 1500);

  const { data: flights, isLoading: areFlightsLoading } = useFlights({
    airline: formValues.airline || "",
    endDate: toApiDateFormat(formValues.arrivalDate),
    // regular expression to remove zeros at the beginning of the flight number
    flightNumber: debouncedFlightNumber?.toString().replace(/^0+/, "") || "",
    startDate: toApiDateFormat(formValues.departureDate),
  });

  const onFlightSelect = (value: FlightInfoDto) => {
    const scheduledOut = new Date(value.scheduledOut);
    const scheduledIn = new Date(value.scheduledIn);
    const scheduledOutHours =
      scheduledOut.getHours() < 10
        ? `0${scheduledOut.getHours()}`
        : scheduledOut.getHours();
    const scheduledOutMinutes =
      scheduledOut.getMinutes() < 10
        ? `0${scheduledOut.getMinutes()}`
        : scheduledOut.getMinutes();
    const scheduledOutTime = `${scheduledOutHours}:${scheduledOutMinutes}`;
    const scheduledInHours =
      scheduledIn.getHours() < 10
        ? `0${scheduledIn.getHours()}`
        : scheduledIn.getHours();
    const scheduledInMinutes =
      scheduledIn.getMinutes() < 10
        ? `0${scheduledIn.getMinutes()}`
        : scheduledIn.getMinutes();
    const scheduledInTime = `${scheduledInHours}:${scheduledInMinutes}`;

    form.setValue("arrivalDate", new Date(value.scheduledIn));
    form.setValue("departureDate", new Date(value.scheduledOut));
    form.setValue("arrivalTime", scheduledInTime);
    form.setValue("departureTime", scheduledOutTime);
    form.setValue("departureOrigin", value.origin.name);
    form.setValue("destination", value.destination.name);
    form.setValue("timezoneId", value.destination.timezone);
    form.setValue("arrivalAirportCode", value.destination.codeIcao);
    form.setValue("departureAirportCode", value.identIcao);
    form.setValue("flightInfo", value);
    form.setValue("departureTimezoneId", value.origin.timezone);
    form.setValue("arrivalTimezoneId", value.destination.timezone);
    form.trigger();
  };

  return (
    <div className="flex flex-col gap-4">
      <ArtiSelect
        items={
          query
            ? airlinesOptions.filter((airline) =>
                airline.label.toLowerCase().includes(query.toLowerCase())
              )
            : airlinesOptions
        }
        label={t("forms:travelEvent.airline")}
        placeholder={t("forms:travelEvent.selectAirline")}
        isLoading={areFlightsLoading}
        async={true}
        value={formValues.flightInfo?.identIata.slice(0, 2)}
        onChange={(value) => {
          if (value.length > 2) return;

          form.setValue("airline", value);
          form.trigger("airline");
        }}
        onAsyncChange={(query) => {
          setQuery(query);
        }}
      />
      <Input
        label={t("forms:travelEvent.flightNumber")}
        value={flightNumber}
        type="number"
        onChange={(e) => {
          setFlightNumber(Number(e.target.value));
          form.setValue("flightNumber", Number(e.target.value));
        }}
      />
      {Array.isArray(flights?.flights) && flights.flights.length ? (
        <ArtiSelect
          //@ts-expect-error - passed object as value instead of a string
          items={flights.flights.map((flight) => ({
            label: `${flight.origin.city} (${formatDateAndTime(toZonedTime(new Date(flight.scheduledOut), flight.origin.timezone))}) ==> ${flight.destination.city} (${formatDateAndTime(toZonedTime(new Date(flight.scheduledIn), flight.destination.timezone))})`,
            value: flight,
          }))}
          className="bg-primary hover:bg-primary-hover focus-visible:ring-primary w-32 disabled:opacity-70"
          placeholder={"Select flight"}
          //@ts-expect-error - passed object as value instead of a string
          onChange={onFlightSelect}
          value={""}
        />
      ) : form.getValues("departureOrigin") ? null : (
        <Body className="text-foreground-two">
          No flights found, please make sure you filled the airline and flight
          number.
        </Body>
      )}
      {form.getValues("departureOrigin") ? (
        <div className="flex flex-col gap-1">
          <Body size="lg" className="text-foreground-two">
            Departure
          </Body>
          <Body>
            {formatDateAndTime(
              toZonedTime(
                formValues.departureDate,
                formValues.flightInfo?.origin.timezone || ""
              )
            ) +
              " - " +
              form.getValues("departureOrigin")}
          </Body>
          <Body size="lg" className="text-foreground-two">
            Arrival
          </Body>
          <Body>
            {formatDateAndTime(
              toZonedTime(
                formValues.arrivalDate,
                formValues.flightInfo?.destination.timezone || ""
              )
            ) +
              " - " +
              form.getValues("destination")}
          </Body>
        </div>
      ) : null}
      <OvernightEventCheckbox />
    </div>
  );
}

function RailForm({ tourInterval }: EventFormProps) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const form = useFormContext<FieldValues>();

  return (
    <div className="flex flex-col gap-4">
      <FormHeading text={t("forms:travelEvent.departure")} />
      <GooglePlacesTravelSearchbox label="Origin" type="departure" />
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="basis-1/2">
          <FormInput
            control={form.control}
            name={"trainNumber"}
            label={t("forms:travelEvent.trainNumber")}
            placeholder=""
          />
        </div>
        <div className="flex grow basis-1/2 items-end gap-4">
          <div className="flex-1">
            <DatePickerHtml
              control={form.control}
              name={"departureDate"}
              label={t("forms:travelEvent.departureTime") + "*"}
              fromDate={tourInterval.startsAt}
              toDate={tourInterval.endsAt}
            />
          </div>
          <div className="mb-2 flex-1 ">
            <FormInput
              control={form.control}
              name={"departureTime"}
              label=""
              type="time"
              placeholder={"05:00"}
            />
          </div>
        </div>
      </div>
      <FormHeading text={t("forms:travelEvent.arrival")} />
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="basis-1/2">
          <GooglePlacesTravelSearchbox label="Destination" type="arrival" />
        </div>
        <div className="flex grow basis-1/2 items-end gap-4">
          <div className="flex-1">
            <DatePickerHtml
              control={form.control}
              name={"arrivalDate"}
              label={t("forms:travelEvent.arrivalTime") + "*"}
              fromDate={tourInterval.startsAt}
              toDate={tourInterval.endsAt}
            />
          </div>
          <div className="mb-2 flex-1 ">
            <FormInput
              control={form.control}
              name={"arrivalTime"}
              label=""
              type="time"
              placeholder={"05:00"}
            />
          </div>
        </div>
      </div>
      <OvernightEventCheckbox />
    </div>
  );
}

function SeaForm({ tourInterval }: EventFormProps) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const form = useFormContext<FieldValues>();

  return (
    <div className="flex flex-col gap-4">
      <FormHeading text={t("forms:travelEvent.departure")} />
      <div className="flex gap-4">
        <div className="flex-1">
          <GooglePlacesTravelSearchbox label="Origin" type="departure" />
        </div>
        <div className="flex-1">
          <FormInput
            control={form.control}
            name={"departureAirportCode"}
            label={t("forms:travelEvent.airportCode")}
            placeholder=""
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="basis-1/2">
          <FormInput
            control={form.control}
            name={"sealNumber"}
            label={t("forms:travelEvent.sealNumber")}
            placeholder=""
          />
        </div>
        <div className="flex grow basis-1/2 items-end gap-4">
          <div className="flex-1">
            <DatePickerHtml
              control={form.control}
              name={"departureDate"}
              label={t("forms:travelEvent.departureTime") + "*"}
              fromDate={tourInterval.startsAt}
              toDate={tourInterval.endsAt}
            />
          </div>
          <div className="mb-2 flex-1 ">
            <FormInput
              control={form.control}
              name={"departureTime"}
              label=""
              type="time"
              placeholder={"05:00"}
            />
          </div>
        </div>
      </div>
      <FormHeading text={t("forms:travelEvent.arrival")} />
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="basis-1/2">
          <GooglePlacesTravelSearchbox label="Destination" type="arrival" />
        </div>
        <div className="flex grow basis-1/2 items-end gap-4">
          <div className="flex-1">
            <DatePickerHtml
              control={form.control}
              name={"arrivalDate"}
              label={t("forms:travelEvent.arrivalTime") + "*"}
              fromDate={tourInterval.startsAt}
              toDate={tourInterval.endsAt}
            />
          </div>
          <div className="mb-2 flex-1 ">
            <FormInput
              control={form.control}
              name={"arrivalTime"}
              label=""
              type="time"
              placeholder={"05:00"}
            />
          </div>
        </div>
      </div>
      <OvernightEventCheckbox />
    </div>
  );
}
