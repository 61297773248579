import { useEffect, useMemo, useState } from "react";
import { CalendarIcon } from "lucide-react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { TourDayDto } from "@/api/base-api";
import { LabelText } from "@/components/Typography/Typography";
import { ChevronDownIcon } from "@/components/Icon/icons";
import { Icon } from "@/components/Icon/Icon";
import { TourDayTag, TourDayTags } from "@articulate/shared";
import { ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import { dayTagsQueryOptions, useDayTags } from "@/api/queries/useDayTags";
import { useTourSuspense } from "@/api/queries/useTour";
import { queryClient } from "@/api/client";
import { useCreateDayTag } from "@/api/mutations/useCreateDayTag";
import { formatDate, toApiDateFormat } from "@/lib/dates";
import { tourDaysQueryOptions } from "@/api/queries/useTourDays";
import { useCreateTourDay } from "@/api/mutations/useCreateTourDay";
import { useRemoveDayTag } from "@/api/mutations/useRemoveDayTag";
import { useTourRole } from "./tourAccess";
import { useUserSubscriptions } from "@/hooks/useUserSubscriptions";

type Props = {
  selectedDay: Date;
  setSelectedDay: (date: Date) => void;
  tourDays: { date: Date; tourDay: TourDayDto | undefined }[];
};

type TourAssignProps = {
  dayIndex: number;
  selectedDay: Props["selectedDay"];
  tourDays: Props["tourDays"];
};

export const TourDatePicker = ({
  selectedDay,
  setSelectedDay,
  tourDays,
}: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <Popover>
      <PopoverTrigger
        asChild
        className="[&>svg]:data-[state=open]:-scale-y-100">
        <Button
          variant="secondary"
          className={cn(
            "bg-surface-one  flex-shrink-0 justify-start text-left font-normal"
          )}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          {selectedDay ? (
            formatDate(selectedDay)
          ) : (
            <span>{t("pickADate")}</span>
          )}
          <ChevronDownIcon className="ml-2 h-4 w-4 transition-transform duration-500" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          className="p-4"
          fromDate={tourDays[0]?.date}
          selected={selectedDay}
          onSelect={(date) => date && setSelectedDay(date)}
          initialFocus
          disabled={{
            after: tourDays?.at(-1)?.date,
            before: tourDays?.[0]?.date,
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export const TourDaysNavigation = ({
  tourDays,
  selectedDay,
  setSelectedDay,
}: Props) => {
  const { tourId } = useParams();
  const [dayIndex, setDayIndex] = useState(0);
  const isPrevDisabled = dayIndex === 0;
  const isNextDisabled = dayIndex === tourDays.length - 1;

  const { isSubscriptionActive } = useUserSubscriptions();
  const { isManager } = useTourRole(tourId!);

  const tourDaysWithFormattedDate = useMemo(
    () =>
      tourDays.map((day) => ({
        ...day,
        formattedDate: toApiDateFormat(day.date),
      })),
    [tourDays]
  );

  const setDate = (index: number) => {
    setSelectedDay(tourDaysWithFormattedDate?.[index]?.date);
    return index;
  };

  useEffect(() => {
    const index = tourDaysWithFormattedDate?.findIndex((item) => {
      return item.formattedDate === toApiDateFormat(selectedDay);
    });

    if (index >= 0) {
      setDayIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  const changeDay = (type: "previous" | "next") => {
    if (type === "previous" && dayIndex > 0) {
      setDayIndex((prevState) => setDate(prevState - 1));
    }
    if (type === "next" && dayIndex + 1 < tourDays.length) {
      setDayIndex((prevState) => setDate(prevState + 1));
    }
  };

  return (
    <div className="flex w-full flex-row justify-between">
      <div className="hidden flex-row gap-2 sm:flex">
        <Button
          variant="secondary"
          className="text-foreground-one"
          size="icon"
          onClick={() => changeDay("previous")}
          disabled={isPrevDisabled}>
          <Icon name="ChevronLeftIcon" size="base" />
        </Button>
        <Button
          variant="secondary"
          className="text-foreground-one"
          size="icon"
          onClick={() => changeDay("next")}
          disabled={isNextDisabled}>
          <Icon name="ChevronRightIcon" size="base" />
        </Button>
      </div>
      {isManager && isSubscriptionActive && (
        <TourAssignType
          dayIndex={dayIndex}
          tourDays={tourDays}
          selectedDay={selectedDay}
        />
      )}
    </div>
  );
};

export const TourAssignType = ({
  dayIndex,
  tourDays,
  selectedDay,
}: TourAssignProps) => {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const { tourId } = useParams();
  const { data } = useTourSuspense(tourId!);

  const { data: dayTags } = useDayTags(data.workspaceId);

  const { mutate } = useCreateDayTag();
  const { mutate: createTourDay } = useCreateTourDay();
  const { mutateAsync: removeDayTag } = useRemoveDayTag();

  const selectedDayType = tourDays?.[dayIndex]?.tourDay?.dayTagId || "";

  const onDayTypeSubmit = (dayId: string) => {
    if (!data?.id) return;

    createTourDay(
      {
        tourId: data.id,
        data: {
          date: toApiDateFormat(selectedDay),
          dayTagId: dayId,
          workspaceId: data.workspaceId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: tourDaysQueryOptions(tourId!).queryKey,
          });
        },
      }
    );
  };

  const createDayTag = (value: string) => {
    const dayTagsNames = dayTags?.dayTags.map((dayTag) => dayTag.name);
    if (dayTagsNames?.includes(value)) return;

    mutate(
      {
        name: value,
        workspaceId: data.workspaceId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: dayTagsQueryOptions(data.workspaceId).queryKey,
          });
        },
      }
    );
  };

  const removeDayTagHandler = async (tagId: string) => {
    await removeDayTag(
      { tagId },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: dayTagsQueryOptions(data.workspaceId).queryKey,
          });
        },
      }
    );
  };

  return (
    <ArtiSelect
      items={
        dayTags
          ? dayTags.dayTags.map((tag) => ({
              label: TourDayTags[tag.name as TourDayTag]
                ? t(`dashboard:tour.dayTags.${tag.name}`, {
                    defaultValue: tag.name,
                  })
                : tag.name,
              value: tag.id,
              className: TourDayTags[tag.name as TourDayTag] ? "" : "italic",
              canBeDeleted: !TourDayTags[tag.name as TourDayTag],
            }))
          : []
      }
      withCreate
      onCreateSubmit={createDayTag}
      onItemDelete={removeDayTagHandler}
      placeholder={t("dashboard:createTour.dayType")}
      defaultValue={selectedDayType}
      className="w-full sm:w-32"
      wrapperClassName="w-full sm:w-auto"
      onChange={onDayTypeSubmit}
      key={`${dayIndex}`}
    />
  );
};

export const TourChangeDays = ({
  selectedDay,
  setSelectedDay,
  tourDays,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(["common"]);

  return (
    <div
      className={cn("p-6 pb-0", {
        "p-0": searchParams.get("tourId"),
      })}>
      <LabelText className="text-foreground-one pb-2" size="md">
        {t("pickADate")}
      </LabelText>
      <div className="flex flex-col gap-4 sm:flex-row">
        <TourDatePicker
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          tourDays={tourDays}
        />
        <TourDaysNavigation
          tourDays={tourDays}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
      </div>
    </div>
  );
};

export default TourChangeDays;
