import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

type QueryParams = {
  from: string;
  to: string;
  workspaceId: string | null;
  tourIds?: string[] | null;
};

export const combinedCalendarQueryOptions = (queryParams: QueryParams) =>
  queryOptions({
    queryKey: [
      "combinedCalendar",
      queryParams.from,
      queryParams.to,
      queryParams.tourIds,
      queryParams.workspaceId,
    ],
    queryFn: async () => {
      const response =
        await API.combinedCalendar.combinedCalendarControllerFindAllByTour(
          queryParams
        );
      return response.data;
    },
    enabled:
      !!queryParams.from && !!queryParams.to && !!queryParams.workspaceId,
  });

export const useCombinedCalendar = (queryParams: QueryParams) => {
  return useQuery(combinedCalendarQueryOptions(queryParams));
};

export const useCombinedCalendarSuspense = (queryParams: QueryParams) => {
  return useSuspenseQuery(combinedCalendarQueryOptions(queryParams));
};
