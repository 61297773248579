import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { Control, FieldValues, Path } from "react-hook-form";
import { FormField, FormItem, FormMessage } from "../ui/form";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Body, LabelText } from "../Typography/Typography";
import { ClassValue } from "clsx";

type ArtiSelectProps = {
  className?: string;
  wrapperClassName?: string;
  items: {
    label: string;
    value: string;
    className?: ClassValue;
    canBeDeleted?: boolean;
  }[];
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  id?: string;
  label?: string;
  async?: boolean;
  onAsyncChange?: (value: string) => void;
  isLoading?: boolean;
  minKeywordLength?: number;
  value?: string;
  onItemDelete?: (value: string) => void;
} & (
  | {
      withCreate: true;
      onCreateSubmit: (value: string) => void;
    }
  | {
      withCreate?: never;
      onCreateSubmit?: never;
    }
);

export function ArtiSelect(props: ArtiSelectProps & { error?: string | null }) {
  const { t } = useTranslation("common");
  const [genericValue, setGenericValue] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const minLength = props.minKeywordLength || 3;

  return (
    <div
      className={cn("space-y-2", props.wrapperClassName)}
      onKeyDown={() => {
        if (props.withCreate || props.async) {
          inputRef.current?.focus();
        }
      }}>
      {props.label && (
        <LabelText className="translate-y-1">{props.label}</LabelText>
      )}
      <Select
        open={open}
        onOpenChange={setOpen}
        defaultValue={props.defaultValue}
        onValueChange={props.onChange}
        {...(props.value !== undefined ? { value: props.value } : {})}>
        <SelectTrigger
          onPointerDown={(e) => e.preventDefault()}
          onClick={() => {
            setOpen((prev) => !prev);

            if (!props.withCreate || !props.async) return;
            setGenericValue("");
          }}
          className={cn(
            "min-w-36 bg-surface-two dark:bg-surface-one",
            props.className
          )}
          id={props.label}>
          <SelectValue
            placeholder={props.placeholder || props.items?.[0]?.label}
          />
        </SelectTrigger>
        <SelectContent
          // Prevents the propagtion of select items - HACKY
          // https://github.com/radix-ui/primitives/issues/1658
          ref={(ref) =>
            ref?.addEventListener("touchend", (e) => {
              e.preventDefault();
            })
          }>
          {props.async && (
            <input
              type="text"
              ref={inputRef}
              className="focus:bg-surface-two text-foreground-one hover:bg-surface-two bg-surface-one relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm text-black outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              value={genericValue}
              placeholder={props.placeholder || t("typeToCreate")}
              onChange={(e) => {
                setGenericValue(e.target.value);
                if (e.target.value.length < minLength) return;
                props.onAsyncChange?.(e.target.value);
              }}
            />
          )}
          {props.withCreate && (
            <input
              type="text"
              ref={inputRef}
              className="focus:bg-surface-two text-foreground-one hover:bg-surface-two bg-surface-one relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm text-black outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              value={genericValue}
              placeholder={props.placeholder || t("typeToCreate")}
              onKeyDown={(e) => {
                if (e.code !== "Enter") return;
                props.onCreateSubmit(genericValue);
                setGenericValue("");
              }}
              onChange={(e) => {
                setGenericValue(e.target.value);
              }}
            />
          )}
          {props.items.map((item) => (
            <SelectItem
              key={item.value}
              value={item.value}
              className={cn(item.className)}
              {...(item.canBeDeleted
                ? { onItemDelete: props.onItemDelete }
                : {})}>
              {item.label}
            </SelectItem>
          ))}
          {props.isLoading && (
            <Body size={"md"} className="text-foreground-two p-2">
              Loading...
            </Body>
          )}
          {props.items.length === 0 && !props.isLoading && (
            <Body size={"md"} className="text-foreground-two p-2">
              No content
            </Body>
          )}
        </SelectContent>
      </Select>
      {props.error && (
        <p className={cn("text-error text-sm font-medium")}>{props.error}</p>
      )}
    </div>
  );
}

type ArtiFormSelectProps<T extends FieldValues> = ArtiSelectProps & {
  control: Control<T>;
  name: Path<T>;
  omitErrorMessage?: boolean;
  omitDefaultFormHanlder?: boolean;
};

export function ArtiFormSelect<T extends FieldValues>({
  wrapperClassName,
  ...props
}: ArtiFormSelectProps<T>) {
  return (
    <FormField
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormItem className={wrapperClassName}>
          <ArtiSelect
            {...props}
            defaultValue={props.defaultValue || field.value}
            onChange={(value) => {
              if (!props.omitDefaultFormHanlder) {
                field.onChange(value);
              }
              props.onChange?.(value);
            }}
          />
          {!props.omitErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
