import { useTranslation } from "react-i18next";
import { Form } from "@/components/ui/form";
import { renderEventForm } from "@/modules/Tours/Events/utils/renderEventForm";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { PersonalEventName, TourEventType } from "@articulate/shared";
import { EventDto, TourWithRoleAndArtistDto } from "@/api/base-api";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { ForwardedRef } from "react";

type Props = {
  onHide: () => void;
  onSubmit: SubmitHandler<FieldValues & { eventName: string; id: string }>;
  form: UseFormReturn<
    FieldValues,
    undefined,
    FieldValues & { eventName: string; id: string }
  >;
  tour?:
    | TourWithRoleAndArtistDto
    | {
        startsAt: TourWithRoleAndArtistDto["startsAt"] | "";
        endsAt: TourWithRoleAndArtistDto["endsAt"] | "";
      };
  eventType: TourEventType | PersonalEventName;
  event?: EventDto | undefined | [];
  formRef?: ForwardedRef<HTMLFormElement>;
};

const EventFormFields = ({
  onHide,
  onSubmit,
  form,
  tour = { startsAt: "", endsAt: "" },
  eventType,
  formRef,
}: Props) => {
  const { t } = useTranslation(["dashboard", "forms", "common"]);

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values) => {
            await onSubmit(values);
            onHide();
          })}
          ref={formRef}>
          {eventType && (
            <div className="flex flex-col gap-4 py-4">
              <div className="flex flex-col gap-4">
                {renderEventForm(eventType, tour)}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4">
            <Separator />
            <div className="flex gap-4">
              <Button className="md:max-w-44 flex grow" type="submit">
                {t("common:save")}
              </Button>
              <Button
                variant="secondary"
                className="md:max-w-44 flex grow"
                type="button"
                onClick={onHide}>
                {t("common:cancel")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};
export default EventFormFields;
