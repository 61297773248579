import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const eventPersonnelRolesQueryOptions = queryOptions({
  queryKey: ["eventPersonnelRoles"],
  queryFn: async () => {
    const response =
      await API.eventPersonnelRoles.eventPersonnelRolesControllerFindAll();
    return response.data;
  },
});

export const useEventPersonnelRoles = () => {
  return useQuery(eventPersonnelRolesQueryOptions);
};

export const useEventPersonnelRolesSuspense = () => {
  return useSuspenseQuery(eventPersonnelRolesQueryOptions);
};
