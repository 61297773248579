import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateEventPersonnelDto } from "../base-api";

export type CreateEventPersonnelArgs = {
  id: string;
  data: CreateEventPersonnelDto;
};

export const useCreateEventPersonnel = () => {
  return useMutation({
    mutationKey: ["createEventPersonnel"],
    mutationFn: async ({ id, data }: CreateEventPersonnelArgs) => {
      const response = await API.events.eventsControllerCreatePersonnel(
        id,
        data
      );

      return response.data;
    },
  });
};
