// This list contains airlines names and IATA codes
export var airlinesList = [
    { name: "Aces", code: "VX" },
    { name: "Action Airlines", code: "XQ" },
    { name: "Acvilla Air", code: "WZ" },
    { name: "ADA Air", code: "ZY" },
    { name: "Adria Airways", code: "JP" },
    { name: "Aea International Pte", code: "7X" },
    { name: "AER Lingus Limited", code: "EI" },
    { name: "Aero Asia International", code: "E4" },
    { name: "Aero California", code: "JR" },
    { name: "Aero Continente", code: "N6" },
    { name: "Aero Costa Rica Acori", code: "ML" },
    { name: "Aero Lineas Sosa", code: "P4" },
    { name: "Aero Lloyd Flugreisen", code: "YP" },
    { name: "Aero postal Alas", code: "VH" },
    { name: "Aero Service", code: "5R" },
    { name: "Aero Services Executive", code: "W4" },
    { name: "Aero Zambia", code: "Z9" },
    { name: "Aerocaribe", code: "QA" },
    { name: "Aerocondor Trans Aereos", code: "2B" },
    { name: "Aeroejecutivo SA de CV", code: "SX" },
    { name: "Aeroflot Russian", code: "SU" },
    { name: "Aeroleasing SA", code: "FP" },
    { name: "Aeroline Gmbh", code: "7E" },
    { name: "Aerolineas Argentinas", code: "AR" },
    { name: "Aerolineas Dominicanas", code: "YU" },
    { name: "Aerolineas Internacional", code: "N2" },
    { name: "Aerolineas Paraguayas", code: "A8" },
    { name: "Aerolineas Santo Domingo", code: "EX" },
    { name: "Aeromexico", code: "AM" },
    { name: "Aeronautica de Cancun", code: "RE" },
    { name: "Aeroperlas", code: "WL" },
    { name: "Aeroperu", code: "PL" },
    { name: "Aeropostal", code: "P5" },
    { name: "Aerosanta Airlines", code: "UJ" },
    { name: "Aeroservicios Carabobo", code: "R7" },
    { name: "Aerosur", code: "5L" },
    { name: "Aerosweet", code: "VV" },
    { name: "Aerotour Dominicano Airline", code: "5N" },
    { name: "Aerovias Dap SA", code: "X8" },
    { name: "Aerovias Venezolanas", code: "V0" },
    { name: "African Intercontinental", code: "OY" },
    { name: "Aigle Azur", code: "ZI" },
    { name: "Air Affaires Afrique", code: "6R" },
    { name: "Air Afrique", code: "RK" },
    { name: "Air Algerie", code: "AH" },
    { name: "Air Alliance", code: "3J" },
    { name: "Air Alpha", code: "CQ" },
    { name: "Air Alps Aviation", code: "A6" },
    { name: "Air Aruba", code: "FQ" },
    { name: "Air Atlantic", code: "9A" },
    { name: "Air Atlantic Dominicana", code: "LU" },
    { name: "Air Austral", code: "UU" },
    { name: "Air B C", code: "ZX" },
    { name: "Air Baltic Corp", code: "BT" },
    { name: "Air Belgium", code: "AJ" },
    { name: "Air Berlin", code: "AB" },
    { name: "Air Bosna", code: "JA" },
    { name: "Air Botnia", code: "KF" },
    { name: "Air Botswana", code: "BP" },
    { name: "Air Bristol Dba Ab Airline", code: "7L" },
    { name: "Air Burkina", code: "2J" },
    { name: "Air Burundi", code: "PB" },
    { name: "Air Caledonie", code: "TY" },
    { name: "Air Calédonie International", code: "SB" },
    { name: "Air Canada", code: "AC" },
    { name: "Air Caraibes", code: "WS" },
    { name: "Air Caribbean Ltd", code: "C2" },
    { name: "Air Charter", code: "R9" },
    { name: "Air Charter Asia", code: "UT" },
    { name: "Air Chathams", code: "CV" },
    { name: "Air China International", code: "CA" },
    { name: "Air City", code: "4F" },
    { name: "Air Company Donbass Airline", code: "5D" },
    { name: "Air Company Elf Air", code: "E6" },
    { name: "Air Company Kraiaero", code: "K3" },
    { name: "Air Creebec", code: "YN" },
    { name: "Air Djibouti", code: "DY" },
    { name: "Air Dolomiti Spa", code: "EN" },
    { name: "Air East", code: "ER" },
    { name: "Air East Limited", code: "A5" },
    { name: "Air Engiadina", code: "RQ" },
    { name: "Air Europa", code: "UX" },
    { name: "Air Europe Spa", code: "PE" },
    { name: "Air Express", code: "XV" },
    { name: "Air Facilities", code: "FZ" },
    { name: "Air Fiji Ltd", code: "PC" },
    { name: "Air France", code: "AF" },
    { name: "Air Gabon", code: "GN" },
    { name: "Air Georgia", code: "DA" },
    { name: "Air Glaciers SA", code: "GB" },
    { name: "Air Greece", code: "JG" },
    { name: "Air Iceland", code: "NY" },
    { name: "Air India", code: "AI" },
    { name: "Air Inter Europe", code: "IT" },
    { name: "Air Inuit Ltd", code: "3H" },
    { name: "Air Ivoire", code: "VU" },
    { name: "Air Jamaica", code: "JM" },
    { name: "Air Jet", code: "BC" },
    { name: "Air Kazakhstan", code: "9Y" },
    { name: "Air Kenya Aviation", code: "QP" },
    { name: "Air Kilroe Limited", code: "9R" },
    { name: "Air Koryo", code: "JS" },
    { name: "Air Liberté", code: "IJ" },
    { name: "Air Lines of Kuban", code: "GW" },
    { name: "Air Link Pty", code: "DR" },
    { name: "Air Lithuania", code: "TT" },
    { name: "Air Littoral", code: "FU" },
    { name: "Air Macau Company Ltd", code: "NX" },
    { name: "Air Madagascar", code: "MD" },
    { name: "Air Malawi Limited", code: "QM" },
    { name: "Air Maldives Ltd", code: "L6" },
    { name: "Air Mali SA", code: "L9" },
    { name: "Air Malta", code: "KM" },
    { name: "Air Mandalay Ltd", code: "6T" },
    { name: "Air Manitoba", code: "7N" },
    { name: "Air Marshall Islands Inc", code: "CW" },
    { name: "Air Mauritanie", code: "MR" },
    { name: "Air Mauritius", code: "MK" },
    { name: "Air Midwest", code: "ZV" },
    { name: "Air Mobility Command", code: "MC" },
    { name: "Air Moldova", code: "9U" },
    { name: "Air Moldova International", code: "RM" },
    { name: "Air Namibia", code: "SW" },
    { name: "Air Nauru", code: "ON" },
    { name: "Air Nevada", code: "LW" },
    { name: "Air New Zealand Ltd", code: "NZ" },
    { name: "Air Nippon Co Ltd", code: "EL" },
    { name: "Air Niugini", code: "PX" },
    { name: "Air Normandie", code: "ID" },
    { name: "Air North", code: "4N" },
    { name: "Air Nostrum", code: "YW" },
    { name: "Air Nova", code: "QK" },
    { name: "Air Nunavut", code: "YH" },
    { name: "Air One", code: "AP" },
    { name: "Air Ostrava Ltd", code: "8K" },
    { name: "Air Pacific Ltd", code: "FJ" },
    { name: "Air Philippines Corp", code: "2P" },
    { name: "Air Provence", code: "P3" },
    { name: "Air Rarotonga", code: "GZ" },
    { name: "Air Rwanda", code: "RY" },
    { name: "Air Sask Aviation", code: "7W" },
    { name: "Air Senegal", code: "DS" },
    { name: "Air Seychelles", code: "HM" },
    { name: "Air Sicilia", code: "BM" },
    { name: "Air Sinai", code: "4D" },
    { name: "Air Slovakia BWJ", code: "GM" },
    { name: "Air South Airlines Inc", code: "WV" },
    { name: "Air South West", code: "8H" },
    { name: "Air St Barthelemy", code: "OJ" },
    { name: "Air St Martin", code: "S6" },
    { name: "Air St Pierre", code: "PJ" },
    { name: "Air St Thomas", code: "ZP" },
    { name: "Air Stord", code: "GO" },
    { name: "Air Straubing", code: "IU" },
    { name: "Air Sunshine", code: "YI" },
    { name: "Air Tahiti", code: "VT" },
    { name: "Air Tahiti Nui", code: "TN" },
    { name: "Air Tanzania Corp", code: "TC" },
    { name: "Air Tindi Ltd", code: "8T" },
    { name: "Air Toulouse", code: "SH" },
    { name: "Air Trans Ireland Services", code: "T7" },
    { name: "Air Transport Pyrenees", code: "TF" },
    { name: "Air Truck", code: "ZH" },
    { name: "Air Tungaru", code: "VK" },
    { name: "Air Two Thousand Ltd", code: "DP" },
    { name: "Air UK Ltd", code: "UK" },
    { name: "Air Ukraine", code: "6U" },
    { name: "Air Urga", code: "3N" },
    { name: "Air Vanuatu", code: "NF" },
    { name: "Air Vegas", code: "6V" },
    { name: "Air Wisconsin Airlines", code: "ZW" },
    { name: "Air Zaire", code: "QC" },
    { name: "Air Zambezi", code: "ZT" },
    { name: "Air Zimbabwe", code: "UM" },
    { name: "Airasia Sdn Bhd", code: "AK" },
    { name: "Airborne of Sweden AB", code: "ZF" },
    { name: "Aircompany Airzena", code: "A9" },
    { name: "Aires", code: "4C" },
    { name: "Airlanka Ltd", code: "UL" },
    { name: "Airlines of Carriacou", code: "C4" },
    { name: "Airlines of Tasmania", code: "IP" },
    { name: "Airlink Airline Ltd", code: "5T" },
    { name: "Airlink Pty Ltd", code: "ND" },
    { name: "Airmoth Regional", code: "TL" },
    { name: "Airpac Airlines", code: "LQ" },
    { name: "Airports Intl Airways", code: "VZ" },
    { name: "Airtran Airways", code: "FL" },
    { name: "Airways International", code: "HO" },
    { name: "Airwork", code: "HT" },
    { name: "Ajt Air International", code: "E9" },
    { name: "Aklak Inc", code: "6L" },
    { name: "Alaska Airlines", code: "AS" },
    { name: "Alaska Central Express", code: "KO" },
    { name: "Alaska Island Air Inc", code: "6D" },
    { name: "Alaska Seaplane Service", code: "J1" },
    { name: "Albanian Airlines Mak", code: "LV" },
    { name: "Alitalia", code: "AZ" },
    { name: "Alitalia Express", code: "XM" },
    { name: "Alitalia Team", code: "RD" },
    { name: "All Nippon Airways", code: "NH" },
    { name: "Alliance", code: "Y2" },
    { name: "Alliance Air", code: "CD" },
    { name: "Alliance Airlines", code: "EO" },
    { name: "Aloha Airlines", code: "AQ" },
    { name: "Aloha Islandair", code: "WP" },
    { name: "Alpi Eagles Spa", code: "E8" },
    { name: "Alpine Aviation", code: "5A" },
    { name: "America West Airlines", code: "HP" },
    { name: "American Airlines", code: "AA" },
    { name: "American Trans Air", code: "TZ" },
    { name: "Amtrak", code: "2V" },
    { name: "Angola Air Charter", code: "C3" },
    { name: "Ansett", code: "AN" },
    { name: "Ansett New Zealand", code: "ZQ" },
    { name: "Antigua Paradise Airways", code: "HU" },
    { name: "Antillean Airlines", code: "LM" },
    { name: "AOM French Airlines", code: "IW" },
    { name: "Apa International Air", code: "7P" },
    { name: "Appolo Airlines S A", code: "K6" },
    { name: "Archana Airways Ltd", code: "F5" },
    { name: "Arctic Air As", code: "8A" },
    { name: "Arctic Transportation", code: "7S" },
    { name: "Ariana Afghan Airlines", code: "FG" },
    { name: "Aries del Sur", code: "D4" },
    { name: "Arkia Israeli Airlines", code: "IZ" },
    { name: "Armenian Airlines", code: "R3" },
    { name: "Asia Service Airlines", code: "Y3" },
    { name: "Asiana Airlines", code: "OZ" },
    { name: "Aspen Mountain Air", code: "AD" },
    { name: "Aspiring Air Services", code: "OI" },
    { name: "Astanair", code: "A7" },
    { name: "Astoria", code: "S3" },
    { name: "Astral aviation", code: "AL" },
    { name: "Athabaska Airways", code: "9T" },
    { name: "Atlant Sv", code: "L4" },
    { name: "Atlantic Airways", code: "RC" },
    { name: "Atlantic Southeast Airlines", code: "EV" },
    { name: "Augsburg Airways", code: "IQ" },
    { name: "Augusta Airways", code: "HB" },
    { name: "Aurigny Air Services", code: "GR" },
    { name: "AUS Air", code: "NO" },
    { name: "Austin Express", code: "7V" },
    { name: "Austral Lineas Aereas", code: "AU" },
    { name: "Australian Commuter Air", code: "KC" },
    { name: "Austrian Airlines", code: "OS" },
    { name: "Austrian Airtransport", code: "OG" },
    { name: "Avant Airlines", code: "OT" },
    { name: "Avensa", code: "VE" },
    { name: "Avia Air Nv", code: "3R" },
    { name: "Aviacion del Noroeste SA", code: "OC" },
    { name: "Aviaco", code: "AO" },
    { name: "Aviacompany Turkmenistan", code: "T5" },
    { name: "Aviacsa", code: "6A" },
    { name: "Avianca", code: "AV" },
    { name: "Avianergo", code: "7U" },
    { name: "Aviaprima Airlines", code: "J5" },
    { name: "Aviateca", code: "GU" },
    { name: "Aviation Commercial", code: "BJ" },
    { name: "Aviation Mineralnye Vody", code: "Q6" },
    { name: "Avior", code: "3B" },
    { name: "Aviosarda", code: "DF" },
    { name: "Azerbaijan Hava Yollari", code: "J2" },
    { name: "Azzurra Air Spa", code: "ZS" },
    { name: "Bahamasair", code: "UP" },
    { name: "Baikal Airlines", code: "X3" },
    { name: "Baker Aviation", code: "8Q" },
    { name: "Balkan", code: "LZ" },
    { name: "Baltic Intl Airlines", code: "TI" },
    { name: "Bangkok Airways", code: "PG" },
    { name: "Base Regional Airlines", code: "5E" },
    { name: "Bashkir Airlines", code: "V9" },
    { name: "Baxter Aviation", code: "6B" },
    { name: "Bearskin Airlines", code: "JV" },
    { name: "Belavia", code: "B2" },
    { name: "Bellair Inc", code: "7G" },
    { name: "Bellview Airlines Ltd", code: "B3" },
    { name: "Bemidji Airlines", code: "CH" },
    { name: "Bering Air", code: "8E" },
    { name: "Berjaya Air", code: "J8" },
    { name: "Bhoja Airlines Ltd", code: "B4" },
    { name: "Big Sky Airlines", code: "GQ" },
    { name: "Biman Bangladesh Airline", code: "BG" },
    { name: "Binter Canarias", code: "NT" },
    { name: "Binter Mediterraneo", code: "AX" },
    { name: "Blade Helicopters", code: "H9" },
    { name: "Blue Sky Carrier", code: "B5" },
    { name: "Bonaire Airways", code: "YM" },
    { name: "Bouraq Airlines", code: "5F" },
    { name: "Braathens", code: "BU" },
    { name: "Bright Air", code: "BO" },
    { name: "Brit Air", code: "DB" },
    { name: "Britannia Airways", code: "BY" },
    { name: "British Airways", code: "BA" },
    { name: "British International", code: "BS" },
    { name: "British Mediterranean", code: "KJ" },
    { name: "British Midland Airways", code: "BD" },
    { name: "Buffalo Airways Ltd", code: "J4" },
    { name: "Business Air Ltd", code: "II" },
    { name: "Business Express", code: "HQ" },
    { name: "Buzz", code: "ZZ" },
    { name: "Bwia International", code: "BW" },
    { name: "Calm Air International", code: "MO" },
    { name: "Cambodia Intl Airlines", code: "XE" },
    { name: "Cameroon Airlines", code: "UY" },
    { name: "Canada Three Thousand", code: "2T" },
    { name: "Canadian Airlines", code: "CP" },
    { name: "Canarias Regional Air", code: "FW" },
    { name: "Cape Air", code: "9K" },
    { name: "Cape Smythe Air Service", code: "6C" },
    { name: "Cape York Air", code: "NS" },
    { name: "Cardinal Airlines Ltd", code: "NN" },
    { name: "Carib Express Ltd", code: "IM" },
    { name: "Caribbean Air", code: "9G" },
    { name: "Caribbean Airlines Inc", code: "B9" },
    { name: "Carnival Airlines", code: "KW" },
    { name: "Carribean Winds Airlines", code: "W8" },
    { name: "Casino Express Airlines", code: "XP" },
    { name: "Cathay Pacific", code: "CX" },
    { name: "Cayman Airways Ltd", code: "KX" },
    { name: "CCair", code: "ED" },
    { name: "Cebu Pacific Air", code: "5J" },
    { name: "Centennial Airlines SA", code: "BE" },
    { name: "Central Mountain Air", code: "9M" },
    { name: "Chalair", code: "M6" },
    { name: "Changan Airlines", code: "2Z" },
    { name: "Chicago Express Airlines", code: "C8" },
    { name: "China Airlines", code: "CI" },
    { name: "China Eastern Airlines", code: "MU" },
    { name: "China General Aviation", code: "GP" },
    { name: "China National Aviation", code: "F6" },
    { name: "China Northern Airlines", code: "CJ" },
    { name: "China Northwest Airlines", code: "WH" },
    { name: "China Southern Airlines", code: "CZ" },
    { name: "China Southwest Airlines", code: "SZ" },
    { name: "China Xinhua Airlines", code: "X2" },
    { name: "China Yunnan Airlines", code: "3Q" },
    { name: "Cimber Air", code: "QI" },
    { name: "City Bird", code: "H2" },
    { name: "Cityflyer Express", code: "FD" },
    { name: "Cityjet", code: "WX" },
    { name: "Co Aéronautique Européen", code: "C9" },
    { name: "Coast Air", code: "BX" },
    { name: "Coastal Air Transport", code: "DQ" },
    { name: "Colgan Air", code: "9L" },
    { name: "Color Air", code: "G2" },
    { name: "Columbia Pacific Airline", code: "7C" },
    { name: "Comair", code: "OH" },
    { name: "Commercial Airways", code: "MN" },
    { name: "Companía Dominicana", code: "DO" },
    { name: "Condor Flugdienst", code: "DE" },
    { name: "Conquest Airlines", code: "5C" },
    { name: "Contact Air", code: "V8" },
    { name: "Continental Airlines", code: "CO" },
    { name: "Continental Micro", code: "CS" },
    { name: "Copa Companía Panamena", code: "CM" },
    { name: "Coral International Air", code: "KN" },
    { name: "Corp Airlines Canberra", code: "UH" },
    { name: "Corporate Express Airline", code: "3C" },
    { name: "Corse Air International", code: "SS" },
    { name: "Corse Méditerranée", code: "XK" },
    { name: "Cosmos Air", code: "ZE" },
    { name: "Country Connection Airline", code: "XL" },
    { name: "Cretan Airlines SA", code: "C5" },
    { name: "Crimea Air", code: "OR" },
    { name: "Croatia Airlines", code: "OU" },
    { name: "Cronus Airlines", code: "X5" },
    { name: "Crossair", code: "LX" },
    { name: "Crossair Europe", code: "QE" },
    { name: "Cubana de Aviación", code: "CU" },
    { name: "Cypress Airlines", code: "R6" },
    { name: "Cyprus Airways", code: "CY" },
    { name: "Czech Airlines", code: "OK" },
    { name: "Daallo Airlines", code: "D3" },
    { name: "Dac Air", code: "6P" },
    { name: "Danish Air Transport", code: "DX" },
    { name: "Debonair Airways", code: "2G" },
    { name: "Delta Air Lines", code: "DL" },
    { name: "Denim Air", code: "2D" },
    { name: "Deutsche BA", code: "DI" },
    { name: "Deutsche Bahn AG", code: "2A" },
    { name: "Diamond Sakha Airlines", code: "D8" },
    { name: "Dinar Lineas Aereas SA", code: "D7" },
    { name: "Djibouti Airlines", code: "MG" },
    { name: "Dnieproavia State", code: "Z6" },
    { name: "Dolphin Express Airlines", code: "8U" },
    { name: "Domodedovo Airlines", code: "E3" },
    { name: "Donetsk Aviation", code: "Q8" },
    { name: "Dorado Air SA", code: "4U" },
    { name: "Downeast Express", code: "E7" },
    { name: "Druk Air", code: "KB" },
    { name: "Eagle Airlines", code: "ZN" },
    { name: "Eagle Aviation Ltd", code: "Y4" },
    { name: "Eagle Canyon Airlines", code: "FE" },
    { name: "Eas Europe Airlines", code: "EA" },
    { name: "East West Airline", code: "4S" },
    { name: "Eastair", code: "XZ" },
    { name: "Eastern Airways Ltd", code: "T3" },
    { name: "Eastland Air", code: "DK" },
    { name: "Eastwind Capital Partner", code: "W9" },
    { name: "Easyjet Airline Company Ltd", code: "U2" },
    { name: "Ecuato Guineana De Aviac", code: "8Y" },
    { name: "Ecuatoriana De Aviación", code: "EU" },
    { name: "Egyptair", code: "MS" },
    { name: "El Al Israel Airlines", code: "LY" },
    { name: "Emerald Airways", code: "G3" },
    { name: "Emerald European Airways", code: "YE" },
    { name: "Emirates", code: "EK" },
    { name: "Era Aviation", code: "7H" },
    { name: "Estonian Air", code: "OV" },
    { name: "Estonian Aviation", code: "S8" },
    { name: "Ethiopian Airlines", code: "ET" },
    { name: "Euralair International", code: "RN" },
    { name: "Eureca SRL", code: "F4" },
    { name: "Euroair", code: "TH" },
    { name: "Euroflight Sweden AB", code: "HZ" },
    { name: "Eurofly Spa", code: "GJ" },
    { name: "Europe Elite", code: "Y6" },
    { name: "European Air Express", code: "M3" },
    { name: "European Air Transport", code: "QY" },
    { name: "European Airlines", code: "2H" },
    { name: "European Airways Ltd", code: "L8" },
    { name: "Euroscot Airways Ltd", code: "MY" },
    { name: "Eurosky Airlines", code: "JO" },
    { name: "Eurowings", code: "EW" },
    { name: "Eva Airways Corp", code: "BR" },
    { name: "Everest Air Ltd", code: "E2" },
    { name: "Executive Airlines", code: "NA" },
    { name: "Executive Express Ltd.", code: "5W" },
    { name: "Expedition Airways", code: "FO" },
    { name: "Express Airlines", code: "9E" },
    { name: "Expresso Aero", code: "N8" },
    { name: "F Airlines B V", code: "FV" },
    { name: "F'airlines B.V.", code: "4X" },
    { name: "Fairlines", code: "LK" },
    { name: "Falcon Aviation AB", code: "IH" },
    { name: "Far Eastern Air Transport", code: "EF" },
    { name: "Faucett", code: "CF" },
    { name: "Fedex", code: "FX" },
    { name: "Finnair", code: "AY" },
    { name: "Finnaviation", code: "FA" },
    { name: "First Air", code: "7F" },
    { name: "Flagship Airlines", code: "8N" },
    { name: "Flamenco Airways", code: "FK" },
    { name: "Flandre Air", code: "IX" },
    { name: "Flight West Airlines", code: "YC" },
    { name: "Flying Colours Airlines", code: "MT" },
    { name: "Flying Enterprise", code: "F3" },
    { name: "Formosa Airlines Corp.", code: "VY" },
    { name: "Forty Mile Air", code: "Q5" },
    { name: "Freedom Air", code: "ZU" },
    { name: "Frontier Airlines", code: "F9" },
    { name: "Frontier Flying Service", code: "2F" },
    { name: "Fujian Airlines", code: "IV" },
    { name: "Futura International", code: "FH" },
    { name: "Gandalf Airlines Spa", code: "G7" },
    { name: "Garuda Indonesia", code: "GA" },
    { name: "GB Airways", code: "GT" },
    { name: "Georgian Airlines", code: "3P" },
    { name: "Ghana Airways", code: "GH" },
    { name: "Gill Aviation Ltd", code: "9C" },
    { name: "Go One Airways", code: "GK" },
    { name: "Golden Air Flyg Ab", code: "DC" },
    { name: "Gomelavia", code: "YD" },
    { name: "Gonini Air Servic", code: "5Z" },
    { name: "Grand International Airway", code: "8L" },
    { name: "Grant Aviation", code: "G9" },
    { name: "Great American Airways", code: "MV" },
    { name: "Great China Airlines", code: "IF" },
    { name: "Great Lakes Aviation Ltd", code: "ZK" },
    { name: "Greenlandair", code: "GL" },
    { name: "Guard Air", code: "FB" },
    { name: "Guizhou Airlines", code: "G4" },
    { name: "Gujarat Airways Ltd", code: "G8" },
    { name: "Gulf Air", code: "GF" },
    { name: "Gulfstream International", code: "3M" },
    { name: "Guyana Airways", code: "GY" },
    { name: "Hageland Aviation", code: "H6" },
    { name: "Hahn Air", code: "HR" },
    { name: "Hahnair Friedrichshafen", code: "FY" },
    { name: "Hainan Airlines", code: "H4" },
    { name: "Haines Airways", code: "7A" },
    { name: "Haiti Aviation", code: "2N" },
    { name: "Haiti Trans Air", code: "X4" },
    { name: "Halisa Air", code: "WD" },
    { name: "Hanair Haiti Ntl Air", code: "8F" },
    { name: "Hapag Lloyd", code: "HF" },
    { name: "Harbor Airlines", code: "HG" },
    { name: "Harbour Air Ltd", code: "H3" },
    { name: "Hawaiian Airlines", code: "HA" },
    { name: "Hazelton Airlines", code: "ZL" },
    { name: "Helenair Corporation Ltd", code: "2Y" },
    { name: "Helgoland Airlines", code: "LE" },
    { name: "Heli Air Monaco", code: "YO" },
    { name: "Heli Inter Riviera", code: "EC" },
    { name: "Heli Trans", code: "5H" },
    { name: "Helicopteros Del Cusco", code: "ES" },
    { name: "Helicopteros del Sureste", code: "UV" },
    { name: "Helijet Airways", code: "JB" },
    { name: "Helikopterservice", code: "YQ" },
    { name: "Hemus Air", code: "DU" },
    { name: "HEX Air", code: "UD" },
    { name: "Highland Air AB", code: "HS" },
    { name: "Hong Kong Dragon Airline", code: "KA" },
    { name: "Horizon Air", code: "QX" },
    { name: "IBERIA", code: "IB" },
    { name: "Icelandair", code: "FI" },
    { name: "Iliamna Air Taxi Inc", code: "LS" },
    { name: "Imperial Air", code: "7I" },
    { name: "Impulse Airlines Ltd", code: "VQ" },
    { name: "Indian Airlines Ltd", code: "IC" },
    { name: "Inter Air", code: "D6" },
    { name: "Inter Air Direct", code: "JC" },
    { name: "Interbrasil Star S.A.", code: "Q9" },
    { name: "Intercont de Aviación", code: "RS" },
    { name: "Interimpex Avioimpex", code: "M4" },
    { name: "International Aviation", code: "YK" },
    { name: "International Business Air", code: "U5" },
    { name: "Intersomal", code: "A2" },
    { name: "Iran Air", code: "IR" },
    { name: "Iran Asseman Airlines", code: "Y7" },
    { name: "Iraqi Airways", code: "IA" },
    { name: "Ireland Airways", code: "2E" },
    { name: "Iron Dragon Fly Air Co", code: "F7" },
    { name: "Island Air Ltd", code: "G5" },
    { name: "Island Airlines", code: "IS" },
    { name: "Island Airlines Pty", code: "DG" },
    { name: "Island Express", code: "2S" },
    { name: "Island Nationair", code: "CN" },
    { name: "Islandsflug", code: "HH" },
    { name: "Islena de Inversiones SA", code: "WC" },
    { name: "Isles Of Scilly Skybus", code: "5Y" },
    { name: "Istanbul Airlines", code: "IL" },
    { name: "Italair", code: "B8" },
    { name: "Itapemirim Transportes", code: "K9" },
    { name: "JAL Japan Airlines", code: "JL" },
    { name: "Japan Air Commuter", code: "3X" },
    { name: "Japan Air System Company", code: "JD" },
    { name: "Japan Asia Airways", code: "EG" },
    { name: "Japan Transocean Air Co", code: "NU" },
    { name: "Jaro Intl Airlines", code: "JT" },
    { name: "Jersey European", code: "JY" },
    { name: "Jet Airways Inc", code: "QJ" },
    { name: "Jet Airways Ltd", code: "9W" },
    { name: "Jet Aspen", code: "J9" },
    { name: "Jet Aviation Business AG", code: "PP" },
    { name: "Jet Train Corp", code: "LF" },
    { name: "Jetall", code: "8J" },
    { name: "JetBlue", code: "B6" },
    { name: "Joint Stock Aviation Co", code: "D9" },
    { name: "Joint Stock Co East Line", code: "P7" },
    { name: "Joint Stock Company", code: "X7" },
    { name: "Joint Stock Company Siat", code: "5M" },
    { name: "Kaiken Lineas Aereas", code: "L3" },
    { name: "Kaliningrad Airlines", code: "K8" },
    { name: "Kampuchea Airlines", code: "KT" },
    { name: "Kar Air", code: "KR" },
    { name: "Karlog Air", code: "2L" },
    { name: "Kazakhstan Airlines", code: "K4" },
    { name: "KD Air Corporation", code: "XC" },
    { name: "Kendell Airlines", code: "KD" },
    { name: "Kenmore Air", code: "M5" },
    { name: "Kenn Borek Air", code: "4K" },
    { name: "Kenya Airways", code: "KQ" },
    { name: "Keystone Air Service", code: "BZ" },
    { name: "Khabarovsk Aviation", code: "H8" },
    { name: "Khors Aircompany Ltd", code: "X6" },
    { name: "Kitty Hawk Airlines Inc", code: "2K" },
    { name: "Kiwi International Air", code: "KP" },
    { name: "KLM", code: "KL" },
    { name: "KLM City Hopper", code: "WA" },
    { name: "KLM Exel", code: "XT" },
    { name: "Knight Air", code: "NE" },
    { name: "Korean Air", code: "KE" },
    { name: "Korsar", code: "6K" },
    { name: "Krasnoyarsk Airlines", code: "7B" },
    { name: "Kuwait Airways", code: "KU" },
    { name: "Kyrghyzstan Airlines", code: "K2" },
    { name: "Kyrnair", code: "KH" },
    { name: "L.B. Limited", code: "72" },
    { name: "LAB Flying Service", code: "JF" },
    { name: "Labrador Airways Ltd", code: "WJ" },
    { name: "Lacsa Airlines", code: "LR" },
    { name: "Ladeco Airlines", code: "UC" },
    { name: "Laker Airways Inc", code: "6F" },
    { name: "LAM Mozambique", code: "TM" },
    { name: "Lan Chile", code: "LA" },
    { name: "Lan Peru", code: "LP" },
    { name: "Landair Intl Airlines", code: "BN" },
    { name: "Lao Aviation", code: "QV" },
    { name: "Lapa", code: "MJ" },
    { name: "Larrys Flying Service", code: "7K" },
    { name: "Las Vegas Airlines", code: "6G" },
    { name: "Lauda Air", code: "NG" },
    { name: "Lesotho Airways", code: "QL" },
    { name: "Lgw Luftfahrtges Walter", code: "HE" },
    { name: "Liat Caribbean Airline", code: "LI" },
    { name: "Libyan Airlines", code: "LN" },
    { name: "Lina Congo", code: "GC" },
    { name: "Lincoln Airlines", code: "RT" },
    { name: "Linea Aerea Ejecutivo", code: "KZ" },
    { name: "Linea Aerea IAACA", code: "KG" },
    { name: "Lineas Aereas Allegro", code: "LL" },
    { name: "Lineas Aereas del Estado", code: "5U" },
    { name: "Link Airways", code: "XU" },
    { name: "Lithuanian Airlines", code: "TE" },
    { name: "Lloyd Aero Boliviano", code: "LB" },
    { name: "Loganair", code: "LC" },
    { name: "Long Island Airlines Ltd", code: "YL" },
    { name: "Love Air", code: "4J" },
    { name: "LTU", code: "LT" },
    { name: "Lufthansa", code: "LH" },
    { name: "Lufthansa Citylin", code: "CL" },
    { name: "LUFTTAXI FLUGGESELLSCHAFT", code: "DV" },
    { name: "Lufttransport", code: "L5" },
    { name: "Luxair", code: "LG" },
    { name: "Lviv Airlines", code: "L7" },
    { name: "Lynden Air Cargo", code: "IK" },
    { name: "Macair Airlines", code: "CC" },
    { name: "Macedonian Airlines", code: "IN" },
    { name: "Maersk Air", code: "DM" },
    { name: "Maersk Air Ltd", code: "VB" },
    { name: "Mafira Air", code: "3A" },
    { name: "Magadan Airlines", code: "H5" },
    { name: "Mahfooz Aviation G Ltd", code: "M2" },
    { name: "Majestic Airways", code: "9F" },
    { name: "Malaysia Airlines", code: "MH" },
    { name: "Malev Hungarian Airlines", code: "MA" },
    { name: "Malmo Aviation", code: "6E" },
    { name: "Malta Air Charter", code: "R5" },
    { name: "Mandarin Airlines", code: "AE" },
    { name: "Manx Airlines", code: "JE" },
    { name: "Markair", code: "BF" },
    { name: "Martinair Holland", code: "MP" },
    { name: "Master Aviation", code: "T9" },
    { name: "Maverick Airways Corp", code: "6M" },
    { name: "Maxair", code: "8M" },
    { name: "Maya Airways", code: "MW" },
    { name: "Mayan World Airlines", code: "EY" },
    { name: "Meridiana Spa", code: "IG" },
    { name: "Merpati", code: "MZ" },
    { name: "Mesa Airlines", code: "YV" },
    { name: "Mesaba Airlines", code: "XJ" },
    { name: "Mexicana", code: "MX" },
    { name: "Miat Mongolian Airlines", code: "OM" },
    { name: "Middle East Airlines", code: "ME" },
    { name: "Midway Airlines", code: "ال" },
    { name: "Midwest Express Airlines", code: "YX" },
    { name: "Millenium Air Corp", code: "2Q" },
    { name: "Milne Bay Air Pty", code: "CG" },
    { name: "Minerva Airlines", code: "02" },
    { name: "Minskavia", code: "4M" },
    { name: "Missionary Aviation", code: "FS" },
    { name: "Modiluft Ltd", code: "M9" },
    { name: "Moldavian Airlines", code: "2M" },
    { name: "Monarch Airlines", code: "ZB" },
    { name: "Moscow Airways", code: "M8" },
    { name: "Mount Cook Airline", code: "NM" },
    { name: "Mustique Airways", code: "Q4" },
    { name: "Myanmar Airways Intl", code: "UB" },
    { name: "Nanjing Airlines", code: "3W" },
    { name: "Naske Air", code: "HC" },
    { name: "National Air Sakha Aviation", code: "K7" },
    { name: "National Airlines Chile", code: "YJ" },
    { name: "National Airlines Inc.", code: "N7" },
    { name: "National Jet Systems Pty", code: "NC" },
    { name: "Nationwide Air", code: "CE" },
    { name: "Necon Air", code: "3Z" },
    { name: "Nego Airline One", code: "YA" },
    { name: "New England Airlines", code: "EJ" },
    { name: "New York Helicopter Corp", code: "HD" },
    { name: "Newwest Airlines Inc", code: "Z5" },
    { name: "Nica", code: "6Y" },
    { name: "Nice Hélicoptères", code: "JX" },
    { name: "Nigeria Airways", code: "WT" },
    { name: "Noman", code: "U4" },
    { name: "Nordeste Linhas Aereas", code: "JH" },
    { name: "Nordic European Airlines", code: "DJ" },
    { name: "Norontair", code: "NR" },
    { name: "North Coast Aviation Pty", code: "N9" },
    { name: "North Vancouver Airlines", code: "VL" },
    { name: "North Wright Air", code: "HW" },
    { name: "Northern Airlines Sanya", code: "QB" },
    { name: "Northern Star Airlines", code: "UO" },
    { name: "Northwest Airlines", code: "NW" },
    { name: "Northwestern Air Lease", code: "3E" },
    { name: "Nwt Air", code: "NV" },
    { name: "O Connor Mt Gambiers", code: "UQ" },
    { name: "Olson Air Service", code: "4B" },
    { name: "OLT Ostfriesische Lufttransport", code: "OL" },
    { name: "Olympic Airways", code: "OA" },
    { name: "Oman Air", code: "WY" },
    { name: "OneWorld Alliance", code: "OW" },
    { name: "Orbi Georgian Air", code: "NQ" },
    { name: "Ord Air Charter PTY LTD", code: "RF" },
    { name: "Orient Avia", code: "V6" },
    { name: "Orient Express Air", code: "OX" },
    { name: "P.T. Dirgantara Air Service", code: "AW" },
    { name: "Pacific Airlines", code: "BL" },
    { name: "Pacific Airways Corp", code: "GX" },
    { name: "Pacific Coastal Airlines", code: "8P" },
    { name: "Pacific Eagle Airlines", code: "JW" },
    { name: "Pacific Island Aviation", code: "9J" },
    { name: "Pacific Midland Airlines", code: "2W" },
    { name: "Pakistan International", code: "PK" },
    { name: "Palair Macedonian Air", code: "3D" },
    { name: "Palestinian Airlines", code: "PF" },
    { name: "Pan Am Air Bridge", code: "OP" },
    { name: "Pan American", code: "PA" },
    { name: "Pantanal Linhas Aereas", code: "P8" },
    { name: "Papillon Airways", code: "HI" },
    { name: "Paradise Island", code: "BK" },
    { name: "Passadero Trans Aereos", code: "Y8" },
    { name: "Pauknair", code: "PV" },
    { name: "Pelangi Air", code: "9P" },
    { name: "Pelita Air Service", code: "EP" },
    { name: "Pemair", code: "PD" },
    { name: "Penair", code: "KS" },
    { name: "Perimeter Airlines", code: "UW" },
    { name: "Philippine Airlines", code: "PR" },
    { name: "Pine State Airlines", code: "PW" },
    { name: "Pluna", code: "PU" },
    { name: "Polynesian Ltd", code: "PH" },
    { name: "Portugalia", code: "NI" },
    { name: "Prima Air", code: "QG" },
    { name: "Promech", code: "Z3" },
    { name: "Proteus", code: "YS" },
    { name: "Proteus Helicopters", code: "6S" },
    { name: "Provincial Airlines", code: "AG" },
    { name: "PT Mandala Airlines", code: "RI" },
    { name: "Ptarmigan Airways", code: "5P" },
    { name: "Pulkovo Airlines", code: "Z8" },
    { name: "Qantas Airways Ltd", code: "QF" },
    { name: "Qatar Airways", code: "QR" },
    { name: "Qwestair", code: "QH" },
    { name: "Ras Fluggesellschaft MBH", code: "RW" },
    { name: "Redwing Airways", code: "7R" },
    { name: "Redwing Airways Inc", code: "RX" },
    { name: "Reeve Aleutian Airways", code: "RV" },
    { name: "Region Air Carribean Ltd", code: "RL" },
    { name: "Regional Air Lines", code: "FN" },
    { name: "Regional Airlines", code: "VM" },
    { name: "Regional Lineas Aereas", code: "XG" },
    { name: "Reguljair", code: "R8" },
    { name: "Reno Air Inc", code: "QQ" },
    { name: "Rheintalflug Seewald", code: "WE" },
    { name: "Rich International Air", code: "JN" },
    { name: "Riga Airlines", code: "GV" },
    { name: "Rio Sul", code: "SL" },
    { name: "Rock Air", code: "8R" },
    { name: "Romavia", code: "WQ" },
    { name: "Rottnest Airlines", code: "DW" },
    { name: "Royal Air Cambodge", code: "VJ" },
    { name: "Royal Air Force", code: "RR" },
    { name: "Royal Air Maroc", code: "AT" },
    { name: "Royal Aviation", code: "QN" },
    { name: "Royal Brunei", code: "BI" },
    { name: "Royal Jordanian", code: "RJ" },
    { name: "Royal Nepal Airlines", code: "RA" },
    { name: "Royal Swazi National Air", code: "ZC" },
    { name: "Royal Tongan Airlines", code: "WR" },
    { name: "Russia", code: "R4" },
    { name: "Russian Airlines", code: "4T" },
    { name: "Ryanair", code: "FR" },
    { name: "Sabair Airlines", code: "ZG" },
    { name: "Sabena", code: "SN" },
    { name: "SAE Swe Aviation Europe", code: "S9" },
    { name: "Saeta", code: "EH" },
    { name: "Sahara India Airlines", code: "S2" },
    { name: "Salair Inc", code: "8S" },
    { name: "Samara Airlines", code: "E5" },
    { name: "Samoa Aviation", code: "TS" },
    { name: "SAN", code: "WB" },
    { name: "Sandaun Air Services", code: "Q3" },
    { name: "Sansa", code: "RZ" },
    { name: "Sar Avions Taxis", code: "QT" },
    { name: "Sardairline Soc Coop Airline", code: "N5" },
    { name: "Sata Air Acores", code: "SP" },
    { name: "Saudi Arabian Airlines", code: "SV" },
    { name: "Scandinavian Airlines", code: "SK" },
    { name: "Scenic Airlines", code: "YR" },
    { name: "Scibe Airlift", code: "ZM" },
    { name: "Seaborne Aviation Inc", code: "BB" },
    { name: "Selcon Airlines Ltd", code: "U9" },
    { name: "Sempati Air", code: "SG" },
    { name: "Servicios Avensa", code: "VC" },
    { name: "Shaheen Air", code: "NL" },
    { name: "Shandong Airlines", code: "SC" },
    { name: "Shanghai Airlines", code: "FM" },
    { name: "Shanxi Airlines", code: "8C" },
    { name: "Shenzhen Airlines", code: "4G" },
    { name: "Shepparton Airlines", code: "OB" },
    { name: "Shorouk Air", code: "7Q" },
    { name: "Shuswap Air", code: "3S" },
    { name: "Shuttle Air Cargo", code: "DD" },
    { name: "Shuttle Inc", code: "TB" },
    { name: "Siberia Airlines", code: "S7" },
    { name: "Sichuan Airlines", code: "3U" },
    { name: "Sierra National Airlines.", code: "LJ" },
    { name: "Silkair", code: "MI" },
    { name: "Simmons Airlines", code: "MQ" },
    { name: "Singapore Airlines", code: "SQ" },
    { name: "Skaergaardsflyget", code: "5Q" },
    { name: "Skippers Aviation Pty Lt", code: "PQ" },
    { name: "Sky West Airlines", code: "00" },
    { name: "Skyline Nepc Ltd", code: "D2" },
    { name: "Skytrans", code: "NP" },
    { name: "Skyways AB", code: "JZ" },
    { name: "Skywest Airlines", code: "YT" },
    { name: "Slovak Airlines", code: "6Q" },
    { name: "Sobelair", code: "Q7" },
    { name: "Soc Aero de Medellin", code: "MM" },
    { name: "Solomon Airlines", code: "IE" },
    { name: "South African Airlink", code: "4Z" },
    { name: "South African Airways", code: "SA" },
    { name: "South African Express", code: "YB" },
    { name: "Southeast European Air", code: "6J" },
    { name: "Southern Air Transport", code: "SJ" },
    { name: "Southern Independent Air", code: "F2" },
    { name: "Southern Winds", code: "A4" },
    { name: "Southwest Airlines Texas", code: "WN" },
    { name: "Spainair", code: "JK" },
    { name: "Spanair", code: "S4" },
    { name: "Spirit Airlines", code: "NK" },
    { name: "State Air Company Odessa", code: "5K" },
    { name: "State Air Company Tajikistan", code: "7J" },
    { name: "State Orenburg Avia", code: "R2" },
    { name: "State United Venture", code: "KV" },
    { name: "Ste Nile Air Guadeloupe", code: "TX" },
    { name: "Ste Nile Air Martinique", code: "PN" },
    { name: "Sterling Airways", code: "NB" },
    { name: "Suckling Airways", code: "CB" },
    { name: "Sudan Airways Co Ltd", code: "SD" },
    { name: "Sun Air", code: "BV" },
    { name: "Sun Air Of Scandinavia", code: "EZ" },
    { name: "Sun Country Airlines", code: "SY" },
    { name: "Sunflower Airlines", code: "PI" },
    { name: "Sunworld Intl Airlines", code: "SM" },
    { name: "Swan Airlines", code: "HK" },
    { name: "Swedeways AB", code: "HJ" },
    { name: "Swiftair SA", code: "W3" },
    { name: "Swiss World Airways", code: "SO" },
    { name: "Swissair", code: "SR" },
    { name: "Syrianair", code: "RB" },
    { name: "Taag", code: "DT" },
    { name: "Taba Transportes Aereos", code: "T2" },
    { name: "Taca Intl Airlines", code: "TA" },
    { name: "TACV Cabo Verde Airlines", code: "VR" },
    { name: "Tajikistan International", code: "W5" },
    { name: "TAM Meridional", code: "JJ" },
    { name: "Tam Regional", code: "KK" },
    { name: "Tamair", code: "GG" },
    { name: "Tame", code: "EQ" },
    { name: "Tanana Air Service", code: "4E" },
    { name: "TAP Air Portugal", code: "TP" },
    { name: "Taquan Air Service Inc", code: "H7" },
    { name: "Tarom", code: "RO" },
    { name: "TAS Airways", code: "TJ" },
    { name: "Tasawi Air Services Ltd", code: "9X" },
    { name: "Tatonduk Flying Service", code: "3K" },
    { name: "Tatra Air", code: "QS" },
    { name: "Tavrey Aircompany", code: "T6" },
    { name: "Tayfunair Inc", code: "Z4" },
    { name: "TCI Skyking Ltd", code: "RU" },
    { name: "Teddy Air", code: "ZJ" },
    { name: "Thai Airways Intl", code: "TG" },
    { name: "Thunderbird", code: "4A" },
    { name: "Thuringia Airlines", code: "FC" },
    { name: "Tie Aviation", code: "5B" },
    { name: "TNT Sava", code: "TD" },
    { name: "Tra.Aereos del Mercosur", code: "PZ" },
    { name: "Trans Aer Guine Bissau", code: "YZ" },
    { name: "Trans Aereos Ejecutivos", code: "GD" },
    { name: "Trans Air", code: "P6" },
    { name: "Trans Air Congo", code: "Y9" },
    { name: "Trans Cote", code: "7T" },
    { name: "Trans Jamaican Airlines", code: "JQ" },
    { name: "Trans North Aviation Ltd", code: "40" },
    { name: "Trans Pacific Airlines", code: "DN" },
    { name: "Trans State Airlines", code: "9N" },
    { name: "Trans Travel Airlines", code: "6N" },
    { name: "Trans World Airlines Inc", code: "TW" },
    { name: "Transaero Airlines", code: "UN" },
    { name: "Transasia Airways", code: "GE" },
    { name: "Transavia airlines", code: "HV" },
    { name: "Transbrasil", code: "TR" },
    { name: "Transcaraibes Air Intl", code: "DZ" },
    { name: "Transeast Airlines", code: "T4" },
    { name: "Transeuropean Airlines", code: "UE" },
    { name: "Transportes aereos", code: "4P" },
    { name: "Transportes Aereos Neuqu", code: "T8" },
    { name: "Transtate Airlines", code: "BH" },
    { name: "Trav Aeriens Madagascar.", code: "OF" },
    { name: "Travelair", code: "U3" },
    { name: "Tropic Air", code: "PM" },
    { name: "Tropical Airlines", code: "VF" },
    { name: "Tuninter", code: "UG" },
    { name: "Tunis Air", code: "TU" },
    { name: "Turan Air Airline Company", code: "3T" },
    { name: "Turkestan Airlines", code: "UF" },
    { name: "Turkish Airlines", code: "TK" },
    { name: "Turks and Caicos Airways", code: "QW" },
    { name: "Tymen Air Carrier", code: "P2" },
    { name: "Tyrolean Airways", code: "VO" },
    { name: "U Land Airlines", code: "WI" },
    { name: "Uganda Airlines", code: "QU" },
    { name: "Ukraine Intl Airlines", code: "PS" },
    { name: "Uni Airways Corp", code: "B7" },
    { name: "United Airlines", code: "UA" },
    { name: "United Aviation", code: "U7" },
    { name: "United Express", code: "DH" },
    { name: "UP Air", code: "UZ" },
    { name: "Ural Airlines", code: "U6" },
    { name: "US Airways", code: "US" },
    { name: "Uzbekistan Airways", code: "HY" },
    { name: "Valdresfly AS", code: "V2" },
    { name: "Valujet Airlines", code: "J7" },
    { name: "Vanair Limited", code: "V3" },
    { name: "Vancouver Island Air Ltd", code: "FT" },
    { name: "Vanguard Airlines Inc", code: "NJ" },
    { name: "Varig", code: "RG" },
    { name: "Vaspbrazilian Airlines", code: "VP" },
    { name: "Venus Airlines", code: "V4" },
    { name: "Viasa Venezolana", code: "VA" },
    { name: "Vieques Air Link", code: "VI" },
    { name: "Vietnam Airlines", code: "VN" },
    { name: "VIP Air", code: "9V" },
    { name: "Virgin Atlantic", code: "VS" },
    { name: "Virgin Express", code: "BQ" },
    { name: "Virgin Islands Airways", code: "S5" },
    { name: "Vistajet", code: "5V" },
    { name: "VLM Vlaamse", code: "VG" },
    { name: "Vnukovo Airlines", code: "V5" },
    { name: "Volare Airlines", code: "8D" },
    { name: "Volga Airlines", code: "G6" },
    { name: "Voyageur Airways", code: "4V" },
    { name: "Warbelows Air Ventures", code: "4W" },
    { name: "Wasaya Airways Ltd", code: "WG" },
    { name: "Waterwings Airways", code: "KY" },
    { name: "West Air Sweden", code: "PT" },
    { name: "West Coast Air", code: "8O" },
    { name: "West Isle Air", code: "7Y" },
    { name: "Westair Commuter Airline", code: "OE" },
    { name: "Western Airlines", code: "EM" },
    { name: "Western Pacific Air", code: "2U" },
    { name: "Western Pacific Airlines", code: "W7" },
    { name: "Whyalla Airlines", code: "WW" },
    { name: "Wideroe", code: "WF" },
    { name: "Wilderness Airlines", code: "6W" },
    { name: "Windward Island Airways", code: "WM" },
    { name: "Wings of Alaska", code: "SE" },
    { name: "World Airways", code: "WO" },
    { name: "Wright Air Services", code: "8V" },
    { name: "Xiamen Airlines", code: "MF" },
    { name: "Xinjiang Airlines", code: "XO" },
    { name: "Yanda Airlines", code: "ST" },
    { name: "Yemenia", code: "IY" },
    { name: "Yugoslav Airlines", code: "JU" },
    { name: "Yute Air Alaska", code: "4Y" },
    { name: "Zambian Express Airways", code: "OQ" },
    { name: "ZAS Airline of Egypt", code: "ZA" },
    { name: "Zimbabwe Express Air", code: "Z7" },
    { name: "Zuliana de Aviaci", code: "OD" },
];
