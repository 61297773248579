import { useTourSuspense } from "@/api/queries/useTour";
import { useEventTypes } from "@/api/queries/useEventTypes";
import { personalEventName, TourEventType } from "@articulate/shared";
import { EventForm } from "@/modules/Tours/Events/EventForm";
import { EventDto } from "@/api/base-api";

export const EventEditForm = ({
  event,
  onHide,
  tourId,
}: {
  event: EventDto;
  onHide: () => void;
  tourId: string;
}) => {
  const { data: tour } = useTourSuspense(tourId!);
  const { data: eventTypes } = useEventTypes();

  const eventType = event.eventTypeId
    ? (eventTypes?.eventTypes.find(
        (eventType) => eventType.id === event?.eventTypeId
      )?.name as TourEventType)
    : personalEventName;

  return (
    <EventForm
      event={event}
      onHide={onHide}
      tour={tour}
      eventType={eventType}
    />
  );
};
