import { MouseEventHandler, useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { EventDto, EventTypesDto, EventsDto } from "@/api/base-api";
import { queryClient } from "@/api/client";
import { useRemoveEvent } from "@/api/mutations/useRemoveEvent";
import { useEventTypes } from "@/api/queries/useEventTypes";
import { tourEventsQueryOptions } from "@/api/queries/useTourEvents";
import Circle from "@/components/Circle/Circle";
import { Icon } from "@/components/Icon/Icon";
import { Body, Header, LabelText } from "@/components/Typography/Typography";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { formatDate, getFormatedTime, toApiDateFormat } from "@/lib/dates";
import { getEventIconName } from "@/lib/getEventIconName";
import {
  personalEventName,
  PersonalEventName,
  TourEventType,
  TourEventTypes,
  tourEventTypes,
} from "@articulate/shared";
import { useTourRole } from "./tourAccess";
import { ArtiTooltip } from "@/components/ArtiTooltip";
import TourTabWrapper from "@/modules/Tours/TourTabWrapper";
import TourEmptyMessage from "@/modules/Tours/TourEmptyMessage";
import { CreateEvent, EventCreateForm } from "./Events/EventCreateForm";
import { EventEditForm } from "@/modules/Tours/Events/EventEditForm";
import { getFormattedEventType } from "@/modules/Tours/Events/eventsSchemas";
import { ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import { EventExpandItem } from "@/modules/Tours/Events/EventExpandItem";
import { Button } from "@/components/ui/button";
import {
  personalEventsQueryOptions,
  usePersonalEvents,
} from "@/api/queries/usePersonalEvents";
import { useRemovePersonalEvent } from "@/api/mutations/useRemovePersonalEvent";
import { tourSupplementalQuestionsQueryOptions } from "@/api/queries/useTourSupplementalQuestions";
import { DatePicker } from "@/components/DatePicker/DatePicker";
import { Path, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTourDayState } from "./TourDayContext";
import { Form } from "@/components/ui/form";
import { useTour } from "@/hooks/useTours";
import { useCopyDayEvents } from "@/api/mutations/useCopyDayEvents";
import { toast } from "@/components/ui/use-toast";
import { productionTrackerQueryOptions } from "@/api/queries/useProductionTracker";

const copyDaySchema = z.object({
  targetDate: z.date(),
});

function TourEvents({
  selectedDay,
  tourEvents,
}: {
  selectedDay: Date;
  tourEvents?: EventsDto;
}) {
  const { t } = useTranslation(["dashboard", "common"]);
  const navigate = useNavigate();
  const { tourId } = useParams();
  const { isManager, isOwner } = useTourRole(tourId!);
  const { data: personalEvents } = usePersonalEvents();
  const [isShownForm, setIsShownForm] = useState<boolean>(false);
  const { data: eventTypes } = useEventTypes();
  const [getValue, setValue] = useState<TourEventType | PersonalEventName | "">(
    ""
  );

  useEffect(() => {
    setIsShownForm(false);
    setValue("");
  }, [selectedDay]);

  const hotelStayEventTypeId = eventTypes?.eventTypes.find(
    (type) => type.name === TourEventTypes.HOTEL_STAY
  )?.id;

  const formatedSelectedDay = toApiDateFormat(selectedDay);
  const dayEvents = tourEvents?.events[formatedSelectedDay];
  const eventsPerDay = tourEvents?.events[formatedSelectedDay]?.filter(
    (event) => event.eventTypeId !== hotelStayEventTypeId
  );
  const personalEventsPerDay = personalEvents?.personalEvents.filter(
    (event) => {
      const shouldShowEvent =
        toApiDateFormat(new Date(event.startTime)) === formatedSelectedDay;

      return shouldShowEvent;
    }
  );
  const eventsInDay: EventDto[] = [
    ...(eventsPerDay || []),
    ...(personalEventsPerDay?.map((event) => ({
      endDate: event.endTime,
      startDate: event.startTime,
      streetAddress: event.streetAddress,
      timezoneId: event.timezone,
      updatedAt: event.updatedAt,
      city: event.city || "",
      countryId: event.countryId || "",
      createdAt: event.createdAt,
      eventTypeId: "",
      id: event.id,
      notes: event.notes,
      state: event.state || "",
      eventDetails: {
        description: "",
        name: event.name,
      },
      eventPersonnel: [],
      eventProvider: null,
      groups: [],
      tourId: "",
      visibleToAll: false,
      zipCode: "",
      additionalInformation: null,
      nextDay: false,
    })) || []),
  ];
  const sortedEventsPerDay = eventsInDay
    .sort(
      (event, event2) =>
        new Date(event.startDate || "").getTime() -
        new Date(event2.startDate || "").getTime()
    )
    .sort((a, b) => Number(a.nextDay) - Number(b.nextDay));

  const isExists = !!sortedEventsPerDay.length || isShownForm;

  const availableEventTypes = eventTypes
    ? [
        ...eventTypes.eventTypes
          .filter(() => {
            if (isManager || isOwner) return true;
            return false;
          })
          .map((eventType) => ({
            label: getFormattedEventType(eventType.name as TourEventType),
            value: eventType.name,
          })),
        {
          label: getFormattedEventType(personalEventName),
          value: personalEventName,
        },
      ]
    : [];

  const addEvent = (value: TourEventType | PersonalEventName) => {
    if (value === "PERSONAL") {
      setValue(value);
      return;
    }
    if (tourEventTypes.includes(value)) {
      setValue(value);
    }
  };

  const renderContent = () => {
    if (isShownForm && tourId) {
      return (
        <EventCreateForm
          tourId={tourId}
          onHide={() => setIsShownForm(false)}
          dayEvents={dayEvents}
          eventTypes={availableEventTypes}
        />
      );
    }

    return (
      <ScrollArea>
        <ul className="flex flex-col gap-2 pb-4">
          {sortedEventsPerDay?.map((event) => (
            <EventItem
              selectedDay={selectedDay}
              eventTypes={eventTypes}
              t={t}
              event={event}
              key={event.id}
            />
          ))}
          {getValue && tourId && (
            <div className="flex w-full grow flex-col rounded-2xl border p-6">
              <div className="flex justify-between">
                <Body size="lg" className="text-foreground-one">
                  {t("dashboard:events.createNewEvent")}
                </Body>
                <button type="button" onClick={() => setValue("")}>
                  <Icon name="TrashIcon" />
                </button>
              </div>
              <CreateEvent
                withScroll
                eventType={getValue}
                onHide={() => setValue("")}
                tourId={tourId}
                dayEvents={dayEvents}
              />
            </div>
          )}
        </ul>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    );
  };

  return (
    <TourTabWrapper
      id="item-0"
      isExists={isExists}
      title={"Schedule"}
      extraButton={
        isManager &&
        !isShownForm && (
          <div className="flex gap-2">
            <CopyDayWithModal />
            <ArtiTooltip content={"Export a day sheet"}>
              <Button
                variant="ghost"
                size={"icon"}
                onClick={() => {
                  navigate(`daysheets/${toApiDateFormat(selectedDay)}`);
                }}>
                <Icon name="CalendarIcon" />
              </Button>
            </ArtiTooltip>
            <ArtiSelect
              items={availableEventTypes}
              className="bg-primary hover:bg-primary-hover focus-visible:ring-primary w-32 disabled:opacity-70"
              placeholder={t("dashboard:events.addEvent")}
              onChange={addEvent}
              value={getValue}
            />
          </div>
        )
      }
      emptyMessage={() => (
        <TourEmptyMessage
          buttonOnClick={() => setIsShownForm(true)}
          buttonText={t("events.addEvent")}
          isManager={isManager}
          subTitle={t("events.addEventsToDay")}
          title={t("events.noEventsScheduled")}
        />
      )}>
      {renderContent()}
    </TourTabWrapper>
  );
}

export const getEventTimezones = ({
  isTravel,
  event,
}: {
  isTravel: boolean;
  event: EventDto;
}) => {
  if (!isTravel) {
    return { startTimezone: event.timezoneId, endTimezone: event.timezoneId };
  }

  const travelInfo = event.additionalInformation?.travel;
  switch (travelInfo?.travelType) {
    case "air":
      return {
        startTimezone: travelInfo.flightInfo?.origin.timezone,
        endTimezone: travelInfo.flightInfo?.destination.timezone,
      };
    case "ground":
    case "rail":
    case "sea":
      return {
        startTimezone: event.timezoneId,
        endTimezone: travelInfo.destinationTimezone,
      };
    default:
      return { startTimezone: event.timezoneId, endTimezone: event.timezoneId };
  }
};

function EventItem({
  eventTypes,
  selectedDay,
  t,
  event,
}: { event: EventDto } & {
  eventTypes: EventTypesDto | undefined;
  selectedDay: Date;
  t: TFunction<["dashboard", "common"]>;
}) {
  const { tourId } = useParams();

  const {
    startDate,
    endDate,
    city,
    eventTypeId,
    id,
    eventDetails,
    state,
    streetAddress,
    additionalInformation,
  } = event;
  const eventType = eventTypeId
    ? eventTypes?.eventTypes.find((eventType) => eventType?.id === eventTypeId)
    : { name: personalEventName };
  const isTravel = eventType?.name === TourEventTypes.TRAVEL;
  const { startTimezone, endTimezone } = getEventTimezones({ isTravel, event });
  const shortStartTimezone =
    startDate && formatInTimeZone(startDate, startTimezone || "", "zzz");
  const shortEndTimezone =
    endDate && endTimezone && formatInTimeZone(endDate, endTimezone, "zzz");
  const timezonedStart = toZonedTime(startDate!, startTimezone!);
  const timezonedEnd = toZonedTime(endDate!, endTimezone!);
  const startTime = startDate && getFormatedTime(timezonedStart);
  const endTime = endDate && getFormatedTime(timezonedEnd);
  const [editingEvent, setEditingEvent] = useState<EventDto | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const cityValue =
    !!city && city !== t("events.cityNotFound") ? `${city}, ` : "";
  const { isManager } = useTourRole(tourId!);

  const { mutateAsync: removeEvent } = useRemoveEvent();
  const { mutateAsync: removePersonalEvent } = useRemovePersonalEvent();

  useEffect(() => {
    setEditingEvent(null);
  }, [selectedDay]);

  const onEdit: MouseEventHandler<HTMLDivElement> = (editEvent) => {
    editEvent.stopPropagation();
    setEditingEvent(event);
  };

  const onDelete = async () => {
    const isPersonalEvent = !eventTypeId;

    if (isPersonalEvent) {
      await removePersonalEvent(
        {
          eventId: id,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: personalEventsQueryOptions.queryKey,
            });
          },
        }
      );
    }

    if (!isPersonalEvent) {
      await removeEvent(
        { id },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: tourEventsQueryOptions(tourId!).queryKey,
            });
          },
        }
      );

      if (eventType?.name !== TourEventTypes.SHOW) return;
      await queryClient.invalidateQueries({
        queryKey: productionTrackerQueryOptions(id).queryKey,
      });
    }

    await queryClient.invalidateQueries({
      queryKey: tourSupplementalQuestionsQueryOptions(tourId!).queryKey,
    });
  };

  return editingEvent ? (
    <div className="border-border rounded-2xl border p-3">
      <EventEditForm
        event={editingEvent}
        tourId={tourId!}
        onHide={() => setEditingEvent(null)}
      />
    </div>
  ) : (
    <div className="border-border w-full min-w-0 justify-between rounded-2xl border p-3">
      <div className="hover:bg-surface-one group flex w-full min-w-0 justify-between">
        <div
          className="flex min-w-0 grow cursor-pointer gap-3"
          onClick={() => setIsCollapsed((prevState) => !prevState)}>
          <div className="flex flex-shrink-0 flex-col items-center gap-1">
            <LabelText className="text-foreground-two">
              {startTime} {shortStartTimezone}
            </LabelText>
            <Separator
              orientation="vertical"
              className="border-foreground-two min-h-5 h-[calc(100%-50px)] border-r border-dashed bg-transparent"
            />
            {endTime && (
              <LabelText className="text-foreground-two">
                {endTime} {shortEndTimezone}
              </LabelText>
            )}
          </div>
          <div className="flex min-w-0 grow gap-2">
            {eventType?.name && (
              <Icon
                name={getEventIconName(
                  eventType.name as TourEventType | PersonalEventName,
                  additionalInformation?.travel?.travelType
                )}
                className="flex-shrink-0"
              />
            )}
            <div className="flex w-full grow flex-col">
              <div className="flex w-full grow flex-col justify-between gap-5">
                <Header
                  size="h2"
                  className="text-foreground-one text-left leading-6">
                  {tourEventTypes.includes(eventDetails?.name as TourEventType)
                    ? t(
                        `tour.eventTypes.${eventDetails?.name as TourEventType}`
                      )
                    : eventDetails?.name}
                </Header>
                {isTravel ? (
                  <div className="flex flex-col justify-between gap-2 md:flex-row md:items-center">
                    <div className="text-foreground-one flex basis-full flex-col items-start gap-2">
                      <LabelText className="text-foreground-two text-left">
                        {additionalInformation?.travel?.departureOrigin}
                      </LabelText>
                    </div>
                    <div className="min-w-6">
                      <Icon name="ArrowRightIcon" />
                    </div>
                    <div className="text-foreground-one flex basis-full flex-col items-start gap-2">
                      <LabelText className="text-foreground-two text-left">
                        {additionalInformation?.travel?.arrivalOrigin}
                      </LabelText>
                    </div>
                  </div>
                ) : (
                  <Body
                    size="md"
                    className="text-foreground-two whitespace-nowrap text-left">
                    {state && `${state}, `}
                    {cityValue}
                    {streetAddress}
                  </Body>
                )}
              </div>
              {!isCollapsed && (
                <EventExpandItem event={event} eventType={eventType?.name} />
              )}
            </div>
          </div>
        </div>
        {isManager && (
          <DropdownMenu>
            <div className="flex items-center">
              <DropdownMenuTrigger className="h-8 w-8 rounded-full">
                <Circle className="flex h-full w-full justify-center opacity-0 group-hover:opacity-100">
                  <Icon name="MoreVerticalIcon" />
                </Circle>
              </DropdownMenuTrigger>
            </div>
            <DropdownMenuContent
              side="right"
              className="bg-surface-one border-border rounded border px-0 py-1">
              <DropdownMenuItem onClick={onEdit}>
                {t("sidebar.artistMenu.edit")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={onDelete}>
                {t("common:delete")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
}

function CopyDayWithModal() {
  const { tourId } = useParams();
  const { t } = useTranslation(["dashboard"]);

  const [isOpen, setIsOpen] = useState(false);
  const { selectedDate } = useTourDayState();
  const { tourEvents } = useTour(tourId!);
  const formatedSelectedDate = selectedDate ? formatDate(selectedDate) : null;

  const { tourDays } = useTour(tourId!);
  const { mutateAsync: copyDayEvents } = useCopyDayEvents();

  const startsAt = new Date(tourDays[0].date);
  const endsAt = new Date(tourDays[tourDays.length - 1].date);
  const form = useForm<z.infer<typeof copyDaySchema>>({
    resolver: zodResolver(copyDaySchema),
  });

  const onDateChange = (
    name: Path<z.infer<typeof copyDaySchema>>,
    newDate: Date
  ) => {
    form.setValue(name, newDate, { shouldDirty: true });
    form.trigger(name);
  };

  const copyEvents = async () => {
    const targetDate = form.getValues("targetDate");
    if (!selectedDate || !tourId || !targetDate) return;

    // first event timezone of selected date
    const eventTimezone =
      tourEvents?.events[toApiDateFormat(selectedDate)]?.[0]?.timezoneId;

    if (!eventTimezone) {
      toast({
        variant: "failed",
        title: t("tour.copyEvents.youNeedAtLeastOneEvent"),
      });
      return;
    }

    await copyDayEvents({
      data: {
        initialDate: toApiDateFormat(selectedDate),
        targetDate: toApiDateFormat(targetDate),
        tourId,
        timezone: eventTimezone,
      },
    });

    await queryClient.invalidateQueries(tourEventsQueryOptions(tourId));
    setIsOpen(false);
    toast({
      variant: "success",
      title: t("tour.copyEvents.copiedSuccessfully"),
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <ArtiTooltip content={t("tour.copyEvents.copyEventsFromDay")}>
        <DialogTrigger asChild>
          <Button variant="ghost" size={"icon"}>
            <Icon name="LinkIcon" />
          </Button>
        </DialogTrigger>
      </ArtiTooltip>
      <DialogContent>
        <DialogTitle>{t("tour.copyEvents.selectTargetDate")}</DialogTitle>
        <Form {...form}>
          <div className="flex flex-col gap-6">
            <Body>
              {t("tour.copyEvents.eventsDate")} - {formatedSelectedDate}
            </Body>
            <DatePicker
              control={form.control}
              name="targetDate"
              onDateChange={onDateChange}
              label={t("tour.copyEvents.targetDate")}
              fromDate={startsAt}
              toDate={endsAt}
              selectedDate={form.getValues("targetDate")}
            />
            <Button className="md:max-w-44 self-end" onClick={copyEvents}>
              {t("tour.copyEvents.copy")}
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default TourEvents;
