import parsePhoneNumber from "libphonenumber-js";

export const getPhoneNumber = (phone?: string | null) => {
  const phoneNumber = parsePhoneNumber(phone || "");

  return {
    areaCode: `+${phoneNumber?.countryCallingCode || "1"}`,
    countryCode: phoneNumber?.country?.toLowerCase() || "us",
    phoneNumber: phoneNumber?.nationalNumber,
  };
};
