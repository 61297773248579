import { useTranslation } from "react-i18next";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import { EventDto, EventTypeDto, EventTypesDto } from "@/api/base-api";
import { Body, LabelText } from "@/components/Typography/Typography";
import { PersonnelList } from "@/modules/Tours/TourHeader";
import { formatDate, getDaysForInterval, getFormatedTime } from "@/lib/dates";
import { Icon } from "@/components/Icon/Icon";
import { getVisibilityOptionsByValue } from "@/modules/Tours/Events/eventsSchemas";
import { airlinesList, TourEventType } from "@articulate/shared";
import { useEventTypes } from "@/api/queries/useEventTypes";
import { useTourGroups } from "@/api/queries/useTourGroups";
import { getEventTimezones } from "../TourEvents";

export const getPlaceTimezone = (
  date: string | null,
  event: EventDto,
  requiredTravelPlaceKey: "destination" | "origin",
  eventTypes?: EventTypesDto
) => {
  const eventType = eventTypes?.eventTypes.find(
    (type) => type.id === event.eventTypeId
  )?.name as TourEventType;
  const isPersonalEvent = !event.eventTypeId;

  const getEventTimezone = () => {
    if (isPersonalEvent) return event.timezoneId || "";

    switch (eventType) {
      case "GLAM":
      case "HOTEL_STAY":
      case "LOAD_IN":
      case "PRODUCTION":
      case "PROMO":
      case "REHEARSAL":
      case "SCHEDULE_ITEM":
      case "SHOW": {
        return event.timezoneId || "";
      }
      case "TRAVEL": {
        if (event.additionalInformation?.travel?.travelType === "air") {
          return (
            event.additionalInformation.travel.flightInfo?.[
              requiredTravelPlaceKey
            ].timezone || ""
          );
        }
        return (
          (requiredTravelPlaceKey === "destination"
            ? event.additionalInformation?.travel?.destinationTimezone
            : event.timezoneId) || ""
        );
      }
    }
  };

  const eventTimezone = getEventTimezone();
  const placeTimezone =
    eventTimezone &&
    formatInTimeZone(new Date(date || ""), eventTimezone, "zzz");

  return placeTimezone;
};

const AssignedUsers = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["dashboard"]);

  const { data: tourGroups } = useTourGroups(event.tourId);

  const groups =
    event.groups?.map((group) => {
      const currGroup = tourGroups?.groups.find(
        (tourGroup) => tourGroup.id === group.id
      );

      return {
        user: { email: currGroup?.name || "" },
        id: currGroup?.id || "",
      };
    }) || [];
  const users = event.eventPersonnel.map((person) => ({
    user: { email: person.email || "" },
    id: person.id,
  }));
  const personnel = [...groups, ...users];

  if (!personnel.length) return null;

  return (
    <div>
      <LabelText className="text-foreground-two pb-2" size={"md"}>
        {t("dashboard:events.assignUsers")}
      </LabelText>
      <PersonnelList
        data={personnel}
        goInvitePersonnel={() => {}}
        withTooltip
      />
    </div>
  );
};

const TravelPlace = ({
  event,
  title,
  type,
}: {
  event: EventDto;
  title: string;
  type: "departure" | "arrival";
}) => {
  const address = (
    type === "departure"
      ? event.additionalInformation?.travel?.travelType === "air"
        ? [event.additionalInformation.travel.departureOrigin]
        : [event.streetAddress, event.zipCode, event.city]
      : [event.additionalInformation?.travel?.arrivalOrigin]
  )
    .filter((part) => part)
    .join(", ");

  if (!address.length) {
    return null;
  }

  return (
    <div className="inline-block">
      <LabelText className="text-foreground-two pb-2" size={"md"}>
        {title}
      </LabelText>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center">
          <Icon
            name="LocationIcon"
            size="small"
            className="text-foreground-one mr-1 h-4 w-4"
            viewBox="0 0 16 16"
          />
          <Body className="text-foreground-one">{address}</Body>
        </div>
      </div>
    </div>
  );
};

const TravelDate = ({
  date,
  event,
}: {
  date: EventDto["startDate" | "endDate"];
  event: EventDto;
  title: string;
}) => {
  const { t } = useTranslation(["dashboard"]);
  const { data: eventTypes } = useEventTypes();

  const { startTimezone, endTimezone } = getEventTimezones({
    isTravel: true,
    event,
  });
  const startDate = toZonedTime(event.startDate!, startTimezone || "");
  const endDate = toZonedTime(event.endDate!, endTimezone || "");
  const eventDays = getDaysForInterval(
    startDate.toISOString(),
    endDate.toISOString()
  ).length;
  const moreDays = eventDays > 1 ? `(+${eventDays - 1})` : "";
  const dateTime =
    date &&
    `${getFormatedTime(startDate)} ${getPlaceTimezone(startDate.toISOString(), event, "origin", eventTypes)} - ${getFormatedTime(endDate)} ${getPlaceTimezone(endDate.toISOString(), event, "destination", eventTypes)} ${moreDays}`;

  return (
    <div className="inline-block">
      <LabelText className="text-foreground-two pb-2" size={"md"}>
        {t("events.date")}
      </LabelText>
      <div className="flex flex-row items-center">
        <Icon
          name="CalendarIcon"
          className="text-foreground-one mr-1 h-4 w-4"
        />
        <Body>{dateTime}</Body>
      </div>
    </div>
  );
};

const Dates = ({ event }: { event: EventDto }) => {
  const { data: eventTypes } = useEventTypes();

  const isTravel = !!event.additionalInformation?.travel?.travelType;
  const { t } = useTranslation(["dashboard", "forms"]);
  const { startTimezone, endTimezone } = getEventTimezones({ isTravel, event });
  const timezonedStartDate = toZonedTime(event.startDate!, startTimezone!);
  const timezonedEndDate = toZonedTime(event.endDate!, endTimezone!);
  const originTimezone = getPlaceTimezone(
    timezonedStartDate.toISOString(),
    event,
    "origin",
    eventTypes
  );
  const destinationTimezone = getPlaceTimezone(
    timezonedEndDate.toISOString(),
    event,
    "destination",
    eventTypes
  );

  const startDateTime =
    timezonedStartDate &&
    `${formatDate(timezonedStartDate)}, ${getFormatedTime(timezonedStartDate)} ${originTimezone}`;
  const endDateTime =
    timezonedEndDate &&
    `${formatDate(timezonedEndDate)}, ${getFormatedTime(timezonedEndDate)} ${destinationTimezone}`;
  const isSingleTime = startDateTime === endDateTime;

  if (!startDateTime && !endDateTime) {
    return null;
  }

  return (
    <div className="inline-block">
      {isSingleTime ? (
        <div className="flex flex-col">
          <LabelText className="text-foreground-two pb-2" size={"md"}>
            {t("events.date")}
          </LabelText>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center">
              <Icon
                name="LocationIcon"
                size="small"
                className="text-foreground-one mr-1 h-4 w-4"
                viewBox="0 0 16 16"
              />
              <Body className="text-foreground-one">{startDateTime}</Body>
            </div>
          </div>
        </div>
      ) : (
        <>
          <LabelText className="text-foreground-two pb-2" size={"md"}>
            {isTravel
              ? t("forms:travelEvent.departureDate")
              : t("createTour.startDate")}
          </LabelText>
          <div className="mb-2 flex flex-col gap-2">
            <div className="flex flex-row items-center">
              <Icon
                name="LocationIcon"
                size="small"
                className="text-foreground-one mr-1 h-4 w-4"
                viewBox="0 0 16 16"
              />
              <Body className="text-foreground-one">{startDateTime}</Body>
            </div>
          </div>
          <LabelText className="text-foreground-two pb-2" size={"md"}>
            {isTravel
              ? t("forms:travelEvent.arrivalDate")
              : t("createTour.endDate")}
          </LabelText>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center">
              <Icon
                name="LocationIcon"
                size="small"
                className="text-foreground-one mr-1 h-4 w-4"
                viewBox="0 0 16 16"
              />
              <Body className="text-foreground-one">{endDateTime}</Body>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Visibility = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["forms", "dashboard"]);

  const { visibleToAll } = event;
  return (
    <div>
      <LabelText className="text-foreground-two pb-2" size={"md"}>
        {t("dashboard:events.visibility")}
      </LabelText>
      <Body>{getVisibilityOptionsByValue(t)[visibleToAll.toString()]}</Body>
    </div>
  );
};

const RequiredEquipment = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    event?.additionalInformation?.loadIn?.requiredEquipment && (
      <div>
        <LabelText className="text-foreground-two pb-2" size={"md"}>
          {t("dashboard:events.requiredEquipment")}
        </LabelText>
        <Body>{event?.additionalInformation?.loadIn?.requiredEquipment}</Body>
      </div>
    )
  );
};

const Notes = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    event?.notes && (
      <div>
        <LabelText className="text-foreground-two pb-2" size={"md"}>
          {t("dashboard:events.notes")}
        </LabelText>
        <Body>{event?.notes}</Body>
      </div>
    )
  );
};

const InterviewerName = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    event?.additionalInformation?.promo?.interviewerName && (
      <div>
        <LabelText className="text-foreground-two pb-2" size={"md"}>
          {t("dashboard:events.interviewerName")}
        </LabelText>
        <Body>{event?.additionalInformation?.promo?.interviewerName}</Body>
      </div>
    )
  );
};

const MediaOutlet = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    event?.additionalInformation?.promo?.mediaOutlet && (
      <div>
        <LabelText className="text-foreground-two pb-2" size={"md"}>
          {t("dashboard:events.mediaOutlet")}
        </LabelText>
        <Body>{event?.additionalInformation?.promo?.mediaOutlet}</Body>
      </div>
    )
  );
};

const TravelExpandedEvent = ({ event }: { event: EventDto }) => {
  const { t } = useTranslation(["forms"]);
  const { startDate } = event;
  const airline = airlinesList.find(
    (airline) =>
      airline.code ===
      event.additionalInformation?.travel?.flightInfo?.identIata.slice(0, 2)
  )?.name;
  const flightNumber = event.additionalInformation?.travel?.flightNumber;

  return (
    <div className="flex flex-col gap-4 pt-4">
      <AssignedUsers event={event} />
      <TravelDate event={event} title="Departure" date={startDate} />
      {airline && (
        <div>
          <LabelText className="text-foreground-two pb-2" size={"md"}>
            {t("travelEvent.airline")}
          </LabelText>
          <Body>{airline}</Body>
        </div>
      )}
      {flightNumber && (
        <div>
          <LabelText className="text-foreground-two pb-2" size={"md"}>
            {t("travelEvent.flightNumber")}
          </LabelText>
          <Body>{flightNumber}</Body>
        </div>
      )}
      <TravelPlace event={event} title="Departure" type="departure" />
      <TravelPlace event={event} title="Arrival" type="arrival" />
      <Visibility event={event} />
    </div>
  );
};

const BaseExpandedEvent = ({
  event,
  withVisibility = true,
}: {
  event: EventDto;
  withVisibility: boolean;
}) => {
  return (
    <div className="flex flex-col gap-4 pt-4">
      <AssignedUsers event={event} />
      <Dates event={event} />
      {withVisibility ? <Visibility event={event} /> : null}
      <Notes event={event} />
    </div>
  );
};

const LoadInExpandedEvent = ({ event }: { event: EventDto }) => {
  return (
    <div className="flex flex-col gap-4 pt-4">
      <AssignedUsers event={event} />
      <Dates event={event} />
      <Visibility event={event} />
      <RequiredEquipment event={event} />
      <Notes event={event} />
    </div>
  );
};

const PromoExpandedEvent = ({ event }: { event: EventDto }) => {
  return (
    <div className="flex flex-col gap-4 pt-4">
      <AssignedUsers event={event} />
      <div className="flex flex-col gap-2 md:flex-row">
        <div className="flex md:basis-1/2">
          <Dates event={event} />
        </div>
        <div className="flex md:basis-1/2">
          <Visibility event={event} />
        </div>
      </div>
      <div className="flex flex-col gap-2 md:flex-row">
        <div className="flex md:basis-1/2">
          <InterviewerName event={event} />
        </div>
        <div className="flex md:basis-1/2">
          <MediaOutlet event={event} />
        </div>
      </div>
      <Notes event={event} />
    </div>
  );
};

export const EventExpandItem = ({
  event,
  eventType,
}: {
  event: EventDto;
  eventType?: EventTypeDto["name"];
}) => {
  if (!eventType) {
    return null;
  }
  switch (eventType) {
    case "TRAVEL":
      return <TravelExpandedEvent event={event} />;
    case "SCHEDULE_ITEM":
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SHOW":
    case "PERSONAL":
      return (
        <BaseExpandedEvent
          event={event}
          withVisibility={eventType !== "PERSONAL"}
        />
      );
    case "LOAD_IN":
      return <LoadInExpandedEvent event={event} />;
    case "PROMO":
      return <PromoExpandedEvent event={event} />;
  }
};
