import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

type FlightsQueryParams = {
  startDate: string;
  endDate: string;
  airline: string;
  flightNumber: string;
};

export const flightsQueryOptions = (queryParams: FlightsQueryParams) =>
  queryOptions({
    queryKey: [
      "flights",
      queryParams.airline,
      queryParams.endDate,
      queryParams.flightNumber,
      queryParams.startDate,
    ],
    queryFn: async () => {
      const response =
        await API.flights.flightsControllerGetFlights(queryParams);
      return response.data;
    },
    enabled:
      !!queryParams.airline &&
      !!queryParams.endDate &&
      !!queryParams.flightNumber &&
      !!queryParams.startDate,
  });

export const useFlights = (queryParams: FlightsQueryParams) => {
  return useQuery(flightsQueryOptions(queryParams));
};

export const useFlightsSuspense = (queryParams: FlightsQueryParams) => {
  return useSuspenseQuery(flightsQueryOptions(queryParams));
};
