import { Sheet, SheetTrigger } from "@/components/ui/sheet";
import { useViewport } from "./useViewport";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { useState } from "react";

const calendarPosition = window.innerHeight <= 850 ? -200 : 0;
const daysInWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export const useCalendar = (howManyMonths = 12) => {
  const { isMobile } = useViewport();
  const [months, setMonths] = useState(howManyMonths);
  const Trigger = isMobile ? SheetTrigger : PopoverTrigger;
  const Wrapper = isMobile ? Sheet : Popover;
  const loadMoreMonths = () => {
    setMonths((prev) => prev + 3);
  };

  return {
    Trigger,
    Wrapper,
    isMobile,
    calendarPosition,
    daysInWeek,
    loadMoreMonths,
    months,
  };
};
