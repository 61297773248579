import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateEventDto } from "../base-api";

export type CreateEventArgs = {
  tourId: string;
  data: CreateEventDto;
};

export const useCreateEvent = () => {
  return useMutation({
    mutationKey: ["createEvent"],
    mutationFn: async ({ tourId, data }: CreateEventArgs) => {
      const response = await API.events.eventsControllerCreate(tourId, data);

      return response.data;
    },
  });
};
