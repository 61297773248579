import { z } from "zod";
import { MemberOrGroup } from "@/modules/Tours/Events/eventsSchemas";
import { queryClient } from "@/api/client";
import { eventPersonnelListQueryOptions } from "@/api/queries/useEventPersonnelList";
import {
  EventDto,
  EventPersonnelDto,
  ProductionTrackerDto,
} from "@/api/base-api";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { AddEventGroupArgs } from "@/api/mutations/useAddEventGroup";
import { UpdateEventPersonnelArgs } from "@/api/mutations/useUpdateEventPersonnel";
import { RemoveEventPersonnelArgs } from "@/api/mutations/useRemoveEventPersonnel";
import { CreateEventPersonnelArgs } from "@/api/mutations/useCreateEventPersonnel";
import {
  PersonalEventName,
  TourEventType,
  TourEventTypes,
} from "@articulate/shared";
import { CreateProductionTrackerArgs } from "@/api/mutations/useCreateProductionTracker";

type Props = {
  eventId: string;
  tourId: string;
  eventType: TourEventType | PersonalEventName;
  membersAndGroups: z.infer<typeof MemberOrGroup>[];
  createEventPersonnel: UseMutateAsyncFunction<
    void,
    Error,
    CreateEventPersonnelArgs,
    unknown
  >;
  removeEventPersonnel: UseMutateAsyncFunction<
    void,
    Error,
    RemoveEventPersonnelArgs,
    unknown
  >;
  updateEventPersonnel: UseMutateAsyncFunction<
    EventPersonnelDto,
    Error,
    UpdateEventPersonnelArgs,
    unknown
  >;
  addEventGroup: UseMutateAsyncFunction<
    void,
    Error,
    AddEventGroupArgs,
    unknown
  >;
  createProductionTracker?: UseMutateAsyncFunction<
    ProductionTrackerDto[],
    Error,
    CreateProductionTrackerArgs,
    unknown
  >;
  dayEvent: EventDto | undefined;
};

export const onCreateEventSuccess = async ({
  addEventGroup,
  createEventPersonnel,
  dayEvent,
  eventId,
  eventType,
  membersAndGroups,
  tourId,
  removeEventPersonnel,
  updateEventPersonnel,
  createProductionTracker,
}: Props) => {
  const onPersonnelUpdateSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: eventPersonnelListQueryOptions(eventId).queryKey,
    });
  };

  const personnelToRemove = dayEvent?.eventPersonnel.filter(
    (item) =>
      !membersAndGroups.find(
        (memberOrGroup) => memberOrGroup.email === item.email
      )
  );

  if (personnelToRemove?.length) {
    await Promise.allSettled(
      personnelToRemove?.map(async (item) => {
        await removeEventPersonnel(
          {
            eventId,
            personnelId: item.id,
          },
          {
            onSuccess: async () => {
              await onPersonnelUpdateSuccess();
            },
          }
        );
      })
    );
  }

  const prevPersonnelEmails = dayEvent?.eventPersonnel.map(
    (item) => item.email
  );
  const personnelToAdd = membersAndGroups.filter(
    (item) => item.email && !prevPersonnelEmails?.includes(item.email)
  );

  if (personnelToAdd.length) {
    await createEventPersonnel(
      {
        id: eventId,
        data: {
          people: personnelToAdd.map((user) => ({
            email: user.email,
            roleId: user.roleId,
          })),
        },
      },
      {
        onSuccess: async () => {
          await onPersonnelUpdateSuccess();
        },
      }
    );
  }

  const personnelToUpdate = dayEvent?.eventPersonnel.filter((personnel) =>
    membersAndGroups.find(
      (item) =>
        item.email &&
        item.email === personnel.email &&
        item.roleId !== personnel.roleId
    )
  );

  if (personnelToUpdate?.length) {
    await Promise.all(
      personnelToUpdate.map(async (personnel) => {
        const newRoleId = membersAndGroups.find(
          (item) => item.email === personnel.email
        )?.roleId;
        if (!newRoleId) return;

        await updateEventPersonnel({
          data: {
            eventId,
            eventPersonnelRoleId: newRoleId,
          },
          id: eventId,
          personnelId: personnel.id,
        });
      })
    );
  }

  const groupsToUpdate = dayEvent?.groups?.filter((group) =>
    membersAndGroups.find(
      (item) =>
        item.id === group.id && item.roleId !== group.eventPersonnelRoleId
    )
  );

  if (groupsToUpdate?.length) {
    await Promise.all(
      groupsToUpdate.map(async (group) => {
        const newRoleId = membersAndGroups.find(
          (item) => item.id === group.id
        )?.roleId;
        if (!newRoleId) return;

        await updateEventPersonnel({
          data: {
            eventId,
            eventPersonnelRoleId: newRoleId,
          },
          id: eventId,
          personnelId: group.eventPersonnelId,
        });
      })
    );
  }

  const groupsToDelete = dayEvent?.groups?.filter(
    (group) => !membersAndGroups.find((item) => item.id === group.id)
  );

  if (groupsToDelete?.length) {
    await Promise.all(
      groupsToDelete.map(async (group) => {
        await removeEventPersonnel(
          {
            eventId,
            personnelId: group.eventPersonnelId,
          },
          {
            onSuccess: async () => {
              await onPersonnelUpdateSuccess();
            },
          }
        );
      })
    );
  }

  const prevGroups = dayEvent?.groups?.map((group) => group.id);
  const groupsToAdd = membersAndGroups.filter(
    (memberOrGroup) =>
      !memberOrGroup.email && !prevGroups?.includes(memberOrGroup.id)
  );

  if (groupsToAdd.length) {
    await addEventGroup({
      eventId,
      data: {
        groups: groupsToAdd.map((group) => ({
          groupId: group.id,
          roleId: group.roleId,
        })),
      },
    });
  }

  if (eventType === TourEventTypes.SHOW && createProductionTracker) {
    await createProductionTracker({
      data: {
        content: {
          audio: {},
          general: {},
          labor: {},
          lighting: {},
          loadIn: {},
          loadOut: {},
          staging: {},
          video: {},
        },
        tourId,
        eventId,
      },
    });
  }
};
