import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateEventPersonnelGroupDto } from "../base-api";

export type AddEventGroupArgs = {
  eventId: string;
  data: CreateEventPersonnelGroupDto;
};

export const useAddEventGroup = () => {
  return useMutation({
    mutationKey: ["addEventGroup"],
    mutationFn: async ({ eventId, data }: AddEventGroupArgs) => {
      const response = await API.events.eventsControllerCreatePersonnelGroup(
        eventId,
        data
      );

      return response.data;
    },
  });
};
