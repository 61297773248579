import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const productionTrackerQueryOptions = (eventId: string) =>
  queryOptions({
    queryKey: ["productionTracker", eventId],
    queryFn: async () => {
      const response =
        await API.productionTrackers.productionTrackersControllerFindOne({
          eventId,
        });
      return response.data;
    },
  });

export const useProductionTracker = (eventId: string) => {
  return useQuery(productionTrackerQueryOptions(eventId));
};

export const useProductionTrackerSuspense = (eventId: string) => {
  return useSuspenseQuery(productionTrackerQueryOptions(eventId));
};
