import { FormCheckboxField } from "@/components/Form/FormCheckboxField";
import { getLoadInFormSchema } from "./eventsSchemas";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { ArtiTooltip } from "@/components/ArtiTooltip";

export function OvernightEventCheckbox() {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const loadInSchema = getLoadInFormSchema(t);
  const form = useFormContext<z.infer<typeof loadInSchema>>();

  return (
    <ArtiTooltip content={t("dashboard:events.nextDayDescription")}>
      <div className="w-1/2" onFocusCapture={(e) => e.stopPropagation()}>
        <FormCheckboxField
          control={form.control}
          items={[{ id: "nextDay", label: "Next day" }]}
          name="nextDay"
          multiple={false}
        />
      </div>
    </ArtiTooltip>
  );
}
