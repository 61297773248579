import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

type Params = { artistId?: string; archived?: boolean };

export const workspaceArtistsQueryOptions = (params?: Params) =>
  queryOptions({
    queryKey: ["artists", "list", params?.artistId, params?.archived],
    queryFn: async () => {
      const response = await API.artists.artistsControllerFindAll(params);
      return response.data;
    },
  });

export const useArtists = (params?: Params) => {
  return useQuery(workspaceArtistsQueryOptions(params));
};

export const useArtistsSuspense = (params?: Params) => {
  return useSuspenseQuery(workspaceArtistsQueryOptions(params));
};
