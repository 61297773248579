import { useTour } from "@/hooks/useTours";
import { TourRole, TourRoles } from "@articulate/shared";

export const useTourRole = (tourId: string) => {
  const { tour } = useTour(tourId);

  const tourRole = tour?.role as TourRole;

  return {
    isManager: [TourRoles.TOUR_MANAGER, TourRoles.TOUR_OWNER].includes(
      tourRole
    ),
    isOwner: tourRole === TourRoles.TOUR_OWNER,
    isMember: tourRole === TourRoles.TOUR_MEMBER,
  };
};
