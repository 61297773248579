import { useNavigate } from "react-router-dom";

import { Body, Header } from "@/components/Typography/Typography";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/Icon/Icon";
import { useUserSubscriptions } from "@/hooks/useUserSubscriptions";

interface IsManagerT {
  buttonLink?: string;
  buttonOnClick?: () => void;
  buttonText: string;
  isButtonDisabled?: boolean;
  isManager?: boolean;
  subTitle: string;
  title: string;
}
interface IsNotManagerT {
  buttonLink?: never;
  buttonOnClick?: never;
  buttonText?: never;
  isButtonDisabled?: never;
  isManager?: boolean;
  subTitle?: never;
  title: string;
}

const TourEmptyMessage = ({
  buttonLink,
  buttonOnClick,
  buttonText,
  isButtonDisabled,
  isManager,
  subTitle,
  title,
}: IsManagerT | IsNotManagerT) => {
  const navigate = useNavigate();

  const { isSubscriptionActive } = useUserSubscriptions();

  return (
    <div className="flex grow items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <Header
          size="h1"
          className="text-foreground-one text-center font-normal">
          {title}
        </Header>
        {isManager && isSubscriptionActive && (
          <>
            <Body size="md" className="text-foreground-two">
              {subTitle}
            </Body>
            <Button
              disabled={isButtonDisabled}
              variant="primary"
              onClick={() => {
                if (buttonOnClick) {
                  buttonOnClick();
                } else if (buttonLink) navigate(buttonLink);
              }}>
              <Icon name="AddIcon" className="mr-1" />
              {buttonText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TourEmptyMessage;
