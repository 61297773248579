import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const tourGroupsQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourGroups", tourId],
    queryFn: async () => {
      const response = await API.groups.groupsControllerFindAllByTour(tourId);
      return response.data;
    },
    enabled: !!tourId,
  });

export const useTourGroups = (tourId: string) => {
  return useQuery(tourGroupsQueryOptions(tourId));
};

export const useTourGroupsSuspense = (tourId: string) => {
  return useSuspenseQuery(tourGroupsQueryOptions(tourId));
};
