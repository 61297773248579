import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const tourSupplementalQuestionsQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourSupplementalQuestions", tourId],
    queryFn: async () => {
      const response =
        await API.supplementalQuestions.supplementalQuestionsControllerFindAllForTour(
          tourId
        );
      return response.data;
    },
  });

export const useTourSupplementalQuestions = (tourId: string) => {
  return useQuery(tourSupplementalQuestionsQueryOptions(tourId));
};

export const useTourSupplementalQuestionsSuspense = (tourId: string) => {
  return useSuspenseQuery(tourSupplementalQuestionsQueryOptions(tourId));
};
