import { Control, FieldValues, Path } from "react-hook-form";

import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";

export type CheckboxFieldItem = {
  id: string;
  label: string;
};

export function FormCheckboxField<T extends FieldValues>({
  items,
  control,
  name,
  label,
  className,
  multiple = true,
}: {
  items: CheckboxFieldItem[];
  control: Control<T>;
  name: Path<T>;
  label?: string;
  className?: string;
  multiple?: boolean;
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem>
          {label && (
            <div className="mb-4">
              <FormLabel className="text-base">{label}</FormLabel>
            </div>
          )}
          {items.map((item) => (
            <FormField
              key={item.id}
              control={control}
              name={name}
              render={({ field }) => {
                return (
                  <FormItem
                    key={item.id}
                    className={cn(
                      "flex flex-row items-center space-x-3 space-y-0",
                      className
                    )}>
                    <FormControl>
                      <Checkbox
                        checked={
                          multiple
                            ? field.value?.includes(item.id)
                            : field.value
                        }
                        onCheckedChange={(checked) => {
                          if (!multiple) {
                            field.onChange(checked);
                            return;
                          }

                          if (!field.value) {
                            field.onChange([item.id]);
                            return;
                          }

                          if (checked) {
                            field.onChange([...field.value, item.id]);
                            return;
                          }

                          field.onChange(
                            field.value?.filter(
                              (value: string) => value !== item.id
                            )
                          );
                        }}
                        className="bg-surface-two"
                      />
                    </FormControl>
                    <FormLabel className="w-full cursor-pointer text-sm font-normal">
                      {item.label}
                    </FormLabel>
                  </FormItem>
                );
              }}
            />
          ))}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
