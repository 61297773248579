import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const userSubscriptionPlanQueryOptions = (userId: string) =>
  queryOptions({
    queryKey: ["userSubscriptionPlan", userId],
    queryFn: async () => {
      const response =
        await API.stripeSubscriptions.stripeSubscriptionsControllerFindUserSubscription(
          userId
        );
      return response.data;
    },
  });

export const useUserSubscriptionPlan = (userId: string) => {
  return useQuery(userSubscriptionPlanQueryOptions(userId));
};

export const useUserSubscriptionPlanSuspense = (userId: string) => {
  return useSuspenseQuery(userSubscriptionPlanQueryOptions(userId));
};
