import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

export const useRemoveEvent = () => {
  return useMutation({
    mutationKey: ["removeEvent"],
    mutationFn: async ({ id }: { id: string }) => {
      const response = await API.events.eventsControllerRemove(id);

      return response.data;
    },
  });
};
