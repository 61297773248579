import { cn } from "@/lib/utils";

type Props = {
  value: string;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
};

export function FacilityItem({ value, isSelected, onClick, className }: Props) {
  return (
    <button
      type="button"
      className={cn(
        "bg-background border-border rounded-2xl border px-3 py-2",
        {
          "bg-primary": isSelected,
        },
        className
      )}
      onClick={onClick}>
      {value}
    </button>
  );
}
