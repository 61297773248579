import { cn } from "@/lib/utils";
import { ClassValue } from "clsx";
import { CSSProperties, ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: ClassValue;
  style?: CSSProperties;
  onClick?: () => void;
};

function Circle({ children, className, style, onClick }: Props) {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      className={cn(
        "border-border bg-surface-two flex h-10 w-10 items-center justify-center rounded-full border",
        className
      )}
      style={style}
      {...(onClick ? { type: "button", onClick } : {})}>
      {children}
    </Component>
  );
}

export default Circle;
