import { PersonalEventName, TourEventType } from "@articulate/shared";
import { getDateFromIsoTimestampIgnoringTimezone } from "@/lib/dates";
import { HotelStayEventForm } from "@/modules/Tours/Events/HotelStayEventForm";
import { LoadInEventForm } from "@/modules/Tours/Events/LoadInEventForm";
import { TravelEventForm } from "@/modules/Tours/Events/TravelEventForm";
import { PromoEventForm } from "@/modules/Tours/Events/PromoEventForm";
import { GenericEventForm } from "@/modules/Tours/Events/GenericEventForm";
import { TourWithRoleAndArtistDto } from "@/api/base-api";
import { PersonalEventForm } from "../PersonalEventForm";

export const renderEventForm = (
  eventType: TourEventType | PersonalEventName,
  tour?:
    | TourWithRoleAndArtistDto
    | {
        startsAt: TourWithRoleAndArtistDto["startsAt"] | "";
        endsAt: TourWithRoleAndArtistDto["endsAt"] | "";
      }
) => {
  const tourInterval = {
    startsAt: getDateFromIsoTimestampIgnoringTimezone(tour?.startsAt || ""),
    endsAt: getDateFromIsoTimestampIgnoringTimezone(tour?.endsAt || ""),
  };

  const sharedProps = {
    tourInterval: tourInterval,
  };

  switch (eventType) {
    case "HOTEL_STAY":
      return <HotelStayEventForm {...sharedProps} />;
    case "LOAD_IN":
      return <LoadInEventForm {...sharedProps} />;
    case "TRAVEL":
      return <TravelEventForm {...sharedProps} />;
    case "PROMO":
      return <PromoEventForm {...sharedProps} />;
    case "PERSONAL":
      return <PersonalEventForm {...sharedProps} />;
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SCHEDULE_ITEM":
    case "SHOW":
      return <GenericEventForm {...sharedProps} />;
  }
};
