import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateProductionTrackerDto } from "../base-api";

export type CreateProductionTrackerArgs = {
  data: CreateProductionTrackerDto;
};

export const useCreateProductionTracker = () => {
  return useMutation({
    mutationKey: ["createProductionTracker"],
    mutationFn: async ({ data }: CreateProductionTrackerArgs) => {
      const response =
        await API.productionTrackers.productionTrackersControllerCreate(data);

      return response.data;
    },
  });
};
