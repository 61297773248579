import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";
import { useDebounce } from "@uidotdev/usehooks";

const DEBOUNCE_MS = 1000;

export const googlePlacesQueryOptions = (query: string) =>
  queryOptions({
    queryKey: ["places", query],
    queryFn: async () => {
      const payload = {
        textQuery: query,
      };

      const response = API.googleApi.googleApiControllerSearchPlaces(payload);

      const data = (await response).data;
      const places = data.places;
      const parsedPlaces = places.map((place) => {
        const street =
          place.addressComponents.find((component) =>
            component.types.includes("route")
          )?.shortText || "";

        const streetNumber =
          place.addressComponents.find((component) =>
            component.types.includes("street_number")
          )?.shortText || "";

        const premise =
          place.addressComponents.find((component) =>
            component.types.includes("premise")
          )?.shortText || "";

        const fullAddress =
          `${street} ${streetNumber} ${premise}`.trim() ||
          place.formattedAddress;

        return {
          name: place.formattedAddress,
          id: place.name,
          postalCode: place.addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.shortText,
          state: place.addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.shortText,
          country: place.addressComponents.find((component) =>
            component.types.includes("country")
          )?.shortText,
          latitude: place.location.latitude
            ? place.location.latitude.toString()
            : "",
          longitude: place.location.longitude
            ? place.location.longitude.toString()
            : "",
          city: place.addressComponents.find((component) =>
            component.types.includes("locality")
          )?.shortText,
          displayName: place.displayName.text,
          streetAddress: fullAddress,
          types: place.types,
        };
      });
      return parsedPlaces;
    },
    enabled: !!query,
    placeholderData: keepPreviousData,
  });

export const useGooglePlaces = (query: string) => {
  const debouncedQuery = useDebounce(query, DEBOUNCE_MS);

  return useQuery(googlePlacesQueryOptions(debouncedQuery));
};

export const useGooglePlacesSuspense = (query: string) => {
  const debouncedQuery = useDebounce(query, DEBOUNCE_MS);

  return useSuspenseQuery(googlePlacesQueryOptions(debouncedQuery));
};
