import {
  CreateEventDto,
  EventDto,
  EventPersonnelDto,
  EventTypesDto,
  PersonalEventDto,
  ProductionTrackerDto,
} from "@/api/base-api";
import { queryClient } from "@/api/client";
import {
  CreateEventArgs,
  useCreateEvent,
} from "@/api/mutations/useCreateEvent";
import {
  CreateEventPersonnelArgs,
  useCreateEventPersonnel,
} from "@/api/mutations/useCreateEventPersonnel";
import {
  RemoveEventPersonnelArgs,
  useRemoveEventPersonnel,
} from "@/api/mutations/useRemoveEventPersonnel";
import {
  UpdateEventBodyT,
  useUpdateEvent,
} from "@/api/mutations/useUpdateEvent";
import { useEventTypes } from "@/api/queries/useEventTypes";
import { tourEventsQueryOptions } from "@/api/queries/useTourEvents";
import {
  FormsSchemaT,
  getDefaultFormFields,
  getDefaultTravelFields,
  getEventFormSchemas,
  getExtendedSharedTravelSchema,
  getGenericFormSchema,
  getHotelFormSchema,
  getLoadInFormSchema,
  getPersonalFormSchema,
  getPromoFormSchema,
} from "@/modules/Tours/Events/eventsSchemas";
import {
  PersonalEventName,
  personalEventName,
  TourEventType,
} from "@articulate/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useEventPersonnelRoles } from "@/api/queries/useEventPersonnelRoles";
import {
  AddEventGroupArgs,
  useAddEventGroup,
} from "@/api/mutations/useAddEventGroup";
import {
  UpdateEventPersonnelArgs,
  useUpdateEventPersonnel,
} from "@/api/mutations/useUpdateEventPersonnel";
import { getDefaultValuesForEventType } from "./utils/getDefaultValuesForEventType";
import { formFields } from "./utils/formFields";
import { onCreateEventSuccess } from "./utils/onCreateEventSuccess";
import { useContext, useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { useTourDayState } from "@/modules/Tours/TourDayContext";
import {
  CreatePersonalEventArgs,
  useCreatePersonalEvent,
} from "@/api/mutations/useCreatePersonalEvent";
import {
  UpdatePersonalEventArgs,
  useUpdatePersonalEvent,
} from "@/api/mutations/useUpdatePersonalEvent";
import { personalEventsQueryOptions } from "@/api/queries/usePersonalEvents";
import { upcomingEventsQueryOptions } from "@/api/queries/useUpcomingEvents";
import { useTourSuspense } from "@/api/queries/useTour";
import { tourSupplementalQuestionsQueryOptions } from "@/api/queries/useTourSupplementalQuestions";
import {
  CreateProductionTrackerArgs,
  useCreateProductionTracker,
} from "@/api/mutations/useCreateProductionTracker";
import { DateContext } from "@/modules/Dashboard/Calendar/Calendar.page";

export type EventFormProps = {
  tourInterval: {
    startsAt: Date;
    endsAt: Date;
  };
};

type SubmitWrapperT = {
  createEvent: UseMutateAsyncFunction<
    EventDto,
    Error,
    CreateEventArgs,
    unknown
  >;
  createEventPersonnel: UseMutateAsyncFunction<
    void,
    Error,
    CreateEventPersonnelArgs,
    unknown
  >;
  removeEventPersonnel: UseMutateAsyncFunction<
    void,
    Error,
    RemoveEventPersonnelArgs,
    unknown
  >;
  updateEventPersonnel: UseMutateAsyncFunction<
    EventPersonnelDto,
    Error,
    UpdateEventPersonnelArgs,
    unknown
  >;
  addEventGroup: UseMutateAsyncFunction<
    void,
    Error,
    AddEventGroupArgs,
    unknown
  >;
  dayEvent?: EventDto | undefined;
  dayEvents?: EventDto[] | undefined;
  eventTypes: EventTypesDto | undefined;
  onSuccess: () => void;
  updateEvent: UseMutateAsyncFunction<
    EventDto,
    Error,
    UpdateEventBodyT,
    unknown
  >;
  values: FieldValues & {
    eventName: string;
    eventType: TourEventType | PersonalEventName;
    id: string;
  };
  t: TFunction<["common", "dashboard", "forms"]>;
  tourId: string;
  createPersonalEvent: UseMutateAsyncFunction<
    PersonalEventDto,
    Error,
    CreatePersonalEventArgs,
    unknown
  >;
  updatePersonalEvent: UseMutateAsyncFunction<
    PersonalEventDto,
    Error,
    UpdatePersonalEventArgs,
    unknown
  >;
  createProductionTracker?: UseMutateAsyncFunction<
    ProductionTrackerDto[],
    Error,
    CreateProductionTrackerArgs,
    unknown
  >;
  artistId: string;
};

const onSubmitWrapper = async ({
  addEventGroup,
  createEvent,
  createEventPersonnel,
  dayEvent,
  dayEvents,
  eventTypes,
  onSuccess,
  removeEventPersonnel,
  tourId,
  updateEvent,
  updateEventPersonnel,
  values,
  t,
  createPersonalEvent,
  updatePersonalEvent,
  createProductionTracker,
  artistId,
}: SubmitWrapperT) => {
  if (!tourId) return;
  const genericSchema = getGenericFormSchema(t);
  const travelSchema = getExtendedSharedTravelSchema(t);
  const hotelSchema = getHotelFormSchema(t);
  const promoSchema = getPromoFormSchema(t);
  const loadInSchema = getLoadInFormSchema(t);
  const personalSchema = getPersonalFormSchema(t);

  type anyEvent =
    | z.infer<typeof genericSchema>
    | z.infer<typeof hotelSchema>
    | z.infer<typeof travelSchema>
    | z.infer<typeof promoSchema>
    | z.infer<typeof loadInSchema>
    | z.infer<typeof personalSchema>;
  type EventType = anyEvent & {
    data: UpdateEventBodyT["data"];
    tourId: string;
  };

  const eventType = values.eventType;
  const isPersonalEvent = eventType === personalEventName;
  const eventTypeId = isPersonalEvent
    ? ""
    : eventTypes?.eventTypes.find((type) => type?.name === eventType)?.id;

  const eventFields = formFields({
    form: values,
    eventType,
    eventTypeId: eventTypeId || "",
    tourId,
    t,
  }) as EventType;

  const getPersonalEventBody = (event: EventType) => ({
    endTime: event.data.endDate || "",
    startTime: event.data.startDate || "",
    city: event.data.city,
    countryId: event.data.countryId,
    notes: event.data.notes,
    state: event.data.state,
    streetAddress: event.data.streetAddress,
    timezone: event.data.timezoneId,
    // @ts-expect-error no time to make it all typesafe
    name: event.data.name,
  });

  const sendRequest = async () => {
    if (eventFields?.id) {
      try {
        if (isPersonalEvent) {
          await updatePersonalEvent({
            eventId: eventFields.id,
            data: getPersonalEventBody(eventFields),
          });
          if (onSuccess) {
            onSuccess();
          }
          return;
        }

        const event = await updateEvent({
          data: eventFields.data,
          id: eventFields.id,
        });
        await onCreateEventSuccess({
          eventId: event.id,
          membersAndGroups: [
            ...(eventFields?.membersAndGroups || []),
            ...(!(eventFields.data as EventDto).visibleToAll
              ? eventFields.spectators
              : []),
          ],
          dayEvent,
          tourId,
          eventType,
          createEventPersonnel,
          removeEventPersonnel,
          updateEventPersonnel,
          addEventGroup,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        console.log({ error });
      }
      return;
    }

    if (!eventFields?.data || !eventFields.tourId) return;

    try {
      if (isPersonalEvent) {
        await createPersonalEvent({ data: getPersonalEventBody(eventFields) });
        if (onSuccess) {
          onSuccess();
        }
        return;
      }

      const event = await createEvent({
        data: eventFields?.data as CreateEventDto,
        tourId: eventFields?.tourId,
      });
      const currEvent = dayEvents?.find((dayE) => dayE.id === event.id);
      await onCreateEventSuccess({
        eventId: event.id,
        membersAndGroups: [
          ...(eventFields?.membersAndGroups || []),
          ...(!(eventFields.data as EventDto).visibleToAll
            ? eventFields.spectators
            : []),
        ],
        dayEvent: currEvent,
        tourId,
        createEventPersonnel,
        removeEventPersonnel,
        updateEventPersonnel,
        addEventGroup,
        eventType,
        createProductionTracker,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  await sendRequest();

  if (isPersonalEvent) {
    await queryClient.invalidateQueries({
      queryKey: personalEventsQueryOptions.queryKey,
    });
    return;
  }
  await queryClient.invalidateQueries({
    queryKey: tourEventsQueryOptions(tourId).queryKey,
  });
  await queryClient.invalidateQueries({
    queryKey: upcomingEventsQueryOptions([artistId]).queryKey,
  });
  await queryClient.invalidateQueries({
    queryKey: tourSupplementalQuestionsQueryOptions(tourId).queryKey,
  });
};

export function useEventForms(
  tourId: string,
  dayEvent: EventDto,
  onSuccess: () => void
) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const { data: eventTypes } = useEventTypes();
  const { data: tour } = useTourSuspense(tourId);

  const eventType = dayEvent?.eventTypeId
    ? (eventTypes?.eventTypes.find(
        (eventType) => eventType.id === dayEvent?.eventTypeId
      )?.name as TourEventType)
    : personalEventName;

  const [value, setValue] = useState<TourEventType | PersonalEventName>(
    eventType
  );

  useEffect(() => {
    if (eventType) setValue(eventType);
  }, [eventType]);

  const formSchemas = getEventFormSchemas(t, value) as FormsSchemaT;

  const { data: eventPersonnelRoles } = useEventPersonnelRoles();
  const { mutateAsync: createEvent } = useCreateEvent();
  const { mutateAsync: updateEvent } = useUpdateEvent();
  const { mutateAsync: createEventPersonnel } = useCreateEventPersonnel();
  const { mutateAsync: removeEventPersonnel } = useRemoveEventPersonnel();
  const { mutateAsync: updateEventPersonnel } = useUpdateEventPersonnel();
  const { mutateAsync: addEventGroup } = useAddEventGroup();
  const { mutateAsync: createPersonalEvent } = useCreatePersonalEvent();
  const { mutateAsync: updatePersonalEvent } = useUpdatePersonalEvent();

  const crewRoleId = useMemo(
    () =>
      eventPersonnelRoles?.eventPersonnelRoles.find(
        (role) => role.role === "CREW"
      )?.id,
    [eventPersonnelRoles?.eventPersonnelRoles]
  );
  const membersAndGroups = useMemo(
    () => [
      ...dayEvent.eventPersonnel.filter(
        (member) => member.roleId === crewRoleId
      ),
      ...(dayEvent.groups
        ?.filter((group) => group.eventPersonnelRoleId === crewRoleId)
        .map((group) => ({
          id: group.id,
          userId: "",
          roleId: group.eventPersonnelRoleId,
          email: "",
        })) || []),
    ],
    [crewRoleId, dayEvent.eventPersonnel, dayEvent.groups]
  );

  const form = useForm<FieldValues>({
    resolver: zodResolver(formSchemas),
    defaultValues: getDefaultValuesForEventType(
      dayEvent,
      eventTypes,
      eventPersonnelRoles,
      membersAndGroups
    ),
  }) as UseFormReturn<
    FieldValues,
    undefined,
    FieldValues & {
      eventName: string;
      eventType: TourEventType | PersonalEventName;
      id: string;
    }
  >;

  useEffect(() => {
    // When editing events, we need to set new values as they're not re-rendering by having 'defaultValues' in form
    const newDefaultValues = getDefaultValuesForEventType(
      dayEvent,
      eventTypes,
      eventPersonnelRoles,
      membersAndGroups
    );
    form.reset(newDefaultValues);
  }, [dayEvent, eventTypes, eventPersonnelRoles, membersAndGroups, form]);

  const onSubmit: SubmitHandler<
    FieldValues & {
      eventName: string;
      eventType: TourEventType | PersonalEventName;
      id: string;
    }
  > = async (values) => {
    await onSubmitWrapper({
      addEventGroup,
      createEvent,
      createEventPersonnel,
      dayEvent,
      eventTypes,
      onSuccess,
      removeEventPersonnel,
      t,
      tourId,
      updateEvent,
      updateEventPersonnel,
      values,
      createPersonalEvent,
      updatePersonalEvent,
      artistId: tour.artistId,
    });
  };

  return {
    onSubmit,
    form,
  };
}

type EventFormT = {
  tourId: string;
  dayEvents: EventDto[] | undefined;
  onSuccess: () => void;
  eventType: TourEventType | PersonalEventName;
  event: FieldValues;
};

export function useEventFormsForCreate({
  tourId,
  dayEvents,
  onSuccess,
  eventType,
  event,
}: EventFormT) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const formSchemas = getEventFormSchemas(t, eventType) as z.Schema;
  const { selectedDate } = useTourDayState();
  const { date } = useContext(DateContext);

  const { data: eventTypes } = useEventTypes();
  const { data: tour } = useTourSuspense(tourId);
  const { mutateAsync: createEvent } = useCreateEvent();
  const { mutateAsync: updateEvent } = useUpdateEvent();
  const { mutateAsync: createEventPersonnel } = useCreateEventPersonnel();
  const { mutateAsync: removeEventPersonnel } = useRemoveEventPersonnel();
  const { mutateAsync: updateEventPersonnel } = useUpdateEventPersonnel();
  const { mutateAsync: addEventGroup } = useAddEventGroup();
  const { mutateAsync: createPersonalEvent } = useCreatePersonalEvent();
  const { mutateAsync: updatePersonalEvent } = useUpdatePersonalEvent();
  const { mutateAsync: createProductionTracker } = useCreateProductionTracker();

  const tourStartsAt = selectedDate || new Date(event.startsAt || "");
  const tourEndsAt = selectedDate || new Date(event.endsAt || "");

  const form = useForm<FieldValues>({
    resolver: zodResolver(formSchemas),
    defaultValues:
      eventType === "TRAVEL"
        ? getDefaultTravelFields("ground", date || tourStartsAt)
        : getDefaultFormFields(
            eventType,
            date || tourStartsAt,
            date || tourEndsAt
          ),
  }) as UseFormReturn<
    FieldValues,
    undefined,
    FieldValues & {
      eventName: string;
      eventType: TourEventType | PersonalEventName;
      id: string;
    }
  >;

  const onSubmit: SubmitHandler<
    FieldValues & {
      eventName: string;
      eventType: TourEventType | PersonalEventName;
      id: string;
    }
  > = async (values) => {
    await onSubmitWrapper({
      addEventGroup,
      createEvent,
      createEventPersonnel,
      dayEvents,
      eventTypes,
      onSuccess,
      removeEventPersonnel,
      t,
      tourId,
      updateEvent,
      updateEventPersonnel,
      values,
      createPersonalEvent,
      updatePersonalEvent,
      artistId: tour.artistId,
      createProductionTracker,
    });
  };

  return {
    onSubmit,
    form,
  };
}
