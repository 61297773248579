export var tourRoles = ["TOUR_MEMBER", "TOUR_MANAGER", "TOUR_OWNER", "VENDOR"];
export var TourRoles = {
    TOUR_MEMBER: "TOUR_MEMBER",
    TOUR_MANAGER: "TOUR_MANAGER",
    TOUR_OWNER: "TOUR_OWNER",
    VENDOR: "VENDOR",
};
export var tourRolesOptions = tourRoles.map(function (role) { return ({
    label: role,
    value: role,
}); });
export var tourRoleTitles = [
    "BACKSTAGE_MANAGER",
    "BACKLINE_TECH",
    "FOH",
    "HOSPITALITY_COORDINATOR",
    "MON",
    "PERSONAL_SECURITY",
    "PRODUCTION_COORDINATOR",
    "SHOW_CALLER",
    "STAGE_MANAGER",
    "TOUR_COORDINATOR",
    "VENUE_SECURITY",
    "VIDEO_DIRECTOR",
];
export var TourRoleTitles = {
    BACKSTAGE_MANAGER: "BACKSTAGE_MANAGER",
    BACKLINE_TECH: "BACKLINE_TECH",
    FOH: "FOH",
    HOSPITALITY_COORDINATOR: "HOSPITALITY_COORDINATOR",
    MON: "MON",
    PERSONAL_SECURITY: "PERSONAL_SECURITY",
    PRODUCTION_COORDINATOR: "PRODUCTION_COORDINATOR",
    SHOW_CALLER: "SHOW_CALLER",
    STAGE_MANAGER: "STAGE_MANAGER",
    TOUR_COORDINATOR: "TOUR_COORDINATOR",
    VENUE_SECURITY: "VENUE_SECURITY",
    VIDEO_DIRECTOR: "VIDEO_DIRECTOR",
};
export var tourRoleTitlesOptions = tourRoleTitles.map(function (role) { return ({
    label: role,
    value: role,
}); });
export var tourEventTypes = [
    "TRAVEL",
    "HOTEL_STAY",
    "SHOW",
    "REHEARSAL",
    "PRODUCTION",
    "PROMO",
    "GLAM",
    "LOAD_IN",
    "SCHEDULE_ITEM",
];
export var TourEventTypes = {
    TRAVEL: "TRAVEL",
    HOTEL_STAY: "HOTEL_STAY",
    SHOW: "SHOW",
    REHEARSAL: "REHEARSAL",
    PRODUCTION: "PRODUCTION",
    PROMO: "PROMO",
    GLAM: "GLAM",
    LOAD_IN: "LOAD_IN",
    SCHEDULE_ITEM: "SCHEDULE_ITEM",
};
export var personalEventName = "PERSONAL";
export var tourDayTags = [
    "TRAVEL_DAY",
    "HOTEL_STAY",
    "SHOW_DAY",
    "REHEARSAL_DAY",
    "PRODUCTION_DAY",
    "PROMO_DAY",
    "GLAM_DAY",
    "LOAD_IN_DAY",
    "OFF_DAY",
    "STUDIO_DAY",
];
export var TourDayTags = {
    TRAVEL_DAY: "Travel day",
    REHEARSAL_DAY: "Rehearsal day",
    GLAM_DAY: "Glam day",
    HOTEL_STAY: "Hotel stay",
    LOAD_IN_DAY: "Load-in day",
    PRODUCTION_DAY: "Production day",
    PROMO_DAY: "Promo day",
    SHOW_DAY: "Show day",
    OFF_DAY: "Off day",
    STUDIO_DAY: "Studio day",
};
export var tourEventRoles = ["CREW", "SPECTATOR"];
export var TourEventRoles = {
    CREW: "CREW",
    SPECTATOR: "SPECTATOR",
};
