import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

type Args = {
  latitude: string;
  longitude: string;
};

export const useTimezone = () => {
  return useMutation({
    mutationKey: ["timezone"],
    mutationFn: async (args: Args) => {
      const response = await API.googleApi.googleApiControllerGetTimezone(args);

      return response.data;
    },
  });
};
