import * as React from "react";

import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { ClassValue } from "clsx";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  additionalContent?: React.ReactNode;
  containerClassName?: ClassValue;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      error,
      additionalContent,
      containerClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("flex flex-col", containerClassName)}>
        {label ? (
          <Label htmlFor={label} className="mb-2">
            {label}
          </Label>
        ) : null}
        <div className="relative">
          <input
            type={type}
            className={cn(
              "text-foreground-two border-border disabled:placeholder-border disabled:bg-border-hover disabled:border-foreground-two focus:border-foreground-one focus:text-foreground-one bg-surface-two dark:bg-surface-one ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring hover:border-border-hover flex h-10 w-full rounded-xl border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
              { "border-error hover:border-error focus:border-error": error },
              className
            )}
            ref={ref}
            {...props}
          />
          {additionalContent}
        </div>
        <p
          className={cn("text-error text-sm opacity-0", {
            "opacity-100": error,
          })}>
          {error}
        </p>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
