import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { DuplicateEventDto } from "../base-api";

type Args = { data: DuplicateEventDto };

export const useCopyDayEvents = () => {
  return useMutation({
    mutationKey: ["copyDayEvents"],
    mutationFn: async ({ data }: Args) => {
      const response = await API.events.eventsControllerDuplicate(data);

      return response.data;
    },
  });
};
