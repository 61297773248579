import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreateTourDayDto } from "../base-api";

type CreateTourDayArgs = {
  tourId: string;
  data: CreateTourDayDto;
};

export const useCreateTourDay = () => {
  return useMutation({
    mutationKey: ["createTourDay"],
    mutationFn: async ({ tourId, data }: CreateTourDayArgs) => {
      const response = await API.tourDays.tourDaysControllerCreate(
        tourId,
        data
      );

      return response.data;
    },
  });
};
