import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const dayTagsQueryOptions = (workspaceId: string) =>
  queryOptions({
    queryKey: ["dayTags", workspaceId],
    queryFn: async () => {
      const response = await API.dayTags.dayTagsControllerFindAll(workspaceId);
      return response.data;
    },
    enabled: !!workspaceId,
  });

export const useDayTags = (workspaceId: string) => {
  return useQuery(dayTagsQueryOptions(workspaceId));
};

export const useDayTagsSuspense = (workspaceId: string) => {
  return useSuspenseQuery(dayTagsQueryOptions(workspaceId));
};
