import { toZonedTime } from "date-fns-tz";
import {
  EventDto,
  EventPersonnel,
  EventPersonnelRolesDto,
  EventTypesDto,
} from "@/api/base-api";
import { formatTime } from "@/lib/dates";
import { airlinesList, personalEventName } from "@articulate/shared";
import { getPhoneNumber } from "@/lib/getPhoneNumber";

export const getDefaultValuesForEventType = (
  event: EventDto,
  eventTypes?: EventTypesDto,
  eventPersonnelRoles?: EventPersonnelRolesDto,
  membersAndGroups?: EventPersonnel[]
) => {
  const eventType = event?.eventTypeId
    ? eventTypes?.eventTypes.find(
        (eventType) => eventType.id === event?.eventTypeId
      )
    : { name: personalEventName };

  const spectatorRoleId = eventPersonnelRoles?.eventPersonnelRoles.find(
    (role) => role.role === "SPECTATOR"
  )?.id;

  const start = toZonedTime(event.startDate!, event.timezoneId!);
  const end = toZonedTime(event.endDate!, event.timezoneId!);
  const formattedStartTime = formatTime(start);
  const formattedEndTime = formatTime(end);
  const spectators = [
    ...event.eventPersonnel.filter(
      (member) => member.roleId === spectatorRoleId
    ),
    ...(event.groups
      ?.filter((group) => group.eventPersonnelRoleId === spectatorRoleId)
      .map((group) => ({
        id: group.id,
        userId: "",
        roleId: group.eventPersonnelRoleId,
        email: "",
      })) || []),
  ];

  switch (eventType?.name) {
    case "HOTEL_STAY": {
      const start = toZonedTime(event.startDate!, event.timezoneId!);
      const end = toZonedTime(event.endDate!, event.timezoneId!);
      return {
        id: event.id,
        city: event.city,
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        countryId: event.countryId,
        checkIn: start,
        checkOut: end,
        state: event.state,
        notes: event.notes,
        postalCode: event.zipCode,
        address: event.streetAddress,
        name: event.additionalInformation?.hotel?.hotelName,
        phoneNumber: getPhoneNumber(
          event.additionalInformation?.hotel?.phoneNumber
        ),
        email: event.additionalInformation?.hotel?.email || "",
        website: event.additionalInformation?.hotel?.website || "",
        keyContacts: event.additionalInformation?.hotel?.keyContacts || "",
        facilities: event.additionalInformation?.hotel?.facilities || undefined,
        membersAndGroups,
        visibility: event.visibleToAll === true ? "true" : "false",
        spectators,
        timezoneId: event.timezoneId,
        nextDay: event.nextDay,
      };
    }
    case "LOAD_IN": {
      const start = toZonedTime(event.startDate!, event.timezoneId!);
      const end = toZonedTime(event.endDate!, event.timezoneId!);
      return {
        id: event.id,
        address: event.streetAddress,
        city: event.city,
        state: event.state,
        postalCode: event.zipCode,
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        countryId: event.countryId,
        startDate: start,
        startTime: formattedStartTime,
        endDate: end,
        endTime: formattedEndTime,
        notes: event.notes,
        requiredEquipment:
          event.additionalInformation?.loadIn?.requiredEquipment || "",
        membersAndGroups,
        visibility: event.visibleToAll === true ? "true" : "false",
        spectators,
        timezoneId: event.timezoneId,
        nextDay: event.nextDay,
      };
    }
    case "TRAVEL": {
      const start = toZonedTime(event.startDate!, event.timezoneId!);
      const end = toZonedTime(event.endDate!, event.timezoneId!);
      const airline =
        airlinesList.find(
          (airline) =>
            airline.code ===
            event.additionalInformation?.travel?.flightInfo?.identIata.slice(
              0,
              2
            )
        )?.name || "";

      return {
        id: event.id,
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        departureDate: start,
        departureTime: formattedStartTime,
        arrivalDate: end,
        arrivalTime: formattedEndTime,
        destination: event.additionalInformation?.travel?.destination || "",
        destinationTimezone:
          event.additionalInformation?.travel?.destinationTimezone || "",
        departureOrigin:
          event.additionalInformation?.travel?.departureOrigin || "",
        countryId: event.countryId,
        travelType: event.additionalInformation?.travel?.travelType || "ground",
        airline,
        flightNumber:
          Number(event.additionalInformation?.travel?.flightNumber) ||
          undefined,
        departureAirportCode:
          event.additionalInformation?.travel?.departureOriginAirportCode ||
          undefined,
        arrivalAirportCode:
          event.additionalInformation?.travel?.arrivalOriginAirportCode ||
          undefined,
        trainNumber:
          event.additionalInformation?.travel?.trainNumber || undefined,
        flightInfo: event.additionalInformation?.travel?.flightInfo,
        sealNumber:
          event.additionalInformation?.travel?.sealNumber || undefined,
        membersAndGroups,
        visibility: event.visibleToAll === true ? "true" : "false",
        city: event.city || "",
        state: event.state || "",
        postalCode: event.zipCode || "",
        address: event.streetAddress || "",
        spectators,
        timezoneId: event.timezoneId,
        departureTimezoneId:
          event.additionalInformation?.travel?.flightInfo?.origin.timezone,
        arrivalTimezoneId:
          event.additionalInformation?.travel?.flightInfo?.destination.timezone,
        nextDay: event.nextDay,
      };
    }
    case "PROMO": {
      const start = toZonedTime(event.startDate!, event.timezoneId!);
      const end = toZonedTime(event.endDate!, event.timezoneId!);

      return {
        id: event.id,
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        address: event.streetAddress,
        city: event.city,
        state: event.state,
        postalCode: event.zipCode,
        countryId: event.countryId,
        startDate: start,
        startTime: formattedStartTime,
        endDate: end,
        endTime: formattedEndTime,
        notes: event.notes,
        interviewerName:
          event.additionalInformation?.promo?.interviewerName || "",
        mediaOutlet: event.additionalInformation?.promo?.mediaOutlet || "",
        membersAndGroups,
        visibility: event.visibleToAll === true ? "true" : "false",
        spectators,
        timezoneId: event.timezoneId,
        nextDay: event.nextDay,
      };
    }
    case "PERSONAL": {
      return {
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        address: event.streetAddress,
        countryId: event.countryId,
        startDate: event.startDate,
        startTime: formattedStartTime,
        endDate: event.endDate,
        endTime: formattedEndTime,
        notes: event.notes,
        visibility: event.visibleToAll === true ? "true" : "false",
        id: event.id,
        membersAndGroups: [],
        spectators: [],
        city: event.city,
        state: event.state,
        postalCode: event.zipCode,
        timezoneId: event.timezoneId,
        nextDay: event.nextDay,
      };
    }
    case "GLAM":
    case "PRODUCTION":
    case "REHEARSAL":
    case "SCHEDULE_ITEM":
    case "SHOW": {
      const start = toZonedTime(event.startDate!, event.timezoneId!);
      const end = toZonedTime(event.endDate!, event.timezoneId!);

      return {
        id: event.id,
        eventName: event.eventDetails?.name,
        eventType: eventType.name,
        address: event.streetAddress,
        city: event.city,
        state: event.state,
        postalCode: event.zipCode,
        countryId: event.countryId,
        startDate: start,
        startTime: formattedStartTime,
        endDate: end,
        endTime: formattedEndTime,
        notes: event.notes,
        membersAndGroups,
        visibility: event.visibleToAll === true ? "true" : "false",
        spectators,
        timezoneId: event.timezoneId,
        nextDay: event.nextDay,
      };
    }
  }
};
