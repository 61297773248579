import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { ClassValue } from "clsx";
import { FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { cn } from "@/lib/utils";
import { toApiDateFormat } from "@/lib/dates";

type Props<T extends FieldValues> = {
  containerClassName?: ClassValue;
  control: Control<T>;
  fromDate?: Date;
  label?: string;
  name: Path<T>;
  toDate?: Date;
};

const isDateValid = (date?: Date) => date && !isNaN(date?.getTime());

export function DatePickerHtml<T extends FieldValues>({
  containerClassName,
  control,
  fromDate,
  label,
  name,
  toDate,
}: Props<T>) {
  return (
    <div className={cn("relative flex grow gap-4", containerClassName)}>
      <FormField
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const handleDateChange = (
            event: React.ChangeEvent<HTMLInputElement>
          ) => {
            if (!event?.target?.value) {
              return new Date();
            }

            const dateSplit = event?.target?.value?.split("-");

            field.onChange(
              dateSplit?.length === 3 &&
                dateSplit[1].length === 2 &&
                dateSplit[2].length === 2
                ? new Date(
                    Number(dateSplit[0]),
                    Number(dateSplit[1]) - 1,
                    Number(dateSplit[2])
                  )
                : new Date()
            );
          };

          return (
            <FormItem className="w-full">
              {label && <FormLabel>{label}</FormLabel>}
              <div className="relative">
                <FormControl>
                  {React.cloneElement(<Input />, {
                    ...field,
                    type: "date",
                    onChange: handleDateChange,
                    value: isDateValid(field.value)
                      ? toApiDateFormat(field.value)
                      : "",
                    ...(isDateValid(fromDate) && {
                      min: toApiDateFormat(fromDate as Date),
                    }),
                    ...(isDateValid(toDate) && {
                      max: toApiDateFormat(toDate as Date),
                    }),
                  })}
                </FormControl>
              </div>
              <p
                className={cn("text-error text-sm opacity-0", {
                  "opacity-100": error?.message,
                })}>
                {error?.message}
              </p>
            </FormItem>
          );
        }}
      />
    </div>
  );
}
