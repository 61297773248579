// Reference: https://www.halfgaar.net/localized-world-airport-codes
export var airports = [
    { code: "BIN", name: "Bamiyan" },
    { code: "BST", name: "Bost" },
    { code: "CCN", name: "Chakcharan" },
    { code: "DAZ", name: "Darwaz" },
    { code: "FBD", name: "Faizabad" },
    { code: "FAH", name: "Farah" },
    { code: "GRG", name: "Gardez" },
    { code: "GZI", name: "Ghazni" },
    { code: "HEA", name: "Herat" },
    { code: "JAA", name: "Jalalabad" },
    { code: "KDH", name: "Kandahar" },
    { code: "KHT", name: "Khost" },
    { code: "KWH", name: "Khwahan" },
    { code: "KBL", name: "Khwaja Rawash" },
    { code: "UND", name: "Kunduz" },
    { code: "KUR", name: "Kuran-O-Munjan" },
    { code: "MMZ", name: "Maimana" },
    { code: "MZR", name: "Mazar-i-sharif" },
    { code: "IMZ", name: "Nimroz" },
    { code: "LQN", name: "Qala Nau" },
    { code: "SBF", name: "Sardeh Band" },
    { code: "SGA", name: "Sheghnan" },
    { code: "TQN", name: "Taluqan" },
    { code: "TII", name: "Tirinkot" },
    { code: "URZ", name: "Uruzgan" },
    { code: "ZAJ", name: "Zaranj" },
    { code: "RDB", name: "Red Dog" },
    { code: "TIA", name: "Rinas Mother Teresa" },
    { code: "AZR", name: "Adrar" },
    { code: "TMR", name: "Aguemar" },
    { code: "OGX", name: "Ain Beida" },
    { code: "BUJ", name: "Ain Eddis" },
    { code: "CZL", name: "Ain El Bey" },
    { code: "BLJ", name: "Batna" },
    { code: "BJA", name: "Bejaia" },
    { code: "BSK", name: "Biskra" },
    { code: "BMW", name: "Bordj Badji Mokhtar" },
    { code: "TID", name: "Bouchekif Abde Al-hafidh Boussof" },
    { code: "ELG", name: "Djamet" },
    { code: "ORN", name: "Es Senia" },
    { code: "GBB", name: "Gara Djebilet" },
    { code: "ELU", name: "Guemar" },
    { code: "ALG", name: "Houari Boumediene" },
    { code: "VVZ", name: "Illizi" },
    { code: "IAM", name: "In Amenas" },
    { code: "INZ", name: "In Salah" },
    { code: "DJG", name: "Inedbirenne" },
    { code: "GJL", name: "Jijel" },
    { code: "KDF", name: "Kouba" },
    { code: "CBH", name: "Leger" },
    { code: "AAE", name: "Les Salines" },
    { code: "MUW", name: "Mascara" },
    { code: "MZW", name: "Mechria" },
    { code: "MQV", name: "Mostaganem" },
    { code: "INF", name: "Newark Liberty International" },
    { code: "GHA", name: "Noumerate" },
    { code: "HME", name: "Oued Irara Apt" },
    { code: "QSF", name: "Setif" },
    { code: "SKI", name: "Skikda" },
    { code: "TAF", name: "Tafaraoui" },
    { code: "TMX", name: "Timimoun" },
    { code: "TIN", name: "Tindouf" },
    { code: "TGR", name: "Touggourt" },
    { code: "TLM", name: "Zenata" },
    { code: "FTI", name: "Fitiuta" },
    { code: "PPG", name: "International" },
    { code: "OFU", name: "Ofu" },
    { code: "TAV", name: "Tau" },
    { code: "ALV", name: "Andorra La Vella H/P" },
    { code: "LAD", name: "4 de Fevereiro" },
    { code: "AZZ", name: "Ambriz" },
    { code: "AAZ", name: "Ambriz" },
    { code: "ANL", name: "Andulo" },
    { code: "CAB", name: "Cabinda" },
    { code: "CFF", name: "Cafunfo" },
    { code: "CNZ", name: "Cangamba" },
    { code: "KNP", name: "Capanda" },
    { code: "CBT", name: "Catumbela" },
    { code: "CAV", name: "Cazombo" },
    { code: "PGI", name: "Chitato" },
    { code: "CTI", name: "Cuito Cuanavale" },
    { code: "DRC", name: "Dirico" },
    { code: "DUE", name: "Dundo" },
    { code: "BUG", name: "Gen V Deslandes" },
    { code: "NOV", name: "Huambo" },
    { code: "JMB", name: "Jamba" },
    { code: "SVP", name: "Kuito" },
    { code: "UAL", name: "Luau" },
    { code: "SDD", name: "Lubango" },
    { code: "LUO", name: "Luena" },
    { code: "LBZ", name: "Lukapa" },
    { code: "GGC", name: "Lumbala" },
    { code: "LZM", name: "Luzamba" },
    { code: "MEG", name: "Malange" },
    { code: "SPP", name: "Menongue" },
    { code: "MSZ", name: "Namibe" },
    { code: "NDF", name: "Ndalatandos" },
    { code: "GXG", name: "Negage" },
    { code: "NGV", name: "Ngiva" },
    { code: "VPE", name: "Ongiva" },
    { code: "PBN", name: "Porto Amboim" },
    { code: "VHC", name: "Saurimo" },
    { code: "SZA", name: "Soyo" },
    { code: "NDD", name: "Sumbe" },
    { code: "UGO", name: "Uige" },
    { code: "CEO", name: "Waco Kungo" },
    { code: "XGN", name: "Xangongo" },
    { code: "AXA", name: "Wallblake" },
    { code: "TNM", name: "Teniente R. Marsh Martin" },
    { code: "BBQ", name: "Barbuda" },
    { code: "ANU", name: "V. C. Bird Intl" },
    { code: "REL", name: "Almirante Zar" },
    { code: "ARR", name: "Alto Rio Senguerr" },
    { code: "AEP", name: "Arpt. Jorge Newbery" },
    { code: "TUC", name: "Benj Matienzo" },
    { code: "CSZ", name: "Brigadier Hector Ruiz" },
    { code: "CVI", name: "Caleta Olivia" },
    { code: "CNQ", name: "Camba Punta" },
    { code: "IRJ", name: "Capitan Vicente Almando" },
    { code: "CPG", name: "Carmen De Patagones" },
    { code: "CTC", name: "Catamarca" },
    { code: "IGR", name: "Cataratas" },
    { code: "CVH", name: "Caviahue" },
    { code: "CRR", name: "Ceres" },
    { code: "CPC", name: "Chapelco" },
    { code: "CNT", name: "Charata" },
    { code: "CLX", name: "Clorinda" },
    { code: "CCT", name: "Colonia Catriel" },
    { code: "OLN", name: "Colonia Sarmiento" },
    { code: "BHI", name: "Comandante" },
    { code: "CRD", name: "Comodoro Rivadavia" },
    { code: "COC", name: "Concordia" },
    { code: "UZU", name: "Curuzu Cuatia" },
    { code: "CUT", name: "Cutral" },
    { code: "EHL", name: "El Bolson" },
    { code: "JUJ", name: "El Cadillal" },
    { code: "FTE", name: "El Calafate" },
    { code: "EMX", name: "El Maiten" },
    { code: "EPA", name: "El Palomar" },
    { code: "MDZ", name: "El Plumerillo" },
    { code: "FMA", name: "El Pucu" },
    { code: "PMY", name: "El Tehuelche" },
    { code: "ELO", name: "Eldorado" },
    { code: "EQS", name: "Esquel" },
    { code: "EZE", name: "Ezeiza Ministro Pistarini" },
    { code: "ROS", name: "Fisherton" },
    { code: "SLA", name: "Gen Belgrano" },
    { code: "GPO", name: "General Pico" },
    { code: "GNR", name: "General Roca" },
    { code: "VGS", name: "General Villegas" },
    { code: "GGS", name: "Gobernador Gregores" },
    { code: "OYA", name: "Goya" },
    { code: "GHU", name: "Gualeguaychu" },
    { code: "IGB", name: "Ingeniero Jacobacci" },
    { code: "RGL", name: "Internacional" },
    { code: "BRC", name: "International" },
    { code: "USH", name: "Islas Malvinas" },
    { code: "JSM", name: "Jose De San Martin" },
    { code: "JNI", name: "Junin" },
    { code: "LCM", name: "La Cumbre" },
    { code: "LPG", name: "La Plata" },
    { code: "ING", name: "Lago Argentino" },
    { code: "LHS", name: "Las Heras" },
    { code: "LLS", name: "Las Lomitas" },
    { code: "LCP", name: "Loncopue" },
    { code: "LMD", name: "Los Menucos" },
    { code: "LGS", name: "Malargue" },
    { code: "MQD", name: "Maquinchao" },
    { code: "MDQ", name: "Mar Del Plata" },
    { code: "MDX", name: "Mercedes" },
    { code: "MJR", name: "Miramar" },
    { code: "MCS", name: "Monte Caseros" },
    { code: "NEC", name: "Necochea" },
    { code: "NQN", name: "Neuquen" },
    { code: "OVR", name: "Olavarria" },
    { code: "ORA", name: "Oran" },
    { code: "COR", name: "Pajas Blancas" },
    { code: "PRA", name: "Parana" },
    { code: "AOL", name: "Paso De Los Libres" },
    { code: "PEH", name: "Pehuajo" },
    { code: "PMQ", name: "Perito Moreno" },
    { code: "PSS", name: "Posadas" },
    { code: "PRQ", name: "Pres. Roque Saenz Pena" },
    { code: "PUD", name: "Puerto Deseado" },
    { code: "RCQ", name: "Reconquista" },
    { code: "RES", name: "Resistencia" },
    { code: "RCU", name: "Rio Cuarto" },
    { code: "RGA", name: "Rio Grande" },
    { code: "RHD", name: "Rio Hondo" },
    { code: "ROY", name: "Rio Mayo" },
    { code: "RYO", name: "Rio Turbio" },
    { code: "SZQ", name: "Saenz Pena" },
    { code: "OES", name: "San Antonio Oeste" },
    { code: "UAQ", name: "San Juan" },
    { code: "ULA", name: "San Julian" },
    { code: "LUQ", name: "San Luis" },
    { code: "AFA", name: "San Rafael" },
    { code: "RZA", name: "Santa Cruz" },
    { code: "SFN", name: "Santa Fe" },
    { code: "RSA", name: "Santa Rosa" },
    { code: "SST", name: "Santa Teresita" },
    { code: "SDE", name: "Santiago Del Estero" },
    { code: "SGV", name: "Sierra Grande" },
    { code: "TDL", name: "Tandil" },
    { code: "TTG", name: "Tartagal" },
    { code: "VCF", name: "Valcheta" },
    { code: "RLO", name: "Valle Del Conlara" },
    { code: "VDM", name: "Viedma" },
    { code: "VDR", name: "Villa Dolores" },
    { code: "VLG", name: "Villa Gesell" },
    { code: "VME", name: "Villa Mercedes" },
    { code: "APZ", name: "Zapala" },
    { code: "LWN", name: "Gyoumri" },
    { code: "EVN", name: "Zvartnots" },
    { code: "AUA", name: "Reina Beatrix" },
    { code: "VCD", name: "Victoria River Downs" },
    { code: "ABG", name: "Abingdon" },
    { code: "ADL", name: "Adelaide International Airport" },
    { code: "XHX", name: "Adelaide Keswick Rail" },
    { code: "AGW", name: "Agnew" },
    { code: "ALH", name: "Albany" },
    { code: "ABX", name: "Albury" },
    { code: "AXL", name: "Alexandria" },
    { code: "ASP", name: "Alice Springs" },
    { code: "XHW", name: "Alice Springs Railway" },
    { code: "ABH", name: "Alpha" },
    { code: "AYD", name: "Alroy Downs" },
    { code: "AWN", name: "Alton Downs" },
    { code: "GTE", name: "Alyangula" },
    { code: "AMT", name: "Amata" },
    { code: "RCN", name: "American River" },
    { code: "AMX", name: "Ammaroo" },
    { code: "ADO", name: "Andamooka" },
    { code: "ANZ", name: "Angus Downs" },
    { code: "AYL", name: "Anthony Lagoon" },
    { code: "AXC", name: "Aramac" },
    { code: "ARY", name: "Ararat" },
    { code: "GYL", name: "Argyle" },
    { code: "AGY", name: "Argyle Downs" },
    { code: "ARM", name: "Armidale" },
    { code: "AAB", name: "Arrabury" },
    { code: "AUD", name: "Augustus Downs" },
    { code: "AUU", name: "Aurukun Mission" },
    { code: "AWP", name: "Austral Downs" },
    { code: "AVG", name: "Auvergne" },
    { code: "AVV", name: "Avalon" },
    { code: "AYR", name: "Ayr" },
    { code: "BDD", name: "Badu Island" },
    { code: "BSJ", name: "Bairnsdale" },
    { code: "LCN", name: "Balcanoona" },
    { code: "BQW", name: "Balgo Hills" },
    { code: "BNK", name: "Ballina Byron Gateway" },
    { code: "BZD", name: "Balranald" },
    { code: "ABM", name: "Bamaga Injinoo" },
    { code: "BYX", name: "Baniyala" },
    { code: "BCI", name: "Barcaldine" },
    { code: "BKP", name: "Barkly Downs" },
    { code: "BWB", name: "Barrow Island" },
    { code: "BVW", name: "Batavia Downs" },
    { code: "BRT", name: "Bathurst Island" },
    { code: "BEE", name: "Beagle Bay" },
    { code: "BDW", name: "Bedford Downs" },
    { code: "BEU", name: "Bedourie" },
    { code: "BXF", name: "Belburn" },
    { code: "BEO", name: "Belmont" },
    { code: "BLN", name: "Benalla" },
    { code: "BXG", name: "Bendigo" },
    { code: "BTX", name: "Betoota" },
    { code: "BVZ", name: "Beverley Springs" },
    { code: "BCZ", name: "Bickerton Island" },
    { code: "BBE", name: "Big Bell" },
    { code: "BIW", name: "Billiluna" },
    { code: "ZBL", name: "Biloela" },
    { code: "BVI", name: "Birdsville" },
    { code: "BZP", name: "Bizant" },
    { code: "BKQ", name: "Blackall" },
    { code: "BLT", name: "Blackwater" },
    { code: "BFC", name: "Bloomfield" },
    { code: "GIC", name: "Boigu Island" },
    { code: "BLS", name: "Bollon" },
    { code: "BCK", name: "Bolwarra" },
    { code: "BOX", name: "Borroloola" },
    { code: "BQL", name: "Boulia" },
    { code: "BRK", name: "Bourke" },
    { code: "ZBO", name: "Bowen" },
    { code: "BMP", name: "Brampton Island" },
    { code: "BWQ", name: "Brewarrina" },
    { code: "BRJ", name: "Bright" },
    { code: "BHT", name: "Brighton Downs" },
    { code: "BNE", name: "Brisbane International" },
    { code: "BHQ", name: "Broken Hill" },
    { code: "BME", name: "Broome" },
    { code: "BTD", name: "Brunette Downs" },
    { code: "BIP", name: "Bulimba" },
    { code: "BUY", name: "Bunbury" },
    { code: "BDB", name: "Bundaberg" },
    { code: "BUC", name: "Burketown" },
    { code: "BWT", name: "Burnie Wynyard" },
    { code: "BQB", name: "Bussellton" },
    { code: "CGV", name: "Caiguna" },
    { code: "CNS", name: "Cairns" },
    { code: "CUD", name: "Caloundra" },
    { code: "CDU", name: "Camden" },
    { code: "CFI", name: "Camfield" },
    { code: "CML", name: "Camooweal" },
    { code: "CBR", name: "Canberra" },
    { code: "CBY", name: "Canobie" },
    { code: "CBI", name: "Cape Barren Island" },
    { code: "CQP", name: "Cape Flattery" },
    { code: "CRY", name: "Carlton Hill" },
    { code: "CVQ", name: "Carnarvon" },
    { code: "CFP", name: "Carpentaria Downs" },
    { code: "CTR", name: "Cattle Creek" },
    { code: "CED", name: "Ceduna" },
    { code: "CES", name: "Cessnock" },
    { code: "CTL", name: "Charleville" },
    { code: "CXT", name: "Charters Towers" },
    { code: "CBC", name: "Cherrabun" },
    { code: "CRH", name: "Cherribah" },
    { code: "LLG", name: "Chillagoe" },
    { code: "CCL", name: "Chinchilla" },
    { code: "CXQ", name: "Christmas Creek" },
    { code: "KAH", name: "City Heliport" },
    { code: "CMQ", name: "Clermont" },
    { code: "CVC", name: "Cleve" },
    { code: "CFH", name: "Clifton Hills" },
    { code: "CNJ", name: "Cloncurry" },
    { code: "CZY", name: "Cluny" },
    { code: "CAZ", name: "Cobar" },
    { code: "CNC", name: "Coconut Island" },
    { code: "CUQ", name: "Coen" },
    { code: "CFS", name: "Coffs Harbour" },
    { code: "XCO", name: "Colac" },
    { code: "CRB", name: "Collarenebri" },
    { code: "CIE", name: "Collie" },
    { code: "KCE", name: "Collinsville" },
    { code: "CBX", name: "Condobolin" },
    { code: "AYQ", name: "Connellan" },
    { code: "CPD", name: "Coober Pedy" },
    { code: "CDA", name: "Cooinda" },
    { code: "CTN", name: "Cooktown" },
    { code: "CLH", name: "Coolah" },
    { code: "COY", name: "Coolawanyah" },
    { code: "COB", name: "Coolibah" },
    { code: "OOM", name: "Cooma" },
    { code: "COJ", name: "Coonabarabran" },
    { code: "CNB", name: "Coonamble" },
    { code: "CRJ", name: "Coorabie" },
    { code: "CMD", name: "Cootamundra" },
    { code: "ODL", name: "Cordillo Downs" },
    { code: "CWW", name: "Corowa" },
    { code: "CYG", name: "Corryong" },
    { code: "CWR", name: "Cowarie" },
    { code: "CCW", name: "Cowell" },
    { code: "CWT", name: "Cowra" },
    { code: "CSD", name: "Cresswell Downs" },
    { code: "CKI", name: "Croker Island" },
    { code: "CDQ", name: "Croydon" },
    { code: "CUG", name: "Cudal" },
    { code: "CUY", name: "Cue" },
    { code: "CMA", name: "Cunnamulla" },
    { code: "DBY", name: "Dalby" },
    { code: "DGD", name: "Dalgaranga" },
    { code: "DVR", name: "Daly River" },
    { code: "DYW", name: "Daly Waters" },
    { code: "NLF", name: "Darnley Island" },
    { code: "DRW", name: "Darwin" },
    { code: "XJG", name: "Darwin Railway" },
    { code: "DAJ", name: "Dauan Island" },
    { code: "DVP", name: "Davenport Downs" },
    { code: "DDI", name: "Daydream Is" },
    { code: "DLV", name: "Delissaville" },
    { code: "DDN", name: "Delta Downs" },
    { code: "DNM", name: "Denham" },
    { code: "DNQ", name: "Deniliquin" },
    { code: "DRB", name: "Derby" },
    { code: "DPO", name: "Devonport" },
    { code: "DYM", name: "Diamantina Lakes" },
    { code: "DRN", name: "Dirranbandi" },
    { code: "DXD", name: "Dixie" },
    { code: "DKV", name: "Docker River" },
    { code: "DOX", name: "Dongara" },
    { code: "DMD", name: "Doomadgee" },
    { code: "DNG", name: "Doongan" },
    { code: "DRD", name: "Dorunda Station" },
    { code: "DFP", name: "Drumduff" },
    { code: "DBO", name: "Dubbo" },
    { code: "DLK", name: "Dulkaninna" },
    { code: "DNB", name: "Dunbar" },
    { code: "DKI", name: "Dunk Island" },
    { code: "SRR", name: "Dunwich" },
    { code: "DHD", name: "Durham Downs" },
    { code: "DRR", name: "Durrie" },
    { code: "DYA", name: "Dysart" },
    { code: "ECH", name: "Echuca" },
    { code: "EDR", name: "Edward River" },
    { code: "EIH", name: "Einasleigh" },
    { code: "ELC", name: "Elcho Island" },
    { code: "EKD", name: "Elkedra" },
    { code: "EMD", name: "Emerald" },
    { code: "ENB", name: "Eneabba West" },
    { code: "EDD", name: "Erldunda" },
    { code: "ERB", name: "Ernabella" },
    { code: "EPR", name: "Esperance" },
    { code: "MEB", name: "Essendon" },
    { code: "ETD", name: "Etadunna" },
    { code: "EUC", name: "Eucla" },
    { code: "EVD", name: "Eva Downs" },
    { code: "EVH", name: "Evans Head" },
    { code: "EXM", name: "Exmouth Gulf" },
    { code: "FLC", name: "Falls Creek" },
    { code: "FIK", name: "Finke" },
    { code: "FLY", name: "Finley" },
    { code: "FIZ", name: "Fitzroy Crossing" },
    { code: "FLS", name: "Flinders Island" },
    { code: "FVL", name: "Flora Valey" },
    { code: "FRB", name: "Forbes" },
    { code: "FOS", name: "Forrest" },
    { code: "WGA", name: "Forrest Hill" },
    { code: "FVR", name: "Forrest River Airport" },
    { code: "FOT", name: "Forster" },
    { code: "FSL", name: "Fossil Downs" },
    { code: "OKB", name: "Fraser Island" },
    { code: "GBP", name: "Gamboola" },
    { code: "GPN", name: "Garden Point" },
    { code: "GSC", name: "Gascoyne Junction" },
    { code: "GAH", name: "Gayndah" },
    { code: "GEX", name: "Geelong" },
    { code: "GTT", name: "Georgetown" },
    { code: "GET", name: "Geraldton" },
    { code: "GBV", name: "Gibb River" },
    { code: "GLT", name: "Gladstone" },
    { code: "GLI", name: "Glen Innes" },
    { code: "GLG", name: "Glengyle" },
    { code: "GLM", name: "Glenormiston" },
    { code: "OOL", name: "Gold Coast" },
    { code: "GLY", name: "Goldsworthy" },
    { code: "GOO", name: "Goondiwindi" },
    { code: "GDD", name: "Gordon Downs" },
    { code: "GOS", name: "Gosford" },
    { code: "GUL", name: "Goulburn" },
    { code: "GBL", name: "Goulburn Island" },
    { code: "GFN", name: "Grafton" },
    { code: "GTS", name: "Granites" },
    { code: "GKL", name: "Great Keppel Island" },
    { code: "GVP", name: "Greenvale" },
    { code: "GGD", name: "Gregory Downs" },
    { code: "GFE", name: "Grenfell" },
    { code: "GFF", name: "Griffith" },
    { code: "GUH", name: "Gunnedah" },
    { code: "GYP", name: "Gympie" },
    { code: "HCQ", name: "Halls Creek" },
    { code: "HLT", name: "Hamilton" },
    { code: "HTI", name: "Hamilton Island" },
    { code: "PBY", name: "Hamilton/Proserpine" },
    { code: "HXX", name: "Hay" },
    { code: "HIS", name: "Hayman Island" },
    { code: "HIP", name: "Headingly" },
    { code: "HAT", name: "Heathlands" },
    { code: "HLV", name: "Helenvale" },
    { code: "JFM", name: "Heliport" },
    { code: "HRN", name: "Heliport" },
    { code: "HRY", name: "Henbury" },
    { code: "HMG", name: "Hermannsburg" },
    { code: "HVB", name: "Hervey Bay" },
    { code: "HIG", name: "Highbury" },
    { code: "HLL", name: "Hillside" },
    { code: "HNK", name: "Hinchinbrook Island" },
    { code: "HBA", name: "Hobart" },
    { code: "HIH", name: "Hook Island" },
    { code: "HOK", name: "Hooker Creek" },
    { code: "HPE", name: "Hope Vale" },
    { code: "HTU", name: "Hopetoun" },
    { code: "HID", name: "Horn Island" },
    { code: "HSM", name: "Horsham" },
    { code: "HGD", name: "Hughenden" },
    { code: "HUB", name: "Humbert River" },
    { code: "IFF", name: "Iffley" },
    { code: "IDK", name: "Indulkana" },
    { code: "IGH", name: "Ingham" },
    { code: "INJ", name: "Injune" },
    { code: "IKP", name: "Inkerman" },
    { code: "INM", name: "Innamincka" },
    { code: "IFL", name: "Innisfail" },
    { code: "IVR", name: "Inverell" },
    { code: "IVW", name: "Inverway" },
    { code: "ISI", name: "Isisford" },
    { code: "JAB", name: "Jabiru" },
    { code: "JAD", name: "Jandakot" },
    { code: "JCK", name: "Julia Creek" },
    { code: "JUN", name: "Jundah" },
    { code: "JUR", name: "Jurien Bay" },
    { code: "KAX", name: "Kalbarri" },
    { code: "KGI", name: "Kalgoorlie" },
    { code: "WAV", name: "Kalkgurung" },
    { code: "KFG", name: "Kalkurung" },
    { code: "KPP", name: "Kalpowar" },
    { code: "UBU", name: "Kalumburu" },
    { code: "KDS", name: "Kamaran Downs" },
    { code: "KDB", name: "Kambalda" },
    { code: "KML", name: "Kamileroi" },
    { code: "KTA", name: "Karratha" },
    { code: "KRB", name: "Karumba" },
    { code: "KNI", name: "Katanning" },
    { code: "KPS", name: "Kempsey" },
    { code: "KRA", name: "Kerang" },
    { code: "KBD", name: "Kimberley Downs" },
    { code: "KNS", name: "King Island" },
    { code: "KGY", name: "Kingaroy" },
    { code: "KBJ", name: "Kings Canyon" },
    { code: "KCS", name: "Kings Creek Station" },
    { code: "KGC", name: "Kingscote" },
    { code: "SYD", name: "Kingsford Smith" },
    { code: "KBB", name: "Kirkimbie" },
    { code: "KOH", name: "Koolatah" },
    { code: "KKP", name: "Koolburra" },
    { code: "KQB", name: "Koonibba" },
    { code: "KWM", name: "Kowanyama" },
    { code: "KUG", name: "Kubin Island" },
    { code: "KGR", name: "Kulgera" },
    { code: "KNX", name: "Kununurra" },
    { code: "KRD", name: "Kurundi" },
    { code: "TGN", name: "La Trobe Regional" },
    { code: "LYT", name: "Lady Elliot Island" },
    { code: "LEL", name: "Lake Evella" },
    { code: "LGE", name: "Lake Gregory" },
    { code: "LNH", name: "Lake Nash" },
    { code: "LFP", name: "Lakefield" },
    { code: "LKD", name: "Lakeland Downs" },
    { code: "LDW", name: "Lansdowne" },
    { code: "LTB", name: "Latrobe" },
    { code: "LST", name: "Launceston" },
    { code: "LUU", name: "Laura" },
    { code: "LUT", name: "Laura Station" },
    { code: "LVO", name: "Laverton" },
    { code: "LWH", name: "Lawn Hill" },
    { code: "LEA", name: "Learmonth" },
    { code: "LGH", name: "Leigh Creek" },
    { code: "LER", name: "Leinster" },
    { code: "LNO", name: "Leonora" },
    { code: "LHG", name: "Lightning Ridge" },
    { code: "LLT", name: "Lilydale Airport" },
    { code: "LIB", name: "Limbunya" },
    { code: "LLP", name: "Linda Downs" },
    { code: "LDC", name: "Lindeman Island" },
    { code: "LSY", name: "Lismore" },
    { code: "LLL", name: "Lissadell" },
    { code: "LZR", name: "Lizard Island" },
    { code: "LOC", name: "Lock" },
    { code: "IRG", name: "Lockhart River" },
    { code: "HAP", name: "Long Island" },
    { code: "LRE", name: "Longreach" },
    { code: "LDH", name: "Lord Howe Island" },
    { code: "LOA", name: "Lorraine" },
    { code: "LTV", name: "Lotusvale" },
    { code: "LTP", name: "Lyndhurst" },
    { code: "UBB", name: "Mabuiag Island" },
    { code: "MNW", name: "Macdonald Downs" },
    { code: "MKY", name: "Mackay" },
    { code: "MVH", name: "Macksville" },
    { code: "MHC", name: "Macmahon Camp 4" },
    { code: "MIZ", name: "Mainoru" },
    { code: "MTL", name: "Maitland" },
    { code: "XMC", name: "Mallacoota" },
    { code: "MQA", name: "Mandora" },
    { code: "MNG", name: "Maningrida" },
    { code: "MJP", name: "Manjimup" },
    { code: "MFP", name: "Manners Creek" },
    { code: "MBB", name: "Marble Bar" },
    { code: "MRG", name: "Mareeba" },
    { code: "MQZ", name: "Margaret River" },
    { code: "MGV", name: "Margaret River" },
    { code: "MXD", name: "Marion Downs" },
    { code: "MRP", name: "Marla" },
    { code: "MCY", name: "Maroochydore" },
    { code: "MQE", name: "Marqua" },
    { code: "RRE", name: "Marree" },
    { code: "MBH", name: "Maryborough" },
    { code: "MCV", name: "Mcarthur River" },
    { code: "MKR", name: "Meekatharra" },
    { code: "MIM", name: "Merimbula" },
    { code: "MLV", name: "Merluna" },
    { code: "RTY", name: "Merty" },
    { code: "MMM", name: "Middlemount" },
    { code: "MQL", name: "Mildura" },
    { code: "MGT", name: "Milingimbi" },
    { code: "MLR", name: "Millicent" },
    { code: "MRL", name: "Miners Lake" },
    { code: "XML", name: "Minlaton" },
    { code: "MIN", name: "Minnipa" },
    { code: "MWY", name: "Miranda Downs" },
    { code: "MTQ", name: "Mitchell" },
    { code: "MIH", name: "Mitchell Plateau" },
    { code: "MXQ", name: "Mitchell River" },
    { code: "MIY", name: "Mittiebah" },
    { code: "ONR", name: "Monkira" },
    { code: "MNQ", name: "Monto" },
    { code: "MWT", name: "Moolawatana" },
    { code: "MOO", name: "Moomba" },
    { code: "MBW", name: "Moorabbin" },
    { code: "OOR", name: "Mooraberree" },
    { code: "MOV", name: "Moranbah" },
    { code: "MWB", name: "Morawa" },
    { code: "MRZ", name: "Moree" },
    { code: "MET", name: "Moreton" },
    { code: "OXY", name: "Morney" },
    { code: "ONG", name: "Mornington" },
    { code: "MRT", name: "Moroak" },
    { code: "MYA", name: "Moruya" },
    { code: "MBF", name: "Mount Buffalo" },
    { code: "MFL", name: "Mount Full Stop" },
    { code: "MGB", name: "Mount Gambier" },
    { code: "GSN", name: "Mount Gunson" },
    { code: "MHU", name: "Mount Hotham" },
    { code: "MHO", name: "Mount House" },
    { code: "ISA", name: "Mount Isa" },
    { code: "WME", name: "Mount Keith" },
    { code: "MMG", name: "Mount Magnet" },
    { code: "MSF", name: "Mount Swan" },
    { code: "MNV", name: "Mountain Valley" },
    { code: "MBN", name: "Mt Barnett" },
    { code: "MKV", name: "Mt Cavenagh" },
    { code: "MTD", name: "Mt Sandford" },
    { code: "MUQ", name: "Muccan" },
    { code: "DGE", name: "Mudgee" },
    { code: "MUP", name: "Mulga Park" },
    { code: "MVK", name: "Mulka" },
    { code: "MXU", name: "Mullewa" },
    { code: "MNE", name: "Mungeranie" },
    { code: "MYI", name: "Murray Island" },
    { code: "MVU", name: "Musgrave" },
    { code: "UTB", name: "Muttaburra" },
    { code: "MYO", name: "Myroodah" },
    { code: "NBR", name: "Nambour" },
    { code: "NBH", name: "Nambucca Heads" },
    { code: "NMR", name: "Nappa Merry" },
    { code: "NPP", name: "Napperby" },
    { code: "NAC", name: "Naracoorte" },
    { code: "NAA", name: "Narrabri" },
    { code: "NRA", name: "Narrandera" },
    { code: "NRG", name: "Narrogin" },
    { code: "NMP", name: "New Moon" },
    { code: "ZNE", name: "Newman" },
    { code: "NRY", name: "Newry" },
    { code: "RPM", name: "Ngukurr" },
    { code: "GOV", name: "Nhulunbuy" },
    { code: "NLS", name: "Nicholson" },
    { code: "NKB", name: "Noonkanbah" },
    { code: "NSA", name: "Noosa" },
    { code: "NSV", name: "Noosaville" },
    { code: "NTN", name: "Normanton" },
    { code: "NSM", name: "Norseman" },
    { code: "NOA", name: "Nowra" },
    { code: "NLL", name: "Nullagine" },
    { code: "NUR", name: "Nullarbor" },
    { code: "NUB", name: "Numbulwar" },
    { code: "UTD", name: "Nutwood Downs" },
    { code: "NYN", name: "Nyngan" },
    { code: "OKY", name: "Oakey" },
    { code: "OBA", name: "Oban" },
    { code: "OPI", name: "Oenpelli" },
    { code: "WGZ", name: "Off Line Point" },
    { code: "XZX", name: "Off line Point" },
    { code: "OLP", name: "Olympic Dam" },
    { code: "ONS", name: "Onslow" },
    { code: "ODD", name: "Oodnadatta" },
    { code: "RBS", name: "Orbost" },
    { code: "ODR", name: "Ord River" },
    { code: "OXO", name: "Orientos" },
    { code: "OYN", name: "Ouyen" },
    { code: "LBH", name: "Palm Beach SPB" },
    { code: "PMK", name: "Palm Island" },
    { code: "PDE", name: "Pandie Pandie" },
    { code: "PBO", name: "Paraburdoo" },
    { code: "PRD", name: "Pardoo" },
    { code: "PKE", name: "Parkes" },
    { code: "PDN", name: "Parndana" },
    { code: "PEA", name: "Penneshaw" },
    { code: "PEY", name: "Penong" },
    { code: "PEP", name: "Peppimenarti" },
    { code: "PER", name: "Perth International" },
    { code: "PUG", name: "Port Augusta" },
    { code: "PTI", name: "Port Douglas" },
    { code: "PHE", name: "Port Hedland" },
    { code: "PHJ", name: "Port Hunter" },
    { code: "PKT", name: "Port Keats" },
    { code: "PLO", name: "Port Lincoln" },
    { code: "PQQ", name: "Port Macquarie" },
    { code: "PPI", name: "Port Pirie" },
    { code: "PTE", name: "Port Stephens" },
    { code: "PTJ", name: "Portland" },
    { code: "UEE", name: "Queenstown" },
    { code: "ULP", name: "Quilpie" },
    { code: "UIR", name: "Quirindi" },
    { code: "BHS", name: "Raglan" },
    { code: "RAM", name: "Ramingining" },
    { code: "RVT", name: "Ravensthorpe" },
    { code: "RMK", name: "Renmark" },
    { code: "RCM", name: "Richmond" },
    { code: "XRH", name: "Richmond AFB" },
    { code: "ROH", name: "Robinhood" },
    { code: "RRV", name: "Robinson River" },
    { code: "RBC", name: "Robinvale" },
    { code: "ROK", name: "Rockhampton" },
    { code: "RDA", name: "Rockhampton Downs" },
    { code: "RBU", name: "Roebourne" },
    { code: "RKY", name: "Rokeby" },
    { code: "RMA", name: "Roma" },
    { code: "RPB", name: "Roper Bar" },
    { code: "RPV", name: "Roper Valley" },
    { code: "RSE", name: "Rose Bay" },
    { code: "RSB", name: "Roseberth" },
    { code: "RLP", name: "Rosella Plains" },
    { code: "RTS", name: "Rottnest Island" },
    { code: "RHL", name: "Roy Hill" },
    { code: "RTP", name: "Rutland Plains" },
    { code: "SBR", name: "Saibai Island" },
    { code: "SXE", name: "Sale" },
    { code: "SRM", name: "Sandringham" },
    { code: "NDS", name: "Sandstone" },
    { code: "NSO", name: "Scone" },
    { code: "MJK", name: "Shark Bay" },
    { code: "SWB", name: "Shaw River" },
    { code: "SGP", name: "Shay Gap" },
    { code: "SHT", name: "Shepparton" },
    { code: "JHQ", name: "Shute Harbour H/P" },
    { code: "SSP", name: "Silver Plains" },
    { code: "SIX", name: "Singleton" },
    { code: "SHU", name: "Smith Point" },
    { code: "SIO", name: "Smithton" },
    { code: "SNB", name: "Snake Bay" },
    { code: "ZGL", name: "South Galway" },
    { code: "SOI", name: "South Molle Island" },
    { code: "SQC", name: "Southern Cross" },
    { code: "SHQ", name: "Southport" },
    { code: "SCG", name: "Spring Creek" },
    { code: "OAG", name: "Springhill" },
    { code: "KSV", name: "Springvale" },
    { code: "ZVG", name: "Springvale" },
    { code: "SGO", name: "St George" },
    { code: "HLS", name: "St Helens" },
    { code: "SNH", name: "Stanthorpe" },
    { code: "SQP", name: "Starcke" },
    { code: "SWC", name: "Stawell" },
    { code: "STF", name: "Stephen Island" },
    { code: "SRN", name: "Strahan" },
    { code: "STH", name: "Strathmore" },
    { code: "KBY", name: "Streaky Bay" },
    { code: "SSK", name: "Sturt Creek" },
    { code: "SFP", name: "Surfers Paradise" },
    { code: "SWH", name: "Swan Hill" },
    { code: "BWU", name: "Sydney Bankstown" },
    { code: "SWZ", name: "Sydney West" },
    { code: "TBL", name: "Tableland" },
    { code: "TMW", name: "Tamworth Regional Airport" },
    { code: "TXR", name: "Tanbar" },
    { code: "TAN", name: "Tangalooma" },
    { code: "XTR", name: "Tara" },
    { code: "TAQ", name: "Tarcoola" },
    { code: "TRO", name: "Taree" },
    { code: "XTO", name: "Taroom" },
    { code: "TEF", name: "Telfer" },
    { code: "TEM", name: "Temora" },
    { code: "TCA", name: "Tennant Creek" },
    { code: "TWN", name: "Tewantin" },
    { code: "THG", name: "Thangool" },
    { code: "XTG", name: "Thargomindah" },
    { code: "TDN", name: "Theda Station" },
    { code: "TDR", name: "Theodore" },
    { code: "TYG", name: "Thylungra" },
    { code: "TYB", name: "Tibooburra" },
    { code: "TBK", name: "Timber Creek" },
    { code: "KTR", name: "Tindal" },
    { code: "TYP", name: "Tobermorey" },
    { code: "TCW", name: "Tocumwal" },
    { code: "TPR", name: "Tom Price" },
    { code: "TWB", name: "Toowoomba" },
    { code: "TWP", name: "Torwood" },
    { code: "TSV", name: "Townsville International" },
    { code: "MEL", name: "Tullamarine" },
    { code: "TUM", name: "Tumut" },
    { code: "TKY", name: "Turkey Creek" },
    { code: "USL", name: "Useless Loop" },
    { code: "VNR", name: "Vanrook" },
    { code: "WBA", name: "Wahai" },
    { code: "WLC", name: "Walcha" },
    { code: "WGE", name: "Walgett" },
    { code: "WLA", name: "Wallal" },
    { code: "WGT", name: "Wangaratta" },
    { code: "SYU", name: "Warraber Island" },
    { code: "WKB", name: "Warracknabeal" },
    { code: "WRW", name: "Warrawagine" },
    { code: "WMB", name: "Warrnambool" },
    { code: "WAZ", name: "Warwick" },
    { code: "WLO", name: "Waterloo" },
    { code: "ORS", name: "Waterport" },
    { code: "WAU", name: "Wauchope" },
    { code: "WAN", name: "Waverney" },
    { code: "WEW", name: "Wee Waa" },
    { code: "WEI", name: "Weipa" },
    { code: "WHL", name: "Welshpool" },
    { code: "WWY", name: "West Wyalong" },
    { code: "WSY", name: "Whitsunday Airstrip" },
    { code: "PPP", name: "Whitsunday Coast" },
    { code: "WYA", name: "Whyalla" },
    { code: "WIO", name: "Wilcannia" },
    { code: "NTL", name: "Williamtown" },
    { code: "HWK", name: "Wilpena Pound" },
    { code: "WUN", name: "Wiluna" },
    { code: "WND", name: "Windarra" },
    { code: "WNR", name: "Windorah" },
    { code: "WIN", name: "Winton" },
    { code: "WIT", name: "Wittenoom" },
    { code: "WLL", name: "Wollogorang" },
    { code: "WOL", name: "Wollongong" },
    { code: "WDI", name: "Wondai" },
    { code: "WON", name: "Wondoola" },
    { code: "WOG", name: "Woodgreen" },
    { code: "UMR", name: "Woomera" },
    { code: "WPK", name: "Wrotham Park" },
    { code: "WUD", name: "Wudinna" },
    { code: "WYN", name: "Wyndham" },
    { code: "KYI", name: "Yalata Mission" },
    { code: "YLG", name: "Yalgoo" },
    { code: "XMY", name: "Yam Island" },
    { code: "YNN", name: "Yandi" },
    { code: "KYB", name: "Yangoonabie" },
    { code: "KYF", name: "Yeelirrie" },
    { code: "OKR", name: "Yorke Island" },
    { code: "ORR", name: "Yorketown" },
    { code: "NGA", name: "Young" },
    { code: "YUE", name: "Yuendumu" },
    { code: "KLU", name: "Alpe Adria" },
    { code: "LNZ", name: "Blue Danube" },
    { code: "HOH", name: "Hohenems" },
    { code: "INN", name: "Innsbruck-kranebitten" },
    { code: "XJS", name: "Kitzbuehl Railway" },
    { code: "QLX", name: "Lauterach" },
    { code: "XGZ", name: "Railway Station" },
    { code: "VIE", name: "Schwechat International" },
    { code: "XWC", name: "Suedbahnhof Rail" },
    { code: "GRZ", name: "Thalerhof" },
    { code: "VDD", name: "Vienna Danubepier Hov" },
    { code: "SZG", name: "W. A. Mozart" },
    { code: "XWW", name: "Westbahnhof Rail" },
    { code: "KVD", name: "Gyandzha" },
    { code: "ZXT", name: "Heydar Aliyev" },
    { code: "GYD", name: "Heydar Aliyev International" },
    { code: "NAJ", name: "Nakhichevan" },
    { code: "WZY", name: "Paradise Island Seaplane Base" },
    { code: "ASD", name: "Andros Town" },
    { code: "GBI", name: "Auxiliary Airfield" },
    { code: "CEL", name: "Cape Eleuthera" },
    { code: "CXY", name: "Cat Cays" },
    { code: "CCZ", name: "Chub Cay" },
    { code: "COX", name: "Congo Town" },
    { code: "CRI", name: "Crooked Island" },
    { code: "LGI", name: "Deadmans Cay" },
    { code: "DCT", name: "Duncan Town" },
    { code: "SML", name: "Estate Airstrip" },
    { code: "GGT", name: "Exuma International" },
    { code: "GHB", name: "Governors Harbour" },
    { code: "FPO", name: "Grand Bahama International" },
    { code: "GHC", name: "Great Harbour" },
    { code: "GTC", name: "Green Turtle" },
    { code: "HBI", name: "Harbour Island" },
    { code: "IGA", name: "Inagua" },
    { code: "BIM", name: "International" },
    { code: "MHH", name: "International" },
    { code: "ELH", name: "International" },
    { code: "MAY", name: "Mangrove Cay" },
    { code: "MSK", name: "Mastic Point" },
    { code: "MYG", name: "Mayaguana" },
    { code: "NAS", name: "Nassau International" },
    { code: "CAT", name: "New Bight" },
    { code: "NET", name: "New Bight" },
    { code: "TBI", name: "New Bright" },
    { code: "NSB", name: "North SPB" },
    { code: "PID", name: "Paradise Island" },
    { code: "PWN", name: "Pitts Town" },
    { code: "PPO", name: "Powell Point" },
    { code: "RCY", name: "Rum Cay" },
    { code: "RSD", name: "S Eleuthera" },
    { code: "SAQ", name: "San Andros" },
    { code: "ZSA", name: "San Salvador" },
    { code: "TZN", name: "South Andros" },
    { code: "SWL", name: "Spanish Wells" },
    { code: "AXP", name: "Springpoint Arpt" },
    { code: "TYM", name: "Staniel Cay" },
    { code: "TCB", name: "Treasure Cay" },
    { code: "WTD", name: "West End" },
    { code: "BAH", name: "Bahrain International" },
    { code: "GBQ", name: "Muharraq" },
    { code: "WGF", name: "Off Line Point" },
    { code: "BZL", name: "Barisal" },
    { code: "CLA", name: "Comilla" },
    { code: "IRD", name: "Ishurdi" },
    { code: "JSR", name: "Jessore" },
    { code: "KHL", name: "Khulna" },
    { code: "ZYL", name: "Osmani International" },
    { code: "CGP", name: "Patenga" },
    { code: "RJH", name: "Rajshahi" },
    { code: "RAU", name: "Rangpur" },
    { code: "SPD", name: "Saidpur" },
    { code: "SDW", name: "Sandwip" },
    { code: "ZHM", name: "Shamshernagar" },
    { code: "SAJ", name: "Sirajganj" },
    { code: "TKR", name: "Thakurgaon" },
    { code: "DAC", name: "Zia International" },
    { code: "BGI", name: "Grantley Adams International" },
    { code: "BQT", name: "Brest" },
    { code: "GME", name: "Gomel" },
    { code: "GNA", name: "Grodna" },
    { code: "MHP", name: "Minsk International 1" },
    { code: "MSQ", name: "Minsk International 2" },
    { code: "MVQ", name: "Mogilev" },
    { code: "VTB", name: "Vitebsk" },
    { code: "ZYZ", name: "Berchem Railway Stn." },
    { code: "BRU", name: "Brussels Airport" },
    { code: "CRL", name: "Charleroi Brussels South" },
    { code: "ZAY", name: "De Keyserlei Bus Stn." },
    { code: "ANR", name: "Deurne" },
    { code: "GNE", name: "Ghent" },
    { code: "XHN", name: "Guillemins, Raiway Stn" },
    { code: "KNO", name: "Knokke/Het Zoute" },
    { code: "KJK", name: "Kortrijk" },
    { code: "LGG", name: "Liege Airport" },
    { code: "OST", name: "Ostend/Bruges International Airport" },
    { code: "BCV", name: "Belmopan" },
    { code: "BGK", name: "Big Creek" },
    { code: "CUK", name: "Caye Caulker" },
    { code: "CYC", name: "Caye Chapel" },
    { code: "CZH", name: "Corozal" },
    { code: "DGA", name: "Dangriga" },
    { code: "INB", name: "Independence" },
    { code: "MZE", name: "Manatee" },
    { code: "SQS", name: "Matthew Spain" },
    { code: "MDB", name: "Melinda" },
    { code: "TZA", name: "Municipal" },
    { code: "ORZ", name: "Orange Walk" },
    { code: "BZE", name: "Philip S.W.Goldson Int" },
    { code: "PLJ", name: "Placencia" },
    { code: "PND", name: "Punta Gorda" },
    { code: "SPR", name: "San Pedro" },
    { code: "STU", name: "Santa Cruz" },
    { code: "SJX", name: "Sartaneja" },
    { code: "SVK", name: "Silver Creek" },
    { code: "COO", name: "Cotonou" },
    { code: "DJA", name: "Djougou" },
    { code: "KDC", name: "Kandi" },
    { code: "NAE", name: "Natitingou" },
    { code: "PKO", name: "Parakou" },
    { code: "SVF", name: "Save" },
    { code: "BDA", name: "Kindley Field" },
    { code: "NWU", name: "NAS" },
    { code: "PBH", name: "Paro" },
    { code: "APB", name: "Apolo" },
    { code: "ASC", name: "Ascension" },
    { code: "BJO", name: "Bermejo" },
    { code: "CAM", name: "Camiri" },
    { code: "SRJ", name: "Capitan G Q Guardia" },
    { code: "CEP", name: "Concepcion" },
    { code: "CIJ", name: "E. Beltram" },
    { code: "LPB", name: "El Alto" },
    { code: "SRZ", name: "El Trompillo" },
    { code: "RIB", name: "Gen Buech" },
    { code: "GYA", name: "Guayaramerin" },
    { code: "CBB", name: "J Wilsterman" },
    { code: "SRE", name: "Juana Azurduy de Padilla" },
    { code: "MGD", name: "Magdalena" },
    { code: "MHW", name: "Monteagudo" },
    { code: "ORU", name: "Oruro" },
    { code: "POI", name: "Potosi" },
    { code: "PUR", name: "Puerto Rico" },
    { code: "PSZ", name: "Puerto Suarez" },
    { code: "REY", name: "Reyes" },
    { code: "RBO", name: "Robore" },
    { code: "RBQ", name: "Rurrenabaque" },
    { code: "SNM", name: "San Ignacio De M" },
    { code: "SNG", name: "San Ignacio De Velasco" },
    { code: "SJV", name: "San Javier" },
    { code: "SJB", name: "San Joaquin" },
    { code: "SJS", name: "San Jose" },
    { code: "MQK", name: "San Matias" },
    { code: "SRD", name: "San Ramon" },
    { code: "SRB", name: "Santa Rosa" },
    { code: "TJA", name: "Tarija" },
    { code: "TDD", name: "Trinidad" },
    { code: "VAH", name: "Vallegrande" },
    { code: "VLM", name: "Villamontes" },
    { code: "VVI", name: "Viru Viru Intl" },
    { code: "BYC", name: "Yacuiba" },
    { code: "SBL", name: "Yacuma" },
    { code: "BNX", name: "Banja Luka" },
    { code: "OMO", name: "Mostar" },
    { code: "SJJ", name: "Sarajevo" },
    { code: "TZL", name: "Tuzla International" },
    { code: "FRW", name: "Francistown" },
    { code: "GNZ", name: "Ghanzi" },
    { code: "HUK", name: "Hukuntsi" },
    { code: "JWA", name: "Jwaneng" },
    { code: "BBK", name: "Kasane" },
    { code: "KHW", name: "Khwai River Lodge" },
    { code: "TLD", name: "Limpopo Valley" },
    { code: "LOQ", name: "Lobatse" },
    { code: "MUB", name: "Maun" },
    { code: "ORP", name: "Orapa" },
    { code: "SVT", name: "Savuti" },
    { code: "PKW", name: "Selebi-Phikwe" },
    { code: "SWX", name: "Shakawe" },
    { code: "GBE", name: "Sir Seretse Khama International" },
    { code: "SXN", name: "Suapan" },
    { code: "TBY", name: "Tsabong" },
    { code: "SSA", name: "Luis Eduardo Magalhaes" },
    { code: "PPB", name: "Adhemar De Barros" },
    { code: "GRU", name: "Aeroporto Internacional Guarulhos" },
    { code: "MVS", name: "Aeroporto Max Feffer" },
    { code: "XAP", name: "Aeroporto Municipal Chapeco" },
    { code: "CWB", name: "Afonso Pena International Airport" },
    { code: "BAT", name: "Airport Chafei Amsei" },
    { code: "BGX", name: "Airport Cmdt. Gustavo Kraemer" },
    { code: "ALT", name: "Alenquer" },
    { code: "AMJ", name: "Almenara" },
    { code: "AFL", name: "Alta Floresta" },
    { code: "ATM", name: "Altamira" },
    { code: "APY", name: "Alto Parnaiba" },
    { code: "APS", name: "Anapolis" },
    { code: "APU", name: "Apucarana" },
    { code: "ARU", name: "Aracatuba" },
    { code: "ARS", name: "Aragarcas" },
    { code: "AUX", name: "Araguaina" },
    { code: "APQ", name: "Arapiraca" },
    { code: "APX", name: "Arapongas" },
    { code: "AAG", name: "Arapoti" },
    { code: "AQA", name: "Araraquara" },
    { code: "AAX", name: "Araxa" },
    { code: "AQM", name: "Ariquemes" },
    { code: "AAI", name: "Arraias" },
    { code: "ZFU", name: "Aruja" },
    { code: "AIF", name: "Assis" },
    { code: "NAT", name: "Augusto Severo" },
    { code: "BFH", name: "Bacacheri" },
    { code: "BSS", name: "Balsas" },
    { code: "BAZ", name: "Barbelos" },
    { code: "BQQ", name: "Barra" },
    { code: "BDC", name: "Barra Do Corda" },
    { code: "BPG", name: "Barra Do Garcas" },
    { code: "BRA", name: "Barreiras" },
    { code: "BRB", name: "Barreirinhas" },
    { code: "CAW", name: "Bartolomeu Lisandro" },
    { code: "RIA", name: "Base Aerea De Santa Maria" },
    { code: "BAU", name: "Bauru" },
    { code: "BVM", name: "Belmonte" },
    { code: "BGV", name: "Bento Goncalves" },
    { code: "BNU", name: "Blumenau" },
    { code: "BVB", name: "Boa Vista" },
    { code: "BCR", name: "Boca Do Acre" },
    { code: "LAZ", name: "Bom Jesus Da Lapa" },
    { code: "BYO", name: "Bonito Airport" },
    { code: "RBB", name: "Borba" },
    { code: "BJP", name: "Braganca Paulista" },
    { code: "BVS", name: "Breves" },
    { code: "BMS", name: "Brumado" },
    { code: "BZC", name: "Buzios" },
    { code: "CFB", name: "Cabo Frio" },
    { code: "CFC", name: "Cacador" },
    { code: "CCX", name: "Caceres" },
    { code: "CCQ", name: "Cachoeira" },
    { code: "CDI", name: "Cachoeiro Itapemirim" },
    { code: "OAL", name: "Cacoal" },
    { code: "CLV", name: "Caldas Novas" },
    { code: "CMT", name: "Cameta" },
    { code: "CMC", name: "Camocim" },
    { code: "CMP", name: "Campo Alegre" },
    { code: "CXJ", name: "Campo Dos Bugres" },
    { code: "CBW", name: "Campo Mourao" },
    { code: "NBV", name: "Cana Brava" },
    { code: "CQA", name: "Canarana Airport" },
    { code: "CNV", name: "Canavieiras" },
    { code: "FLB", name: "Cangapara" },
    { code: "CKS", name: "Carajas" },
    { code: "CAF", name: "Carauari" },
    { code: "CRQ", name: "Caravelas" },
    { code: "CZB", name: "Carlos Ruhl" },
    { code: "CLN", name: "Carolina" },
    { code: "CAU", name: "Caruaru" },
    { code: "CTP", name: "Carutapera" },
    { code: "CAC", name: "Cascavel" },
    { code: "CSS", name: "Cassilandia" },
    { code: "JPA", name: "Castro Pinto" },
    { code: "TLZ", name: "Catalao" },
    { code: "IGU", name: "Cataratas" },
    { code: "LEC", name: "Chapada Diamantina" },
    { code: "CIZ", name: "Coari" },
    { code: "CSW", name: "Colorado do Oeste" },
    { code: "ERM", name: "Comandante Kraemer" },
    { code: "CDJ", name: "Conceicao Do Araguaia" },
    { code: "CCI", name: "Concordia" },
    { code: "STZ", name: "Confresa" },
    { code: "CFO", name: "Confreza" },
    { code: "CGH", name: "Congonhas" },
    { code: "CKO", name: "Cornelio Procopio" },
    { code: "CCM", name: "Criciuma" },
    { code: "CPU", name: "Cururupu" },
    { code: "SJL", name: "Da Cachoeira" },
    { code: "DMT", name: "Diamantino" },
    { code: "DNO", name: "Dianopolis" },
    { code: "DIQ", name: "Divinopolis" },
    { code: "MVF", name: "Dixsept Rosado" },
    { code: "CTQ", name: "Do Palmar" },
    { code: "LVB", name: "Dos Galpoes" },
    { code: "DOU", name: "Dourados" },
    { code: "MII", name: "Dr Gastao Vidigal" },
    { code: "PVI", name: "Edu Chaves" },
    { code: "IOS", name: "Eduardo Gomes" },
    { code: "STM", name: "Eduardo Gomes" },
    { code: "UDI", name: "Eduardo Gomes" },
    { code: "MAO", name: "Eduardo Gomes Intl" },
    { code: "ERN", name: "Eirunepe" },
    { code: "UMU", name: "Ernesto Geisel" },
    { code: "URB", name: "Ernesto Pochler" },
    { code: "ESI", name: "Espinosa" },
    { code: "VIX", name: "Eurico Sales" },
    { code: "ALQ", name: "Federal" },
    { code: "PET", name: "Federal" },
    { code: "FEJ", name: "Feijo" },
    { code: "FEC", name: "Feira De Santana" },
    { code: "FEN", name: "Fernando De Noronha" },
    { code: "FRC", name: "Franca" },
    { code: "FBE", name: "Francisco Beltrao" },
    { code: "JDF", name: "Francisco De Assis" },
    { code: "GIG", name: "Galeão–antonio Carlos Jobim International Airport" },
    { code: "PVH", name: "Governador Jorge Teixeira" },
    { code: "GVR", name: "Governador Valadares" },
    { code: "GCV", name: "Gravatai" },
    { code: "GDP", name: "Guadalupe" },
    { code: "GJM", name: "Guajara-Mirim" },
    { code: "GNM", name: "Guanambi" },
    { code: "GUZ", name: "Guarapari" },
    { code: "REC", name: "Guararapes Intl" },
    { code: "GUJ", name: "Guaratingueta" },
    { code: "GMS", name: "Guimaraes" },
    { code: "GRP", name: "Gurupi" },
    { code: "LOI", name: "Helmuth Baungartem" },
    { code: "FLN", name: "Hercilio Luz" },
    { code: "ITR", name: "Hidroeletrica" },
    { code: "HRZ", name: "Horizontina" },
    { code: "ILB", name: "Ilha Solteira" },
    { code: "IMP", name: "Imperatriz" },
    { code: "MCP", name: "Internacional" },
    { code: "PNZ", name: "Internacional" },
    { code: "TBT", name: "Internacional" },
    { code: "CPQ", name: "International" },
    { code: "PMG", name: "International" },
    { code: "CGR", name: "Intl. Airport Campo Grande" },
    { code: "CMG", name: "Intl. Airport Corumba" },
    { code: "CZS", name: "Intl. Airpt. Cruzeiro Do Sul" },
    { code: "CGB", name: "Intl. Airpt. Marechal Rondon" },
    { code: "IPU", name: "Ipiau" },
    { code: "IPG", name: "Ipiranga" },
    { code: "IRE", name: "Irece" },
    { code: "ITN", name: "Itabuna" },
    { code: "ITA", name: "Itacoatiara" },
    { code: "ITB", name: "Itaituba" },
    { code: "ITI", name: "Itambacuri" },
    { code: "ITP", name: "Itaperuna" },
    { code: "ITQ", name: "Itaqui" },
    { code: "AUB", name: "Itauba" },
    { code: "ITE", name: "Itubera" },
    { code: "IJU", name: "J. Batista Bos Filho Airport" },
    { code: "JCR", name: "Jacareacanga" },
    { code: "JCM", name: "Jacobina" },
    { code: "JNA", name: "Januaria" },
    { code: "JTI", name: "Jatai" },
    { code: "JEQ", name: "Jequie" },
    { code: "JPR", name: "Ji-Parana" },
    { code: "JCB", name: "Joacaba" },
    { code: "CPV", name: "Joao Suassuna" },
    { code: "JIA", name: "Juina" },
    { code: "JRN", name: "Juruena" },
    { code: "LBR", name: "Labrea" },
    { code: "LAJ", name: "Lages" },
    { code: "JOI", name: "Lauro Carneiro De Loyola" },
    { code: "RAO", name: "Leite Lopes" },
    { code: "LEP", name: "Leopoldina" },
    { code: "LIP", name: "Lins" },
    { code: "LDB", name: "Londrina" },
    { code: "MEA", name: "Macae" },
    { code: "VAG", name: "Maj. Brig. Trompowsky" },
    { code: "MNX", name: "Manicore" },
    { code: "MAB", name: "Maraba" },
    { code: "SLZ", name: "Marechal Cunha Machado" },
    { code: "MTG", name: "Mato Grosso" },
    { code: "MBK", name: "Matupa" },
    { code: "MBZ", name: "Maues" },
    { code: "NTM", name: "Miracema Do Norte" },
    { code: "MTE", name: "Monte Alegre" },
    { code: "MEU", name: "Monte Dourado" },
    { code: "MOC", name: "Montes Claros" },
    { code: "PNG", name: "Municipal" },
    { code: "PTO", name: "Municipal" },
    { code: "NNU", name: "Nanuque" },
    { code: "NVT", name: "Navegantes" },
    { code: "NQL", name: "Niquelandia" },
    { code: "NOK", name: "Nova Xavantina" },
    { code: "NVP", name: "Novo Aripuana" },
    { code: "OBI", name: "Obidos" },
    { code: "OYK", name: "Oiapoque" },
    { code: "ORX", name: "Oriximina" },
    { code: "OIA", name: "Ourilandia" },
    { code: "OUS", name: "Ourinhos" },
    { code: "MCZ", name: "Palmares" },
    { code: "PMW", name: "Palmas" },
    { code: "PLU", name: "Pampulha Domestic" },
    { code: "PBB", name: "Paranaiba" },
    { code: "PIN", name: "Parintins" },
    { code: "PFB", name: "Passo Fundo" },
    { code: "PSW", name: "Passos" },
    { code: "POJ", name: "Patos De Minas" },
    { code: "PAV", name: "Paulo Afonso" },
    { code: "PCS", name: "Picos" },
    { code: "PBQ", name: "Pimenta Bueno" },
    { code: "PHI", name: "Pinheiro" },
    { code: "FOR", name: "Pinto Martins" },
    { code: "PIV", name: "Pirapora" },
    { code: "PIG", name: "Pitinga" },
    { code: "POO", name: "Pocos De Caldas" },
    { code: "PLL", name: "Ponta Pelada" },
    { code: "LCB", name: "Pontes e Lacerda" },
    { code: "PBX", name: "Porto Alegre Do Norte" },
    { code: "PTQ", name: "Porto de Moz" },
    { code: "PBV", name: "Porto Dos Gauchos" },
    { code: "PNB", name: "Porto Nacional" },
    { code: "BPS", name: "Porto Seguro" },
    { code: "PPY", name: "Pouso Alegre" },
    { code: "PDF", name: "Prado" },
    { code: "RBR", name: "Pres. Medici" },
    { code: "BSB", name: "Presidente Juscelino Kubitschek" },
    { code: "PGG", name: "Progresso" },
    { code: "RDC", name: "Redencao" },
    { code: "MGF", name: "Regional De Maringa SBMG" },
    { code: "JDO", name: "Regional Do Cariri" },
    { code: "IDO", name: "Rio" },
    { code: "RIG", name: "Rio Grande" },
    { code: "RVD", name: "Rio Verde" },
    { code: "ROO", name: "Rondonopolis" },
    { code: "URG", name: "Ruben Berta" },
    { code: "POA", name: "Salgado Filho" },
    { code: "SNZ", name: "Santa Cruz" },
    { code: "CSU", name: "Santa Cruz Do Sul" },
    { code: "SFV", name: "Santa Fe Do Sul" },
    { code: "GYN", name: "Santa Genoveva" },
    { code: "AJU", name: "Santa Maria" },
    { code: "SRA", name: "Santa Rosa" },
    { code: "SSZ", name: "Santos" },
    { code: "PHB", name: "Santos Dumont" },
    { code: "SDU", name: "Santos Dumont" },
    { code: "SXO", name: "Sao Felix Do Araguaia" },
    { code: "SXX", name: "Sao Felix Do Xingu" },
    { code: "SJP", name: "Sao Jose Do Rio Preto" },
    { code: "SJK", name: "Sao Jose Dos Campos" },
    { code: "SSO", name: "Sao Lourenco" },
    { code: "SQY", name: "Sao Lourenco Do Sul" },
    { code: "SBJ", name: "Sao Mateus" },
    { code: "SQM", name: "São Miguel de Aragao" },
    { code: "SQX", name: "Sao Miguel do Oeste" },
    { code: "ZMD", name: "Sena Madureira" },
    { code: "THE", name: "Senador Petrônio Portella" },
    { code: "SEI", name: "Senhor Do Bonfim" },
    { code: "GEL", name: "Sepe Tiaraju" },
    { code: "RRN", name: "Serra Norte" },
    { code: "RSG", name: "Serra Pelada" },
    { code: "OPS", name: "Sinop" },
    { code: "SOD", name: "Sorocaba" },
    { code: "SWM", name: "Suia-Missu" },
    { code: "RWS", name: "Sumare" },
    { code: "CNF", name: "Tancredo Neves International" },
    { code: "GPB", name: "Tancredo Thomaz Faria" },
    { code: "TGQ", name: "Tangara da Serra" },
    { code: "TRQ", name: "Tarauaca" },
    { code: "TFF", name: "Tefe" },
    { code: "TXF", name: "Teixeira de Freitas" },
    { code: "TEC", name: "Telemaco Borba" },
    { code: "TFL", name: "Teofilo Otoni" },
    { code: "TOW", name: "Toledo" },
    { code: "TSQ", name: "Torres" },
    { code: "TMT", name: "Trombetas" },
    { code: "TUZ", name: "Tucuma" },
    { code: "TUR", name: "Tucurui" },
    { code: "UBT", name: "Ubatuba" },
    { code: "UBA", name: "Uberaba" },
    { code: "UNA", name: "Una" },
    { code: "IPN", name: "Usiminas" },
    { code: "BEL", name: "Val De Cans" },
    { code: "VAL", name: "Valenca" },
    { code: "VIA", name: "Vienna" },
    { code: "BVH", name: "Vilhena" },
    { code: "VCP", name: "Viracopos" },
    { code: "VDC", name: "Vitoria Da Conquista" },
    { code: "VOT", name: "Votuporanga" },
    { code: "AXE", name: "Xanxere" },
    { code: "EIS", name: "Beef Island" },
    { code: "VIJ", name: "Virgin Gorda" },
    { code: "BWN", name: "Bandar Seri Begwan International Airport" },
    { code: "BOJ", name: "Bourgas" },
    { code: "GOZ", name: "Gorna Oriahovitsa" },
    { code: "HKV", name: "Haskovo" },
    { code: "JAM", name: "Jambol" },
    { code: "KDG", name: "Kardjali" },
    { code: "PVN", name: "Pleven" },
    { code: "PDV", name: "Plovdiv" },
    { code: "ROU", name: "Rousse" },
    { code: "SLS", name: "Silistra" },
    { code: "SOF", name: "Sofia" },
    { code: "SZR", name: "Stara Zagora" },
    { code: "TGV", name: "Targovishte" },
    { code: "VAR", name: "Varna" },
    { code: "VID", name: "Vidin" },
    { code: "XAR", name: "Aribinda" },
    { code: "ARL", name: "Arly" },
    { code: "BNR", name: "Banfora" },
    { code: "XBG", name: "Bogande" },
    { code: "BOY", name: "Borgo" },
    { code: "XBO", name: "Boulsa" },
    { code: "DGU", name: "Dedougou" },
    { code: "DIP", name: "Diapaga" },
    { code: "XDE", name: "Diebougou" },
    { code: "XDJ", name: "Djibo" },
    { code: "DOR", name: "Dori" },
    { code: "FNG", name: "Fada Ngourma" },
    { code: "XGA", name: "Gaoua" },
    { code: "XGG", name: "Gorom-Gorom" },
    { code: "XKA", name: "Kantchari" },
    { code: "XKY", name: "Kaya" },
    { code: "XLU", name: "Leo" },
    { code: "XNU", name: "Nouna" },
    { code: "OUA", name: "Ouagadougou" },
    { code: "OUG", name: "Ouahigouya" },
    { code: "XPA", name: "Pama" },
    { code: "PUP", name: "Po" },
    { code: "XSE", name: "Sebba" },
    { code: "TMQ", name: "Tambao" },
    { code: "TEG", name: "Tenkodogo" },
    { code: "TUQ", name: "Tougan" },
    { code: "XZA", name: "Zabre" },
    { code: "GID", name: "Gitega" },
    { code: "BJM", name: "International" },
    { code: "KRE", name: "Kirundo" },
    { code: "BBM", name: "Battambang" },
    { code: "KMT", name: "Kampot" },
    { code: "KKZ", name: "Koh Kong" },
    { code: "KZK", name: "Kompong Thom" },
    { code: "KZC", name: "Kompong-Chhna" },
    { code: "KZD", name: "Krakor" },
    { code: "KTI", name: "Kratie" },
    { code: "MWV", name: "Mundulkiri" },
    { code: "OMY", name: "Oddor Meanche" },
    { code: "PAI", name: "Pailin" },
    { code: "PNH", name: "Phnom Penh International" },
    { code: "HPP", name: "Poipet" },
    { code: "RBE", name: "Ratanakiri" },
    { code: "REP", name: "Siem Reap" },
    { code: "KOS", name: "Sihanoukville" },
    { code: "TNX", name: "Stung Treng" },
    { code: "SVR", name: "Svay Rieng" },
    { code: "BFX", name: "Bafoussam" },
    { code: "BLC", name: "Bamenda" },
    { code: "BPC", name: "Bamenda" },
    { code: "OUR", name: "Batouri" },
    { code: "DLA", name: "Douala" },
    { code: "DSC", name: "Dschang" },
    { code: "EBW", name: "Ebolowa" },
    { code: "FOM", name: "Foumban" },
    { code: "GOU", name: "Garoua" },
    { code: "KLE", name: "Kaele" },
    { code: "KOB", name: "Koutaba" },
    { code: "KBI", name: "Kribi" },
    { code: "VCC", name: "Limbe" },
    { code: "MMF", name: "Mamfe" },
    { code: "NGE", name: "Ngaoundere" },
    { code: "NKS", name: "Nkongsamba" },
    { code: "NSI", name: "Nsimalen International" },
    { code: "MVR", name: "Salam" },
    { code: "TKC", name: "Tiko" },
    { code: "GXX", name: "Yagoua" },
    { code: "BTA", name: "Yaoundé" },
    { code: "YAO", name: "Yaounde Airport" },
    { code: "YNF", name: "Deer Lake/Stephenville" },
    { code: "ZMH", name: "108 Mile Ranch" },
    { code: "YXX", name: "Abbotsford" },
    { code: "LAK", name: "Aklavik" },
    { code: "AKV", name: "Akulivik" },
    { code: "YLT", name: "Alert" },
    { code: "YAL", name: "Alert Bay" },
    { code: "ZAA", name: "Alice Arm" },
    { code: "YTF", name: "Alma" },
    { code: "YAE", name: "Alta Lake" },
    { code: "YEY", name: "Amos" },
    { code: "YAA", name: "Anahim Lake" },
    { code: "YAX", name: "Angling Lake" },
    { code: "YAB", name: "Arctic Bay" },
    { code: "YYW", name: "Armstrong" },
    { code: "YNR", name: "Arnes" },
    { code: "YEK", name: "Arviat" },
    { code: "YAF", name: "Asbestos Hill" },
    { code: "YZA", name: "Ashcroft" },
    { code: "YIB", name: "Atikokan" },
    { code: "YAT", name: "Attawapiskat" },
    { code: "YPJ", name: "Aupaluk" },
    { code: "YBG", name: "Bagotville" },
    { code: "YBC", name: "Baie Comeau" },
    { code: "YBJ", name: "Baie Johan Beetz" },
    { code: "YBK", name: "Baker Lake" },
    { code: "YBF", name: "Bamfield" },
    { code: "YBA", name: "Banff" },
    { code: "ZBF", name: "Bathurst" },
    { code: "XBE", name: "Bearskin Lake" },
    { code: "YZC", name: "Beatton River" },
    { code: "YXQ", name: "Beaver Creek" },
    { code: "ZEL", name: "Bella Bella" },
    { code: "QBC", name: "Bella Coola" },
    { code: "YBV", name: "Berens River" },
    { code: "YIG", name: "Big Bay Marina" },
    { code: "YYA", name: "Big Bay Yacht Club" },
    { code: "YTL", name: "Big Trout" },
    { code: "YOS", name: "Billy Bishop Regional" },
    { code: "YBI", name: "Black Tickle" },
    { code: "YBX", name: "Blanc Sablon" },
    { code: "XBB", name: "Blubber Bay" },
    { code: "YVB", name: "Bonaventure" },
    { code: "YBY", name: "Bonnyville" },
    { code: "YBN", name: "Borden" },
    { code: "YDT", name: "Boundary Bay" },
    { code: "YBR", name: "Brandon" },
    { code: "YDR", name: "Broadview" },
    { code: "YBT", name: "Brochet" },
    { code: "XBR", name: "Brockville" },
    { code: "ZBM", name: "Bromont" },
    { code: "YBM", name: "Bronson Creek" },
    { code: "YZM", name: "Buchans" },
    { code: "YVT", name: "Buffalo Narrows" },
    { code: "YBH", name: "Bull Harbour" },
    { code: "YPZ", name: "Burns Lake" },
    { code: "YDB", name: "Burwash Landings" },
    { code: "YKZ", name: "Buttonville Municipal" },
    { code: "YYC", name: "Calgary International Airport" },
    { code: "YCB", name: "Cambridge Bay" },
    { code: "YBL", name: "Campbell River" },
    { code: "YTE", name: "Cape Dorset" },
    { code: "YCJ", name: "Cape St James" },
    { code: "YCI", name: "Caribou Island" },
    { code: "YCV", name: "Cartierville" },
    { code: "YRF", name: "Cartwright" },
    { code: "YCG", name: "Castlegar" },
    { code: "YAC", name: "Cat Lake" },
    { code: "YLD", name: "Chapleau" },
    { code: "YML", name: "Charlevoix" },
    { code: "YCL", name: "Charlo" },
    { code: "YHG", name: "Charlottetown" },
    { code: "YYG", name: "Charlottetown" },
    { code: "XFZ", name: "Charny" },
    { code: "XCM", name: "Chatham" },
    { code: "YCS", name: "Chesterfield Inlet" },
    { code: "YCQ", name: "Chetwynd" },
    { code: "YHR", name: "Chevery" },
    { code: "YMT", name: "Chibougamau" },
    { code: "CJH", name: "Chilko Lake" },
    { code: "YCW", name: "Chilliwack Municipal Airport" },
    { code: "YKU", name: "Chisasibi" },
    { code: "ZUM", name: "Churchill Falls" },
    { code: "YWQ", name: "Chute-Des-Passes" },
    { code: "YLM", name: "Clinton Creek" },
    { code: "XCL", name: "Cluff Lake" },
    { code: "YCY", name: "Clyde River" },
    { code: "YCP", name: "Co-op Point" },
    { code: "CXH", name: "Coal Harbour" },
    { code: "YCN", name: "Cochrane" },
    { code: "YOD", name: "Cold Lake" },
    { code: "YKC", name: "Collins Bay" },
    { code: "YCK", name: "Colville Lake" },
    { code: "YQQ", name: "Comox" },
    { code: "YZS", name: "Coral Harbour" },
    { code: "YCT", name: "Coronation" },
    { code: "YCF", name: "Cortes Bay" },
    { code: "YCA", name: "Courtenay" },
    { code: "YYM", name: "Cowley" },
    { code: "YXC", name: "Cranbrook" },
    { code: "CFQ", name: "Creston Valley Airport" },
    { code: "YCR", name: "Cross Lake" },
    { code: "YDH", name: "Daniels Harbour" },
    { code: "YDN", name: "Dauphin" },
    { code: "YDI", name: "Davis Inlet" },
    { code: "YDA", name: "Dawson City" },
    { code: "YDQ", name: "Dawson Creek" },
    { code: "YRD", name: "Dean River" },
    { code: "YDL", name: "Dease Lake" },
    { code: "YGY", name: "Deception" },
    { code: "YDF", name: "Deer Lake" },
    { code: "YVZ", name: "Deer Lake" },
    { code: "YWJ", name: "Deline" },
    { code: "YDS", name: "Desolation Sound" },
    { code: "DVK", name: "Diavik" },
    { code: "YDG", name: "Digby" },
    { code: "YPR", name: "Digby Island" },
    { code: "YDX", name: "Doc Creek" },
    { code: "YZD", name: "Downsview Airport" },
    { code: "DUQ", name: "Duncan/Quam" },
    { code: "YWF", name: "Dwtown Waterfront H/P" },
    { code: "YXR", name: "Earlton" },
    { code: "ZEM", name: "East Main" },
    { code: "YEM", name: "East Manitoulin" },
    { code: "YXD", name: "Edmonton City Center Airport" },
    { code: "YEG", name: "Edmonton International" },
    { code: "YET", name: "Edson" },
    { code: "YOA", name: "Ekati" },
    { code: "YEL", name: "Elliot Lake" },
    { code: "YPF", name: "Esquimalt" },
    { code: "YEN", name: "Estevan" },
    { code: "YEP", name: "Estevan Point" },
    { code: "YEU", name: "Eureka" },
    { code: "YND", name: "Executive Gatineau-Ottawa" },
    { code: "YCZ", name: "Fairmont Hot Springs" },
    { code: "ZFW", name: "Fairview" },
    { code: "YOE", name: "Falher" },
    { code: "ZIF", name: "Fallowfield Railway" },
    { code: "ZFA", name: "Faro" },
    { code: "YFO", name: "Flin Flon" },
    { code: "ZFD", name: "Fond Du Lac" },
    { code: "YFG", name: "Fontanges" },
    { code: "YOJ", name: "Footner Lake" },
    { code: "YFE", name: "Forestville" },
    { code: "YFA", name: "Fort Albany" },
    { code: "YPY", name: "Fort Chipewyan" },
    { code: "YGH", name: "Fort Good Hope" },
    { code: "YFH", name: "Fort Hope" },
    { code: "YJF", name: "Fort Liard" },
    { code: "YMM", name: "Fort Mcmurray" },
    { code: "ZFM", name: "Fort Mcpherson" },
    { code: "YYE", name: "Fort Nelson" },
    { code: "YFL", name: "Fort Reliance" },
    { code: "YFR", name: "Fort Resolution" },
    { code: "YER", name: "Fort Severn" },
    { code: "YFS", name: "Fort Simpson" },
    { code: "YSM", name: "Fort Smith" },
    { code: "YXJ", name: "Fort St John" },
    { code: "YFX", name: "Fox Harbour" },
    { code: "YFC", name: "Fredericton" },
    { code: "YCX", name: "Gagetown" },
    { code: "YGA", name: "Gagnon" },
    { code: "YQX", name: "Gander International" },
    { code: "YGG", name: "Ganges Harbor" },
    { code: "YGQ", name: "Geraldton" },
    { code: "YGS", name: "Germansen" },
    { code: "ZGS", name: "Gethsemani" },
    { code: "YGX", name: "Gillam" },
    { code: "YGB", name: "Gillies Bay" },
    { code: "YGM", name: "Gimli" },
    { code: "YHK", name: "Gjoa Haven" },
    { code: "YPA", name: "Glass Field" },
    { code: "YGO", name: "Gods Narrows" },
    { code: "ZGI", name: "Gods River" },
    { code: "YYR", name: "Goose Bay" },
    { code: "YZE", name: "Gore Bay" },
    { code: "YGE", name: "Gorge Harbor" },
    { code: "ZGF", name: "Grand Forks" },
    { code: "YGC", name: "Grande Cache" },
    { code: "YQU", name: "Grande Prairie" },
    { code: "XGL", name: "Granville Lake" },
    { code: "DAS", name: "Great Bear Lake" },
    { code: "YQM", name: "Greater Moncton International Airport" },
    { code: "YGN", name: "Greenway Sound" },
    { code: "YZX", name: "Greenwood" },
    { code: "YGZ", name: "Grise Fiord" },
    { code: "YHT", name: "Haines Junction" },
    { code: "YHC", name: "Hakai Pass" },
    { code: "YHZ", name: "Halifax International" },
    { code: "YUX", name: "Hall Beach" },
    { code: "YHM", name: "Hamilton" },
    { code: "HNX", name: "Hanna" },
    { code: "YHH", name: "Harbor SPB" },
    { code: "ZRR", name: "Harbour" },
    { code: "ZNA", name: "Harbour" },
    { code: "YTB", name: "Hartley Bay" },
    { code: "YDJ", name: "Hatchet Lake" },
    { code: "YGV", name: "Havre St Pierre" },
    { code: "YHY", name: "Hay River" },
    { code: "YHF", name: "Hearst" },
    { code: "ZHP", name: "High Prairie" },
    { code: "YHI", name: "Holman" },
    { code: "YHE", name: "Hope" },
    { code: "YHO", name: "Hopedale" },
    { code: "YHN", name: "Hornepayne" },
    { code: "YHB", name: "Hudson Bay" },
    { code: "YGT", name: "Igloolik" },
    { code: "ZUC", name: "Ignace" },
    { code: "YGR", name: "Iles De La Madeleine" },
    { code: "ILF", name: "Ilford" },
    { code: "YDC", name: "Industrial Airport" },
    { code: "YPH", name: "Inukjuak" },
    { code: "YEV", name: "Inuvik/mike Zubko" },
    { code: "TIL", name: "Inverlake" },
    { code: "YFB", name: "Iqaluit" },
    { code: "YIV", name: "Island Lk/Garden Hill" },
    { code: "YIK", name: "Ivujivik" },
    { code: "YXE", name: "J.G. Diefenbaker International Airport" },
    { code: "YWG", name: "James Armstrong Richardson International Airport" },
    { code: "YJP", name: "Jasper-hinton" },
    { code: "ZJG", name: "Jenpeg" },
    { code: "YJO", name: "Johnny Mountain" },
    { code: "YKA", name: "Kamloops" },
    { code: "XGR", name: "Kangiqsualujjuaq" },
    { code: "YWB", name: "Kangiqsujuaq" },
    { code: "YKG", name: "Kangirsuk" },
    { code: "YYU", name: "Kapuskasing" },
    { code: "XKS", name: "Kasabonika" },
    { code: "ZKE", name: "Kaschechewan" },
    { code: "YAU", name: "Kattiniq/Donaldson" },
    { code: "KEW", name: "Keewaywin" },
    { code: "ZKG", name: "Kegaska" },
    { code: "YLW", name: "Kelowna Airport" },
    { code: "KES", name: "Kelsey" },
    { code: "XKO", name: "Kemano" },
    { code: "YKI", name: "Kennosao Lake" },
    { code: "YQK", name: "Kenora" },
    { code: "YKJ", name: "Key Lake" },
    { code: "YXI", name: "Killaloe" },
    { code: "XBW", name: "Killineq" },
    { code: "YQE", name: "Kimberley" },
    { code: "YLC", name: "Kimmirut" },
    { code: "YKY", name: "Kindersley" },
    { code: "KIF", name: "Kingfisher Lake" },
    { code: "YGK", name: "Kingston" },
    { code: "KNY", name: "Kinoosao" },
    { code: "YKX", name: "Kirkland Lake" },
    { code: "YKF", name: "Kitchener-Waterloo Regional" },
    { code: "YKK", name: "Kitkatla" },
    { code: "YKT", name: "Klemtu" },
    { code: "YKE", name: "Knee Lake" },
    { code: "KNV", name: "Knights Inlet" },
    { code: "YBB", name: "Kugaaruk" },
    { code: "YCO", name: "Kugluktuk" },
    { code: "YVP", name: "Kuujjuaq" },
    { code: "YGW", name: "Kuujjuarapik" },
    { code: "YGL", name: "La Grande" },
    { code: "YTM", name: "La Macaza" },
    { code: "YVC", name: "La Ronge" },
    { code: "SSQ", name: "La Sarre" },
    { code: "ZLT", name: "La Tabatiere" },
    { code: "YLQ", name: "La Tuque" },
    { code: "YLB", name: "Lac Biche" },
    { code: "XLB", name: "Lac Brochet" },
    { code: "YLF", name: "LaForges" },
    { code: "YAR", name: "Lagrande 3" },
    { code: "YAH", name: "Lagrande 4" },
    { code: "YLA", name: "Langara" },
    { code: "YLY", name: "Langley Regional" },
    { code: "YLH", name: "Lansdowne House" },
    { code: "LRQ", name: "Laurie River" },
    { code: "XLF", name: "Leaf Bay" },
    { code: "YLR", name: "Leaf Rapids" },
    { code: "YLS", name: "Lebel-Sur-Quevillon" },
    { code: "YYZ", name: "Lester B. Pearson International" },
    { code: "YQL", name: "Lethbridge" },
    { code: "ZGR", name: "Little Grand Rapids" },
    { code: "YLL", name: "Lloydminster" },
    { code: "YXU", name: "London International" },
    { code: "YLX", name: "Long Point" },
    { code: "YWO", name: "Lupin" },
    { code: "YSG", name: "Lutselke" },
    { code: "YAJ", name: "Lyall Harbour" },
    { code: "YYL", name: "Lynn Lake" },
    { code: "XMP", name: "Macmillan Pass" },
    { code: "YDK", name: "Main Duck Island" },
    { code: "YMN", name: "Makkovik" },
    { code: "YMG", name: "Manitouwadge" },
    { code: "YMW", name: "Maniwaki" },
    { code: "YAQ", name: "Maple Bay" },
    { code: "YSP", name: "Marathon" },
    { code: "YMC", name: "Maricourt Airstrip" },
    { code: "ZMT", name: "Masset" },
    { code: "YNM", name: "Matagami" },
    { code: "YME", name: "Matane" },
    { code: "YMA", name: "Mayo" },
    { code: "YLJ", name: "Meadow Lake" },
    { code: "YXH", name: "Medicine Hat" },
    { code: "YMB", name: "Merritt" },
    { code: "YMR", name: "Merry Island" },
    { code: "YYQ", name: "Metropolitan Area" },
    { code: "YMI", name: "Minaki" },
    { code: "YAV", name: "Miners Bay" },
    { code: "YLP", name: "Mingan" },
    { code: "YMX", name: "Mirabel" },
    { code: "YCH", name: "Miramichi" },
    { code: "YYY", name: "Mont Joli" },
    { code: "YMF", name: "Montagne Harbor" },
    {
        code: "YUL",
        name: "Montréal-pierre Elliott Trudeau International Airport",
    },
    { code: "YMJ", name: "Moose Jaw / Air Vice Marshal C. M. Mcewen Airport" },
    { code: "YMO", name: "Moosonee" },
    { code: "YHD", name: "Municipal" },
    { code: "YAG", name: "Municipal" },
    { code: "YQA", name: "Muskoka" },
    { code: "MSA", name: "Muskrat Dam" },
    { code: "YBS", name: "Musselwhite" },
    { code: "YDP", name: "Nain" },
    { code: "YQN", name: "Nakina" },
    { code: "YED", name: "Namao Fld" },
    { code: "ZNU", name: "Namu" },
    { code: "YCD", name: "Nanaimo Arpt" },
    { code: "YSR", name: "Nanisivik" },
    { code: "YNA", name: "Natashquan" },
    { code: "YNP", name: "Natuashish" },
    { code: "ZNG", name: "Negginan" },
    { code: "YNS", name: "Nemiscau" },
    { code: "YBD", name: "New Westminster" },
    { code: "YNI", name: "Nitchequon" },
    { code: "YNK", name: "Nootka Sound" },
    { code: "YVQ", name: "Norman Wells" },
    { code: "YQW", name: "North Battleford" },
    { code: "YYB", name: "North Bay" },
    { code: "YNO", name: "North Spirit Lake" },
    { code: "YNE", name: "Norway House" },
    { code: "NWP", name: "NS" },
    { code: "YRT", name: "Nuuk" },
    { code: "YDW", name: "Obre Lake" },
    { code: "ZOF", name: "Ocean Falls" },
    { code: "YOG", name: "Ogoki" },
    { code: "YOC", name: "Old Crow" },
    { code: "ZFB", name: "Old Fort Bay" },
    { code: "YOO", name: "Oshawa" },
    { code: "YOW", name: "Ottawa Macdonald-Cartier International" },
    { code: "YOH", name: "Oxford House" },
    { code: "YIF", name: "Pakuashipi" },
    { code: "YXP", name: "Pangnirtung" },
    { code: "YDE", name: "Paradise River" },
    { code: "YPD", name: "Parry Sound" },
    { code: "YPC", name: "Paulatuk" },
    { code: "YPE", name: "Peace River" },
    { code: "YPO", name: "Peawanuck" },
    { code: "YTA", name: "Pembroke And Area Apt" },
    { code: "YQS", name: "Pembroke Area Mncpl" },
    { code: "YPT", name: "Pender Harbor" },
    { code: "YYF", name: "Penticton" },
    { code: "YWA", name: "Petawawa" },
    { code: "YPQ", name: "Peterborough" },
    { code: "YPL", name: "Pickle Lake" },
    { code: "YPM", name: "Pikangikum" },
    { code: "PIW", name: "Pikwitonei" },
    { code: "WPC", name: "Pincher Creek" },
    { code: "ZPO", name: "Pine House" },
    { code: "YPP", name: "Pine Point" },
    { code: "YNL", name: "Points North Landing" },
    { code: "YIO", name: "Pond Inlet" },
    { code: "YHP", name: "Poplar Hill" },
    { code: "XPP", name: "Poplar River" },
    { code: "YPB", name: "Port Alberni" },
    { code: "YZT", name: "Port Hardy" },
    { code: "YPS", name: "Port Hawkesbury" },
    { code: "YHA", name: "Port Hope Simpson" },
    { code: "YMP", name: "Port McNeil" },
    { code: "YPN", name: "Port Menier" },
    { code: "YPI", name: "Port Simpson" },
    { code: "YPG", name: "Portage La Prairie" },
    { code: "YSO", name: "Postville" },
    { code: "WPL", name: "Powell Lake" },
    { code: "YPW", name: "Powell River" },
    { code: "XDV", name: "Pr. George Rail Stn" },
    { code: "XDW", name: "Pr. Rupert Rail Stn" },
    { code: "YXS", name: "Prince George" },
    { code: "XPK", name: "Pukatawagan" },
    { code: "YPX", name: "Puvirnituq" },
    { code: "YVM", name: "Qikiqtarjuaq" },
    { code: "XQU", name: "Qualicum" },
    { code: "YQC", name: "Quaqtaq" },
    { code: "YQB", name: "Québec" },
    { code: "XLJ", name: "Quebec Stn. Rail Svce." },
    { code: "ZQS", name: "Queen Charlotte Is" },
    { code: "YQZ", name: "Quesnel" },
    { code: "YRA", name: "Rae Lakes" },
    { code: "YOP", name: "Rainbow Lake" },
    { code: "YQF", name: "Red Deer" },
    { code: "YRL", name: "Red Lake" },
    { code: "YRS", name: "Red Sucker Lake" },
    { code: "YQR", name: "Regina" },
    { code: "YCC", name: "Regional" },
    { code: "YUT", name: "Repulse Bay" },
    { code: "YRB", name: "Resolute" },
    { code: "YRE", name: "Resolution Island" },
    { code: "YRV", name: "Revelstoke" },
    { code: "YRG", name: "Rigolet" },
    { code: "YXK", name: "Rimouski" },
    { code: "YYI", name: "Rivers" },
    { code: "YRN", name: "Rivers Inlet" },
    { code: "YTN", name: "Riviere Au Tonnerre" },
    { code: "YRI", name: "Riviere Du Loup" },
    { code: "YRJ", name: "Roberval" },
    { code: "YRM", name: "Rocky Mountain House" },
    { code: "XRR", name: "Ross River" },
    { code: "ZRJ", name: "Round Lake" },
    { code: "YUY", name: "Rouyn-Noranda" },
    { code: "YSA", name: "Sable Island" },
    { code: "ZPB", name: "Sachigo Lake" },
    { code: "YSY", name: "Sachs Harbour" },
    { code: "YSV", name: "Saglek" },
    { code: "YSJ", name: "Saint John" },
    { code: "XFY", name: "Sainte-Foy Rail Svce." },
    { code: "YZG", name: "Salluit" },
    { code: "YSN", name: "Salmon Arm" },
    { code: "YZP", name: "Sandspit" },
    { code: "ZSJ", name: "Sandy Lake" },
    { code: "YSK", name: "Sanikiluaq" },
    { code: "YSI", name: "Sans Souci" },
    { code: "YZR", name: "Sarnia" },
    { code: "YAM", name: "Sault Ste Marie" },
    { code: "YKL", name: "Schefferville" },
    { code: "ZSW", name: "Seal Cove" },
    { code: "YTP", name: "Seaplane Base" },
    { code: "YHS", name: "Sechelt" },
    { code: "YZV", name: "Sept-Iles" },
    { code: "ZTM", name: "Shamattawa" },
    { code: "YAW", name: "Shearwater" },
    { code: "YSX", name: "Shearwater" },
    { code: "YSC", name: "Sherbrooke" },
    { code: "YLO", name: "Shilo" },
    { code: "SYF", name: "Silva Bay" },
    { code: "YXL", name: "Sioux Lookout" },
    { code: "YSS", name: "Slate Island" },
    { code: "YZH", name: "Slave Lake" },
    { code: "YSH", name: "Smith Falls" },
    { code: "YYD", name: "Smithers" },
    { code: "YXF", name: "Snake River" },
    { code: "YFJ", name: "Snare Lake" },
    { code: "XSI", name: "South Indian Lake" },
    { code: "ZFL", name: "South Trout Lake" },
    { code: "YSQ", name: "Spring Island" },
    { code: "YBW", name: "Springbank" },
    { code: "YSE", name: "Squamish" },
    { code: "YSZ", name: "Squirrel Cove" },
    { code: "YAY", name: "St Anthony" },
    { code: "YCM", name: "St Catharines" },
    { code: "YHU", name: "St Hubert" },
    { code: "YJN", name: "St Jean" },
    { code: "XLM", name: "St Lambert Rail Svce." },
    { code: "YSL", name: "St Leonard" },
    { code: "YDO", name: "St Methode" },
    { code: "ZSP", name: "St Paul" },
    { code: "YYT", name: "St. John´s International" },
    { code: "YST", name: "Ste Therese Point" },
    { code: "YJT", name: "Stephenville" },
    { code: "ZST", name: "Stewart" },
    { code: "YSF", name: "Stony Rapids" },
    { code: "YRR", name: "Stuart Island" },
    { code: "YTC", name: "Sturdee" },
    { code: "YSB", name: "Sudbury" },
    { code: "XDY", name: "Sudbury Jct Rail Stn" },
    { code: "YSD", name: "Suffield" },
    { code: "YTG", name: "Sullivan Bay" },
    { code: "SUR", name: "Summer Beaver" },
    { code: "YSU", name: "Summerside" },
    { code: "IUM", name: "Summit Lake" },
    { code: "ZJN", name: "Swan River" },
    { code: "YYN", name: "Swift Current" },
    { code: "YQY", name: "Sydney" },
    { code: "XTL", name: "Tadoule Lake" },
    { code: "YYH", name: "Taloyoak" },
    { code: "YTX", name: "Telegraph Creek" },
    { code: "YBQ", name: "Telegraph Harbour" },
    { code: "YXT", name: "Terrace" },
    { code: "YTJ", name: "Terrace Bay" },
    { code: "YZW", name: "Teslin" },
    { code: "ZTB", name: "Tete-a-La Baleine" },
    { code: "YQD", name: "The Pas" },
    { code: "YTD", name: "Thicket Portage" },
    { code: "YTH", name: "Thompson" },
    { code: "YQT", name: "Thunder Bay" },
    { code: "YTS", name: "Timmins" },
    { code: "YTT", name: "Tisdale" },
    { code: "YAZ", name: "Tofino Airport" },
    { code: "YTZ", name: "Toronto City Centre Airport" },
    { code: "YTO", name: "Toronto Pearson International" },
    { code: "YKD", name: "Township Airport" },
    { code: "YTR", name: "Trenton" },
    { code: "YTI", name: "Triple Island" },
    { code: "YRQ", name: "Trois-Rivieres" },
    { code: "YUB", name: "Tuktoyaktuk" },
    { code: "ZFN", name: "Tulita" },
    { code: "YTK", name: "Tulugak" },
    { code: "TUX", name: "Tumbler Ridge" },
    { code: "YUD", name: "Umiujaq" },
    { code: "YBE", name: "Uranium City" },
    { code: "YOY", name: "Valcartier" },
    { code: "YVR", name: "Vancouver Intl" },
    { code: "XEA", name: "Vancouver Rail Stn." },
    { code: "YVG", name: "Vermilion" },
    { code: "YVE", name: "Vernon Regional Airport" },
    { code: "YWH", name: "Victoria Inner Harbor" },
    { code: "YYJ", name: "Victoria International" },
    { code: "YWK", name: "Wabush" },
    { code: "YKQ", name: "Waskaganish" },
    { code: "YQH", name: "Watson Lake" },
    { code: "YXZ", name: "Wawa Airport" },
    { code: "YWP", name: "Webequie" },
    { code: "YNC", name: "Wemindji" },
    { code: "YLE", name: "Wha Ti" },
    { code: "YXN", name: "Whale Cove" },
    { code: "YWS", name: "Whistler" },
    { code: "YWR", name: "White River" },
    { code: "YZU", name: "Whitecourt" },
    { code: "YXY", name: "Whitehorse International" },
    { code: "YVV", name: "Wiarton/Keppel Airport" },
    { code: "YWM", name: "Williams Harbour" },
    { code: "YWL", name: "Williams Lake" },
    { code: "YQG", name: "Windsor" },
    { code: "YWN", name: "Winisk" },
    { code: "ZWL", name: "Wollaston Lake" },
    { code: "YWY", name: "Wrigley" },
    { code: "WNN", name: "Wunnummin Lake" },
    { code: "YQI", name: "Yarmouth" },
    { code: "YZF", name: "Yellowknife" },
    { code: "ZAC", name: "York Landing" },
    { code: "YQV", name: "Yorkton" },
    { code: "SID", name: "Amilcar Cabral Intl" },
    { code: "BVR", name: "Esperadinha" },
    { code: "RAI", name: "Francisco Mendes" },
    { code: "MTI", name: "Mosteiros" },
    { code: "SNE", name: "Preguica" },
    { code: "BVC", name: "Rabil" },
    { code: "VXE", name: "San Pedro" },
    { code: "NTO", name: "Santo Antao" },
    { code: "SFL", name: "Sao Filipe" },
    { code: "MMO", name: "Vila Do Maio" },
    { code: "CYB", name: "Gerrard-Smith" },
    { code: "LYB", name: "Little Cayman" },
    { code: "GCM", name: "Owen Roberts International" },
    { code: "BMF", name: "Bakouma" },
    { code: "BBY", name: "Bambari" },
    { code: "BGU", name: "Bangassou" },
    { code: "BGF", name: "Bangui" },
    { code: "BTG", name: "Batangafo" },
    { code: "BBT", name: "Berberati" },
    { code: "IRO", name: "Birao" },
    { code: "BSN", name: "Bossangoa" },
    { code: "BEM", name: "Bossembele" },
    { code: "BOP", name: "Bouar" },
    { code: "BCF", name: "Bouca" },
    { code: "BOZ", name: "Bozoum" },
    { code: "BIV", name: "Bria" },
    { code: "CRF", name: "Carnot" },
    { code: "GDI", name: "Gordil" },
    { code: "GDA", name: "Gounda" },
    { code: "KWD", name: "Kawadjia" },
    { code: "KOL", name: "Koumala" },
    { code: "NDL", name: "Ndele" },
    { code: "ODA", name: "Ouadda" },
    { code: "ODJ", name: "Ouanda Djalle" },
    { code: "RFA", name: "Rafai" },
    { code: "AIG", name: "Yalinga" },
    { code: "IMO", name: "Zemio" },
    { code: "AEH", name: "Abecher" },
    { code: "AOD", name: "Abou Deia" },
    { code: "AMC", name: "Am Timan" },
    { code: "ATV", name: "Ati" },
    { code: "BKR", name: "Bokoro" },
    { code: "OTC", name: "Bol" },
    { code: "OGR", name: "Bongor" },
    { code: "OUT", name: "Bousso" },
    { code: "FYT", name: "Faya" },
    { code: "LTC", name: "Lai" },
    { code: "AMO", name: "Mao" },
    { code: "MEF", name: "Melfi" },
    { code: "MVO", name: "Mongo" },
    { code: "MQQ", name: "Moundou" },
    { code: "NDJ", name: "Ndjamena" },
    { code: "OUM", name: "Oum Hadjer" },
    { code: "PLF", name: "Pala" },
    { code: "SRH", name: "Sarh" },
    { code: "AKM", name: "Zakouma" },
    { code: "WAP", name: "Alto Palena" },
    { code: "ZUD", name: "Ancud" },
    { code: "SCL", name: "Arturo Merino Benitez" },
    { code: "TOQ", name: "Barriles" },
    { code: "ZOS", name: "Canal Balo" },
    { code: "CCP", name: "Carriel Sur" },
    { code: "IQQ", name: "Cavancha" },
    { code: "ANF", name: "Cerro Moreno" },
    { code: "SMB", name: "Cerro Sombrero" },
    { code: "ARI", name: "Chacalluta" },
    { code: "WCH", name: "Chaiten" },
    { code: "CPO", name: "Chamonate" },
    { code: "CNR", name: "Chanaral" },
    { code: "CCH", name: "Chile Chico" },
    { code: "YAI", name: "Chillan" },
    { code: "LGR", name: "Cochrane" },
    { code: "COW", name: "Coquimbo" },
    { code: "CJC", name: "El Loa" },
    { code: "ESR", name: "El Salvador" },
    { code: "FRT", name: "Frutillar" },
    { code: "FFU", name: "Futaleufu" },
    { code: "WCA", name: "Gamboa" },
    { code: "LSC", name: "La Florida" },
    { code: "LSQ", name: "Los Angeles" },
    { code: "ULC", name: "Los Cerrillos" },
    { code: "ZCO", name: "Maquehue" },
    { code: "IPC", name: "Mataveri Intl" },
    { code: "OVL", name: "Ovalle" },
    { code: "ZAL", name: "Pichoy" },
    { code: "WPR", name: "Porvenir" },
    { code: "PUQ", name: "Pres Ibanez" },
    { code: "ZPC", name: "Pucon" },
    { code: "WPA", name: "Puerto Aisen" },
    { code: "PUX", name: "Puerto Varas" },
    { code: "WPU", name: "Puerto Williams" },
    { code: "TLX", name: "Talca" },
    { code: "TTC", name: "Taltal" },
    { code: "GXQ", name: "Ten. Vidal" },
    { code: "PNT", name: "Teniente J. Gallardo" },
    { code: "BBA", name: "Teniente Vidal" },
    { code: "PMC", name: "Tepual" },
    { code: "VLR", name: "Vallenar" },
    { code: "VAP", name: "Valparaiso" },
    { code: "ZIC", name: "Victoria" },
    { code: "KNA", name: "Vina del Mar" },
    { code: "AKU", name: "Aksu" },
    { code: "AAT", name: "Altay" },
    { code: "AVA", name: "An Shun/Huang Guo Shu" },
    { code: "AKA", name: "Ankang" },
    { code: "AQG", name: "Anqing" },
    { code: "AOG", name: "Anshan" },
    { code: "AYN", name: "Anyang" },
    { code: "BPX", name: "Bangda" },
    { code: "BSD", name: "Baoshan" },
    { code: "BAV", name: "Baotou" },
    { code: "BHY", name: "Beihai" },
    { code: "PEK", name: "Beijing Capital Int." },
    { code: "BFU", name: "Bengbu" },
    { code: "CGQ", name: "Changchun" },
    { code: "CGD", name: "Changde" },
    { code: "CNI", name: "Changhai" },
    { code: "CSX", name: "Changsha" },
    { code: "CIH", name: "Changzhi" },
    { code: "CZX", name: "Changzhou" },
    { code: "WUZ", name: "Changzhoudao" },
    { code: "CHG", name: "Chaoyang Airport" },
    { code: "CIF", name: "Chifeng" },
    { code: "CKG", name: "Chongqing Jiangbei International" },
    { code: "SJW", name: "Daguocun" },
    { code: "DLU", name: "Dali" },
    { code: "DLC", name: "Dalian" },
    { code: "DDG", name: "Dandong" },
    { code: "DAX", name: "Daxian" },
    { code: "DYG", name: "Dayong" },
    { code: "DZU", name: "Dazu" },
    { code: "DIG", name: "Diqing" },
    { code: "DGM", name: "Dongguan" },
    { code: "DSN", name: "Dongsheng" },
    { code: "DOY", name: "Dongying" },
    { code: "DNH", name: "Dunhuang" },
    { code: "XTA", name: "Econ/Tech Dev. Area" },
    { code: "ENH", name: "Enshi" },
    { code: "SYX", name: "Fenghuang International" },
    { code: "FUG", name: "Fuyang" },
    { code: "FYN", name: "Fuyun" },
    { code: "FOC", name: "Fuzhou" },
    { code: "KOW", name: "Ganzhou" },
    { code: "JHG", name: "Gasa" },
    { code: "GOQ", name: "Golmud" },
    { code: "LXA", name: "Gonggar/Lhasa" },
    { code: "GYS", name: "Guang Yuan" },
    { code: "GHN", name: "Guanghan" },
    { code: "LHK", name: "Guanghua" },
    { code: "KWL", name: "Guilin" },
    { code: "KWE", name: "Guiyang" },
    { code: "HAK", name: "Haikou" },
    { code: "HLD", name: "Hailar" },
    { code: "HMI", name: "Hami" },
    { code: "HGH", name: "Hangzhou" },
    { code: "HZG", name: "Hanzhong" },
    { code: "HRB", name: "Harbin" },
    { code: "ZMY", name: "Harbour" },
    { code: "HEK", name: "Heihe" },
    { code: "HNY", name: "Hengyang" },
    { code: "HET", name: "Hohhot" },
    { code: "SHA", name: "Hongqiao" },
    { code: "HTN", name: "Hotan" },
    { code: "HHA", name: "Huanghua International" },
    { code: "HYN", name: "Huangyan" },
    { code: "HUZ", name: "Huizhou" },
    { code: "ZTI", name: "Humen Port" },
    { code: "JMU", name: "Jiamusi" },
    { code: "ZBD", name: "Jiang Men Ferry" },
    { code: "JGN", name: "Jiayuguan" },
    { code: "JIL", name: "Jilin" },
    { code: "TNA", name: "Jinan" },
    { code: "JDZ", name: "Jingdezhen" },
    { code: "JNG", name: "Jining" },
    { code: "JJN", name: "Jinjiang" },
    { code: "JNZ", name: "Jinzhou" },
    { code: "JZH", name: "Jiu Zhai Huang Long" },
    { code: "CHW", name: "Jiuhuang" },
    { code: "JIU", name: "Jiujiang" },
    { code: "KRY", name: "Karamay" },
    { code: "KHG", name: "Kashi" },
    { code: "KRL", name: "Korla" },
    { code: "KMG", name: "Kunming" },
    { code: "KCA", name: "Kuqa" },
    { code: "LHW", name: "Lanzhou Airport" },
    { code: "LZD", name: "Lanzhoudong" },
    { code: "LCX", name: "Liancheng" },
    { code: "LIA", name: "Liangping" },
    { code: "ZIY", name: "Lianhuashan Port" },
    { code: "LYG", name: "Lianyungang" },
    { code: "LJG", name: "Lijiang" },
    { code: "LNJ", name: "Lincang" },
    { code: "LLF", name: "LingLing" },
    { code: "LXI", name: "Linxi" },
    { code: "LYI", name: "Linyi" },
    { code: "HZH", name: "Liping" },
    { code: "NGB", name: "Lishe International Airport" },
    { code: "LZH", name: "Liuzhou" },
    { code: "NKG", name: "Lukou International Airport" },
    { code: "LYA", name: "Luoyang" },
    { code: "LUZ", name: "Lushan" },
    { code: "LZO", name: "Luzhou" },
    { code: "LUM", name: "Mangshi" },
    { code: "NZH", name: "Manzhouli" },
    { code: "MXZ", name: "Meixian" },
    { code: "MIG", name: "Mian Yang" },
    { code: "MDG", name: "Mudanjiang" },
    { code: "KHN", name: "Nanchang" },
    { code: "NAO", name: "Nanchong" },
    { code: "ZEF", name: "Nanhai Ferry" },
    { code: "NNG", name: "Nanning" },
    { code: "NTG", name: "Nantong" },
    { code: "NNY", name: "Nanyang" },
    { code: "NAY", name: "Nanyuan Airport" },
    { code: "CAN", name: "New Baiyun" },
    { code: "ZAX", name: "Pan Yu Ferry" },
    { code: "PNJ", name: "Peng Lai/Sha He Kou" },
    { code: "XTF", name: "Port Free Trade Zone" },
    { code: "PVG", name: "Pu Dong" },
    { code: "IQM", name: "Qiemo" },
    { code: "TAO", name: "Qingdao" },
    { code: "IQN", name: "Qingyang" },
    { code: "SHP", name: "Qinhuangdao" },
    { code: "NDG", name: "Qiqihar" },
    { code: "RUG", name: "Rugao" },
    { code: "HFE", name: "Shanghai" },
    { code: "SHF", name: "Shanhaiguan" },
    { code: "SXJ", name: "Shanshan" },
    { code: "SWA", name: "Shantou" },
    { code: "HSC", name: "Shaoguan" },
    { code: "SHS", name: "Shashi" },
    { code: "ZCU", name: "She Kou Ferry" },
    { code: "ZYK", name: "Shekou Port" },
    { code: "SHE", name: "Shenyang" },
    { code: "SZX", name: "Shenzhen" },
    { code: "CTU", name: "Shuangliu" },
    { code: "ZAS", name: "Shunde Ferry" },
    { code: "SYM", name: "Simao" },
    { code: "SZV", name: "Suzhou" },
    { code: "TCG", name: "Tacheng" },
    { code: "ZCA", name: "Tai Shan" },
    { code: "TYN", name: "Taiyuan" },
    { code: "WUH", name: "Tianhe International" },
    { code: "TSN", name: "Tianjin Binhai International" },
    { code: "TNH", name: "Tonghua Liuhe" },
    { code: "TGO", name: "Tongliao" },
    { code: "TEN", name: "Tongren" },
    { code: "TXN", name: "Tunxi" },
    { code: "HLH", name: "Ulanhot" },
    { code: "URC", name: "Urumqi" },
    { code: "WXN", name: "Wanxian" },
    { code: "WEF", name: "Weifang" },
    { code: "WEH", name: "Weihai" },
    { code: "WNZ", name: "Wenzhou" },
    { code: "WHU", name: "Wuhu" },
    { code: "WUX", name: "Wuxi" },
    { code: "XMN", name: "Xiamen" },
    { code: "XFN", name: "Xiangfan" },
    { code: "XIY", name: "Xianyang" },
    { code: "XIC", name: "Xichang" },
    { code: "SIA", name: "Xiguan" },
    { code: "XIL", name: "Xilinhot" },
    { code: "ZBZ", name: "Xin Hui Ferry" },
    { code: "XEN", name: "Xingcheng" },
    { code: "XIN", name: "Xingning" },
    { code: "XNT", name: "Xingtai" },
    { code: "ACX", name: "Xingyi" },
    { code: "XNN", name: "Xining" },
    { code: "XUZ", name: "Xuzhou" },
    { code: "YNZ", name: "Yancheng" },
    { code: "YNJ", name: "Yanji" },
    { code: "YNT", name: "Yantai" },
    { code: "YBP", name: "Yibin" },
    { code: "YIH", name: "Yichang" },
    { code: "YLN", name: "Yilan" },
    { code: "INC", name: "Yinchuan" },
    { code: "YIN", name: "Yining" },
    { code: "YIW", name: "Yiwu" },
    { code: "YUA", name: "Yuanmou" },
    { code: "UYN", name: "Yulin" },
    { code: "YCU", name: "Yun Cheng" },
    { code: "ZHA", name: "Zhanjiang" },
    { code: "ZAT", name: "Zhaotong" },
    { code: "CGO", name: "Zhengzhou" },
    { code: "ZGC", name: "Zhongchuan" },
    { code: "ZGN", name: "Zhongshan Ferry Port" },
    { code: "HSN", name: "Zhoushan" },
    { code: "ZYI", name: "Zunyi" },
    { code: "CCK", name: "Cocos Islands" },
    { code: "ACD", name: "Acandi" },
    { code: "ARF", name: "Acaricuara" },
    { code: "ACL", name: "Aguaclara" },
    { code: "OCV", name: "Aguasclaras" },
    { code: "SOX", name: "Alberto Lleras Camargo" },
    { code: "CLO", name: "Alfonso B. Aragon" },
    { code: "AFI", name: "Amalfi" },
    { code: "ADN", name: "Andes" },
    { code: "APO", name: "Apartado" },
    { code: "API", name: "Apiay" },
    { code: "ACR", name: "Araracuara" },
    { code: "AUC", name: "Arauca" },
    { code: "ARQ", name: "Arauquita" },
    { code: "ARO", name: "Arboletas" },
    { code: "ACM", name: "Arica" },
    { code: "AYC", name: "Ayacucho" },
    { code: "AYA", name: "Ayapel" },
    { code: "BHF", name: "Bahia Cupica" },
    { code: "BSC", name: "Bahia Solano" },
    { code: "MGN", name: "Baracoa" },
    { code: "BAC", name: "Barranca De Upia" },
    { code: "NBB", name: "Barrancominas" },
    { code: "BUN", name: "Buenaventura" },
    { code: "CUC", name: "Camilo Dazo" },
    { code: "CJD", name: "Candilejas" },
    { code: "PSO", name: "Cano" },
    { code: "FLA", name: "Capitolio" },
    { code: "CPB", name: "Capurgana" },
    { code: "CQT", name: "Caquetania" },
    { code: "CCO", name: "Carimagua" },
    { code: "SRS", name: "Cartagena" },
    { code: "CRC", name: "Cartago" },
    { code: "CUO", name: "Caruru" },
    { code: "PZA", name: "Casanare" },
    { code: "CSR", name: "Casuarito" },
    { code: "CAQ", name: "Caucasia" },
    { code: "CPL", name: "Chaparral" },
    { code: "IGO", name: "Chigorodo" },
    { code: "IVO", name: "Chivolo" },
    { code: "CIM", name: "Cimitarra" },
    { code: "DZI", name: "Codazzi" },
    { code: "CZU", name: "Corozal" },
    { code: "CVE", name: "Covenas" },
    { code: "RAV", name: "Cravo Norte" },
    { code: "CUI", name: "Currillo" },
    { code: "BAQ", name: "E Cortissoz" },
    { code: "EBG", name: "El Bagre" },
    { code: "ECR", name: "El Charco" },
    { code: "AXM", name: "El Eden" },
    { code: "ECO", name: "El Encanto" },
    { code: "ELJ", name: "El Recreo" },
    { code: "EYP", name: "El Yopal" },
    { code: "BOG", name: "Eldorado International" },
    { code: "EOH", name: "Enrique Olaya Herrera International Airport" },
    { code: "ULQ", name: "Farfan" },
    { code: "GRA", name: "Gamarra" },
    { code: "LET", name: "Gen. A.V. Cobo" },
    { code: "GGL", name: "Gilgal" },
    { code: "GIR", name: "Girardot" },
    { code: "TRB", name: "Gonzalo" },
    { code: "GCA", name: "Guacamaya" },
    { code: "GAA", name: "Guamal" },
    { code: "GPI", name: "Guapi" },
    { code: "GMC", name: "Guerima" },
    { code: "HTZ", name: "Hato Corozal" },
    { code: "HRR", name: "Herrera" },
    { code: "IBE", name: "Ibague" },
    { code: "ISD", name: "Iscuande" },
    { code: "MDE", name: "Jose Maria Cordova" },
    { code: "JUO", name: "Jurado" },
    { code: "LCR", name: "La Chorrera" },
    { code: "TCO", name: "La Florida" },
    { code: "NVA", name: "La Marguita" },
    { code: "LPD", name: "La Pedrera" },
    { code: "LPE", name: "La Primavera" },
    { code: "LAT", name: "La Uribe" },
    { code: "VVC", name: "La Vanguardia" },
    { code: "LMC", name: "Lamacarena" },
    { code: "LGT", name: "Las Gaviotas" },
    { code: "LGZ", name: "Leguizamo" },
    { code: "LMX", name: "Lopez De Micay" },
    { code: "LRI", name: "Lorica" },
    { code: "RVE", name: "Los Colonizadores" },
    { code: "NAD", name: "Macanal" },
    { code: "PPN", name: "Machangara" },
    { code: "MCJ", name: "Maicao" },
    { code: "COG", name: "Mandinga" },
    { code: "MQU", name: "Mariquita" },
    { code: "PEI", name: "Matecana" },
    { code: "MND", name: "Medina" },
    { code: "MFS", name: "Miraflores" },
    { code: "MIX", name: "Miriti" },
    { code: "MVP", name: "Mitu" },
    { code: "MMP", name: "Mompos" },
    { code: "MFB", name: "Monfort" },
    { code: "MTB", name: "Monte Libano" },
    { code: "MOY", name: "Monterrey" },
    { code: "MHF", name: "Morichal" },
    { code: "MQR", name: "Mosquera" },
    { code: "ULS", name: "Mulatos" },
    { code: "NAR", name: "Nare" },
    { code: "NCI", name: "Necocli" },
    { code: "NUH", name: "Nunchia" },
    { code: "NQU", name: "Nuqui" },
    { code: "ORC", name: "Orocue" },
    { code: "OTU", name: "Otu" },
    { code: "PAL", name: "Palanquero" },
    { code: "BGA", name: "Palo Negro" },
    { code: "EUO", name: "Paratebueno" },
    { code: "PYN", name: "Payan" },
    { code: "PTX", name: "Pitalito" },
    { code: "PLA", name: "Planadas" },
    { code: "PLC", name: "Planeta Rica" },
    { code: "PLT", name: "Plato" },
    { code: "PRE", name: "Pore" },
    { code: "PVA", name: "Providencia" },
    { code: "PUU", name: "Puerto Asis" },
    { code: "PBE", name: "Puerto Berrio" },
    { code: "PYA", name: "Puerto Boyaca" },
    { code: "PCR", name: "Puerto Carreno" },
    { code: "PDA", name: "Puerto Inirida" },
    { code: "LQM", name: "Puerto Leguizamo" },
    { code: "PCC", name: "Puerto Rico" },
    { code: "UIB", name: "Quibdo" },
    { code: "CTG", name: "Rafael Nunez" },
    { code: "RCH", name: "Riohacha" },
    { code: "RON", name: "Rondon" },
    { code: "MTR", name: "S. Jeronimo" },
    { code: "SNT", name: "Sabana De Torres" },
    { code: "ADZ", name: "San Andres Island" },
    { code: "ELB", name: "San Bernado" },
    { code: "SSD", name: "San Felipe" },
    { code: "SJE", name: "San Jose Del Gua" },
    { code: "SJR", name: "San Juan D Ur" },
    { code: "SJH", name: "San Juan Del Cesar" },
    { code: "IPI", name: "San Luis" },
    { code: "SQE", name: "San Luis De Pale" },
    { code: "SJG", name: "San Pedro Jagua" },
    { code: "NPU", name: "San Pedro Uraba" },
    { code: "SVI", name: "San Vicente" },
    { code: "SQB", name: "Santa Ana" },
    { code: "SCA", name: "Santa Catalina" },
    { code: "SMC", name: "Santa Maria" },
    { code: "SSL", name: "Santa Rosalia" },
    { code: "MZL", name: "Santaguida" },
    { code: "SRO", name: "Santana Ramos" },
    { code: "SMR", name: "Simon Bolivar" },
    { code: "SQF", name: "Solano" },
    { code: "SOH", name: "Solita" },
    { code: "TTM", name: "Tablon De Tamara" },
    { code: "TME", name: "Tame" },
    { code: "TCD", name: "Tarapaca" },
    { code: "TAU", name: "Tauramena" },
    { code: "TIB", name: "Tibu" },
    { code: "TBD", name: "Timbiqui" },
    { code: "TLU", name: "Tolu" },
    { code: "TDA", name: "Trinidad" },
    { code: "UNC", name: "Unguia" },
    { code: "URI", name: "Uribe" },
    { code: "URR", name: "Urrao" },
    { code: "VUP", name: "Valledupar" },
    { code: "EJA", name: "Variguies" },
    { code: "VGZ", name: "Villagarzon" },
    { code: "AYG", name: "Yaguara" },
    { code: "AYI", name: "Yari" },
    { code: "VAB", name: "Yavarate" },
    { code: "AZT", name: "Zapatoca" },
    { code: "SFR", name: "San Fernando" },
    { code: "DZA", name: "Dzaoudzi" },
    { code: "YVA", name: "Iconi" },
    { code: "NWA", name: "Moheli" },
    { code: "AJN", name: "Ouani" },
    { code: "HAH", name: "Prince Said Ibrahim In" },
    { code: "BTB", name: "Betou" },
    { code: "BOE", name: "Boundji" },
    { code: "DJM", name: "Djambala" },
    { code: "EPN", name: "Epena" },
    { code: "EWO", name: "Ewo" },
    { code: "GMM", name: "Gamboma" },
    { code: "ION", name: "Impfondo" },
    { code: "KEE", name: "Kelle" },
    { code: "KNJ", name: "Kindamba" },
    { code: "LCO", name: "Lague" },
    { code: "LKC", name: "Lekana" },
    { code: "DIS", name: "Loubomo" },
    { code: "KMK", name: "Makabana" },
    { code: "MKJ", name: "Makoua" },
    { code: "BZV", name: "Maya Maya" },
    { code: "MSX", name: "Mossendjo" },
    { code: "MUY", name: "Mouyondzi" },
    { code: "NKY", name: "Nkayi" },
    { code: "OKG", name: "Okoyo" },
    { code: "OUE", name: "Ouesso" },
    { code: "FTX", name: "Owando" },
    { code: "PNR", name: "Pointe Noire" },
    { code: "SIB", name: "Sibiti" },
    { code: "SOE", name: "Souanke" },
    { code: "ANJ", name: "Zanaga" },
    { code: "BKY", name: "Kavumu" },
    { code: "AIT", name: "Aitutaki" },
    { code: "AIU", name: "Atiu Island" },
    { code: "MGS", name: "Mangaia Island" },
    { code: "MHX", name: "Manihiki Island" },
    { code: "MUK", name: "Mauke Island" },
    { code: "MOI", name: "Mitiaro Island" },
    { code: "PYE", name: "Penrhyn Island" },
    { code: "PZK", name: "Puka Puka Is/Attol" },
    { code: "RAR", name: "Rarotonga" },
    { code: "BCL", name: "Barra Colorado" },
    { code: "BAI", name: "Buenos Aires" },
    { code: "CSC", name: "Canas" },
    { code: "OTR", name: "Coto 47" },
    { code: "LIR", name: "Daniel Oduber International" },
    { code: "FMG", name: "Flamingo" },
    { code: "FON", name: "Fortuna" },
    { code: "GLF", name: "Golfito" },
    { code: "NCT", name: "Guanacaste" },
    { code: "SZC", name: "Guanacaste" },
    { code: "GPL", name: "Guapiles" },
    { code: "SJO", name: "Juan Santamaría International" },
    { code: "LCS", name: "Las Canas" },
    { code: "LIO", name: "Limon" },
    { code: "LSL", name: "Los Chiles" },
    { code: "NOB", name: "Nosara Beach" },
    { code: "PMZ", name: "Palmar Sur" },
    { code: "PLD", name: "Playa Samara" },
    { code: "PJM", name: "Puerto Jimenez" },
    { code: "PBP", name: "Punta Islita" },
    { code: "JAP", name: "Punta Renes" },
    { code: "XQP", name: "Quepos" },
    { code: "RFR", name: "Rio Frio" },
    { code: "TOO", name: "San Vito" },
    { code: "TNO", name: "Tamarindo" },
    { code: "TMU", name: "Tambor" },
    { code: "SYQ", name: "Tobias Bolaños International" },
    { code: "TTQ", name: "Tortuquero" },
    { code: "UPL", name: "Upala" },
    { code: "OGO", name: "Abengourou" },
    { code: "ABO", name: "Aboisso" },
    { code: "BDK", name: "Bondoukou" },
    { code: "BGG", name: "Bongouanou" },
    { code: "BRZ", name: "Borotou" },
    { code: "BYK", name: "Bouake" },
    { code: "BQO", name: "Bouna" },
    { code: "BXI", name: "Boundiali" },
    { code: "BUU", name: "Buyo" },
    { code: "DJO", name: "Daloa" },
    { code: "DNC", name: "Danane" },
    { code: "DIM", name: "Dimbokro" },
    { code: "DIV", name: "Divo" },
    { code: "ABJ", name: "Felix Houphouet Boigny" },
    { code: "FEK", name: "Ferkessedougou" },
    { code: "GGN", name: "Gagnoa" },
    { code: "GGO", name: "Guiglo" },
    { code: "BBV", name: "Hkg" },
    { code: "KTC", name: "Katiola" },
    { code: "HGO", name: "Korhogo" },
    { code: "LKT", name: "Lakota" },
    { code: "MJC", name: "Man" },
    { code: "MOK", name: "Mankono" },
    { code: "KEO", name: "Odienne" },
    { code: "OFI", name: "Ouango Fitini" },
    { code: "SPY", name: "San Pedro" },
    { code: "ZSS", name: "Sassandra" },
    { code: "SEO", name: "Seguela" },
    { code: "TBX", name: "Taabo" },
    { code: "TXU", name: "Tabou" },
    { code: "TGX", name: "Tingrela" },
    { code: "TOZ", name: "Touba" },
    { code: "ASK", name: "Yamoussoukro" },
    { code: "ZUE", name: "Zuenoula" },
    { code: "BWK", name: "Bol" },
    { code: "DBV", name: "Dubrovnik" },
    { code: "LSZ", name: "Losinj Arpt" },
    { code: "OSI", name: "Osijek" },
    { code: "ZAG", name: "Pleso" },
    { code: "PUY", name: "Pula" },
    { code: "RJK", name: "Rijeka" },
    { code: "SPU", name: "Split" },
    { code: "ZAD", name: "Zadar" },
    { code: "SNU", name: "Abel Santa Maria" },
    { code: "SCU", name: "Antonio Maceo" },
    { code: "BCA", name: "Baracoa" },
    { code: "BYM", name: "C.M. de Cespedes" },
    { code: "CCC", name: "Cayo Coco" },
    { code: "CYO", name: "Cayo Largo Del Sur" },
    { code: "CFG", name: "Cienfuegos" },
    { code: "HOG", name: "Frank Pais" },
    { code: "NBW", name: "Guantanamo NAS" },
    { code: "CMW", name: "Ign Agramonte Intl" },
    { code: "HAV", name: "Jose Marti Intl" },
    { code: "VRA", name: "Juan Gualberto Gomez" },
    { code: "VRO", name: "Kawama" },
    { code: "LCL", name: "La Coloma" },
    { code: "VTU", name: "Las Tunas" },
    { code: "GAO", name: "Los Canos" },
    { code: "AVI", name: "Maximo Gomez" },
    { code: "MJG", name: "Mayajigua" },
    { code: "ICR", name: "Nicaro" },
    { code: "MOA", name: "Orestes Acosta" },
    { code: "UPB", name: "Playa Baracoa" },
    { code: "PST", name: "Preston" },
    { code: "UPA", name: "Punta Alegre" },
    { code: "UMA", name: "Punta De Maisi" },
    { code: "GER", name: "Rafael Cabrera" },
    { code: "SNJ", name: "San Julian" },
    { code: "USS", name: "Sancti Spiritus" },
    { code: "MZO", name: "Sierra Maestra" },
    { code: "SZJ", name: "Siguanea" },
    { code: "TND", name: "Trinidad" },
    { code: "AKT", name: "Akrotiri Raf" },
    { code: "EPK", name: "Episkopi" },
    { code: "ECN", name: "Ercan" },
    { code: "GEC", name: "Gecitkale" },
    { code: "PFO", name: "International" },
    { code: "LCA", name: "Larnaca" },
    { code: "NIC", name: "Nicosia International" },
    { code: "ZBE", name: "Dolni Benesov" },
    { code: "GTW", name: "Holesov" },
    { code: "KLV", name: "Karlovy Vary" },
    { code: "UHE", name: "Kunovice" },
    { code: "MKA", name: "Marianske Lazne" },
    { code: "OSR", name: "Mosnov" },
    { code: "OLO", name: "Olomouc" },
    { code: "PED", name: "Pardubice" },
    { code: "PRG", name: "Prague - Ruzyne International" },
    { code: "PRV", name: "Prerov" },
    { code: "BRQ", name: "Turany" },
    { code: "MNO", name: "Manono" },
    { code: "BDT", name: "Gbadolite" },
    { code: "GOM", name: "Goma" },
    { code: "IKL", name: "Ikela" },
    { code: "INO", name: "Inongo" },
    { code: "FKI", name: "Kisangani" },
    { code: "KWZ", name: "Kolwezi" },
    { code: "LJA", name: "Lodja" },
    { code: "FBM", name: "Luano" },
    { code: "FIH", name: "N’djili International" },
    { code: "TSH", name: "Tshikapa" },
    { code: "FDU", name: "Bandundu" },
    { code: "AAL", name: "Aalborg" },
    { code: "AAR", name: "Aarhus Airport" },
    { code: "ODE", name: "Beldringe" },
    { code: "BLL", name: "Billund" },
    { code: "RNN", name: "Bornholm" },
    { code: "EBJ", name: "Esbjerg" },
    { code: "PQT", name: "Heliport" },
    { code: "KRP", name: "Karup" },
    { code: "CPH", name: "Kastrup" },
    { code: "QJV", name: "Limousine Service" },
    { code: "MRW", name: "Maribo" },
    { code: "XJE", name: "Rialway Service" },
    { code: "RKE", name: "Roskilde Airport" },
    { code: "CNL", name: "Sindal" },
    { code: "SQW", name: "Skive Airport" },
    { code: "SGD", name: "Sonderborg" },
    { code: "QSG", name: "Sonderborg" },
    { code: "STA", name: "Stauning Vestjyllands Airport" },
    { code: "VEJ", name: "Vejle" },
    { code: "SKS", name: "Vojens" },
    { code: "AII", name: "Alisabieh" },
    { code: "JIB", name: "Ambouli" },
    { code: "MHI", name: "Musha" },
    { code: "OBC", name: "Obock" },
    { code: "TDJ", name: "Tadjoura" },
    { code: "DCF", name: "Cane Field" },
    { code: "DOM", name: "Melville Hall" },
    { code: "EPS", name: "Arroyo Barril International" },
    { code: "BRX", name: "Barahona" },
    { code: "CBJ", name: "Cabo Rojo" },
    { code: "LRM", name: "Casa de Campo International" },
    { code: "STI", name: "Cibao International Airport" },
    { code: "COZ", name: "Constanza" },
    { code: "POP", name: "La Union" },
    { code: "SDQ", name: "Las Americas" },
    { code: "PUJ", name: "Punta Cana International" },
    { code: "SNX", name: "Sabana De Mar" },
    { code: "AZS", name: "Samaná El Catey International" },
    { code: "SJM", name: "San Juan" },
    { code: "HEX", name: "Santo Domingo Herrera" },
    { code: "BCH", name: "Cakung International" },
    { code: "DIC", name: "Dili City Heliport" },
    { code: "DIL", name: "Presidente Nicolau Lobato International Airport" },
    { code: "BHA", name: "Bahia De Caraquez" },
    { code: "GPS", name: "Baltra" },
    { code: "LOH", name: "Camilo Ponce Enríquez Airport" },
    { code: "ATF", name: "Chachoan" },
    { code: "OCC", name: "Coca" },
    { code: "LTX", name: "Cotopaxi International" },
    { code: "CUE", name: "Cuenca" },
    { code: "ESM", name: "Esmeraldas" },
    { code: "JIP", name: "Jipijapa" },
    { code: "LGQ", name: "Lago Agrio" },
    { code: "MRR", name: "Macara" },
    { code: "XMS", name: "Macas" },
    { code: "MCH", name: "Machala" },
    { code: "MEC", name: "Manta" },
    { code: "UIO", name: "Mariscal Sucre" },
    { code: "MZD", name: "Mendez" },
    { code: "PTZ", name: "Pastaza" },
    { code: "PYO", name: "Putumayo" },
    { code: "PVO", name: "Reales Tamarindos" },
    { code: "SNC", name: "Salinas" },
    { code: "SCY", name: "San Cristobal" },
    { code: "GYE", name: "Simon Bolivar" },
    { code: "SUQ", name: "Sucua" },
    { code: "TPN", name: "Tiputini" },
    { code: "TUA", name: "Tulcan" },
    { code: "AUE", name: "Abu Rudeis" },
    { code: "ABS", name: "Abu Simbel" },
    { code: "AAC", name: "Al Arish International Airport" },
    { code: "DBB", name: "Alalamain Intl." },
    { code: "HBE", name: "Amiryah West" },
    { code: "ATZ", name: "Assiut" },
    { code: "ASW", name: "Aswan" },
    { code: "CAI", name: "Cairo International" },
    { code: "DAK", name: "Dakhla" },
    { code: "EMY", name: "El Minya" },
    { code: "ALY", name: "El Nohza" },
    { code: "HRG", name: "Hurghada" },
    { code: "UVL", name: "Kharga" },
    { code: "LXR", name: "Luxor" },
    { code: "MFO", name: "Manguna" },
    { code: "RMF", name: "Marsa Alam Intl" },
    { code: "MUH", name: "Mersa Matruh" },
    { code: "SKV", name: "Mount Sinai" },
    { code: "SSH", name: "Ophira International" },
    { code: "PSD", name: "Port Said" },
    { code: "TFR", name: "Ramadan" },
    { code: "GSQ", name: "Shark Elowainat" },
    { code: "SQK", name: "Sidi Barani" },
    { code: "SEW", name: "Siwa" },
    { code: "TCP", name: "Taba International" },
    { code: "ELT", name: "Tour Sinai City" },
    { code: "SAL", name: "El Salvador International" },
    { code: "BSG", name: "Bata" },
    { code: "SSG", name: "Santa Isabel" },
    { code: "ASA", name: "Assab International" },
    { code: "MSW", name: "Massawa International" },
    { code: "TES", name: "Tessenei" },
    { code: "ASM", name: "Yohannes IV" },
    { code: "URE", name: "Kuressaare" },
    { code: "EPU", name: "Parnu" },
    { code: "QUF", name: "Pirita Harbour" },
    { code: "TAY", name: "Tartu" },
    { code: "TLL", name: "Ulemiste" },
    { code: "DIR", name: "Aba Tenna D Yilma" },
    { code: "AMH", name: "Arba Mintch" },
    { code: "ALK", name: "Asela" },
    { code: "ASO", name: "Asosa" },
    { code: "AWH", name: "Awareh" },
    { code: "AWA", name: "Awassa" },
    { code: "AXU", name: "Axum" },
    { code: "BJR", name: "Bahar Dar" },
    { code: "BEI", name: "Beica" },
    { code: "PWI", name: "Beles" },
    { code: "ADD", name: "Bole International" },
    { code: "BCY", name: "Bulchi" },
    { code: "XBL", name: "Buno Bedelle" },
    { code: "MKD", name: "Chagni" },
    { code: "DSE", name: "Combolcha" },
    { code: "DBM", name: "Debra Marcos" },
    { code: "DBT", name: "Debra Tabor" },
    { code: "DGC", name: "Degahbur" },
    { code: "DEM", name: "Dembidollo" },
    { code: "GMB", name: "Gambela" },
    { code: "GLC", name: "Geladi" },
    { code: "ETE", name: "Genda Wuha" },
    { code: "GHD", name: "Ghimbi" },
    { code: "GNN", name: "Ghinnir" },
    { code: "GOB", name: "Goba" },
    { code: "GDE", name: "Gode/Iddidole" },
    { code: "GDQ", name: "Gondar" },
    { code: "GOR", name: "Gore" },
    { code: "HUE", name: "Humera" },
    { code: "SHC", name: "Indaselassie" },
    { code: "JIJ", name: "Jigiga" },
    { code: "JIM", name: "Jimma" },
    { code: "BCO", name: "Jinka" },
    { code: "ABK", name: "Kabri Dar" },
    { code: "LFO", name: "Kelafo" },
    { code: "LLI", name: "Lalibela" },
    { code: "MQX", name: "Makale" },
    { code: "MKS", name: "Mekane Selam" },
    { code: "MZX", name: "Mena" },
    { code: "NDM", name: "Mendi" },
    { code: "MTF", name: "Mizan Teferi" },
    { code: "OTA", name: "Mota" },
    { code: "MYS", name: "Moyale" },
    { code: "MUJ", name: "Mui" },
    { code: "EGL", name: "Neghelli" },
    { code: "NEK", name: "Nekemt" },
    { code: "SZE", name: "Semera Airport" },
    { code: "SKR", name: "Shakiso" },
    { code: "SXU", name: "Soddu" },
    { code: "TIE", name: "Tippi" },
    { code: "TUJ", name: "Tum" },
    { code: "WAC", name: "Waca" },
    { code: "WRA", name: "Warder" },
    { code: "MPN", name: "Mount Pleasant" },
    { code: "PSY", name: "Port Stanley" },
    { code: "FAE", name: "Vagar" },
    { code: "YAP", name: "Yap International" },
    { code: "BFJ", name: "Ba" },
    { code: "BXL", name: "Blue Lagoon" },
    { code: "CST", name: "Castaway" },
    { code: "ICI", name: "Cicia" },
    { code: "BVF", name: "Dama" },
    { code: "KDV", name: "Kandavu" },
    { code: "KXF", name: "Koro Island" },
    { code: "KVU", name: "Korolevu" },
    { code: "LBS", name: "Labasa" },
    { code: "LKB", name: "Lakeba" },
    { code: "LUC", name: "Laucala Is" },
    { code: "LEV", name: "Levuka Airfield" },
    { code: "PTF", name: "Malololailai" },
    { code: "MNF", name: "Mana Island Airstrip" },
    { code: "TVU", name: "Matei" },
    { code: "MFJ", name: "Moala" },
    { code: "NAN", name: "Nadi International" },
    { code: "NTA", name: "Natadola" },
    { code: "SUV", name: "Nausori" },
    { code: "NGI", name: "Ngau Island" },
    { code: "ONU", name: "Ono I Lau" },
    { code: "PHR", name: "Pacific Harbor" },
    { code: "RBI", name: "Rabi" },
    { code: "RTA", name: "Rotuma Island" },
    { code: "AQS", name: "Saqani" },
    { code: "SVU", name: "Savusavu" },
    { code: "TTL", name: "Turtle Island" },
    { code: "VBV", name: "Vanuabalavu" },
    { code: "VAU", name: "Vatukoula" },
    { code: "VTF", name: "Vatulele" },
    { code: "KAY", name: "Wakaya Island" },
    { code: "YAS", name: "Yasawa Island" },
    { code: "QVY", name: "Bus Station" },
    { code: "ENF", name: "Enontekio" },
    { code: "KEV", name: "Halli" },
    { code: "HEM", name: "Helsinki-malmi" },
    { code: "HEL", name: "Helsinki-vantaa" },
    { code: "SJY", name: "Ilmajoki" },
    { code: "IVL", name: "Ivalo" },
    { code: "JOE", name: "Joensuu" },
    { code: "JYV", name: "Jyvaskyla" },
    { code: "KAJ", name: "Kajaani" },
    { code: "KHJ", name: "Kauhajoki" },
    { code: "KAU", name: "Kauhava" },
    { code: "KEM", name: "Kemi/Tornio" },
    { code: "KTT", name: "Kittila" },
    { code: "KOK", name: "Kruunupyy" },
    { code: "KUO", name: "Kuopio" },
    { code: "KAO", name: "Kuusamo" },
    { code: "LPP", name: "Lappeenranta" },
    { code: "MHQ", name: "Mariehamn" },
    { code: "MIK", name: "Mikkeli" },
    { code: "OUL", name: "Oulu" },
    { code: "POR", name: "Pori" },
    { code: "RVN", name: "Rovaniemi" },
    { code: "SVL", name: "Savonlinna" },
    { code: "SOT", name: "Sodankyla" },
    { code: "TMP", name: "Tampere-pirkkala" },
    { code: "TKU", name: "Turku" },
    { code: "UTI", name: "Utti" },
    { code: "VAA", name: "Vaasa" },
    { code: "VRK", name: "Varkaus" },
    { code: "SKP", name: "Petrovec" },
    { code: "JAH", name: "Agora Helipad" },
    { code: "AHZ", name: "Alpe D Huez" },
    { code: "NCY", name: "Annecy-Meythe" },
    { code: "LME", name: "Arnage" },
    { code: "CFE", name: "Aulnat" },
    { code: "AUR", name: "Aurillac" },
    { code: "AUF", name: "Auxerre Branches" },
    { code: "XZN", name: "Avignon Railway" },
    { code: "AVN", name: "Avignon-Caumont" },
    { code: "AVF", name: "Avoriaz" },
    { code: "BAE", name: "Barcelonnette" },
    { code: "PGX", name: "Bassillac" },
    { code: "BVA", name: "Beauvais-Tille" },
    { code: "LIG", name: "Bellegarde" },
    { code: "BZR", name: "Beziers Vias" },
    { code: "PIS", name: "Biard" },
    { code: "BIQ", name: "Biarritz Parme" },
    { code: "TLS", name: "Blagnac" },
    { code: "URO", name: "Boos" },
    { code: "BOU", name: "Bourges" },
    { code: "EBU", name: "Boutheon" },
    { code: "MEN", name: "Brenoux" },
    { code: "BES", name: "Brest Lesquin" },
    { code: "ANG", name: "Brie-Champniers" },
    { code: "LYN", name: "Bron" },
    { code: "CQF", name: "Calais" },
    { code: "AJA", name: "Campo Dell Oro" },
    { code: "CFR", name: "Carpiquet" },
    { code: "VAF", name: "Chabeuil" },
    { code: "CMF", name: "Chambery" },
    { code: "CDG", name: "Charles De Gaulle" },
    { code: "VHY", name: "Charmeil" },
    { code: "SYT", name: "Charolais Bourgogne S." },
    { code: "CHR", name: "Chateauroux" },
    { code: "CMR", name: "Colmar-houssen" },
    { code: "NCE", name: "Cote D'azur International Airport" },
    { code: "CVF", name: "Courchevel" },
    { code: "CSF", name: "Creil" },
    { code: "JCA", name: "Croisette Heliport" },
    { code: "DXA", name: "Deux Alpes" },
    { code: "DPE", name: "Dieppe" },
    { code: "DIJ", name: "Dijon" },
    { code: "DLP", name: "Disneyland Paris" },
    { code: "LVA", name: "Entrammes" },
    { code: "SXB", name: "Entzheim" },
    { code: "ENC", name: "Essey" },
    { code: "MLH", name: "EuroAirport" },
    { code: "EVX", name: "Evreux" },
    { code: "BOR", name: "Fontaine" },
    { code: "FRJ", name: "Frejus" },
    { code: "MZM", name: "Frescaty" },
    { code: "ZFQ", name: "Gare de Bordeaux" },
    { code: "FNI", name: "Garons" },
    { code: "GFR", name: "Granville" },
    { code: "GNB", name: "Grenoble-isere Airport" },
    { code: "MCU", name: "Gueret" },
    { code: "XPZ", name: "Harbour" },
    { code: "JEV", name: "Heliport" },
    { code: "JDP", name: "Heliport De Paris" },
    { code: "JLP", name: "Juan Les Pins" },
    { code: "JPU", name: "La Defense Heliport" },
    { code: "AGF", name: "La Garenne" },
    { code: "LTT", name: "La Mole" },
    { code: "PLG", name: "La Plagne" },
    { code: "ZAO", name: "Laberandie" },
    { code: "LRH", name: "Laleu" },
    { code: "LRT", name: "Lann Bihoue" },
    { code: "BVE", name: "Laroche" },
    { code: "LBG", name: "Le Bourget" },
    { code: "CTT", name: "Le Castellet" },
    { code: "TLN", name: "Le Palyvestre" },
    { code: "CET", name: "Le Pontreau" },
    { code: "LBI", name: "Le Sequestre" },
    { code: "LTQ", name: "Le Touquet" },
    { code: "EDM", name: "Les Ajoncs" },
    { code: "LIL", name: "Lesquin" },
    { code: "XDB", name: "Lille Europe Rail Svc" },
    { code: "XFA", name: "Lille Flanders Rail Sv" },
    { code: "PGF", name: "Llabanere" },
    { code: "LPY", name: "Loudes" },
    { code: "XYD", name: "Lyon Part-dieu Rail Sv" },
    { code: "XYL", name: "Lyon Perrache Rail Svc" },
    { code: "XYV", name: "Lyon Satolas Rail Svc" },
    { code: "CEQ", name: "Mandelieu" },
    { code: "ANE", name: "Marce" },
    { code: "RDZ", name: "Marcillac" },
    { code: "MRS", name: "Marseille Provence Airport" },
    { code: "XRF", name: "Marseille Railway" },
    { code: "CER", name: "Maupertus" },
    { code: "DCM", name: "Mazamet" },
    { code: "RYN", name: "Medis" },
    { code: "MVV", name: "Megeve" },
    { code: "BOD", name: "Mérignac" },
    { code: "HZB", name: "Merville/Calonne" },
    { code: "ETZ", name: "Metz-nancy-lorraine" },
    { code: "VNE", name: "Meucon" },
    { code: "EPL", name: "Mirecourt" },
    { code: "XYT", name: "Montaudran" },
    { code: "LBY", name: "Montoir" },
    { code: "SNR", name: "Montoir" },
    { code: "MPL", name: "Montpellier Méditerranée" },
    { code: "XPJ", name: "Montpellier Railway" },
    { code: "MXN", name: "Morlaix" },
    { code: "NTE", name: "Nantes Atlantique" },
    { code: "NVS", name: "Nevers" },
    { code: "NIT", name: "Niort" },
    { code: "LEH", name: "Octeville" },
    { code: "ORE", name: "Orleans" },
    { code: "ORY", name: "Orly" },
    { code: "POX", name: "Paris Cergy Pontoise" },
    { code: "CNG", name: "Parvaud" },
    { code: "PUF", name: "Pau-Pyrénées" },
    { code: "DNR", name: "Pleurtuit" },
    { code: "UIP", name: "Pluguffan" },
    { code: "BIA", name: "Poretta" },
    { code: "PRP", name: "Propriano" },
    { code: "RHE", name: "Reims" },
    { code: "RNE", name: "Renaison" },
    { code: "ZFJ", name: "Rennes" },
    { code: "EGC", name: "Roumanieres" },
    { code: "RCO", name: "Saint Agnant" },
    { code: "SBK", name: "Saint Brieuc-Armor" },
    { code: "CCF", name: "Salvaza" },
    { code: "SEC", name: "Serre Chevalier" },
    { code: "LAI", name: "Servel" },
    { code: "SOZ", name: "Solenzara" },
    { code: "SXD", name: "Sophia Antipolis" },
    { code: "SCP", name: "St Crepin" },
    { code: "DOL", name: "St Gatien" },
    { code: "RNS", name: "St Jacques" },
    { code: "TUF", name: "St Symphorien" },
    { code: "LYS", name: "St-Exupéry" },
    { code: "CLY", name: "Ste Catherine" },
    { code: "FSC", name: "Sud Corse" },
    { code: "GAT", name: "Tallard" },
    { code: "LSO", name: "Talmont" },
    { code: "LDE", name: "Tarbes Ossun Lourdes" },
    { code: "DLE", name: "Tavaux" },
    { code: "TGF", name: "Tignes" },
    { code: "TNF", name: "Toussus-le-noble" },
    { code: "OBS", name: "Vals-Lanas" },
    { code: "XCR", name: "Vatry International" },
    { code: "QYW", name: "Vieux Port" },
    { code: "VTL", name: "Vittel" },
    { code: "MPY", name: "Maripasoula" },
    { code: "REI", name: "Regina" },
    { code: "CAY", name: "Rochambeau" },
    { code: "XAU", name: "Saul" },
    { code: "OYP", name: "St-Georges de lOyapock" },
    { code: "LDX", name: "St-Laurent du Maroni" },
    { code: "AHE", name: "Ahe Airport" },
    { code: "AAA", name: "Anaa" },
    { code: "APK", name: "Apataki" },
    { code: "AXR", name: "Arutua" },
    { code: "AUQ", name: "Atuona" },
    { code: "PPT", name: "Faa'a" },
    { code: "FAC", name: "Faaite" },
    { code: "FHZ", name: "Fakahina" },
    { code: "FAV", name: "Fakarava" },
    { code: "FGU", name: "Fangatau" },
    { code: "GMR", name: "Gambier Is" },
    { code: "HOI", name: "Hao Island" },
    { code: "HHZ", name: "Hikueru" },
    { code: "HIX", name: "Hiva Oa" },
    { code: "HUH", name: "Huahine" },
    { code: "KXU", name: "Katiu" },
    { code: "KHZ", name: "Kauehi" },
    { code: "KKR", name: "Kaukura Atoll" },
    { code: "MKP", name: "Makemo" },
    { code: "XMH", name: "Manihi" },
    { code: "MVT", name: "Mataiva" },
    { code: "MAU", name: "Maupiti" },
    { code: "BOB", name: "Motu-mute" },
    { code: "NAU", name: "Napuka Island" },
    { code: "NHV", name: "Nuku Hiva" },
    { code: "NUK", name: "Nukutavake" },
    { code: "PKP", name: "Puka Puka" },
    { code: "PUK", name: "Pukarua" },
    { code: "RFP", name: "Raiatea" },
    { code: "RVV", name: "Raivavae" },
    { code: "RGI", name: "Rangiroa" },
    { code: "REA", name: "Reao" },
    { code: "RUR", name: "Rurutu" },
    { code: "TKP", name: "Takapoto" },
    { code: "TKX", name: "Takaroa" },
    { code: "TJN", name: "Takume" },
    { code: "TKV", name: "Tatakoto" },
    { code: "MOZ", name: "Temae" },
    { code: "TTI", name: "Tetiaroa Is" },
    { code: "TIH", name: "Tikehau Atoll" },
    { code: "TUB", name: "Tubuai" },
    { code: "TPX", name: "Tupai" },
    { code: "ZTA", name: "Tureira" },
    { code: "UAH", name: "Ua Huka" },
    { code: "UAP", name: "Ua Pou" },
    { code: "VHZ", name: "Vahitahi" },
    { code: "AKE", name: "Akieni" },
    { code: "AWE", name: "Alowe" },
    { code: "BAW", name: "Biawonque" },
    { code: "BMM", name: "Bitam" },
    { code: "BGP", name: "Bongo" },
    { code: "BGB", name: "Booue" },
    { code: "FOU", name: "Fougamou" },
    { code: "MVB", name: "Franceville/Mvengue" },
    { code: "GAX", name: "Gamba" },
    { code: "IGE", name: "Iguela" },
    { code: "GKO", name: "Kongoboumba" },
    { code: "KOU", name: "Koulamoutou" },
    { code: "LBQ", name: "Lambarene" },
    { code: "LTL", name: "Lastourville" },
    { code: "LEO", name: "Leconi" },
    { code: "LBV", name: "Libreville" },
    { code: "MKU", name: "Makokou" },
    { code: "KMD", name: "Mandji" },
    { code: "MGO", name: "Manega" },
    { code: "MYB", name: "Mayoumba" },
    { code: "MBC", name: "Mbigou" },
    { code: "MDV", name: "Medouneu" },
    { code: "MKB", name: "Mekambo" },
    { code: "MVG", name: "Mevang" },
    { code: "GIM", name: "Miele Mimbale" },
    { code: "MVX", name: "Minvoul" },
    { code: "MZC", name: "Mitzic" },
    { code: "MGX", name: "Moabi" },
    { code: "MFF", name: "Moanda" },
    { code: "MJL", name: "Mouila" },
    { code: "KDN", name: "Ndende" },
    { code: "NKA", name: "Nkan" },
    { code: "OKN", name: "Okondja" },
    { code: "OMB", name: "Omboue" },
    { code: "OUU", name: "Ouanga" },
    { code: "OWE", name: "Owendo" },
    { code: "OYE", name: "Oyem" },
    { code: "POG", name: "Port Gentil" },
    { code: "ZKM", name: "Sette Cama" },
    { code: "TCH", name: "Tchibanga" },
    { code: "WGY", name: "Wagny" },
    { code: "WNE", name: "Wora Na Ye" },
    { code: "BJL", name: "Yundum International" },
    { code: "SUI", name: "Babusheri" },
    { code: "BUS", name: "Batum" },
    { code: "KUT", name: "Kopitnari" },
    { code: "TBS", name: "Lochini" },
    { code: "AAH", name: "Aachen/Merzbruck" },
    { code: "WIE", name: "Air Base" },
    { code: "AOC", name: "Altenburg Nobitz" },
    { code: "AGB", name: "Augsburg - Muehlhausen" },
    { code: "BMR", name: "Baltrum" },
    { code: "BBH", name: "Barth" },
    { code: "BER", name: "Berlin Metropolitan Area" },
    { code: "TXL", name: "Berlin-tegel / Otto Lilienthal" },
    { code: "BFE", name: "Bielefeld" },
    { code: "BYU", name: "Bindlacher-Berg" },
    { code: "BBJ", name: "Bitburg Air Base" },
    { code: "LHA", name: "Black Forest" },
    { code: "PHM", name: "Boeblingen" },
    { code: "BMK", name: "Borkum" },
    { code: "BWE", name: "Braunschweig" },
    { code: "ZCD", name: "Breitenau AAF" },
    { code: "BRE", name: "Bremen" },
    { code: "BRV", name: "Bremerhaven" },
    { code: "URD", name: "Burg Feuerstein" },
    { code: "ZTZ", name: "Chemnitz Railway Stn." },
    { code: "CGN", name: "Cologne/bonn" },
    { code: "ZTT", name: "Cottbus Railway Stn." },
    { code: "NDZ", name: "Cuxhaven" },
    { code: "FCN", name: "Cuxhaven/Nordholz" },
    { code: "ZSU", name: "Dessau Railway Stn." },
    { code: "DTM", name: "Dortmund" },
    { code: "DRS", name: "Dresden Arpt" },
    { code: "DUS", name: "Düsseldorf International Airport" },
    { code: "EIB", name: "Eisenach" },
    { code: "EME", name: "Emden" },
    { code: "SCN", name: "Ensheim" },
    { code: "ERF", name: "Erfurt" },
    { code: "ESS", name: "Essen" },
    { code: "XFW", name: "Finkenwerder" },
    { code: "AGE", name: "Flugplatz" },
    { code: "FRA", name: "Frankfurt International Airport" },
    { code: "HHN", name: "Frankfurt-Hahn" },
    { code: "ZFR", name: "Frankfurt/Oder Railway" },
    { code: "MUC", name: "Franz Josef Strauss" },
    { code: "FDH", name: "Friedrichshafen" },
    { code: "FRZ", name: "Fritzlar Airbase" },
    { code: "FEL", name: "Fuerstenfeldbruck" },
    { code: "GKE", name: "Geilenkirchen" },
    { code: "ZGA", name: "Gera Railway" },
    { code: "GHF", name: "Giebelstadt" },
    { code: "ZGE", name: "Goerlitz Railway" },
    { code: "ZGO", name: "Gotha Railway" },
    { code: "ZGW", name: "Greifswald Railway" },
    { code: "FMO", name: "Greven" },
    { code: "GUT", name: "Guetersloh" },
    { code: "GTI", name: "Guettin" },
    { code: "ZHZ", name: "Halle Railway" },
    { code: "HAM", name: "Hamburg Airport" },
    { code: "HAJ", name: "Hanover Arpt" },
    { code: "ZVR", name: "Hanover Railway" },
    { code: "HEI", name: "Heide-Buesum" },
    { code: "HDB", name: "Heidelberg" },
    { code: "HGL", name: "Helgoland" },
    { code: "HDF", name: "Heringsdorf" },
    { code: "HOQ", name: "Hof" },
    { code: "IGS", name: "Ingolstadt-manching" },
    { code: "ZJS", name: "Jena Railway" },
    { code: "JUI", name: "Juist" },
    { code: "KLT", name: "Kaiserslautern" },
    { code: "KSF", name: "Kassel-Calden" },
    { code: "KEL", name: "Kiel-Holtenau" },
    { code: "KZG", name: "Kitzingen" },
    { code: "KOQ", name: "Koethen" },
    { code: "RLG", name: "Laage" },
    { code: "LGO", name: "Langeoog" },
    { code: "LEJ", name: "Leipzig-halle" },
    { code: "XLW", name: "Lemwerder" },
    { code: "LBC", name: "Lübeck Blankensee" },
    { code: "MHG", name: "Mannheim Arpt" },
    { code: "FMM", name: "Memmingen-Allgäu" },
    { code: "ZVM", name: "Messe-BF Railway Servi" },
    { code: "MGL", name: "Moenchen-gl. - Dus Exp" },
    { code: "QGV", name: "Neu Isenburg" },
    { code: "FNB", name: "Neubrandenburg" },
    { code: "EUM", name: "Neumuenster" },
    { code: "NOE", name: "Norddeich" },
    { code: "NOD", name: "Norden" },
    { code: "NRD", name: "Norderney" },
    { code: "NUE", name: "Nürnberg" },
    { code: "OBF", name: "Oberpfaffenhofen" },
    { code: "XZE", name: "Off line Point" },
    { code: "PAD", name: "Paderborn/lippstadt" },
    { code: "SZW", name: "Parchim Airport" },
    { code: "PEF", name: "Peenemuende" },
    { code: "UPF", name: "Pforheim" },
    { code: "BGN", name: "R.A.F." },
    { code: "LRC", name: "R.A.F." },
    { code: "GWW", name: "Raf Gatow" },
    { code: "ZIU", name: "Railway" },
    { code: "RMS", name: "Ramstein" },
    { code: "REB", name: "Rechlin" },
    { code: "FRF", name: "Rhein-Main AFB" },
    { code: "GMY", name: "Rheindahlen" },
    { code: "ZAU", name: "Sachsen Railway Stn." },
    { code: "FLF", name: "Schaferhaus" },
    { code: "WBG", name: "Schleswig-jagel" },
    { code: "SXF", name: "Schönefeld" },
    { code: "SEX", name: "Sembach" },
    { code: "SGE", name: "Siegerland Airport" },
    { code: "FKB", name: "Soellingen" },
    { code: "SPM", name: "Spangdahlem" },
    { code: "PSH", name: "St Peter" },
    { code: "QDU", name: "Station" },
    { code: "STR", name: "Stuttgart Echterdingen" },
    { code: "THF", name: "Tempelhof" },
    { code: "VAC", name: "Varrelbusch" },
    { code: "RBM", name: "Wallmuhle" },
    { code: "NRN", name: "Weeze" },
    { code: "GWT", name: "Westerland - Sylt" },
    { code: "WID", name: "Wildenrath" },
    { code: "WVN", name: "Wilhelmshaven" },
    { code: "OHR", name: "Wyk Auf Foehr" },
    { code: "ACC", name: "Kotoka" },
    { code: "KMS", name: "Kumasi" },
    { code: "NYI", name: "Sunyani" },
    { code: "TKD", name: "Takoradi" },
    { code: "TML", name: "Tamale" },
    { code: "GIB", name: "North Front" },
    { code: "AGQ", name: "Agrinion" },
    { code: "PVK", name: "Aktion" },
    { code: "PKH", name: "Alexion" },
    { code: "PYR", name: "Andravida" },
    { code: "GPA", name: "Araxos Airport" },
    { code: "KSO", name: "Aristoteles Airport" },
    { code: "JTY", name: "Astypalaia" },
    { code: "JKH", name: "Chios" },
    { code: "RHO", name: "Diagoras" },
    { code: "AXD", name: "Dimokritos" },
    { code: "DRM", name: "Drama" },
    { code: "ATH", name: "Eleftherios Venizelos" },
    { code: "SPJ", name: "Eleftherios Venizelos" },
    { code: "HEW", name: "Hellinikon" },
    { code: "JIK", name: "Ikaria" },
    { code: "IOA", name: "Ioannina" },
    { code: "CFU", name: "Ioannis Kapodistrias" },
    { code: "KLX", name: "Kalamata" },
    { code: "AOK", name: "Karpathos" },
    { code: "KSJ", name: "Kasos Island" },
    { code: "KZS", name: "Kastelorizo" },
    { code: "EFL", name: "Kefalonia Istland International Airport" },
    { code: "KIT", name: "Kithira" },
    { code: "KGS", name: "Kos Island International Airport" },
    { code: "LRA", name: "Larisa" },
    { code: "LRS", name: "Leros" },
    { code: "LXS", name: "Limnos" },
    { code: "SKG", name: "Macedonia International" },
    { code: "KVA", name: "Megas Alexandros International" },
    { code: "JMK", name: "Mikonos" },
    { code: "MLO", name: "Milos" },
    { code: "MJT", name: "Mytilene International Airport" },
    { code: "JNX", name: "Naxos Airport" },
    { code: "VOL", name: "Nea Anchialos" },
    { code: "HER", name: "Nikos Kazantzakis Airport" },
    { code: "PAS", name: "Paros" },
    { code: "KZI", name: "Philippos Airport" },
    { code: "SMI", name: "Samos" },
    { code: "JTR", name: "Santorini" },
    { code: "JSH", name: "Sitia" },
    { code: "JSI", name: "Skiathos" },
    { code: "SKU", name: "Skiros" },
    { code: "CHQ", name: "Souda" },
    { code: "JSS", name: "Spetsai Island" },
    { code: "JSY", name: "Syros Island" },
    { code: "ZTH", name: "Zakinthos International Airport" },
    { code: "JEG", name: "Aasiaat" },
    { code: "JRK", name: "Arsuk" },
    { code: "DUN", name: "Dundas" },
    { code: "JFR", name: "Frederikshab" },
    { code: "XIQ", name: "Harbour" },
    { code: "QCU", name: "Heliport" },
    { code: "QGQ", name: "Heliport" },
    { code: "JGR", name: "Heliport" },
    { code: "QFI", name: "Heliport" },
    { code: "QJI", name: "Heliport" },
    { code: "QRY", name: "Heliport" },
    { code: "QPW", name: "Heliport" },
    { code: "JSU", name: "Heliport" },
    { code: "JNS", name: "Heliport" },
    { code: "QMK", name: "Heliport" },
    { code: "JJU", name: "Heliport" },
    { code: "QUP", name: "Heliport" },
    { code: "JUV", name: "Heliport" },
    { code: "JAV", name: "Ilulissat" },
    { code: "OBY", name: "Ittoqqortoormiit" },
    { code: "SFJ", name: "Kangerlussuaq" },
    { code: "KUS", name: "Kulusuk" },
    { code: "JNN", name: "Nanortalik" },
    { code: "UAK", name: "Narsarsuaq" },
    { code: "CNP", name: "Neerlerit Inaat" },
    { code: "GOH", name: "Nuuk" },
    { code: "THU", name: "Pituffik" },
    { code: "NAQ", name: "Qaanaaq" },
    { code: "JCH", name: "Qasigiannguit" },
    { code: "JGO", name: "Qeqertarsuaq" },
    { code: "JHS", name: "Sisimiut" },
    { code: "AGM", name: "Tasiilaq" },
    { code: "UMD", name: "Uummannaq" },
    { code: "CRU", name: "Carriacou Is" },
    { code: "GND", name: "Point Salines International" },
    { code: "BBR", name: "Baillif" },
    { code: "DSD", name: "La Desirade" },
    { code: "PTP", name: "Le Raizet" },
    { code: "GBJ", name: "Les Bases" },
    { code: "MSB", name: "Marigot SPB" },
    { code: "SBH", name: "St Barthelemy" },
    { code: "SFC", name: "St Francois" },
    { code: "HTB", name: "Terre-de-Bas" },
    { code: "LSS", name: "Terre-de-Haut" },
    { code: "NGM", name: "Agana NAS" },
    { code: "UAM", name: "Andersen AFB" },
    { code: "GUM", name: "Guam International" },
    { code: "NRV", name: "USCG Shore Station" },
    { code: "RER", name: "Base Aerea Del Sur" },
    { code: "CMM", name: "Carmelita" },
    { code: "CIQ", name: "Chiquimula" },
    { code: "CTF", name: "Coatepeque Airport" },
    { code: "CBV", name: "Coban" },
    { code: "DON", name: "Dos Lagunas" },
    { code: "ENJ", name: "El Naranjo" },
    { code: "TKM", name: "El Peten" },
    { code: "HUG", name: "Huehuetenango" },
    { code: "GUA", name: "La Aurora" },
    { code: "LCF", name: "Las Vegas" },
    { code: "MCR", name: "Melchor De Menco" },
    { code: "PCG", name: "Paso Caballos" },
    { code: "PKJ", name: "Playa Grande" },
    { code: "PON", name: "Poptun" },
    { code: "PBR", name: "Puerto Barrios" },
    { code: "AQB", name: "Quiche Airport" },
    { code: "RUV", name: "Rubelsanto" },
    { code: "FRS", name: "Santa Elena" },
    { code: "UAX", name: "Uaxactun" },
    { code: "BKJ", name: "Boke" },
    { code: "CKY", name: "Conakry" },
    { code: "FAA", name: "Faranah" },
    { code: "FIG", name: "Fria" },
    { code: "KNN", name: "Kankan" },
    { code: "KSI", name: "Kissidougou" },
    { code: "LEK", name: "Labe" },
    { code: "MCA", name: "Macenta" },
    { code: "NZE", name: "Nzerekore" },
    { code: "SBI", name: "Sambailo" },
    { code: "GII", name: "Siguiri" },
    { code: "BQE", name: "Bubaque" },
    { code: "BXO", name: "Buochs" },
    { code: "OXB", name: "Osvaldo Vieira International" },
    { code: "AHL", name: "Aishalton" },
    { code: "NAI", name: "Annai" },
    { code: "BMJ", name: "Baramita" },
    { code: "GFO", name: "Bartica" },
    { code: "BCG", name: "Bemichi" },
    { code: "GEO", name: "Cheddi Jagan International" },
    { code: "EKE", name: "Ekereku" },
    { code: "IMB", name: "Imbaimadai" },
    { code: "KAI", name: "Kaieteur" },
    { code: "KAR", name: "Kamarang" },
    { code: "KRM", name: "Karanambo" },
    { code: "KRG", name: "Karasabai" },
    { code: "KTO", name: "Kato" },
    { code: "KKG", name: "Konawaruk" },
    { code: "KPG", name: "Kurupung" },
    { code: "LTM", name: "Lethem" },
    { code: "LUB", name: "Lumid Pau" },
    { code: "USI", name: "Mabaruma" },
    { code: "MHA", name: "Mahdia" },
    { code: "VEG", name: "Maikwak" },
    { code: "MWJ", name: "Matthews Ridge" },
    { code: "MYM", name: "Monkey Mountain" },
    { code: "OGL", name: "Ogle" },
    { code: "ORJ", name: "Orinduik" },
    { code: "PMT", name: "Paramakotoi" },
    { code: "PRR", name: "Paruima" },
    { code: "PIQ", name: "Pipillipai" },
    { code: "PKM", name: "Port Kaituma" },
    { code: "SDC", name: "Sandcreek" },
    { code: "SKM", name: "Skeldon" },
    { code: "CAP", name: "Cap Haitien" },
    { code: "JAK", name: "Jacmel" },
    { code: "JEE", name: "Jeremie" },
    { code: "CYA", name: "Les Cayes" },
    { code: "PAX", name: "Port De Paix" },
    { code: "PAP", name: "Toussaint Louverture International" },
    { code: "AHS", name: "Ahuas" },
    { code: "BHG", name: "Brus Laguna" },
    { code: "TJI", name: "Capiro" },
    { code: "CAA", name: "Catacamas" },
    { code: "CDD", name: "Cauquira" },
    { code: "RUY", name: "Copan" },
    { code: "ENQ", name: "Coronel E Soto Cano AB" },
    { code: "XPL", name: "Coronel Enrique Soto Cano AB" },
    { code: "CYL", name: "Coyoles" },
    { code: "EDQ", name: "Erandique" },
    { code: "LCE", name: "Goloson International" },
    { code: "GAC", name: "Gracias" },
    { code: "GUO", name: "Gualaco" },
    { code: "GJA", name: "Guanaja" },
    { code: "IRN", name: "Iriona" },
    { code: "JUT", name: "Juticalpa" },
    { code: "LEZ", name: "La Esperanza" },
    { code: "LUI", name: "La Union" },
    { code: "LLH", name: "Las Limas" },
    { code: "LMH", name: "Limon" },
    { code: "MRJ", name: "Marcala" },
    { code: "OAN", name: "Olanchito" },
    { code: "PCH", name: "Palacios" },
    { code: "PEU", name: "Puerto Lempira" },
    { code: "SAP", name: "Ramon Villeda Morales International" },
    { code: "RTB", name: "Roatan" },
    { code: "SET", name: "San Esteban" },
    { code: "SDH", name: "Santa Rosa Copan" },
    { code: "SCD", name: "Sulaco" },
    { code: "TEA", name: "Tela" },
    { code: "TCF", name: "Tocoa" },
    { code: "TGU", name: "Toncontin" },
    { code: "UII", name: "Utila" },
    { code: "VTA", name: "Victoria" },
    { code: "ORO", name: "Yoro" },
    { code: "HHP", name: "H K Heliport" },
    { code: "HKG", name: "Hong Kong International" },
    { code: "KTZ", name: "Kwun Tong" },
    { code: "ZJK", name: "Off Line Point" },
    { code: "QDM", name: "Shek Mum" },
    { code: "DEB", name: "Debrecen" },
    { code: "XXJ", name: "Deli Railway" },
    { code: "BUD", name: "Ferihegy" },
    { code: "QGY", name: "Gyor-Per" },
    { code: "MCQ", name: "Miskolc" },
    { code: "QPJ", name: "Pecs-Pogany" },
    { code: "SOB", name: "Saarmelleek/balaton" },
    { code: "AEY", name: "Akureyri" },
    { code: "BJD", name: "Bakkafjordur" },
    { code: "BIU", name: "Bildudalur" },
    { code: "BLO", name: "Blonduos" },
    { code: "BGJ", name: "Borgarfjordur Eystri" },
    { code: "BXV", name: "Breiddalsvik" },
    { code: "DJU", name: "Djupivogur" },
    { code: "EGS", name: "Egilsstadir" },
    { code: "FAG", name: "Fagurholsmyri" },
    { code: "FAS", name: "Faskrudsfjordur" },
    { code: "FLI", name: "Flateyri" },
    { code: "GJR", name: "Gjogur" },
    { code: "GRY", name: "Grimsey" },
    { code: "GUU", name: "Grundarfjordur" },
    { code: "HVK", name: "Holmavik" },
    { code: "HFN", name: "Hornafjordur" },
    { code: "HZK", name: "Husavik" },
    { code: "HVM", name: "Hvammstangi" },
    { code: "IFJ", name: "Isafjordur" },
    { code: "RKV", name: "Keflavik" },
    { code: "KEF", name: "Keflavik International" },
    { code: "OPA", name: "Kopasker" },
    { code: "NOR", name: "Nordfjordur" },
    { code: "OFJ", name: "Olafsfjordur" },
    { code: "PFJ", name: "Patreksfjordur" },
    { code: "RFN", name: "Raufarhofn" },
    { code: "RHA", name: "Reykholar" },
    { code: "MVA", name: "Reykiahlid" },
    { code: "OLI", name: "Rif" },
    { code: "SAK", name: "Saudarkrokur" },
    { code: "SEJ", name: "Seydisfjordur" },
    { code: "SIJ", name: "Siglufjordur" },
    { code: "SYK", name: "Stykkisholmur" },
    { code: "SUY", name: "Sudureyri" },
    { code: "TLK", name: "Talknafjordur" },
    { code: "TEY", name: "Thingeyri" },
    { code: "VEY", name: "Vestmannaeyjar" },
    { code: "VPN", name: "Vopnafjordur" },
    { code: "AGX", name: "Agatti Island" },
    { code: "AMD", name: "Ahmedabad" },
    { code: "AJL", name: "Aizawl" },
    { code: "AKD", name: "Akola" },
    { code: "IXV", name: "Along" },
    { code: "LKO", name: "Amausi" },
    { code: "LUH", name: "Amritsar" },
    { code: "IXB", name: "Bagdogra" },
    { code: "IXE", name: "Bajpe" },
    { code: "IXL", name: "Bakula Rimpoche" },
    { code: "RGH", name: "Balurghat" },
    { code: "IXD", name: "Bamrauli" },
    { code: "BLR", name: "Bangalore International Airport" },
    { code: "SHL", name: "Barapani" },
    { code: "BEK", name: "Bareli" },
    { code: "BEP", name: "Bellary" },
    { code: "BUP", name: "Bhatinda" },
    { code: "BHU", name: "Bhavnagar" },
    { code: "BHO", name: "Bhopal" },
    { code: "BBI", name: "Bhubaneswar" },
    { code: "BKB", name: "Bikaner" },
    { code: "PAB", name: "Bilaspur" },
    { code: "IXR", name: "Birsa Munda International" },
    { code: "GAU", name: "Borjhar" },
    { code: "CBD", name: "Car Nicobar" },
    { code: "IXC", name: "Chandigarh" },
    { code: "HYD", name: "Chennai Airport" },
    { code: "BOM", name: "Chhatrapati Shivaji International" },
    { code: "IXU", name: "Chikkalthana" },
    { code: "RAJ", name: "Civil" },
    { code: "TRZ", name: "Civil" },
    { code: "COK", name: "Cochin International" },
    { code: "COH", name: "Cooch Behar" },
    { code: "CDP", name: "Cuddapah" },
    { code: "UDR", name: "Dabok" },
    { code: "GOI", name: "Dabolim" },
    { code: "NMB", name: "Daman" },
    { code: "DAE", name: "Daparizo" },
    { code: "DAI", name: "Darjeeling" },
    { code: "DED", name: "Dehra Dun" },
    { code: "DEP", name: "Deparizo" },
    { code: "IDR", name: "Devi Ahilyabai Holkar" },
    { code: "DBD", name: "Dhanbad" },
    { code: "DIB", name: "Dibrugarh" },
    { code: "DMU", name: "Dimapur" },
    { code: "DIU", name: "Diu" },
    { code: "DHM", name: "Gaggal Airport" },
    { code: "ISK", name: "Gandhinagar Arpt" },
    { code: "GAY", name: "Gaya" },
    { code: "GOP", name: "Gorakhpur" },
    { code: "JGA", name: "Govardhanpur" },
    { code: "GUX", name: "Guna" },
    { code: "GWL", name: "Gwalior" },
    { code: "HSS", name: "Hissar" },
    { code: "HBX", name: "Hubli" },
    { code: "DEL", name: "Indira Gandhi Intl" },
    { code: "JLR", name: "Jabalpur" },
    { code: "JGB", name: "Jagdalpur" },
    { code: "JSA", name: "Jaisalmer" },
    { code: "PYB", name: "Jeypore" },
    { code: "JDH", name: "Jodhpur" },
    { code: "IXH", name: "Kailashahar" },
    { code: "IXQ", name: "Kamalpur" },
    { code: "IXY", name: "Kandla" },
    { code: "KNU", name: "Kanpur" },
    { code: "IXK", name: "Keshod" },
    { code: "HJR", name: "Khajuraho" },
    { code: "AGR", name: "Kheria" },
    { code: "IXN", name: "Khowai" },
    { code: "KLH", name: "Kolhapur" },
    { code: "KTU", name: "Kota" },
    { code: "CCJ", name: "Kozhikode Airport" },
    { code: "KUU", name: "Kullu Manali" },
    { code: "IXS", name: "Kumbhirgram" },
    { code: "IXI", name: "Lilabari" },
    { code: "PNQ", name: "Lohegaon" },
    { code: "MAA", name: "Madras International" },
    { code: "IXM", name: "Madurai" },
    { code: "LDA", name: "Malda" },
    { code: "MOH", name: "Mohanbari" },
    { code: "IMF", name: "Municipal" },
    { code: "MZA", name: "Muzaffarnagar" },
    { code: "MZU", name: "Muzaffarpur" },
    { code: "MYQ", name: "Mysore" },
    { code: "NDC", name: "Nanded" },
    { code: "CCU", name: "Netaji Subhas Chandra" },
    { code: "NVY", name: "Neyveli" },
    { code: "OMN", name: "Osmanabad" },
    { code: "PGH", name: "Pantnagar" },
    { code: "IXT", name: "Pasighat" },
    { code: "IXP", name: "Pathankot" },
    { code: "PAT", name: "Patna" },
    { code: "CJB", name: "Peelamedu" },
    { code: "PNY", name: "Pondicherry" },
    { code: "PBD", name: "Porbandar" },
    { code: "IXZ", name: "Port Blair" },
    { code: "PUT", name: "Puttaprathe" },
    { code: "RPR", name: "Raipur" },
    { code: "ATQ", name: "Raja Sansi" },
    { code: "RJA", name: "Rajahmundry" },
    { code: "RJI", name: "Rajouri" },
    { code: "RMD", name: "Ramagundam" },
    { code: "RTC", name: "Ratnagiri" },
    { code: "REW", name: "Rewa" },
    { code: "RRK", name: "Rourkela" },
    { code: "JRH", name: "Rowriah" },
    { code: "BHJ", name: "Rudra Mata" },
    { code: "RUP", name: "Rupsi" },
    { code: "SXV", name: "Salem" },
    { code: "TEZ", name: "Salonibari" },
    { code: "IXG", name: "Sambre" },
    { code: "JAI", name: "Sanganeer" },
    { code: "TNI", name: "Satna" },
    { code: "IXJ", name: "Satwari" },
    { code: "SSE", name: "Sholapur" },
    { code: "SLV", name: "Simla" },
    { code: "IXA", name: "Singerbhil" },
    { code: "IXW", name: "Sonari" },
    { code: "NAG", name: "Sonegaon" },
    { code: "SXR", name: "Srinagar" },
    { code: "STV", name: "Surat" },
    { code: "TEI", name: "Tezu" },
    { code: "TJV", name: "Thanjavur" },
    { code: "TRV", name: "Thiruvananthapuram International" },
    { code: "TIR", name: "Tirupati" },
    { code: "TCR", name: "Tuticorin" },
    { code: "BDQ", name: "Vadodara" },
    { code: "VNS", name: "Varanasi" },
    { code: "VGA", name: "Vijayawada" },
    { code: "VTZ", name: "Vishakhapatnam" },
    { code: "WGC", name: "Warangal" },
    { code: "ZER", name: "Zero" },
    { code: "MLG", name: "Abdul Rahman Saleh" },
    { code: "SRG", name: "Achmad Yani" },
    { code: "SOC", name: "Adi Sumarmo" },
    { code: "JOG", name: "Adisutjipto" },
    { code: "AEG", name: "Aek Godang" },
    { code: "ARD", name: "Alor Island" },
    { code: "AHI", name: "Amahai" },
    { code: "AGD", name: "Anggi" },
    { code: "AAS", name: "Apalapsili" },
    { code: "ARJ", name: "Arso" },
    { code: "ABU", name: "Atambua" },
    { code: "AUT", name: "Atauro" },
    { code: "AYW", name: "Ayawasi" },
    { code: "BXB", name: "Babo" },
    { code: "TTE", name: "Babullah" },
    { code: "BXD", name: "Bade" },
    { code: "BJW", name: "Bajawa" },
    { code: "NAF", name: "Banaina" },
    { code: "NDA", name: "Bandanaira" },
    { code: "BXM", name: "Batom" },
    { code: "BTW", name: "Batu Licin" },
    { code: "BUW", name: "Baubau" },
    { code: "BJK", name: "Benjina" },
    { code: "BMU", name: "Bima" },
    { code: "GNS", name: "Binaka" },
    { code: "NTI", name: "Bintuni" },
    { code: "BUI", name: "Bokondini" },
    { code: "BJG", name: "Bolaang" },
    { code: "BXT", name: "Bontang" },
    { code: "SWQ", name: "Brang Bidji" },
    { code: "TKG", name: "Branti" },
    { code: "TJQ", name: "Bulutumbang" },
    { code: "BYQ", name: "Bunyu" },
    { code: "UOL", name: "Buol" },
    { code: "CPF", name: "Cepu" },
    { code: "SIQ", name: "Dabo" },
    { code: "DRH", name: "Dabra" },
    { code: "DTD", name: "Datadawai" },
    { code: "ELR", name: "Elelim" },
    { code: "KOE", name: "Eltari" },
    { code: "EWI", name: "Enarotali" },
    { code: "ENE", name: "Ende" },
    { code: "EWE", name: "Ewer" },
    { code: "FKQ", name: "Fak Fak" },
    { code: "BIK", name: "Frans Kaisepo" },
    { code: "GAV", name: "Gag Island" },
    { code: "GLX", name: "Galela" },
    { code: "GEB", name: "Gebe" },
    { code: "AKQ", name: "Gunung Batin" },
    { code: "HLP", name: "Halim Perdana Kusuma" },
    { code: "BTH", name: "Hang Nadim" },
    { code: "UPG", name: "Hasanudin" },
    { code: "BDO", name: "Husein Sastranegara" },
    { code: "ILA", name: "Illaga" },
    { code: "IUL", name: "Ilu" },
    { code: "INX", name: "Inanwatan" },
    { code: "SQR", name: "Inco Soroako Waws" },
    { code: "RGT", name: "Japura" },
    { code: "SOQ", name: "Jefman" },
    { code: "SUB", name: "Juanda" },
    { code: "TRK", name: "Juwata" },
    { code: "KNG", name: "Kaimana" },
    { code: "BEJ", name: "Kalimaru" },
    { code: "KBX", name: "Kambuaya" },
    { code: "KCD", name: "Kamur" },
    { code: "KWB", name: "Karimunjawa" },
    { code: "KBF", name: "Karubaga" },
    { code: "KAZ", name: "Kau" },
    { code: "KEQ", name: "Kebar" },
    { code: "KEA", name: "Keisah" },
    { code: "LLN", name: "Kelila" },
    { code: "KLQ", name: "Keluang" },
    { code: "KEI", name: "Kepi" },
    { code: "KRC", name: "Kerinci" },
    { code: "KTG", name: "Ketapang" },
    { code: "TNJ", name: "Kidjang" },
    { code: "KMM", name: "Kimam" },
    { code: "KOX", name: "Kokonao" },
    { code: "KCI", name: "Kon" },
    { code: "KBU", name: "Kotabaru" },
    { code: "LAH", name: "Labuha" },
    { code: "LUV", name: "Langgur" },
    { code: "LKA", name: "Larantuka" },
    { code: "LHI", name: "Lereh" },
    { code: "LWE", name: "Lewoleba" },
    { code: "LSX", name: "Lhok Sukon" },
    { code: "LSW", name: "Lhoksumawe" },
    { code: "LPU", name: "Long Apung" },
    { code: "LBW", name: "Long Bawan" },
    { code: "LYK", name: "Lunyuk" },
    { code: "LUW", name: "Luwuk" },
    { code: "PLM", name: "Mahmud Badaruddin Ii" },
    { code: "MPT", name: "Maliana" },
    { code: "MJU", name: "Mamuju" },
    { code: "MAL", name: "Mangole" },
    { code: "MJY", name: "Mangunjaya" },
    { code: "MSI", name: "Masalembo" },
    { code: "MXB", name: "Masamba" },
    { code: "MWK", name: "Matak" },
    { code: "MNA", name: "Melangguane" },
    { code: "RDE", name: "Merdey" },
    { code: "PDG", name: "Minangkabau International Airport" },
    { code: "MDP", name: "Mindiptana" },
    { code: "ONI", name: "Moanamani" },
    { code: "MKQ", name: "Mopah" },
    { code: "OTI", name: "Morotai Island" },
    { code: "MPC", name: "Muko-Muko" },
    { code: "LII", name: "Mulia" },
    { code: "LBJ", name: "Mutiara" },
    { code: "PLW", name: "Mutiara" },
    { code: "MUF", name: "Muting" },
    { code: "NBX", name: "Nabire" },
    { code: "NAH", name: "Naha" },
    { code: "NAM", name: "Namlea" },
    { code: "NPO", name: "Nangapinoh" },
    { code: "SBG", name: "Narita" },
    { code: "NTX", name: "Natuna Ranai" },
    { code: "DPS", name: "Ngurah Rai International" },
    { code: "FOO", name: "Numfoor" },
    { code: "NNX", name: "Nunukan" },
    { code: "OBD", name: "Obano" },
    { code: "OEC", name: "Ocussi" },
    { code: "OKQ", name: "Okaba" },
    { code: "OKL", name: "Oksibil" },
    { code: "BKS", name: "Padangkemiling" },
    { code: "PKY", name: "Palangkaraya" },
    { code: "PBW", name: "Palibelo" },
    { code: "PKN", name: "Pangkalanbuun" },
    { code: "PGK", name: "Pangkalpinang" },
    { code: "PPR", name: "Pasir Pangarayan" },
    { code: "AMQ", name: "Pattimura" },
    { code: "PDO", name: "Pendopo" },
    { code: "CBN", name: "Penggung" },
    { code: "DUM", name: "Pinang Kampai" },
    { code: "MES", name: "Polonia" },
    { code: "PUM", name: "Pomala" },
    { code: "PCB", name: "Pondok Cabe" },
    { code: "PSJ", name: "Poso" },
    { code: "PPJ", name: "Pulau Panjang" },
    { code: "PWL", name: "Purwokerto" },
    { code: "PSU", name: "Putussibau" },
    { code: "RSK", name: "Ransiki" },
    { code: "MKW", name: "Rendani" },
    { code: "RKI", name: "Rokot" },
    { code: "RTI", name: "Roti" },
    { code: "RTG", name: "Ruteng" },
    { code: "KOD", name: "Sabiha Gökçen Havaalanı" },
    { code: "SRI", name: "Samarinda" },
    { code: "SMQ", name: "Sampit" },
    { code: "MDC", name: "Samratulangi" },
    { code: "SQN", name: "Sanana" },
    { code: "SGQ", name: "Sanggata" },
    { code: "SAE", name: "Sangir" },
    { code: "ZRM", name: "Sarmi" },
    { code: "SXK", name: "Saumlaki" },
    { code: "SAU", name: "Sawu" },
    { code: "AMI", name: "Selaparang" },
    { code: "SEH", name: "Senggeh" },
    { code: "ZEG", name: "Senggo" },
    { code: "SZH", name: "Senipah" },
    { code: "DJJ", name: "Sentani" },
    { code: "BPN", name: "Sepingan" },
    { code: "ZRI", name: "Serui" },
    { code: "MEQ", name: "Seunagan" },
    { code: "NKD", name: "Sinak" },
    { code: "SQG", name: "Sintang" },
    { code: "RKO", name: "Sipora" },
    { code: "BDJ", name: "Sjamsudin Noor" },
    { code: "CGK", name: "Soekarno-Hatta International" },
    { code: "JKT", name: "Soekarno-hatta Intl" },
    { code: "ZKL", name: "Steenkool" },
    { code: "UAI", name: "Suai" },
    { code: "RAQ", name: "Sugimanuru" },
    { code: "BTJ", name: "Sultan Lskandarmuda Airport" },
    { code: "PKU", name: "Sultan Syarif Kasim Ii" },
    { code: "DJB", name: "Sultan Taha Syarifudn" },
    { code: "SEQ", name: "Sungai Pakning" },
    { code: "PNK", name: "Supadio" },
    { code: "TAX", name: "Taliabu" },
    { code: "TTR", name: "Tana Toraja" },
    { code: "TNB", name: "Tanah Grogot" },
    { code: "TMH", name: "Tanahmerah" },
    { code: "TJB", name: "Tanjung Balai" },
    { code: "TJS", name: "Tanjung Selor" },
    { code: "TJG", name: "Tanjung Warukin" },
    { code: "TPK", name: "Tapaktuan" },
    { code: "TXM", name: "Teminabuan" },
    { code: "TIM", name: "Timika" },
    { code: "TMY", name: "Tiom" },
    { code: "TMC", name: "Tjilik Riwut" },
    { code: "TLI", name: "Tolitoli" },
    { code: "GTO", name: "Tolotio" },
    { code: "SUP", name: "Trunojoyo" },
    { code: "TBM", name: "Tumbang Samba" },
    { code: "CXP", name: "Tunggul Wulung" },
    { code: "UBR", name: "Ubrub" },
    { code: "VIQ", name: "Viqueque" },
    { code: "WET", name: "Wagethe" },
    { code: "WGP", name: "Waingapu" },
    { code: "MOF", name: "Waioti" },
    { code: "WMX", name: "Wamena" },
    { code: "WAR", name: "Waris" },
    { code: "WSR", name: "Wasior" },
    { code: "KDI", name: "Wolter Monginsidi" },
    { code: "UGU", name: "Zugapa" },
    { code: "ABD", name: "Abadan" },
    { code: "AEU", name: "Abu Musa" },
    { code: "AKW", name: "Aghajari" },
    { code: "AWZ", name: "Ahwaz" },
    { code: "AJK", name: "Araak" },
    { code: "ADU", name: "Ardabil" },
    { code: "BBL", name: "Babolsar" },
    { code: "IAQ", name: "Bahregan" },
    { code: "BXR", name: "Bam" },
    { code: "BND", name: "Bandar Abbas" },
    { code: "BDH", name: "Bandar Lengeh" },
    { code: "XBJ", name: "Birjand" },
    { code: "BSM", name: "Bishe-kola" },
    { code: "BJB", name: "Bojnord" },
    { code: "BUZ", name: "Bushehr" },
    { code: "ZBR", name: "Chah-Bahar" },
    { code: "SRY", name: "Dashte Naz" },
    { code: "DEF", name: "Dezful" },
    { code: "FAZ", name: "Fasa" },
    { code: "GZW", name: "Ghazvin" },
    { code: "GBT", name: "Gorgan" },
    { code: "HDM", name: "Hamadan" },
    { code: "HDR", name: "Havadarya" },
    { code: "IIL", name: "Ilaam" },
    { code: "IHR", name: "Iran Shahr" },
    { code: "IFN", name: "Isfahan International" },
    { code: "KNR", name: "Jam" },
    { code: "JYR", name: "Jiroft" },
    { code: "KLM", name: "Kalaleh" },
    { code: "KER", name: "Kerman" },
    { code: "KSH", name: "Kermanshah" },
    { code: "KHA", name: "Khaneh" },
    { code: "KHK", name: "Khark Island" },
    { code: "KHD", name: "Khorramabad" },
    { code: "KHY", name: "Khoy" },
    { code: "KIH", name: "Kish Island" },
    { code: "LFM", name: "Lamerd" },
    { code: "LRR", name: "Lar A/P" },
    { code: "LVP", name: "Lavan" },
    { code: "MRX", name: "Mahshahr" },
    { code: "MHD", name: "Mashad" },
    { code: "THR", name: "Mehrabad/ghaleh Morghi" },
    { code: "NUJ", name: "Nojeh" },
    { code: "NSH", name: "Now Shahr" },
    { code: "OMI", name: "Omidieh" },
    { code: "YEH", name: "Persian Gulf International" },
    { code: "RJN", name: "Rafsanjan" },
    { code: "RZR", name: "Ramsar" },
    { code: "RAS", name: "Rasht" },
    { code: "AFZ", name: "Sabzevar" },
    { code: "ACP", name: "Sahand" },
    { code: "SDG", name: "Sanandaj" },
    { code: "CKT", name: "Sarakhs" },
    { code: "CQD", name: "Shahre-kord" },
    { code: "SYZ", name: "Shiraz" },
    { code: "SYJ", name: "Sirjan" },
    { code: "SXI", name: "Sirri Island" },
    { code: "TBZ", name: "Tabriz" },
    { code: "TEW", name: "Tohid" },
    { code: "OMH", name: "Urmieh" },
    { code: "AZD", name: "Yazd" },
    { code: "ACZ", name: "Zabol A/P" },
    { code: "ZAH", name: "Zahedan" },
    { code: "JWN", name: "Zanjan" },
    { code: "EBL", name: "Erbil International" },
    { code: "BGW", name: "Al Rasheed" },
    { code: "SDA", name: "Baghdad International" },
    { code: "XQC", name: "Balad" },
    { code: "BMN", name: "Bamerny" },
    { code: "XQV", name: "Baqubah" },
    { code: "BSR", name: "International" },
    { code: "XIH", name: "Khan Al Baghdadi" },
    { code: "KIK", name: "Kirkuk" },
    { code: "OSM", name: "Mosul" },
    { code: "XTM", name: "Ramadi" },
    { code: "OSO", name: "Sulaimaniyah International" },
    { code: "XTV", name: "Tikrit" },
    { code: "BYT", name: "Bantry" },
    { code: "BLY", name: "Belmullet" },
    { code: "ZSL", name: "Bus Station" },
    { code: "GWY", name: "Carnmore" },
    { code: "CLB", name: "Castlebar" },
    { code: "WEX", name: "Castlebridge" },
    { code: "SXL", name: "Collooney" },
    { code: "NNR", name: "Connemara" },
    { code: "ORK", name: "Cork" },
    { code: "CFN", name: "Donegal" },
    { code: "DUB", name: "Dublin" },
    { code: "INQ", name: "Inisheer" },
    { code: "IIA", name: "Inishmaan" },
    { code: "NOC", name: "Ireland West Airport Knock" },
    { code: "KIR", name: "Kerry" },
    { code: "KKY", name: "Kilkenny" },
    { code: "IOR", name: "Kilronan" },
    { code: "LTR", name: "Letterkenny" },
    { code: "CHE", name: "Reenroe" },
    { code: "SNN", name: "Shannon" },
    { code: "WAT", name: "Waterford" },
    { code: "BEV", name: "Beer Sheba" },
    { code: "TLV", name: "Ben Gurion Intl" },
    { code: "ETH", name: "Eilat" },
    { code: "EIY", name: "Ein Yahav" },
    { code: "GHK", name: "Gush Katif" },
    { code: "HFA", name: "Haifa" },
    { code: "---", name: "Herzlia" },
    { code: "JRS", name: "Jerusalem" },
    { code: "KSW", name: "Kiryat Shmona" },
    { code: "MTZ", name: "Masada" },
    { code: "MIP", name: "Mitspeh Ramon" },
    { code: "VDA", name: "Ovda" },
    { code: "RPN", name: "Rosh Pina" },
    { code: "SDV", name: "Sde Dov" },
    { code: "YOT", name: "Yotvata" },
    { code: "UDN", name: "Airfield" },
    { code: "ALL", name: "Albenga" },
    { code: "TTB", name: "Arbatax" },
    { code: "AVB", name: "Aviano" },
    { code: "GRS", name: "Baccarini" },
    { code: "BLX", name: "Belluno" },
    { code: "ZMF", name: "Beverello Harbor" },
    { code: "TPS", name: "Birgi" },
    { code: "BZO", name: "Bolzano" },
    { code: "VBS", name: "Brescia Montichiari" },
    { code: "ZBN", name: "Bus Station" },
    { code: "ZMR", name: "Bus Station" },
    { code: "ZMO", name: "Bus Station" },
    { code: "NAP", name: "Capodichino" },
    { code: "PRJ", name: "Capri" },
    { code: "XIK", name: "Central Station" },
    { code: "CIA", name: "Ciampino" },
    { code: "CIY", name: "Comiso" },
    { code: "OLB", name: "Costa Smeralda" },
    { code: "GOA", name: "Cristoforo Colombo" },
    { code: "CRV", name: "Crotone" },
    { code: "CAG", name: "Elmas" },
    { code: "AOI", name: "Falconara" },
    { code: "AHO", name: "Fertilia" },
    { code: "CTA", name: "Fontanarossa" },
    { code: "LCC", name: "Galatina" },
    { code: "PSA", name: "Galileo Galilei" },
    { code: "FOG", name: "Gino Lisa" },
    { code: "PMF", name: "Giuseppe Verdi" },
    { code: "BLQ", name: "Guglielmo Marconi" },
    { code: "ZJY", name: "Harbour" },
    { code: "ZJJ", name: "Harbour" },
    { code: "ZIP", name: "Harbour" },
    { code: "ZJE", name: "Harbour" },
    { code: "ZIQ", name: "Harbour" },
    { code: "ZJX", name: "Harbour" },
    { code: "ZIE", name: "Harbour" },
    { code: "ISH", name: "Ischia" },
    { code: "JLO", name: "Jesolo" },
    { code: "RAN", name: "La Spreta" },
    { code: "LMP", name: "Lampedusa" },
    { code: "FCO", name: "Leonardo da Vinci International" },
    { code: "CUF", name: "Levaldigi" },
    { code: "PSR", name: "Liberi" },
    { code: "LIN", name: "Linate" },
    { code: "LCV", name: "Lucca" },
    { code: "FRL", name: "Luigi Ridolfi" },
    { code: "TAR", name: "M. A. Grottag" },
    { code: "MXP", name: "Malpensa" },
    { code: "VCE", name: "Marco Polo" },
    { code: "EBA", name: "Marina Di Campo" },
    { code: "QMM", name: "Marina Di Massa" },
    { code: "ZMJ", name: "Mergellina Harbour" },
    { code: "XVY", name: "Mestre Rail Stn." },
    { code: "RMI", name: "Miramare" },
    { code: "BGY", name: "Orio al Serio" },
    { code: "BRI", name: "Palese" },
    { code: "PNL", name: "Pantelleria" },
    { code: "BDS", name: "Papola Casale" },
    { code: "FLR", name: "Peretola" },
    { code: "PEJ", name: "Peschiei" },
    { code: "XIX", name: "Porta Nuova Rail" },
    { code: "PMO", name: "Punta Raisi" },
    { code: "DCI", name: "Rafsu Decimomannu" },
    { code: "TRS", name: "Ronchi Dei Legionari" },
    { code: "SUF", name: "S Eufemia" },
    { code: "TSF", name: "S. Angelo" },
    { code: "TQR", name: "San Domino Island" },
    { code: "GBN", name: "San Giovanni Rotondo" },
    { code: "TRN", name: "Sandro Pertini" },
    { code: "PEG", name: "Sant Egidio" },
    { code: "XVQ", name: "Santa Lucia Rail" },
    { code: "SWK", name: "Segrate" },
    { code: "SAY", name: "Siena" },
    { code: "NSY", name: "Sigonella NAF" },
    { code: "RRO", name: "Sorrento" },
    { code: "TFC", name: "Taormina Harbour" },
    { code: "XRJ", name: "Termini Rail Stn." },
    { code: "REG", name: "Tito Menniti" },
    { code: "VRN", name: "Valerio Catullo" },
    { code: "VIC", name: "Vicenza" },
    { code: "OCJ", name: "Boscobel" },
    { code: "POT", name: "Ken Jones" },
    { code: "MVJ", name: "Marlboro" },
    { code: "NEG", name: "Negril" },
    { code: "KIN", name: "Norman Manley" },
    { code: "MBJ", name: "Sangster International" },
    { code: "KTP", name: "Tinson" },
    { code: "AGJ", name: "Aguni" },
    { code: "AXT", name: "Akita" },
    { code: "ASJ", name: "Amami O Shima" },
    { code: "AOJ", name: "Aomori" },
    { code: "AKJ", name: "Asahikawa" },
    { code: "NJA", name: "Atsugi NAS" },
    { code: "BPU", name: "Beppu" },
    { code: "NGO", name: "Centrair" },
    { code: "FUJ", name: "Fukue" },
    { code: "FKJ", name: "Fukui" },
    { code: "FUK", name: "Fukuoka" },
    { code: "HAC", name: "Hachijo Jima" },
    { code: "HHE", name: "Hachinohe" },
    { code: "HKD", name: "Hakodate" },
    { code: "HNA", name: "Hanamaki" },
    { code: "HTR", name: "Hateruma" },
    { code: "MMY", name: "Hirara" },
    { code: "HIW", name: "Hiroshima West" },
    { code: "WKJ", name: "Hokkaido" },
    { code: "IEJ", name: "Iejima" },
    { code: "IKI", name: "Iki" },
    { code: "HIJ", name: "International" },
    { code: "ISG", name: "Ishigaki" },
    { code: "ITM", name: "Itami" },
    { code: "IWJ", name: "Iwami" },
    { code: "IWO", name: "Iwo Jima Airbase" },
    { code: "IZO", name: "Izumo" },
    { code: "GAJ", name: "Junmachi" },
    { code: "DNA", name: "Kadena AB" },
    { code: "KOJ", name: "Kagoshima" },
    { code: "UKY", name: "Kansai" },
    { code: "KIX", name: "Kansai International" },
    { code: "KKX", name: "Kikaiga Shima" },
    { code: "KKJ", name: "Kita Kyushu" },
    { code: "KTD", name: "Kitadaito" },
    { code: "UKB", name: "Kobe" },
    { code: "KCZ", name: "Kochi" },
    { code: "NKM", name: "Komaki AFB" },
    { code: "KMQ", name: "Komatsu" },
    { code: "KMJ", name: "Kumamoto" },
    { code: "UEO", name: "Kumejima" },
    { code: "KUJ", name: "Kushimoto" },
    { code: "KUH", name: "Kushiro" },
    { code: "MUS", name: "Marcus Island" },
    { code: "MMD", name: "Maridor" },
    { code: "MMJ", name: "Matsumoto" },
    { code: "MYJ", name: "Matsuyama" },
    { code: "MMB", name: "Memanbetsu" },
    { code: "YGJ", name: "Miho" },
    { code: "MSJ", name: "Misawa" },
    { code: "MYE", name: "Miyake Jima" },
    { code: "KMI", name: "Miyazaki" },
    { code: "MBE", name: "Monbetsu" },
    { code: "NGS", name: "Nagasaki" },
    { code: "OKA", name: "Naha" },
    { code: "AHA", name: "Naha Air Force Base" },
    { code: "SHB", name: "Nakashibetsu" },
    { code: "CTS", name: "New Chitose Airport" },
    { code: "NRT", name: "New Tokyo International Airport" },
    { code: "KIJ", name: "Niigata" },
    { code: "IHA", name: "Niihama" },
    { code: "IIN", name: "Nishinoomote" },
    { code: "NTQ", name: "Noto Airport" },
    { code: "OBO", name: "Obihiro" },
    { code: "ONJ", name: "Odate Noshiro" },
    { code: "XZJ", name: "Off line Point" },
    { code: "OIT", name: "Oita" },
    { code: "OKD", name: "Okadama" },
    { code: "OKJ", name: "Okayama" },
    { code: "OKI", name: "Oki Island" },
    { code: "OKE", name: "Okino Erabu" },
    { code: "OIR", name: "Okushiri" },
    { code: "OMJ", name: "Omura" },
    { code: "OIM", name: "Oshima" },
    { code: "RBJ", name: "Rebun" },
    { code: "RIS", name: "Rishiri" },
    { code: "SDO", name: "Ryotsu Sado Is" },
    { code: "SDS", name: "Sado Shima" },
    { code: "HSG", name: "Saga" },
    { code: "SDJ", name: "Sendai" },
    { code: "SHI", name: "Shimojishima" },
    { code: "SHM", name: "Shirahama" },
    { code: "SYO", name: "Shonai" },
    { code: "TJH", name: "Tajima" },
    { code: "TAK", name: "Takamatsu" },
    { code: "TNE", name: "Tanegashima" },
    { code: "TRA", name: "Tarama" },
    { code: "TKN", name: "Tokunoshima" },
    { code: "TKS", name: "Tokushima" },
    { code: "HND", name: "Tokyo Haneda International" },
    { code: "TTJ", name: "Tottori" },
    { code: "TOY", name: "Toyama" },
    { code: "TSJ", name: "Tsushima" },
    { code: "UBJ", name: "Ube" },
    { code: "KUM", name: "Yakushima" },
    { code: "YOK", name: "Yokohama" },
    { code: "OKO", name: "Yokota Afb" },
    { code: "OGN", name: "Yonaguni Jima" },
    { code: "RNJ", name: "Yoronjima" },
    { code: "OMF", name: "King Hussein" },
    { code: "AQJ", name: "King Hussein Intl" },
    { code: "MPQ", name: "Maan" },
    { code: "ADJ", name: "Marka International Airport" },
    { code: "AMM", name: "Queen Alia Intl" },
    { code: "AKX", name: "Aktyubinsk" },
    { code: "ALA", name: "Almaty" },
    { code: "AYK", name: "Arkalyk" },
    { code: "TSE", name: "Astana" },
    { code: "ATX", name: "Atbasar" },
    { code: "GUW", name: "Atyrau International" },
    { code: "BXH", name: "Balhash" },
    { code: "BXJ", name: "Burundai" },
    { code: "EKB", name: "Ekibastuz" },
    { code: "KGF", name: "Karaganda" },
    { code: "KOV", name: "Kokshetau" },
    { code: "KSN", name: "Kostanay" },
    { code: "KZO", name: "Kzyl-Orda" },
    { code: "PWQ", name: "Pavlodar" },
    { code: "PPK", name: "Petropavlovsk" },
    { code: "PLX", name: "Semipalatinsk" },
    { code: "SCO", name: "Shevchenko" },
    { code: "CIT", name: "Shimkent" },
    { code: "TDK", name: "Taldy-Kurgan" },
    { code: "URA", name: "Uralsk / Podstepnyy" },
    { code: "UKK", name: "Ust-kamenogorsk" },
    { code: "HRC", name: "Zhairem" },
    { code: "DMB", name: "Zhambyl" },
    { code: "DZN", name: "Zhezhazgan" },
    { code: "ASV", name: "Amboseli" },
    { code: "BMQ", name: "Bamburi" },
    { code: "EDL", name: "Eldoret" },
    { code: "EYS", name: "Eliye Springs" },
    { code: "FER", name: "Fergusons Gulf" },
    { code: "GAS", name: "Garissa" },
    { code: "HOA", name: "Hola" },
    { code: "NBO", name: "Jomo Kenyatta International" },
    { code: "KLK", name: "Kalokol" },
    { code: "KEY", name: "Kericho" },
    { code: "KRV", name: "Kerio Valley" },
    { code: "ILU", name: "Kilaguni" },
    { code: "KIS", name: "Kisumu" },
    { code: "KTL", name: "Kitale" },
    { code: "KIU", name: "Kiunga" },
    { code: "KWY", name: "Kiwayu" },
    { code: "LBN", name: "Lake Baringo" },
    { code: "LKU", name: "Lake Rudolf" },
    { code: "LAU", name: "Lamu" },
    { code: "LBK", name: "Liboi" },
    { code: "LOK", name: "Lodwar" },
    { code: "LKG", name: "Lokichoggio" },
    { code: "LOY", name: "Loyangalani" },
    { code: "MYD", name: "Malindi" },
    { code: "NDE", name: "Mandera" },
    { code: "MRE", name: "Mara Lodges" },
    { code: "RBT", name: "Marsabit" },
    { code: "MBA", name: "Moi International" },
    { code: "OYL", name: "Moyale" },
    { code: "MUM", name: "Mumias" },
    { code: "NUU", name: "Nakuru" },
    { code: "NYK", name: "Nanyuki" },
    { code: "NYE", name: "Nyeri" },
    { code: "NZO", name: "Nzoia" },
    { code: "UAS", name: "Samburu" },
    { code: "UKA", name: "Ukunda" },
    { code: "WJR", name: "Wajir" },
    { code: "WIL", name: "Wilson" },
    { code: "ABF", name: "Abaiang" },
    { code: "AEA", name: "Abemama Atoll" },
    { code: "AAK", name: "Aranuka" },
    { code: "AIS", name: "Arorae Island" },
    { code: "BEZ", name: "Beru" },
    { code: "TRW", name: "Bonriki" },
    { code: "BBG", name: "Butaritari" },
    { code: "CIS", name: "Canton Island" },
    { code: "CXI", name: "Cassidy International Airport" },
    { code: "KUC", name: "Kuria" },
    { code: "MNK", name: "Maiana" },
    { code: "MTK", name: "Makin Island" },
    { code: "MZK", name: "Marakei" },
    { code: "NIG", name: "Nikunau" },
    { code: "NON", name: "Nonouti" },
    { code: "OOT", name: "Onotoa" },
    { code: "TBF", name: "Tabiteuea North" },
    { code: "TSU", name: "Tabiteuea South" },
    { code: "TNV", name: "Tabuaeran" },
    { code: "TMN", name: "Tamana Island" },
    { code: "TNQ", name: "Teraina" },
    { code: "FNJ", name: "Sunan International" },
    { code: "CJJ", name: "Cheongju" },
    { code: "JCJ", name: "Chuja Heliport" },
    { code: "TAE", name: "Daegu" },
    { code: "KAG", name: "Gangneung" },
    { code: "PUS", name: "Gimhae" },
    { code: "GMP", name: "Gimpo International" },
    { code: "KUV", name: "Gunsan" },
    { code: "KWJ", name: "Gwangju" },
    { code: "JGE", name: "Heliport" },
    { code: "JCN", name: "Heliport" },
    { code: "ICN", name: "Incheon International" },
    { code: "CJU", name: "Jeju Airport" },
    { code: "CHN", name: "Jeonju" },
    { code: "CHF", name: "Jinhae" },
    { code: "MPK", name: "Mokpo" },
    { code: "OSN", name: "Osan AB" },
    { code: "KPO", name: "Pohang" },
    { code: "HIN", name: "Sacheon" },
    { code: "SUK", name: "Samcheok" },
    { code: "JSP", name: "Seogwipo Heliport" },
    { code: "SHO", name: "Seolak" },
    { code: "SWU", name: "Suwon" },
    { code: "WJU", name: "WonJu" },
    { code: "YNY", name: "Yangyang" },
    { code: "YEC", name: "Yecheon" },
    { code: "SYS", name: "Yeosu" },
    { code: "RSU", name: "Yeosu" },
    { code: "XIJ", name: "Ahmed Al Jaber" },
    { code: "KWI", name: "Kuwait International" },
    { code: "FRU", name: "Manas" },
    { code: "OSS", name: "Osh International Airport" },
    { code: "AOU", name: "Attopeu" },
    { code: "OUI", name: "Ban Houei" },
    { code: "PCQ", name: "Bounneua" },
    { code: "HOE", name: "Houeisay" },
    { code: "KOG", name: "Khong" },
    { code: "LXG", name: "Luang Namtha" },
    { code: "LPQ", name: "Luang Prabang" },
    { code: "UON", name: "Muong Sai" },
    { code: "ODY", name: "Oudomxai" },
    { code: "PKS", name: "Paksane" },
    { code: "PKZ", name: "Pakse" },
    { code: "NEU", name: "Sam Neua" },
    { code: "VNA", name: "Saravane" },
    { code: "ZVK", name: "Savannakhet" },
    { code: "ZBY", name: "Sayaboury" },
    { code: "SND", name: "Seno" },
    { code: "THK", name: "Thakhek" },
    { code: "UDO", name: "Udomxay" },
    { code: "VGG", name: "Vangrieng" },
    { code: "VNG", name: "Viengxay" },
    { code: "VTE", name: "Wattay" },
    { code: "XAY", name: "Xayabury" },
    { code: "XKH", name: "Xieng Khouang" },
    { code: "XIE", name: "Xienglom" },
    { code: "DGP", name: "Daugavpils" },
    { code: "LPX", name: "Liepaya" },
    { code: "RIX", name: "Riga International" },
    { code: "BEY", name: "Beirut Rafic Hariri Airport" },
    { code: "KYE", name: "Kleyate" },
    { code: "LEF", name: "Lebakeng" },
    { code: "LRB", name: "Leribe" },
    { code: "LES", name: "Lesobeng" },
    { code: "MFC", name: "Mafeteng" },
    { code: "MSG", name: "Matsaile" },
    { code: "MKH", name: "Mokhotlong" },
    { code: "MSU", name: "Moshoeshoe Intl" },
    { code: "NKU", name: "Nkaus" },
    { code: "PEL", name: "Pelaneng" },
    { code: "UNE", name: "Qachas Nek" },
    { code: "UTG", name: "Quthing" },
    { code: "SHK", name: "Sehonghong" },
    { code: "SKQ", name: "Sekakes" },
    { code: "SOK", name: "Semongkong" },
    { code: "SHZ", name: "Seshutes" },
    { code: "THB", name: "Thaba-Tseka" },
    { code: "TKO", name: "Tlokoeng" },
    { code: "CPA", name: "A. Tubman" },
    { code: "BYL", name: "Bella Yella" },
    { code: "UCN", name: "Buchanan" },
    { code: "FOY", name: "Foya" },
    { code: "GRC", name: "Grand Cess" },
    { code: "NIA", name: "Nimba" },
    { code: "SNI", name: "R.E. Murray" },
    { code: "RVC", name: "Rivercess" },
    { code: "ROB", name: "Roberts Intl" },
    { code: "SAZ", name: "Sasstown" },
    { code: "MLW", name: "Sprigg Payne" },
    { code: "TPT", name: "Tapeta" },
    { code: "THC", name: "Tchien" },
    { code: "VOI", name: "Voinjama" },
    { code: "WES", name: "Weasua" },
    { code: "WOI", name: "Wologissi" },
    { code: "BEN", name: "Benina Intl" },
    { code: "BCQ", name: "Brack" },
    { code: "LTD", name: "Ghadames" },
    { code: "GHT", name: "Ghat" },
    { code: "HUQ", name: "Houn" },
    { code: "TIP", name: "International" },
    { code: "AKF", name: "Kufrah" },
    { code: "LAQ", name: "La Braq" },
    { code: "LMQ", name: "Marsa Brega" },
    { code: "DNF", name: "Martuba" },
    { code: "MRA", name: "Misurata" },
    { code: "MJI", name: "Mitiga" },
    { code: "QMQ", name: "Murzuq" },
    { code: "NFR", name: "Nafoora" },
    { code: "SEB", name: "Sebha" },
    { code: "SRX", name: "Sert" },
    { code: "TOB", name: "Tobruk" },
    { code: "QUB", name: "Ubari" },
    { code: "KUN", name: "Kaunas" },
    { code: "PLQ", name: "Palanga International" },
    { code: "PNV", name: "Panevezys" },
    { code: "SQQ", name: "Siauliai International" },
    { code: "VNO", name: "Vilnius" },
    { code: "LUX", name: "Findel" },
    { code: "MFM", name: "Macau International" },
    { code: "OHD", name: "Ohrid" },
    { code: "IVA", name: "Ambanja" },
    { code: "AHY", name: "Ambatolahy" },
    { code: "AMY", name: "Ambatomainty" },
    { code: "WAM", name: "Ambatondrazaka" },
    { code: "AMB", name: "Ambilobe" },
    { code: "MJN", name: "Amborovy" },
    { code: "AMP", name: "Ampanihy" },
    { code: "HVA", name: "Analalava" },
    { code: "ZWA", name: "Andapa" },
    { code: "WAD", name: "Andriamena" },
    { code: "JVA", name: "Ankavandra" },
    { code: "WAK", name: "Ankazoabo" },
    { code: "NKO", name: "Ankokoambo" },
    { code: "TNR", name: "Antananarivo" },
    { code: "WAQ", name: "Antsalova" },
    { code: "ANM", name: "Antsirabato" },
    { code: "ATJ", name: "Antsirabe" },
    { code: "DIE", name: "Antsiranana/Arrachart" },
    { code: "WAI", name: "Antsohihy" },
    { code: "WBE", name: "Bealanana" },
    { code: "WBD", name: "Befandriana" },
    { code: "OVA", name: "Bekily" },
    { code: "BMD", name: "Belo" },
    { code: "WBO", name: "Beroroha" },
    { code: "BPY", name: "Besalampy" },
    { code: "BKU", name: "Betioky" },
    { code: "DOA", name: "Doany" },
    { code: "RVA", name: "Farafangana" },
    { code: "NOS", name: "Fascene" },
    { code: "WFI", name: "Fianarantsoa" },
    { code: "IHO", name: "Ihosy" },
    { code: "ILK", name: "Ilaka" },
    { code: "WMV", name: "Madirovalo" },
    { code: "VVB", name: "Mahanoro" },
    { code: "MXT", name: "Maintirano" },
    { code: "WML", name: "Malaimbandy" },
    { code: "WMP", name: "Mampikony" },
    { code: "WVK", name: "Manakara" },
    { code: "WMR", name: "Mananara" },
    { code: "MNJ", name: "Mananjary" },
    { code: "WMD", name: "Mandabe" },
    { code: "WMA", name: "Mandritsara" },
    { code: "MJA", name: "Manja" },
    { code: "FTU", name: "Marillac" },
    { code: "WMN", name: "Maroantsetra" },
    { code: "ZVA", name: "Miandrivazo" },
    { code: "TVA", name: "Morafenobe" },
    { code: "WOR", name: "Moramba" },
    { code: "MXM", name: "Morombe" },
    { code: "MOQ", name: "Morondava" },
    { code: "WPB", name: "Port Berge" },
    { code: "SMS", name: "Sainte Marie" },
    { code: "SVB", name: "Sambava" },
    { code: "DWB", name: "Soalala" },
    { code: "TMM", name: "Tamatave" },
    { code: "WTA", name: "Tambohorano" },
    { code: "TDV", name: "Tanandava" },
    { code: "TTS", name: "Tsaratanana" },
    { code: "TLE", name: "Tulear" },
    { code: "VND", name: "Vangaindrano" },
    { code: "VAT", name: "Vatomandry" },
    { code: "VOH", name: "Vohemar" },
    { code: "CEH", name: "Chelinda" },
    { code: "BLZ", name: "Chileka" },
    { code: "CMK", name: "Club Makokola" },
    { code: "DWA", name: "Dwangwa" },
    { code: "KGJ", name: "Karonga" },
    { code: "LLW", name: "Kumuzu International Airport" },
    { code: "LIX", name: "Likoma" },
    { code: "MAI", name: "Mangochi" },
    { code: "MYZ", name: "Monkey Bay" },
    { code: "ZZU", name: "Mzuzu" },
    { code: "LMB", name: "Salima" },
    { code: "AOR", name: "Alor Setar" },
    { code: "BKM", name: "Bakalalan" },
    { code: "BBN", name: "Bario" },
    { code: "MKZ", name: "Batu Berendam" },
    { code: "BLG", name: "Belaga" },
    { code: "BTU", name: "Bintulu" },
    { code: "ZMK", name: "Bintulu Port" },
    { code: "BWH", name: "Butterworth" },
    { code: "XPQ", name: "Ferry" },
    { code: "GTB", name: "Genting" },
    { code: "KPI", name: "Kapit" },
    { code: "KGU", name: "Keningau" },
    { code: "KTE", name: "Kerteh" },
    { code: "ZWR", name: "Kota Kinabalu Port" },
    { code: "BKI", name: "Kota-Kinabalu International Airport" },
    { code: "KUL", name: "Kuala Lumpur International Airport" },
    { code: "KUA", name: "Kuantan" },
    { code: "ZJB", name: "Kuantan Port" },
    { code: "KCH", name: "Kuching" },
    { code: "ZGY", name: "Kuching Port" },
    { code: "KUD", name: "Kudat" },
    { code: "LBU", name: "Labuan" },
    { code: "LDU", name: "Lahad Datu" },
    { code: "LGK", name: "Langakawi Intl" },
    { code: "LWY", name: "Lawas" },
    { code: "LSM", name: "Lawas" },
    { code: "LMN", name: "Limbang" },
    { code: "LKH", name: "Long Akah" },
    { code: "LLM", name: "Long Lama" },
    { code: "LGL", name: "Long Lellang" },
    { code: "GSA", name: "Long Pasia" },
    { code: "ODN", name: "Long Seridan" },
    { code: "LSU", name: "Long Sukang" },
    { code: "RDN", name: "LTS Pulau Redang" },
    { code: "MUR", name: "Marudi" },
    { code: "MEP", name: "Mersing" },
    { code: "MYY", name: "Miri" },
    { code: "MKM", name: "Mukah" },
    { code: "MZV", name: "Mulu, Sarawak" },
    { code: "PAY", name: "Pamol" },
    { code: "ZLW", name: "Pasir Gudang Port" },
    { code: "PEN", name: "Penang International" },
    { code: "ZJR", name: "Penang Port" },
    { code: "RNU", name: "Ranau" },
    { code: "SXS", name: "Sahabat 16" },
    { code: "SDK", name: "Sandakan" },
    { code: "BSE", name: "Sematan" },
    { code: "SMM", name: "Semporna" },
    { code: "SPE", name: "Sepulot" },
    { code: "SBW", name: "Sibu" },
    { code: "SGG", name: "Simanggang" },
    { code: "SPT", name: "Sipitang" },
    { code: "SWY", name: "Sitiawan" },
    { code: "SZB", name: "Sultan Abdul Aziz Shah" },
    { code: "IPH", name: "Sultan Azlan Shah Airport" },
    { code: "JHB", name: "Sultan Ismail Intl" },
    { code: "KBR", name: "Sultan Ismail Petra" },
    { code: "TGG", name: "Sultan Mahmood" },
    { code: "GTK", name: "Sungei Tekai" },
    { code: "TPG", name: "Taiping" },
    { code: "SXT", name: "Taman Negara" },
    { code: "ZJT", name: "Tanjung Pelepas Port" },
    { code: "TWU", name: "Tawau" },
    { code: "TEL", name: "Telupid" },
    { code: "TOD", name: "Tioman" },
    { code: "TMG", name: "Tomanggong" },
    { code: "GAN", name: "Gan Airport" },
    { code: "HAQ", name: "Hanimaadhoo" },
    { code: "KDM", name: "Kaadedhdhoo" },
    { code: "KDO", name: "Kadhdhoo" },
    { code: "MLE", name: "Male International" },
    { code: "BKO", name: "Bamako" },
    { code: "GUD", name: "Goundam" },
    { code: "KYS", name: "Kayes" },
    { code: "KNZ", name: "Kenieba" },
    { code: "KTX", name: "Koutiala" },
    { code: "MZI", name: "Mopti" },
    { code: "NRM", name: "Nara" },
    { code: "NIX", name: "Nioro" },
    { code: "SZU", name: "Segou" },
    { code: "KSS", name: "Sikasso" },
    { code: "TOM", name: "Tombouctou" },
    { code: "EYL", name: "Yelimane" },
    { code: "GZM", name: "Gozo Heliport" },
    { code: "JCO", name: "Heliport" },
    { code: "MLA", name: "Malta International" },
    { code: "AIP", name: "Ailinglapalap Island" },
    { code: "AIM", name: "Ailuk Island" },
    { code: "AIC", name: "Airok" },
    { code: "MAJ", name: "Amata Kabua Intl" },
    { code: "AMR", name: "Arno" },
    { code: "AUL", name: "Aur Island" },
    { code: "EBN", name: "Ebadon" },
    { code: "EAL", name: "Elenak" },
    { code: "ENT", name: "Enewetak Island" },
    { code: "EJT", name: "Enijet" },
    { code: "BII", name: "Enyu Airfield" },
    { code: "IMI", name: "Ine Island" },
    { code: "JAT", name: "Jabot" },
    { code: "UIT", name: "Jaluit Island" },
    { code: "JEJ", name: "Jeh" },
    { code: "KBT", name: "Kaben" },
    { code: "KIO", name: "Kili Island" },
    { code: "KWA", name: "Kwajalein" },
    { code: "LML", name: "Lae Island" },
    { code: "LIK", name: "Likiep Island" },
    { code: "LOF", name: "Loen" },
    { code: "MJE", name: "Majkin" },
    { code: "MAV", name: "Maloelap Island" },
    { code: "MJB", name: "Mejit Island" },
    { code: "MIJ", name: "Mili Island" },
    { code: "NDK", name: "Namdrik Island" },
    { code: "NMU", name: "Namu" },
    { code: "RNP", name: "Rongelap Island" },
    { code: "TBV", name: "Tabal" },
    { code: "TIC", name: "Tinak Island" },
    { code: "UJE", name: "Ujae Island" },
    { code: "UTK", name: "Utirik Island" },
    { code: "WJA", name: "Woja" },
    { code: "WTO", name: "Wotho Island" },
    { code: "WTE", name: "Wotje Island" },
    { code: "FDF", name: "Lamentin" },
    { code: "BGH", name: "Abbaye" },
    { code: "AEO", name: "Aioun El Atrouss" },
    { code: "AJJ", name: "Akjoujt" },
    { code: "LEG", name: "Aleg" },
    { code: "OTL", name: "Boutilimit" },
    { code: "CGT", name: "Chinguitti" },
    { code: "ZLG", name: "El Gouera" },
    { code: "FGD", name: "Fderik" },
    { code: "KED", name: "Kaedi" },
    { code: "KFA", name: "Kiffa" },
    { code: "MBR", name: "Mbout" },
    { code: "ATR", name: "Mouakchott" },
    { code: "MOM", name: "Moudjeria" },
    { code: "EMN", name: "Nema" },
    { code: "NDB", name: "Nouadhibou" },
    { code: "NKC", name: "Nouakchott" },
    { code: "SEY", name: "Selibaby" },
    { code: "THT", name: "Tamchakett" },
    { code: "THI", name: "Tichitt" },
    { code: "TIY", name: "Tidjikja" },
    { code: "TMD", name: "Timbedra" },
    { code: "OUZ", name: "Zouerate" },
    { code: "RRG", name: "Rodrigues Is" },
    { code: "MRU", name: "Sir Seewoosagur Ramgoolam Int" },
    { code: "AJS", name: "Abreojos" },
    { code: "AGU", name: "Aguascalients" },
    { code: "XAL", name: "Alamos" },
    { code: "AZG", name: "Apatzingan" },
    { code: "AZP", name: "Atizapan" },
    { code: "BHL", name: "Bahia Angeles" },
    { code: "CPE", name: "Campeche International" },
    { code: "CNA", name: "Cananea" },
    { code: "CUN", name: "Cancún International" },
    { code: "VSA", name: "Capitan Carlos Rovirosa" },
    { code: "CTM", name: "Chetumal" },
    { code: "CZA", name: "Chichen Itza" },
    { code: "CUA", name: "Ciudad Constitucion" },
    { code: "CME", name: "Ciudad Del Carmen" },
    { code: "MMC", name: "Ciudad Mante" },
    { code: "CEN", name: "Ciudad Obregon" },
    { code: "CVM", name: "Ciudad Victoria" },
    { code: "CLQ", name: "Colima" },
    { code: "CJT", name: "Copalar" },
    { code: "CZM", name: "Cozumel" },
    { code: "BJX", name: "Del Bajío International" },
    { code: "GDL", name: "Don Miguel Hidalgo y Costilla International" },
    { code: "ESE", name: "Ensenada" },
    { code: "LMM", name: "Federal" },
    { code: "CUL", name: "Fedl De Bachigualato" },
    { code: "MLM", name: "Francisco J. Múgica Internacional" },
    { code: "LOM", name: "Francisco P. V. Y R." },
    { code: "TRC", name: "Francisco Sarabia" },
    { code: "TAM", name: "Gen F Javier Mina" },
    { code: "CUU", name: "Gen Fierro Villalobos" },
    { code: "GYM", name: "Gen Jose M Yanez" },
    { code: "REX", name: "Gen Lucio Blanco" },
    { code: "MTY", name: "Gen Mariano Escobedo" },
    { code: "HMO", name: "Gen Pesqueira Garcia" },
    { code: "MZT", name: "Gen Rafael Buelna" },
    { code: "TIJ", name: "General A. L. Rodriguez Intl" },
    { code: "ACA", name: "General Juan N. Alvarez International" },
    { code: "LAP", name: "General Manuel Márquez De León International Airport" },
    { code: "CVJ", name: "General Mariano Matamoros Airport" },
    { code: "DGO", name: "Guadalupe Victoria" },
    { code: "GUB", name: "Guerrero Negro" },
    { code: "PBC", name: "Hermanos Serdan International" },
    { code: "HUX", name: "Huatulco" },
    { code: "ZIH", name: "Internacional" },
    { code: "MEX", name: "Internacional Benito Juarez" },
    { code: "ACN", name: "International" },
    { code: "CJS", name: "Intl Abraham Gonzalez" },
    { code: "NLD", name: "Intl Quetzalcoatl" },
    { code: "ISJ", name: "Isla Mujeres" },
    { code: "IZT", name: "Ixtepec" },
    { code: "JAL", name: "Jalapa" },
    { code: "ZCL", name: "La Calera" },
    { code: "VER", name: "Las Bajadas" },
    { code: "LZC", name: "Lazaro Cardenas" },
    { code: "TLC", name: "Licenciado Adolfo Lopez Mateos International" },
    { code: "PVR", name: "Licenciado Gustavo Díaz Ordaz International" },
    { code: "TGZ", name: "Llano San Juan" },
    { code: "LTO", name: "Loreto" },
    { code: "SJD", name: "Los Cabos" },
    { code: "ZLO", name: "Manzanillo" },
    { code: "MAM", name: "Matamoros" },
    { code: "MXL", name: "Mexicali" },
    { code: "MTT", name: "Minatitlan" },
    { code: "LOV", name: "Monclova" },
    { code: "MUG", name: "Mulege" },
    { code: "NOG", name: "Nogales" },
    { code: "NCG", name: "Nueva Casas Grandes" },
    { code: "PQM", name: "Palenque" },
    { code: "PDS", name: "Piedras Negras" },
    { code: "PNO", name: "Pinotepa Nacional" },
    { code: "PCM", name: "Playa Del Carmen" },
    { code: "PUH", name: "Pochutla" },
    { code: "PXM", name: "Puerto Escondido" },
    { code: "PPE", name: "Puerto Penasco" },
    { code: "PJZ", name: "Puerto Vallarta" },
    { code: "PCV", name: "Punta Chivato" },
    { code: "PCO", name: "Punta Colorada" },
    { code: "QRO", name: "Queretaro" },
    { code: "MID", name: "Rejon" },
    { code: "SCX", name: "Salina Cruz" },
    { code: "SLW", name: "Saltillo" },
    { code: "SFH", name: "San Felipe" },
    { code: "SGM", name: "San Ignacio" },
    { code: "SLP", name: "San Luis Potosi" },
    { code: "UAC", name: "San Luis Rio Colorado" },
    { code: "SNQ", name: "San Quintin" },
    { code: "NLU", name: "Santa Lucia" },
    { code: "SRL", name: "Santa Rosalia" },
    { code: "PAZ", name: "Tajin" },
    { code: "TSL", name: "Tamuin" },
    { code: "TAP", name: "Tapachula International" },
    { code: "TCN", name: "Tehuacan" },
    { code: "TPQ", name: "Tepic" },
    { code: "TZM", name: "Tizimin" },
    { code: "TUY", name: "Tulum" },
    { code: "NTR", name: "Tuxtla Gutierrez" },
    { code: "UPN", name: "Uruapan" },
    { code: "VIB", name: "Villa Constitucion" },
    { code: "OAX", name: "Xoxocotlan" },
    { code: "ZMM", name: "Zamora" },
    { code: "KSA", name: "Kosrae" },
    { code: "PNI", name: "Pohnpei" },
    { code: "TKK", name: "Truk" },
    { code: "ULI", name: "Ulithi" },
    { code: "BZY", name: "Beltsy" },
    { code: "KIV", name: "International Airport Chisinau" },
    { code: "MCM", name: "Fontvieille Heliport" },
    { code: "LTI", name: "Altai" },
    { code: "AVK", name: "Arvaikheer" },
    { code: "UUN", name: "Baruun-Urt" },
    { code: "BYN", name: "Bayankhongor" },
    { code: "UGA", name: "Bulgan" },
    { code: "ULN", name: "Chinggis Khaan International" },
    { code: "COQ", name: "Choibalsan" },
    { code: "DLZ", name: "Dalanzadgad" },
    { code: "ERT", name: "Erdenet" },
    { code: "KHR", name: "Kharkhorin" },
    { code: "HTM", name: "Khatgal" },
    { code: "HVD", name: "Khovd" },
    { code: "HJT", name: "Khujirt" },
    { code: "MXW", name: "Mandalgobi" },
    { code: "MXV", name: "Moron" },
    { code: "ULO", name: "Ulaangom" },
    { code: "ULG", name: "Ulgit" },
    { code: "ULZ", name: "Uliastai" },
    { code: "UNR", name: "Underkhaan" },
    { code: "TGD", name: "Golubovci" },
    { code: "IVG", name: "Ivangrad" },
    { code: "TIV", name: "Tivat" },
    { code: "MNI", name: "Gerald's Airport" },
    { code: "AGA", name: "Agadir Almassira" },
    { code: "CAS", name: "Anfa" },
    { code: "TNG", name: "Boukhalef" },
    { code: "AHU", name: "Charif Al Idrissi" },
    { code: "VIL", name: "Dakhla" },
    { code: "SEK", name: "Er Errachidia" },
    { code: "ESU", name: "Essaouira" },
    { code: "GLN", name: "Goulimime" },
    { code: "EUN", name: "Hassan I" },
    { code: "OUD", name: "Les Angades" },
    { code: "MEK", name: "Meknes" },
    { code: "RAK", name: "Menara" },
    { code: "CMN", name: "Mohamed V" },
    { code: "ERH", name: "Moulay Ali Cherif" },
    { code: "NDR", name: "Nador" },
    { code: "NNA", name: "NAF" },
    { code: "OZZ", name: "Ouarzazate" },
    { code: "SFI", name: "Safi" },
    { code: "FEZ", name: "Sais" },
    { code: "RBA", name: "Sale" },
    { code: "TTU", name: "Sania Ramel" },
    { code: "SII", name: "Sidi Ifni" },
    { code: "SMW", name: "Smara" },
    { code: "TTA", name: "Tan Tan" },
    { code: "TFY", name: "Tarfaya" },
    { code: "AME", name: "Alto Molocue" },
    { code: "ANO", name: "Angoche" },
    { code: "BJN", name: "Bajone" },
    { code: "BZB", name: "Bazaruto Island" },
    { code: "BEW", name: "Beira" },
    { code: "BCW", name: "Benguera Island" },
    { code: "CMZ", name: "Caia" },
    { code: "INE", name: "Chinde" },
    { code: "TGS", name: "Chokwe" },
    { code: "FXO", name: "Cuamba" },
    { code: "DGK", name: "Dugong" },
    { code: "VJQ", name: "Gurue" },
    { code: "IBO", name: "Ibo" },
    { code: "IDC", name: "Ila Da Chilonzuene" },
    { code: "IBL", name: "Indigo Bay Lodge" },
    { code: "IHC", name: "Inhaca" },
    { code: "INH", name: "Inhambane" },
    { code: "IMG", name: "Inhaminga" },
    { code: "VXC", name: "Lichinga" },
    { code: "LBM", name: "Luabo" },
    { code: "LFB", name: "Lumbo" },
    { code: "MJS", name: "Maganja Da Costa" },
    { code: "MFW", name: "Magaruque" },
    { code: "MPM", name: "Maputo International" },
    { code: "RRM", name: "Marromeu" },
    { code: "TET", name: "Matundo" },
    { code: "MZB", name: "Mocimboa Praia" },
    { code: "MMW", name: "Moma" },
    { code: "MTU", name: "Montepuez" },
    { code: "MUD", name: "Mueda" },
    { code: "MNC", name: "Nacala" },
    { code: "APL", name: "Nampula" },
    { code: "NND", name: "Nangade" },
    { code: "LMZ", name: "Palma" },
    { code: "PEB", name: "Pebane" },
    { code: "PDD", name: "Ponta de Ouro" },
    { code: "POL", name: "Porto Amelia" },
    { code: "UEL", name: "Quelimane" },
    { code: "NTC", name: "Santa Carolina" },
    { code: "TCV", name: "Tete" },
    { code: "VNX", name: "Vilanculos" },
    { code: "VJB", name: "Xai Xai" },
    { code: "BPE", name: "Bagan" },
    { code: "BSX", name: "Bassein" },
    { code: "BMO", name: "Bhamo" },
    { code: "AKY", name: "Civil" },
    { code: "TVY", name: "Dawe" },
    { code: "GAW", name: "Gangaw" },
    { code: "GWA", name: "Gwa" },
    { code: "HEH", name: "Heho" },
    { code: "HEB", name: "Henzada" },
    { code: "HOX", name: "Homalin" },
    { code: "KMV", name: "Kalemyo" },
    { code: "KAW", name: "Kawthaung" },
    { code: "KET", name: "Keng Tung" },
    { code: "KHM", name: "Khamti" },
    { code: "KYP", name: "Kyaukpyu" },
    { code: "KYT", name: "Kyauktaw" },
    { code: "LSH", name: "Lashio" },
    { code: "LIW", name: "Loikaw" },
    { code: "MWQ", name: "Magwe" },
    { code: "MGU", name: "Manaung" },
    { code: "MDL", name: "Mandalay International" },
    { code: "MNU", name: "Maulmyine" },
    { code: "RGN", name: "Mingaladon" },
    { code: "MOE", name: "Momeik" },
    { code: "MOG", name: "Mong Hsat" },
    { code: "MGK", name: "Mong Ton" },
    { code: "MGZ", name: "Myeik" },
    { code: "MYT", name: "Myitkyina" },
    { code: "NMS", name: "Namsang" },
    { code: "NMT", name: "Namtu" },
    { code: "NYU", name: "Nyaung-u" },
    { code: "PAA", name: "Pa-an" },
    { code: "PKK", name: "Pakokku" },
    { code: "PPU", name: "Papun" },
    { code: "PAU", name: "Pauk" },
    { code: "PRU", name: "Prome" },
    { code: "PBU", name: "Putao" },
    { code: "THL", name: "Tachilek" },
    { code: "SNW", name: "Thandwe" },
    { code: "TIO", name: "Tilin" },
    { code: "XYE", name: "Ye" },
    { code: "AIW", name: "Ai-ais" },
    { code: "ADI", name: "Arandis" },
    { code: "BQI", name: "Bagani" },
    { code: "ERS", name: "Eros" },
    { code: "GOG", name: "Gobabis" },
    { code: "GFY", name: "Grootfontein" },
    { code: "HAL", name: "Halali" },
    { code: "WDH", name: "Hosea Kutako International" },
    { code: "KMP", name: "J.G.H. Van Der Wath" },
    { code: "KAS", name: "Karasburg" },
    { code: "LHU", name: "Lianshulu" },
    { code: "LUD", name: "Luderitz" },
    { code: "MQG", name: "Midgard" },
    { code: "OKU", name: "Mokuti Lodge" },
    { code: "MJO", name: "Mount Etjo Lodge" },
    { code: "MPA", name: "Mpacha" },
    { code: "NNI", name: "Namutoni" },
    { code: "OKF", name: "Okaukuejo" },
    { code: "OMG", name: "Omega" },
    { code: "OND", name: "Ondangwa" },
    { code: "OGV", name: "Ongava Game Reserve" },
    { code: "OPW", name: "Opuwa" },
    { code: "OMD", name: "Oranjemund" },
    { code: "OHI", name: "Oshakati" },
    { code: "OTJ", name: "Otjiwarongo" },
    { code: "ZSZ", name: "Railway Station" },
    { code: "ZWH", name: "Railway Station" },
    { code: "WVB", name: "Rooikop" },
    { code: "NDU", name: "Rundu" },
    { code: "SWP", name: "Swakopmund" },
    { code: "TCY", name: "Terrace Bay" },
    { code: "INU", name: "Nauru International" },
    { code: "BGL", name: "Baglung" },
    { code: "BIT", name: "Baitadi" },
    { code: "BJH", name: "Bajhang" },
    { code: "BJU", name: "Bajura Arpt" },
    { code: "BDP", name: "Bhadrapur" },
    { code: "BWA", name: "Bhairawa" },
    { code: "BHR", name: "Bharatpur Airport" },
    { code: "BHP", name: "Bhojpur" },
    { code: "BIR", name: "Biratnagar" },
    { code: "HRJ", name: "Chaurjhari" },
    { code: "DNP", name: "Dang" },
    { code: "DAP", name: "Darchula" },
    { code: "DHI", name: "Dhangarhi" },
    { code: "DOP", name: "Dolpa" },
    { code: "GKH", name: "Gorkha" },
    { code: "JKR", name: "Janakpur" },
    { code: "JIR", name: "Jiri" },
    { code: "JMO", name: "Jomsom" },
    { code: "JUM", name: "Jumla" },
    { code: "SKH", name: "Kadmandu" },
    { code: "LDN", name: "Lamidanda" },
    { code: "LTG", name: "Langtang" },
    { code: "LUA", name: "Lukla" },
    { code: "XMG", name: "Mahendranagar" },
    { code: "NGX", name: "Manang" },
    { code: "MEY", name: "Meghauli" },
    { code: "KEP", name: "Nepalganj" },
    { code: "PPL", name: "Phaplu" },
    { code: "PKR", name: "Pokhara" },
    { code: "RJB", name: "Rajbiraj" },
    { code: "RHP", name: "Ramechhap" },
    { code: "RPA", name: "Rolpa" },
    { code: "RUK", name: "Rukumkot" },
    { code: "RUM", name: "Rumjatar" },
    { code: "FEB", name: "Sanfebagar" },
    { code: "SIH", name: "Silgadi Doti" },
    { code: "SIF", name: "Simara" },
    { code: "IMK", name: "Simikot" },
    { code: "SYH", name: "Syangboche" },
    { code: "TPJ", name: "Taplejung Suketar" },
    { code: "TPU", name: "Tikapur" },
    { code: "KTM", name: "Tribhuvan" },
    { code: "TMI", name: "Tumling Tar" },
    { code: "AMS", name: "Amsterdam-Schiphol" },
    { code: "DHR", name: "De Kooy/Den Helder Airport" },
    { code: "GRQ", name: "Eelde" },
    { code: "EIN", name: "Eindhoven" },
    { code: "GLZ", name: "Gilze-Rijen" },
    { code: "ZYH", name: "Holland Spoor Rail Station" },
    { code: "LWR", name: "Leeuwarden" },
    { code: "LEY", name: "Lelystad" },
    { code: "MST", name: "Maastricht/aachen" },
    { code: "RTM", name: "Rotterdam Zestienhoven" },
    { code: "UTC", name: "Soesterberg" },
    { code: "ENS", name: "Twente" },
    { code: "LID", name: "Valkenburg" },
    { code: "UDE", name: "Volkel" },
    { code: "BZM", name: "Woensdrecht" },
    { code: "WOE", name: "Woensdrecht" },
    { code: "SFG", name: "Esperance" },
    { code: "EUX", name: "F D Roosevelt" },
    { code: "BON", name: "Flamingo International" },
    { code: "CUR", name: "Hato International Airport" },
    { code: "SAB", name: "J. Yrausquin" },
    { code: "SXM", name: "Princess Juliana International" },
    { code: "BMY", name: "Belep Island" },
    { code: "HNG", name: "Hienghene" },
    { code: "HLU", name: "Houailou" },
    { code: "ILP", name: "Ile Des Pins" },
    { code: "IOU", name: "Ile Ouen" },
    { code: "KNQ", name: "Kone" },
    { code: "KOC", name: "Koumac" },
    { code: "LIF", name: "Lifou" },
    { code: "GEA", name: "Magenta" },
    { code: "MEE", name: "Mare" },
    { code: "PDC", name: "Mueo" },
    { code: "UVE", name: "Ouvea" },
    { code: "TGJ", name: "Tiga" },
    { code: "NOU", name: "Tontouta" },
    { code: "TOU", name: "Touho" },
    { code: "AMZ", name: "Ardmore" },
    { code: "DUD", name: "Dunedin International" },
    { code: "TEU", name: "Manapouri" },
    { code: "PPQ", name: "Paraparaumu" },
    { code: "TKZ", name: "Tokoroa" },
    { code: "BHE", name: "Woodbourne Air Station" },
    { code: "ALR", name: "Alexandra" },
    { code: "ASG", name: "Ashburton" },
    { code: "AKL", name: "Auckland International" },
    { code: "CHC", name: "Christchurch International" },
    { code: "CMV", name: "Coromandel" },
    { code: "DGR", name: "Dargaville" },
    { code: "FGL", name: "Fox Glacier" },
    { code: "WHO", name: "Franz Josef" },
    { code: "GIS", name: "Gisborne" },
    { code: "GTN", name: "Glentanner" },
    { code: "GBZ", name: "Great Barrier Island" },
    { code: "GMN", name: "Greymouth" },
    { code: "HLZ", name: "Hamilton" },
    { code: "NPE", name: "Hawkes Bay" },
    { code: "HKK", name: "Hokitika Arpt" },
    { code: "IVC", name: "Invercargill" },
    { code: "KKO", name: "Kaikohe" },
    { code: "KBZ", name: "Kaikoura" },
    { code: "KAT", name: "Kaitaia" },
    { code: "CHT", name: "Karewa" },
    { code: "KUI", name: "Kawau Island" },
    { code: "KKE", name: "Kerikeri/Bay of Islands" },
    { code: "PCN", name: "Koromiko" },
    { code: "MRO", name: "Masterton" },
    { code: "MTA", name: "Matamata" },
    { code: "MHB", name: "Mechanics Bay" },
    { code: "MFN", name: "Milford Sound" },
    { code: "MZP", name: "Motueka" },
    { code: "MON", name: "Mount Cook Airport" },
    { code: "NSN", name: "Nelson" },
    { code: "NPL", name: "New Plymouth" },
    { code: "OAM", name: "Oamaru" },
    { code: "PKL", name: "Pakatoa Island" },
    { code: "PMR", name: "Palmerston North" },
    { code: "GBS", name: "Port Fitzroy" },
    { code: "TWZ", name: "Pukaki/Twizel" },
    { code: "ZQN", name: "Queenstown" },
    { code: "RAG", name: "Raglan" },
    { code: "ROT", name: "Rotorua" },
    { code: "OHA", name: "Royal Air Force Base" },
    { code: "SZS", name: "Stewart Island" },
    { code: "WIK", name: "Surfdale" },
    { code: "THH", name: "Taharoa" },
    { code: "KTF", name: "Takaka" },
    { code: "TUO", name: "Taupo" },
    { code: "TRG", name: "Tauranga City" },
    { code: "TMZ", name: "Thames" },
    { code: "TIU", name: "Timaru" },
    { code: "WGN", name: "Waitangi" },
    { code: "WKA", name: "Wanaka" },
    { code: "WAG", name: "Wanganui" },
    { code: "WLG", name: "Wellington International" },
    { code: "WSZ", name: "Westport" },
    { code: "WHK", name: "Whakatane" },
    { code: "WRE", name: "Whangarei" },
    { code: "WTZ", name: "Whitianga" },
    { code: "MGA", name: "Augusto C Sandino" },
    { code: "BEF", name: "Bluefields" },
    { code: "RNI", name: "Corn Island" },
    { code: "NVG", name: "Nueva Guinea" },
    { code: "PUZ", name: "Puerto Cabezas" },
    { code: "NCR", name: "San Carlos" },
    { code: "BZA", name: "San Pedro" },
    { code: "SIU", name: "Siuna" },
    { code: "WSP", name: "Waspam" },
    { code: "AJY", name: "Agades" },
    { code: "RLT", name: "Arlit" },
    { code: "BKN", name: "Birni Nkoni" },
    { code: "MFQ", name: "Maradi" },
    { code: "NIM", name: "Niamey" },
    { code: "THZ", name: "Tahoua" },
    { code: "ZND", name: "Zinder" },
    { code: "AKR", name: "Akure" },
    { code: "KAN", name: "Aminu Kano Intl Apt" },
    { code: "BCU", name: "Bauchi" },
    { code: "BNI", name: "Benin City" },
    { code: "CBQ", name: "Calabar" },
    { code: "ENU", name: "Enugu" },
    { code: "IBA", name: "Ibadan" },
    { code: "ILR", name: "Ilorin" },
    { code: "JOS", name: "Jos" },
    { code: "KAD", name: "Kaduna" },
    { code: "MIU", name: "Maiduguri" },
    { code: "MDI", name: "Makurdi" },
    { code: "MXJ", name: "Minna" },
    { code: "LOS", name: "Murtala Muhammed" },
    { code: "ABV", name: "Nnamdi Azikiwe International Airport" },
    { code: "PHC", name: "Port Harcourt" },
    { code: "PHG", name: "Port Harcourt City" },
    { code: "SKO", name: "Sokoto" },
    { code: "QVL", name: "Victoria Island" },
    { code: "QRW", name: "Warri" },
    { code: "YOL", name: "Yola" },
    { code: "ZAR", name: "Zaria" },
    { code: "IUE", name: "Hanan" },
    { code: "NLK", name: "Norfolk Island" },
    { code: "SPN", name: "International" },
    { code: "ROP", name: "Rota" },
    { code: "TIQ", name: "Tinian" },
    { code: "ALF", name: "Alta" },
    { code: "ANX", name: "Andenes" },
    { code: "MOL", name: "Aro" },
    { code: "LKL", name: "Banak" },
    { code: "BDU", name: "Bardufoss" },
    { code: "BJF", name: "Batsfjord" },
    { code: "BGO", name: "Bergen Airport, Flesland" },
    { code: "BVG", name: "Berlevag" },
    { code: "BOO", name: "Bodo" },
    { code: "FDE", name: "Bringeland" },
    { code: "BNN", name: "Bronnoy" },
    { code: "DLD", name: "Dagali Airport" },
    { code: "EVE", name: "Evenes" },
    { code: "FRO", name: "Flora" },
    { code: "NVK", name: "Framnes" },
    { code: "HMR", name: "Hamar Arpt" },
    { code: "HFT", name: "Hammerfest" },
    { code: "QFQ", name: "Harbour" },
    { code: "QFK", name: "Harbour" },
    { code: "HAA", name: "Hasvik" },
    { code: "HAU", name: "Haugesund" },
    { code: "SOG", name: "Haukasen" },
    { code: "SVJ", name: "Helle" },
    { code: "KKN", name: "Hoeybuktmoen" },
    { code: "HOV", name: "Hovden" },
    { code: "MJF", name: "Kjaerstad" },
    { code: "KRS", name: "Kjevik" },
    { code: "GLL", name: "Klanten Arpt" },
    { code: "KSU", name: "Kvernberget" },
    { code: "LKN", name: "Leknes" },
    { code: "FAN", name: "Lista" },
    { code: "MEH", name: "Mehamn" },
    { code: "MQN", name: "Mo I Rana" },
    { code: "OSY", name: "Namsos" },
    { code: "NTB", name: "Notodden" },
    { code: "OLA", name: "Orland" },
    { code: "GEN", name: "Oslo - Gardermoen Airport" },
    { code: "OSL", name: "Oslo Airport, Gardermoen" },
    { code: "RRS", name: "Roros" },
    { code: "RVK", name: "Ryumsjoen" },
    { code: "SDN", name: "Sandane" },
    { code: "TRF", name: "Sandefjord" },
    { code: "XKC", name: "Sandnes Rail St." },
    { code: "SKN", name: "Skagen" },
    { code: "SKE", name: "Skien" },
    { code: "SVG", name: "Sola" },
    { code: "SOJ", name: "Sorkjosen" },
    { code: "SYG", name: "Spitsberg" },
    { code: "SSJ", name: "Stokka" },
    { code: "RET", name: "Stolport" },
    { code: "VRY", name: "Stolport" },
    { code: "SRP", name: "Stord Airport" },
    { code: "XUV", name: "Storekvina Railway" },
    { code: "XUW", name: "Storen Railway" },
    { code: "LYR", name: "Svalbard" },
    { code: "TOS", name: "Tromso/langnes" },
    { code: "VDS", name: "Vadso" },
    { code: "HVG", name: "Valan" },
    { code: "VDB", name: "Valdres" },
    { code: "VAW", name: "Vardoe" },
    { code: "AES", name: "Vigra" },
    { code: "TRD", name: "Værnes" },
    { code: "WWG", name: "Al Koude" },
    { code: "RMB", name: "Buraimi" },
    { code: "BYB", name: "Dibaa" },
    { code: "KHS", name: "Khasab" },
    { code: "OMM", name: "Marmul" },
    { code: "MSH", name: "Masirah" },
    { code: "SLL", name: "Salalah" },
    { code: "MCT", name: "Seeb" },
    { code: "SUH", name: "Sur" },
    { code: "TTH", name: "Thumrait" },
    { code: "AAW", name: "Abbottabad" },
    { code: "LHE", name: "Alama Iqbal International" },
    { code: "ATG", name: "Attock" },
    { code: "WGB", name: "Bahawalnagar" },
    { code: "BHV", name: "Bahawalpur" },
    { code: "BNP", name: "Bannu" },
    { code: "BHW", name: "Bhagatanwala Apt" },
    { code: "CWP", name: "Campbellpore" },
    { code: "CHB", name: "Chilas" },
    { code: "CJL", name: "Chitral" },
    { code: "DDU", name: "Dadu" },
    { code: "DBA", name: "Dalbandin" },
    { code: "DEA", name: "Dera Ghazi Khan" },
    { code: "DSK", name: "Dera Ismail Khan" },
    { code: "LYP", name: "Faisalabad" },
    { code: "GIL", name: "Gilgit" },
    { code: "GRT", name: "Gujrat" },
    { code: "GWD", name: "Gwadar" },
    { code: "HDD", name: "Hyderabad" },
    { code: "ISB", name: "Islamabad International" },
    { code: "JAG", name: "Jacobabad" },
    { code: "JIW", name: "Jiwani" },
    { code: "KCF", name: "Kadanwari" },
    { code: "KBH", name: "Kalat" },
    { code: "KDD", name: "Khuzdar" },
    { code: "OHT", name: "Kohat" },
    { code: "LRG", name: "Lora Lai" },
    { code: "XJM", name: "Mangla" },
    { code: "HRA", name: "Mansehra" },
    { code: "MWD", name: "Mianwali" },
    { code: "MPD", name: "Mirpur Khas" },
    { code: "MJD", name: "Mohenjodaro" },
    { code: "MUX", name: "Multan International" },
    { code: "MFG", name: "Muzaffarabad" },
    { code: "WNS", name: "Nawabshah" },
    { code: "NHS", name: "Nushki" },
    { code: "ORW", name: "Ormara" },
    { code: "PJG", name: "Panjgur" },
    { code: "PAJ", name: "Para Chinar" },
    { code: "PSI", name: "Pasni" },
    { code: "PEW", name: "Peshawar" },
    { code: "KHI", name: "Quaid-e-azam Intl" },
    { code: "UET", name: "Quetta" },
    { code: "RYK", name: "Rahim Yar Khan" },
    { code: "RAZ", name: "Rawala Kot" },
    { code: "SWN", name: "Sahiwal" },
    { code: "SDT", name: "Saidu Sharif" },
    { code: "SGI", name: "Sargodha Apt" },
    { code: "RZS", name: "Sawan" },
    { code: "SYW", name: "Sehwen Sharif" },
    { code: "SWV", name: "Shikarpur" },
    { code: "SKT", name: "Sialkot International" },
    { code: "SBQ", name: "Sibi" },
    { code: "KDU", name: "Skardu" },
    { code: "SUL", name: "Sui" },
    { code: "SKZ", name: "Sukkur" },
    { code: "TFT", name: "Taftan" },
    { code: "BDN", name: "Talhar" },
    { code: "TLB", name: "Tarbela" },
    { code: "TUK", name: "Turbat" },
    { code: "WAF", name: "Wana" },
    { code: "PZH", name: "Zhob" },
    { code: "ROR", name: "Babelthuap/Koror" },
    { code: "GZA", name: "Gaza - Yaser Arafat International Airport" },
    { code: "ZDM", name: "Ramallah Heliport" },
    { code: "ACU", name: "Achutupo" },
    { code: "AIL", name: "Ailigandi" },
    { code: "BFQ", name: "Bahia Pinas" },
    { code: "BLB", name: "Balboa" },
    { code: "BOC", name: "Bocas Del Toro" },
    { code: "CDE", name: "Caledonia" },
    { code: "CTE", name: "Carti" },
    { code: "CHX", name: "Changuinola" },
    { code: "CTD", name: "Chitre" },
    { code: "OTD", name: "Contadora" },
    { code: "CZJ", name: "Corazon De Jesus" },
    { code: "PVE", name: "El Porvenir" },
    { code: "ELE", name: "El Real" },
    { code: "ONX", name: "Enrique Adolfo Jimenez" },
    { code: "DAV", name: "Enrique Malek" },
    { code: "GHE", name: "Garachine" },
    { code: "HOW", name: "Howard Afb" },
    { code: "JQE", name: "Jaque" },
    { code: "PLP", name: "La Palma" },
    { code: "MPI", name: "Mamitupo" },
    { code: "MPP", name: "Mulatupo" },
    { code: "NGN", name: "Nargana" },
    { code: "PAC", name: "Paitilla-Marcos A. Gelabert" },
    { code: "PYC", name: "Playon Chico" },
    { code: "AML", name: "Puerto Armuellas" },
    { code: "PUE", name: "Puerto Obaldia" },
    { code: "RIZ", name: "Rio Alzucar" },
    { code: "RSI", name: "Rio Sidra" },
    { code: "RIT", name: "Rio Tigre" },
    { code: "SAX", name: "Sambu" },
    { code: "NBL", name: "San Blas" },
    { code: "NMG", name: "San Miguel" },
    { code: "SFW", name: "Santa Fe" },
    { code: "SYP", name: "Santiago" },
    { code: "TJC", name: "Ticantiki" },
    { code: "PTY", name: "Tocumen International" },
    { code: "TUW", name: "Tubala" },
    { code: "TUE", name: "Tupile" },
    { code: "UTU", name: "Ustupo" },
    { code: "PYV", name: "Yaviza" },
    { code: "ABW", name: "Abau" },
    { code: "AFR", name: "Afore" },
    { code: "AUP", name: "Agaun" },
    { code: "AIH", name: "Aiambak" },
    { code: "AIE", name: "Aiome" },
    { code: "ATP", name: "Airstrip" },
    { code: "AYU", name: "Aiyura" },
    { code: "AMF", name: "Ama" },
    { code: "AMU", name: "Amanab" },
    { code: "AZB", name: "Amazon Bay" },
    { code: "AMG", name: "Amboin" },
    { code: "AUJ", name: "Ambunti" },
    { code: "ADC", name: "Andakombe" },
    { code: "AGG", name: "Angoram" },
    { code: "AKG", name: "Anguganak" },
    { code: "AOB", name: "Annanberg" },
    { code: "APR", name: "April River" },
    { code: "ARP", name: "Aragip" },
    { code: "RAW", name: "Arawa" },
    { code: "AOA", name: "Aroa" },
    { code: "AON", name: "Arona" },
    { code: "KIE", name: "Aropa" },
    { code: "APP", name: "Asapa" },
    { code: "AEK", name: "Aseki" },
    { code: "ABP", name: "Atkamba" },
    { code: "AUI", name: "Aua Island" },
    { code: "AUV", name: "Aumo" },
    { code: "AWB", name: "Awaba" },
    { code: "AWR", name: "Awar" },
    { code: "BAP", name: "Baibara" },
    { code: "VMU", name: "Baimuru" },
    { code: "BDZ", name: "Baindoung" },
    { code: "BAJ", name: "Bali" },
    { code: "OPU", name: "Balimo" },
    { code: "BCP", name: "Bambu" },
    { code: "BMZ", name: "Bamu" },
    { code: "BNZ", name: "Banz" },
    { code: "BPD", name: "Bapi" },
    { code: "BWJ", name: "Bawan" },
    { code: "BSP", name: "Bensbach" },
    { code: "BEA", name: "Bereina" },
    { code: "BSV", name: "Besakoa" },
    { code: "BWP", name: "Bewani" },
    { code: "BAA", name: "Bialla" },
    { code: "BPK", name: "Biangabip" },
    { code: "BRP", name: "Biaru" },
    { code: "BIJ", name: "Biliau" },
    { code: "BIZ", name: "Bimin" },
    { code: "XBN", name: "Biniguni" },
    { code: "BNV", name: "Boana" },
    { code: "BOV", name: "Boang" },
    { code: "BNM", name: "Bodinumu" },
    { code: "BOQ", name: "Boku" },
    { code: "BVP", name: "Bolovip" },
    { code: "BMH", name: "Bomai" },
    { code: "WWK", name: "Boram" },
    { code: "BPB", name: "Boridi" },
    { code: "BRH", name: "Brahman" },
    { code: "UBI", name: "Buin" },
    { code: "BUA", name: "Buka" },
    { code: "BUL", name: "Bulolo" },
    { code: "BNT", name: "Bundi" },
    { code: "CGC", name: "Cape Gloucester" },
    { code: "CPI", name: "Cape Orford" },
    { code: "CPN", name: "Cape Rodney" },
    { code: "CVL", name: "Cape Vogel" },
    { code: "CMU", name: "Chimbu" },
    { code: "SQT", name: "China Straits Airstrip" },
    { code: "CVB", name: "Chungribu" },
    { code: "DAO", name: "Dabo" },
    { code: "DLB", name: "Dalbertis" },
    { code: "DAU", name: "Daru" },
    { code: "DAF", name: "Daup" },
    { code: "DBP", name: "Debepare" },
    { code: "DER", name: "Derim" },
    { code: "DNU", name: "Dinangat" },
    { code: "DOS", name: "Dios" },
    { code: "DDM", name: "Dodoima" },
    { code: "DOI", name: "Doini" },
    { code: "DOO", name: "Dorobisoro" },
    { code: "DPU", name: "Dumpu" },
    { code: "EFG", name: "Efogi" },
    { code: "EPT", name: "Eliptamin" },
    { code: "EMS", name: "Embessa" },
    { code: "EMI", name: "Emirau" },
    { code: "EMO", name: "Emo" },
    { code: "EGA", name: "Engati" },
    { code: "ERE", name: "Erave" },
    { code: "ERU", name: "Erume" },
    { code: "FNE", name: "Fane" },
    { code: "FRQ", name: "Feramin" },
    { code: "FIN", name: "Finschhafen" },
    { code: "FAQ", name: "Freida River" },
    { code: "FUB", name: "Fulleborn" },
    { code: "FUM", name: "Fuma" },
    { code: "GAR", name: "Garaina" },
    { code: "GRL", name: "Garasa" },
    { code: "GRH", name: "Garuahi" },
    { code: "GMI", name: "Gasmata Island" },
    { code: "GBC", name: "Gasuke" },
    { code: "GEW", name: "Gewoia" },
    { code: "PNP", name: "Girua" },
    { code: "GWN", name: "Gnarowein" },
    { code: "GOE", name: "Gonalia" },
    { code: "GOC", name: "Gora" },
    { code: "GKA", name: "Goroka" },
    { code: "GEI", name: "Green Islands" },
    { code: "GVI", name: "Green River" },
    { code: "GUG", name: "Guari" },
    { code: "GAZ", name: "Guasopa" },
    { code: "GLP", name: "Gulgubip" },
    { code: "GUE", name: "Guriaso" },
    { code: "GUR", name: "Gurney" },
    { code: "GAP", name: "Gusap" },
    { code: "HBD", name: "Habi" },
    { code: "HEO", name: "Haelogo" },
    { code: "HAZ", name: "Hatzfeldthaven" },
    { code: "HWA", name: "Hawabango" },
    { code: "HYF", name: "Hayfields" },
    { code: "HNI", name: "Heiweni" },
    { code: "HIT", name: "Hivaro" },
    { code: "HNN", name: "Honinabi" },
    { code: "HKN", name: "Hoskins" },
    { code: "IMA", name: "Iamalele" },
    { code: "IAU", name: "Iaura" },
    { code: "IBI", name: "Iboki" },
    { code: "IHU", name: "Ihu" },
    { code: "ILX", name: "Ileg" },
    { code: "IMN", name: "Imane" },
    { code: "IMD", name: "Imonda" },
    { code: "IDN", name: "Indagen" },
    { code: "IUS", name: "Inus" },
    { code: "IOK", name: "Iokea" },
    { code: "IOP", name: "Ioma" },
    { code: "ITK", name: "Itokama" },
    { code: "POM", name: "Jackson Fld" },
    { code: "JAQ", name: "Jacquinot Bay" },
    { code: "JOP", name: "Josephstaal" },
    { code: "KBM", name: "Kabwum" },
    { code: "HGU", name: "Kagamuga" },
    { code: "KGW", name: "Kagi" },
    { code: "AGK", name: "Kagua" },
    { code: "KIA", name: "Kaiapit" },
    { code: "KZF", name: "Kaintiba" },
    { code: "RKU", name: "Kairuku" },
    { code: "KDQ", name: "Kamberatoro" },
    { code: "KMF", name: "Kamina" },
    { code: "KJU", name: "Kamiraba" },
    { code: "KAQ", name: "Kamulai" },
    { code: "KEX", name: "Kanabea" },
    { code: "KNE", name: "Kanainj" },
    { code: "KDP", name: "Kandep" },
    { code: "KDR", name: "Kandrian" },
    { code: "KTK", name: "Kanua" },
    { code: "KPL", name: "Kapal" },
    { code: "KAK", name: "Kar" },
    { code: "KRX", name: "Kar Kar" },
    { code: "KAF", name: "Karato" },
    { code: "KRJ", name: "Karawari" },
    { code: "KMR", name: "Karimui" },
    { code: "KXR", name: "Karoola" },
    { code: "KSB", name: "Kasanombe" },
    { code: "KVG", name: "Kavieng" },
    { code: "KWO", name: "Kawito" },
    { code: "KEG", name: "Keglsugl" },
    { code: "KNL", name: "Kelanoa" },
    { code: "KRU", name: "Kerau" },
    { code: "KMA", name: "Kerema" },
    { code: "KII", name: "Kibuli" },
    { code: "KIZ", name: "Kikinonda" },
    { code: "KRI", name: "Kikori" },
    { code: "KIQ", name: "Kira" },
    { code: "KSG", name: "Kisengan" },
    { code: "KVE", name: "Kitava" },
    { code: "UNG", name: "Kiunga" },
    { code: "KWX", name: "Kiwai Island" },
    { code: "KMB", name: "Koinambe" },
    { code: "KKD", name: "Kokoda" },
    { code: "KOR", name: "Kokoro" },
    { code: "KQL", name: "Kol" },
    { code: "KCJ", name: "Komaio" },
    { code: "HOC", name: "Komako" },
    { code: "KOM", name: "Komo-Manda" },
    { code: "KPM", name: "Kompiam" },
    { code: "KPF", name: "Kondubol" },
    { code: "KGB", name: "Konge" },
    { code: "KPA", name: "Kopiago" },
    { code: "KDE", name: "Koroba" },
    { code: "KSP", name: "Kosipe" },
    { code: "KUW", name: "Kugururok River" },
    { code: "KGM", name: "Kungum" },
    { code: "KUP", name: "Kupiano" },
    { code: "KUQ", name: "Kuri" },
    { code: "KWV", name: "Kurwina" },
    { code: "KUX", name: "Kuyol" },
    { code: "LAB", name: "Lablab" },
    { code: "LGM", name: "Laiagam" },
    { code: "LMY", name: "Lake Murray" },
    { code: "LMG", name: "Lamassa" },
    { code: "LNM", name: "Langimar" },
    { code: "LHP", name: "Lehu" },
    { code: "LTF", name: "Leitre" },
    { code: "LNC", name: "Lengbati" },
    { code: "LPN", name: "Leron Plains" },
    { code: "LNG", name: "Lese" },
    { code: "LNV", name: "Lihir Island" },
    { code: "LGN", name: "Linga Linga" },
    { code: "LNQ", name: "Loani" },
    { code: "LSJ", name: "Long Island" },
    { code: "LSA", name: "Losuia" },
    { code: "LWI", name: "Lowai" },
    { code: "LMI", name: "Lumi" },
    { code: "MAG", name: "Madang" },
    { code: "MPG", name: "Makini" },
    { code: "MMV", name: "Mal" },
    { code: "MLQ", name: "Malalaua" },
    { code: "MQO", name: "Malam" },
    { code: "MKN", name: "Malekolon" },
    { code: "MAP", name: "Mamai" },
    { code: "MRM", name: "Manare" },
    { code: "MVI", name: "Manetai" },
    { code: "MGP", name: "Manga" },
    { code: "UUU", name: "Manumu" },
    { code: "MPF", name: "Mapoda" },
    { code: "MPU", name: "Mapua" },
    { code: "MWI", name: "Maramuni" },
    { code: "MWG", name: "Marawaka" },
    { code: "MGG", name: "Margarima" },
    { code: "MNP", name: "Maron" },
    { code: "MBV", name: "Masa" },
    { code: "MRH", name: "May River" },
    { code: "MDU", name: "Mendi" },
    { code: "MYX", name: "Menyamya" },
    { code: "MXK", name: "Mindik" },
    { code: "MZN", name: "Minj" },
    { code: "MIS", name: "Misima Island" },
    { code: "MPX", name: "Miyanmin" },
    { code: "MJJ", name: "Moki" },
    { code: "MAS", name: "Momote" },
    { code: "MHY", name: "Morehead" },
    { code: "MXH", name: "Moro" },
    { code: "OBM", name: "Morobe" },
    { code: "GUV", name: "Mougulu" },
    { code: "UAE", name: "Mount Aue" },
    { code: "LNF", name: "Munbil" },
    { code: "MDM", name: "Munduku" },
    { code: "MWU", name: "Mussau" },
    { code: "NDN", name: "Nadunumu" },
    { code: "LAE", name: "Nadzab" },
    { code: "ATN", name: "Namatanai" },
    { code: "NBA", name: "Nambaiyufa" },
    { code: "NDI", name: "Namudi" },
    { code: "NKN", name: "Nankina" },
    { code: "NOO", name: "Naoro" },
    { code: "GBF", name: "Negarbo" },
    { code: "NGR", name: "Ningerum" },
    { code: "NPG", name: "Nipa" },
    { code: "IIS", name: "Nissan Island" },
    { code: "NOM", name: "Nomad River" },
    { code: "NMN", name: "Nomane" },
    { code: "NWT", name: "Nowata" },
    { code: "NUG", name: "Nuguria" },
    { code: "UKU", name: "Nuku" },
    { code: "NUT", name: "Nutuve" },
    { code: "OBX", name: "Obo" },
    { code: "OGE", name: "Ogeranang" },
    { code: "OKV", name: "Okao" },
    { code: "OKP", name: "Oksapmin" },
    { code: "OLQ", name: "Olsobip" },
    { code: "OML", name: "Omkalai" },
    { code: "OSE", name: "Omora" },
    { code: "ONB", name: "Ononge" },
    { code: "OPB", name: "Open Bay" },
    { code: "RAX", name: "Oram" },
    { code: "OTY", name: "Oria" },
    { code: "OSG", name: "Ossima" },
    { code: "PAW", name: "Pambwa" },
    { code: "PGN", name: "Pangia" },
    { code: "PGB", name: "Pangoa" },
    { code: "PPX", name: "Param" },
    { code: "PMP", name: "Pimaga" },
    { code: "PDI", name: "Pindiu" },
    { code: "EIA", name: "Popondetta" },
    { code: "RGE", name: "Porgera" },
    { code: "PUA", name: "Puas" },
    { code: "PMN", name: "Pumani" },
    { code: "PUI", name: "Pureni" },
    { code: "RBP", name: "Rabaraba" },
    { code: "RAA", name: "Rakanda" },
    { code: "RNR", name: "Robinson River" },
    { code: "RMN", name: "Rumginae" },
    { code: "RUU", name: "Ruti" },
    { code: "SBV", name: "Sabah" },
    { code: "SFU", name: "Safia" },
    { code: "SGJ", name: "Sagarai" },
    { code: "SDI", name: "Saidor" },
    { code: "SAM", name: "Salamo" },
    { code: "SGK", name: "Sangapi" },
    { code: "SMH", name: "Sapmanga" },
    { code: "SWG", name: "Satwag" },
    { code: "SXW", name: "Sauren" },
    { code: "SXH", name: "Sehulea" },
    { code: "SBC", name: "Selbang" },
    { code: "SPV", name: "Sepik Plains" },
    { code: "SXA", name: "Sialum" },
    { code: "SSS", name: "Siassi" },
    { code: "SIL", name: "Sila" },
    { code: "SMJ", name: "Sim" },
    { code: "SIM", name: "Simbai" },
    { code: "NIS", name: "Simberi" },
    { code: "SGB", name: "Singaua" },
    { code: "SIZ", name: "Sissano" },
    { code: "SWE", name: "Siwea" },
    { code: "SPH", name: "Sopu" },
    { code: "SBE", name: "Suabi" },
    { code: "SKC", name: "Suki" },
    { code: "ULE", name: "Sule" },
    { code: "SUZ", name: "Suria" },
    { code: "TBA", name: "Tabibuga" },
    { code: "TBG", name: "Tabubil" },
    { code: "TAJ", name: "Tadji" },
    { code: "TGL", name: "Tagula" },
    { code: "TLW", name: "Talasea" },
    { code: "TPI", name: "Tapini" },
    { code: "TBQ", name: "Tarabo" },
    { code: "TRJ", name: "Tarakbits" },
    { code: "TIZ", name: "Tari" },
    { code: "TUT", name: "Tauta" },
    { code: "TWY", name: "Tawa" },
    { code: "TKB", name: "Tekadu" },
    { code: "TKW", name: "Tekin" },
    { code: "TFM", name: "Telefomin" },
    { code: "TEP", name: "Teptep" },
    { code: "TEO", name: "Terapo" },
    { code: "TDB", name: "Tetabedi" },
    { code: "TFB", name: "Tifalmin" },
    { code: "TBE", name: "Timbunke" },
    { code: "TCK", name: "Tinboli Airport" },
    { code: "TIG", name: "Tingwon" },
    { code: "RAB", name: "Tokua" },
    { code: "TLO", name: "Tol" },
    { code: "TON", name: "Tonu" },
    { code: "TCJ", name: "Torembi Airport" },
    { code: "TOK", name: "Torokina" },
    { code: "TFI", name: "Tufi" },
    { code: "TLP", name: "Tumolbil" },
    { code: "UMC", name: "Umba" },
    { code: "UPR", name: "Upiara" },
    { code: "USO", name: "Usino" },
    { code: "UVO", name: "Uvol" },
    { code: "VAI", name: "Vanimo" },
    { code: "VIV", name: "Vivigani" },
    { code: "WAB", name: "Wabag" },
    { code: "WAO", name: "Wabo" },
    { code: "WGU", name: "Wagau" },
    { code: "WKN", name: "Wakunai" },
    { code: "AGL", name: "Wanigela" },
    { code: "WTT", name: "Wantoat" },
    { code: "WNU", name: "Wanuma" },
    { code: "WBM", name: "Wapenamanda" },
    { code: "WSU", name: "Wasu" },
    { code: "WSA", name: "Wasua" },
    { code: "WUG", name: "Wau" },
    { code: "WAJ", name: "Wawoi Falls" },
    { code: "WEP", name: "Weam" },
    { code: "WED", name: "Wedau" },
    { code: "WPM", name: "Wipim" },
    { code: "WIU", name: "Witu" },
    { code: "WTP", name: "Woitape" },
    { code: "WOA", name: "Wonenara" },
    { code: "WUV", name: "Wuvulu Is" },
    { code: "KYX", name: "Yalumet" },
    { code: "KPE", name: "Yapsiei" },
    { code: "KSX", name: "Yasuru" },
    { code: "PGE", name: "Yegepa" },
    { code: "XYR", name: "Yellow River" },
    { code: "YEQ", name: "Yenkis" },
    { code: "YVD", name: "Yeva" },
    { code: "KGH", name: "Yongai" },
    { code: "ZEN", name: "Zenag" },
    { code: "SMP", name: "Stockholm" },
    { code: "AGT", name: "Alejo Garcia" },
    { code: "AYO", name: "Ayolas" },
    { code: "BFA", name: "Bahia Negra" },
    { code: "FLM", name: "Filadelfia" },
    { code: "OLK", name: "Fuerte Olimpo" },
    { code: "VMI", name: "INC" },
    { code: "ESG", name: "Mariscal Estigarribia" },
    { code: "CIO", name: "MCAL Lopez" },
    { code: "PJC", name: "Pedro Juan Caballero" },
    { code: "PIL", name: "Pilar" },
    { code: "PCJ", name: "Puerto La Victoria" },
    { code: "PBT", name: "Puerto Leda" },
    { code: "ASU", name: "Silvio Pettirossi" },
    { code: "ENO", name: "Teniente Prim Alarcon" },
    { code: "ALD", name: "Alerta" },
    { code: "ANS", name: "Andahuaylas" },
    { code: "ATA", name: "Anta" },
    { code: "BLP", name: "Bellavista" },
    { code: "IQT", name: "C.F. Secada" },
    { code: "CJA", name: "Cajamarca" },
    { code: "PCL", name: "Captain Rolden International Airport" },
    { code: "CHH", name: "Chachapoyas" },
    { code: "CHM", name: "Chimbote" },
    { code: "CIX", name: "Cornel Ruiz" },
    { code: "HUU", name: "Huanuco" },
    { code: "IBP", name: "Iberia" },
    { code: "ILQ", name: "Ilo" },
    { code: "JAU", name: "Jauja" },
    { code: "LIM", name: "Jorge Chavez International" },
    { code: "JJI", name: "Juanjui" },
    { code: "JUL", name: "Juliaca" },
    { code: "MFT", name: "Machu Picchu" },
    { code: "MBP", name: "Moyobamba" },
    { code: "PIO", name: "Pisco" },
    { code: "PIU", name: "Piura" },
    { code: "PEM", name: "Puerto Maldonado" },
    { code: "UMI", name: "Quincemil" },
    { code: "RIJ", name: "Rioja" },
    { code: "AQP", name: "Rodriguez Ballon" },
    { code: "RIM", name: "Rodriguez De Mendoza" },
    { code: "SJA", name: "San Juan" },
    { code: "APE", name: "San Juan Aposento" },
    { code: "SMG", name: "Santa Maria" },
    { code: "SYC", name: "Shiringayoc" },
    { code: "TCQ", name: "Tacna" },
    { code: "TYL", name: "Talara" },
    { code: "TPP", name: "Tarapoto" },
    { code: "TGI", name: "Tingo Maria" },
    { code: "TRU", name: "Trujillo" },
    { code: "TBP", name: "Tumbes" },
    { code: "CUZ", name: "Velazco Astete" },
    { code: "AYP", name: "Yanamilla" },
    { code: "YMS", name: "Yurimaguas" },
    { code: "AAV", name: "Allah Valley" },
    { code: "CBO", name: "Awang" },
    { code: "BCD", name: "Bacolod" },
    { code: "BNQ", name: "Baganga" },
    { code: "BQA", name: "Baler" },
    { code: "BSO", name: "Basco" },
    { code: "BPH", name: "Bislig" },
    { code: "BXU", name: "Butuan" },
    { code: "CGY", name: "Cagayan De Oro Domestic Airport" },
    { code: "CDY", name: "Cagayan De Sulu" },
    { code: "CYP", name: "Calbayog" },
    { code: "CYZ", name: "Cauayan" },
    { code: "RZP", name: "CLR Airport" },
    { code: "XCN", name: "Coron Airport" },
    { code: "NCP", name: "Cubi Pt NAS" },
    { code: "CUJ", name: "Culion" },
    { code: "CYU", name: "Cuyo" },
    { code: "TAC", name: "D.Z. Romualdez" },
    { code: "CRK", name: "Diosdado Macapagal International" },
    { code: "DPL", name: "Dipolog" },
    { code: "DGT", name: "Dumaguete" },
    { code: "ENI", name: "El Nido" },
    { code: "EUQ", name: "Evelio Javier" },
    { code: "DVO", name: "Francisco Bangoy International" },
    { code: "GES", name: "General Santos International" },
    { code: "ILO", name: "Iloilo International" },
    { code: "MXI", name: "Imelda Romualdez Marcos" },
    { code: "IPE", name: "Ipil" },
    { code: "JOL", name: "Jolo" },
    { code: "KLO", name: "Kalibo" },
    { code: "OZC", name: "Labo" },
    { code: "LAO", name: "Laoag International Airport" },
    { code: "LGP", name: "Legazpi" },
    { code: "BAG", name: "Loakan" },
    { code: "LBX", name: "Lubang" },
    { code: "CEB", name: "Mactan-Cebu International" },
    { code: "MLP", name: "Malabang" },
    { code: "MPH", name: "Malay" },
    { code: "CGM", name: "Mambajao" },
    { code: "MBO", name: "Mamburao" },
    { code: "XMA", name: "Maramag" },
    { code: "IGN", name: "Maria Cristina" },
    { code: "MRQ", name: "Marinduque" },
    { code: "MBT", name: "Masbate" },
    { code: "SJI", name: "Mcguire Fld" },
    { code: "NSP", name: "NAF" },
    { code: "DTE", name: "Naga" },
    { code: "WNP", name: "Naga" },
    { code: "CRM", name: "National" },
    { code: "MNL", name: "Ninoy Aquino Intl" },
    { code: "OMC", name: "Ormoc" },
    { code: "PAG", name: "Pagadian" },
    { code: "PPS", name: "Puerto Princesa" },
    { code: "USU", name: "Puerto Princessa" },
    { code: "TBH", name: "Romblon" },
    { code: "RXS", name: "Roxas City" },
    { code: "SFE", name: "San Fernando" },
    { code: "SGS", name: "Sanga Sanga" },
    { code: "SGL", name: "Sangley Point NAS" },
    { code: "SSV", name: "Siasi" },
    { code: "ICO", name: "Sicogon Island" },
    { code: "XSO", name: "Siocon" },
    { code: "SUG", name: "Surigao" },
    { code: "TAG", name: "Tagbilaran" },
    { code: "TGB", name: "Tagbita" },
    { code: "TDG", name: "Tandag" },
    { code: "TWT", name: "Tawitawi" },
    { code: "TUG", name: "Tuguegarao" },
    { code: "VRC", name: "Virac" },
    { code: "ZAM", name: "Zamboanga International" },
    { code: "IEG", name: "Babimost" },
    { code: "BZG", name: "Bydgoszcz I. J. Paderewski Airport" },
    { code: "WRO", name: "Copernicus Airport" },
    { code: "CZW", name: "Czestochowa" },
    { code: "WAW", name: "Frederic Chopin" },
    { code: "SZZ", name: "Goleniow" },
    { code: "KRK", name: "J. Paul Ii Balice Intl" },
    { code: "RZE", name: "Jasionka" },
    { code: "OSZ", name: "Koszalin" },
    { code: "POZ", name: "Lawica" },
    { code: "GDN", name: "Lech Walesa" },
    { code: "LCJ", name: "Lodz Lublinek" },
    { code: "SZY", name: "Mazury" },
    { code: "KTW", name: "Pyrzowice" },
    { code: "OSP", name: "Redzikowo" },
    { code: "BGZ", name: "Braga" },
    { code: "BGC", name: "Braganca" },
    { code: "CHV", name: "Chaves" },
    { code: "CBP", name: "Coimbra" },
    { code: "CVU", name: "Corvo Island" },
    { code: "COV", name: "Covilha" },
    { code: "FAO", name: "Faro" },
    { code: "ZYF", name: "Faro TP" },
    { code: "OPO", name: "Francisco Sá Carneiro" },
    { code: "GRW", name: "Graciosa Island" },
    { code: "HOR", name: "Horta" },
    { code: "TER", name: "Lajes Ab" },
    { code: "ZYD", name: "Lisbon TP" },
    { code: "FNC", name: "Madeira" },
    { code: "PDL", name: "Nordela" },
    { code: "PIX", name: "Pico Island" },
    { code: "LIS", name: "Portela" },
    { code: "PRM", name: "Portimao" },
    { code: "PXO", name: "Porto Santo" },
    { code: "FLW", name: "Santa Cruz" },
    { code: "SJZ", name: "Sao Jorge Island" },
    { code: "SIE", name: "Sines" },
    { code: "SMA", name: "Vila Do Porto" },
    { code: "VRL", name: "Vila Real" },
    { code: "VSE", name: "Viseu" },
    { code: "ARE", name: "Arecibo" },
    { code: "BQN", name: "Borinquen" },
    { code: "CPX", name: "Culebra" },
    { code: "FAJ", name: "Diego Jimenez Torres" },
    { code: "DDP", name: "Dorado Beach" },
    { code: "MAZ", name: "Eugenio M De Hostos" },
    { code: "HUC", name: "Humacao Arpt" },
    { code: "SIG", name: "Isla Grande" },
    { code: "SJU", name: "Luis Munoz Marin Intl" },
    { code: "PSE", name: "Mercedita" },
    { code: "PPD", name: "Palmas Del Mar" },
    { code: "NRR", name: "Roosevelt NAS" },
    { code: "VQS", name: "Vieques" },
    { code: "XJD", name: "Al Udeid AB" },
    { code: "DOH", name: "Doha" },
    { code: "XOZ", name: "Doha Free Zone" },
    { code: "USN", name: "Ulsan" },
    { code: "RUN", name: "Gillot" },
    { code: "ZSE", name: "St Pierre dela Reunion" },
    { code: "ARW", name: "Arad" },
    { code: "BCM", name: "Bacau" },
    { code: "BAY", name: "Baia Mare" },
    { code: "BBU", name: "Baneasa" },
    { code: "CSB", name: "Caransebes" },
    { code: "CLJ", name: "Cluj Napoca International Airport" },
    { code: "CRA", name: "Craiova" },
    { code: "DVA", name: "Deva" },
    { code: "OTP", name: "Henri Coanda International" },
    { code: "IAS", name: "Iasi" },
    { code: "CND", name: "Kogalniceanu" },
    { code: "OMR", name: "Oradea" },
    { code: "SCV", name: "Salcea" },
    { code: "SUJ", name: "Satu Mare" },
    { code: "SBZ", name: "Sibiu" },
    { code: "TSR", name: "Timisoara" },
    { code: "TGM", name: "Transilvania" },
    { code: "TCE", name: "Tulcea" },
    { code: "HMA", name: "Khanty-Mansiysk" },
    { code: "KGP", name: "Kogalym International" },
    { code: "SVX", name: "Koltsovo International Airport" },
    { code: "RAT", name: "Raduzhnyi" },
    { code: "RYB", name: "Rybinsk" },
    { code: "CSH", name: "Solovky" },
    { code: "ABA", name: "Abakan" },
    { code: "ACS", name: "Achinsk" },
    { code: "AER", name: "Adler/Sochi" },
    { code: "ADH", name: "Aldan" },
    { code: "AMV", name: "Amderma" },
    { code: "DYR", name: "Anadyr" },
    { code: "AAQ", name: "Anapa" },
    { code: "ARH", name: "Arkhangelsk" },
    { code: "ASF", name: "Astrakhan" },
    { code: "BWO", name: "Balakovo" },
    { code: "BAX", name: "Barnaul" },
    { code: "EGO", name: "Belgorod" },
    { code: "BCX", name: "Beloreck" },
    { code: "BQS", name: "Blagoveschensk" },
    { code: "BTK", name: "Bratsk" },
    { code: "BZK", name: "Briansk" },
    { code: "UUA", name: "Bugulma" },
    { code: "BKA", name: "Bykovo" },
    { code: "CSY", name: "Cheboksary" },
    { code: "CEK", name: "Chelyabinsk" },
    { code: "CEE", name: "Cherepovets" },
    { code: "CYX", name: "Cherskiy" },
    { code: "VOZ", name: "Chertovitskoye" },
    { code: "HTA", name: "Chita" },
    { code: "CKL", name: "Chkalovsky" },
    { code: "CKH", name: "Chokurdah" },
    { code: "DKS", name: "Dikson" },
    { code: "DME", name: "Domodedovo" },
    { code: "ESL", name: "Elista" },
    { code: "EIE", name: "Eniseysk" },
    { code: "GDZ", name: "Gelendzik" },
    { code: "GRV", name: "Groznyj" },
    { code: "HTG", name: "Hatanga" },
    { code: "IAA", name: "Igarka" },
    { code: "INA", name: "Inta" },
    { code: "IKT", name: "Irkutsk" },
    { code: "IWA", name: "Ivanova" },
    { code: "IJK", name: "Izhevsk" },
    { code: "JOK", name: "Joshkar-Ola" },
    { code: "KGD", name: "Kaliningrad Devau" },
    { code: "KLF", name: "Kaluga" },
    { code: "KZN", name: "Kazan" },
    { code: "KEJ", name: "Kemerovo" },
    { code: "KVX", name: "Kirov" },
    { code: "KVK", name: "Kirovsk" },
    { code: "KXK", name: "Komsomolsk Na Amure" },
    { code: "KMW", name: "Kostroma" },
    { code: "KSZ", name: "Kotlas" },
    { code: "KJA", name: "Krasnojarsk" },
    { code: "KRO", name: "Kurgan" },
    { code: "URS", name: "Kursk" },
    { code: "KUF", name: "Kurumoch" },
    { code: "KYZ", name: "Kyzyl" },
    { code: "LPK", name: "Lipetsk" },
    { code: "GDX", name: "Magadan" },
    { code: "GDG", name: "Magdagachi" },
    { code: "MQF", name: "Magnitogorsk" },
    { code: "MCX", name: "Makhachkala" },
    { code: "KLD", name: "Migalovo" },
    { code: "MRV", name: "Mineralnye Vodyj" },
    { code: "MJZ", name: "Mirnyj" },
    { code: "MMK", name: "Murmansk Airport Murmashi" },
    { code: "NBC", name: "Naberevnye Chelny" },
    { code: "NYM", name: "Nadym" },
    { code: "NAL", name: "Nalchik" },
    { code: "NNM", name: "Naryan-Mar" },
    { code: "NEF", name: "Neftekamsk" },
    { code: "NFG", name: "Nefteyugansk" },
    { code: "NER", name: "Neryungri" },
    { code: "NJC", name: "Nizhnevartovsk" },
    { code: "NOZ", name: "Novokuznetsk" },
    { code: "NOI", name: "Novorossijsk" },
    { code: "NUX", name: "Novy Urengoy" },
    { code: "KHV", name: "Novyy" },
    { code: "OHO", name: "Okhotsk" },
    { code: "OKT", name: "Oktiabrskij" },
    { code: "OMS", name: "Omsk" },
    { code: "OEL", name: "Orel" },
    { code: "REN", name: "Orenburg" },
    { code: "OSW", name: "Orsk" },
    { code: "KRR", name: "Pashkovsky" },
    { code: "PEX", name: "Pechora" },
    { code: "PEZ", name: "Penza" },
    { code: "PEE", name: "Perm" },
    { code: "PKC", name: "Petropavlovsk-Kamchats" },
    { code: "PES", name: "Petrozavodsk" },
    { code: "PWE", name: "Pevek" },
    { code: "PYJ", name: "Polyarnyj" },
    { code: "PVX", name: "Provedenia" },
    { code: "PVS", name: "Provideniya" },
    { code: "PKV", name: "Pskov" },
    { code: "LED", name: "Pulkovo" },
    { code: "TJM", name: "Roschino" },
    { code: "ROV", name: "Rostov" },
    { code: "RZN", name: "Ryazan" },
    { code: "SLY", name: "Salekhard" },
    { code: "SKX", name: "Saransk" },
    { code: "RTW", name: "Saratov" },
    { code: "SVO", name: "Sheremetyevo" },
    { code: "LNX", name: "Smolensk" },
    { code: "STW", name: "Stavropol" },
    { code: "SGC", name: "Surgut" },
    { code: "SCW", name: "Syktyvkar" },
    { code: "TBW", name: "Tambov" },
    { code: "IKS", name: "Tiksi" },
    { code: "TOX", name: "Tobolsk" },
    { code: "OVB", name: "Tolmachevo" },
    { code: "TOF", name: "Tomsk" },
    { code: "TYA", name: "Tula" },
    { code: "TYD", name: "Tynda" },
    { code: "UFA", name: "Ufa" },
    { code: "UCT", name: "Ukhta" },
    { code: "UUD", name: "Ulan-ude, Mukhino" },
    { code: "URJ", name: "Uraj" },
    { code: "USK", name: "Usinsk" },
    { code: "UIK", name: "Ust-Ilimsk" },
    { code: "UKX", name: "Ust-Kut" },
    { code: "VUS", name: "Velikij Ustyug" },
    { code: "VLU", name: "Velikiye Luki" },
    { code: "OGZ", name: "Vladikavkaz" },
    { code: "VVO", name: "Vladivostok" },
    { code: "VKO", name: "Vnukovo" },
    { code: "VLK", name: "Volgodonsk" },
    { code: "VOG", name: "Volgograd" },
    { code: "VGD", name: "Vologda" },
    { code: "VKT", name: "Vorkuta" },
    { code: "ULY", name: "Vostochny" },
    { code: "YKS", name: "Yakutsk" },
    { code: "IAR", name: "Yaroslavl" },
    { code: "NVR", name: "Yerievo" },
    { code: "GOJ", name: "Nizhniy Novgorod" },
    { code: "RVH", name: "Rzhevka" },
    { code: "UUS", name: "Yuzhno-Sakhalinsk" },
    { code: "BTQ", name: "Butare" },
    { code: "GYI", name: "Gisenyi" },
    { code: "KGL", name: "Gregoire Kayibanda" },
    { code: "KME", name: "Kamembe" },
    { code: "RHG", name: "Ruhengeri" },
    { code: "NEV", name: "Newcastle" },
    { code: "SKB", name: "Robert L Bradshaw International" },
    { code: "SLU", name: "George F L Charles" },
    { code: "UVF", name: "Hewanorra" },
    { code: "CIW", name: "Canouan Island" },
    { code: "SVD", name: "E. T. Joshua" },
    { code: "BQU", name: "J.F. Mitchell" },
    { code: "MQS", name: "Mustique" },
    { code: "PLI", name: "Palm Island" },
    { code: "UNI", name: "Union Island" },
    { code: "AAU", name: "Asau" },
    { code: "FGI", name: "Fagali I" },
    { code: "APW", name: "Faleolo" },
    { code: "LAV", name: "Lalomalava" },
    { code: "MXS", name: "Maota Savaii Is" },
    { code: "PGP", name: "Porto Alegre" },
    { code: "PCP", name: "Principe" },
    { code: "TMS", name: "Sao Tome Is" },
    { code: "AHB", name: "Abha" },
    { code: "ABT", name: "Al-Aqiq" },
    { code: "HOF", name: "Alahsa" },
    { code: "RAE", name: "Arar" },
    { code: "BHH", name: "Bisha" },
    { code: "XZF", name: "Dammam Port" },
    { code: "DWD", name: "Dawadmi" },
    { code: "DHA", name: "Dhahran" },
    { code: "ELQ", name: "Gassim Regional Airport" },
    { code: "URY", name: "Gurayat" },
    { code: "HBT", name: "Hafr Albatin" },
    { code: "HAS", name: "Hail" },
    { code: "GIZ", name: "Jazan" },
    { code: "AJF", name: "Jouf" },
    { code: "KMX", name: "Khamis Mushait" },
    { code: "JED", name: "King Abdulaziz International" },
    { code: "DMM", name: "King Fahd International Airport" },
    { code: "RUH", name: "King Khaled Intl" },
    { code: "KMC", name: "King Khalid Military" },
    { code: "MJH", name: "Majma" },
    { code: "MED", name: "Mohammad Bin Abdulaziz" },
    { code: "EAM", name: "Nejran" },
    { code: "AKH", name: "Prince Sultan Air Base" },
    { code: "AQI", name: "Qaisumah" },
    { code: "RAH", name: "Rafha" },
    { code: "SHW", name: "Sharurah" },
    { code: "SLF", name: "Sulayel" },
    { code: "TUU", name: "Tabuk" },
    { code: "TIF", name: "Taif" },
    { code: "TUI", name: "Turaif" },
    { code: "UZH", name: "Unayzah" },
    { code: "WAE", name: "Wadi Ad Dawasir" },
    { code: "EJH", name: "Wedjh" },
    { code: "YNB", name: "Yanbu" },
    { code: "ZUL", name: "Zilfi" },
    { code: "ABZ", name: "Dyce" },
    { code: "EDI", name: "Turnhouse" },
    { code: "BXE", name: "Bakel" },
    { code: "CSK", name: "Cap Skirring" },
    { code: "DKR", name: "Dakar-Yoff-Léopold Sédar Senghor International" },
    { code: "KLC", name: "Kaolack" },
    { code: "KGG", name: "Kedougou" },
    { code: "KDA", name: "Kolda" },
    { code: "MAX", name: "Matam" },
    { code: "NIK", name: "Niokolo Koba" },
    { code: "POD", name: "Podor" },
    { code: "RDT", name: "Richard Toll" },
    { code: "SMY", name: "Simenti" },
    { code: "XLS", name: "St Louis" },
    { code: "TUD", name: "Tambacounda" },
    { code: "ZIG", name: "Ziguinchor" },
    { code: "BJY", name: "Batajnica" },
    { code: "INI", name: "Constantine The Great International" },
    { code: "BEG", name: "Nikola Tesla" },
    { code: "UZC", name: "Ponikve" },
    { code: "PRN", name: "Prishtina International Airport" },
    { code: "HNO", name: "Hercegnovi" },
    { code: "BDI", name: "Bird Island" },
    { code: "DEI", name: "Denis Island" },
    { code: "DES", name: "Desroches" },
    { code: "FRK", name: "Fregate Is" },
    { code: "PRI", name: "Praslin Island" },
    { code: "SEZ", name: "Seychelles International Airport" },
    { code: "KBS", name: "Bo" },
    { code: "BTE", name: "Bonthe" },
    { code: "DSL", name: "Daru" },
    { code: "GBK", name: "Gbangbatok" },
    { code: "HGS", name: "Hastings" },
    { code: "KBA", name: "Kabala" },
    { code: "KEN", name: "Kenema" },
    { code: "FNA", name: "Lungi International" },
    { code: "JMY", name: "Mammy Yoko Heliport" },
    { code: "SRK", name: "Sierra Leone" },
    { code: "WYE", name: "Yengema" },
    { code: "SIN", name: "Changi" },
    { code: "QPG", name: "Paya Lebar" },
    { code: "XSP", name: "Seletar" },
    { code: "KSC", name: "Barca" },
    { code: "LUE", name: "Lucenec" },
    { code: "PZY", name: "Piestany" },
    { code: "TAT", name: "Poprad/tatry" },
    { code: "POV", name: "Presov" },
    { code: "ILZ", name: "Zilina" },
    { code: "BTS", name: "M. R. Štefánika" },
    { code: "SLD", name: "Sliac" },
    { code: "LJU", name: "Brnik" },
    { code: "MBX", name: "Maribor International Airport" },
    { code: "POW", name: "Portoroz" },
    { code: "AFT", name: "Afutara Aerodrome" },
    { code: "ANH", name: "Anuha Island Resort" },
    { code: "ATD", name: "Atoifi" },
    { code: "AVU", name: "Avu Avu" },
    { code: "BAS", name: "Balalae" },
    { code: "VEV", name: "Barakoma" },
    { code: "RRI", name: "Barora" },
    { code: "BPF", name: "Batuna Aerodrome" },
    { code: "BNY", name: "Bellona" },
    { code: "CHY", name: "Choiseul Bay" },
    { code: "FRE", name: "Fera Island" },
    { code: "GTA", name: "Gatokae Aerodrom" },
    { code: "GEF", name: "Geva Airstrip" },
    { code: "GZO", name: "Gizo" },
    { code: "GSI", name: "Guadalcanal" },
    { code: "HIR", name: "Henderson Intl" },
    { code: "KGE", name: "Kagau" },
    { code: "IRA", name: "Kirakira" },
    { code: "KUE", name: "Kukundu" },
    { code: "KWR", name: "Kwai Harbour" },
    { code: "KWS", name: "Kwailabesi Aerodrom" },
    { code: "RUS", name: "Marau Sound" },
    { code: "MBU", name: "Mbambanakira" },
    { code: "MNY", name: "Mono" },
    { code: "MUA", name: "Munda" },
    { code: "NAZ", name: "Nana" },
    { code: "ONE", name: "Onepusu" },
    { code: "OTV", name: "Ontong Java" },
    { code: "PRS", name: "Parasi" },
    { code: "RBV", name: "Ramata" },
    { code: "RNL", name: "Rennell" },
    { code: "RIN", name: "Ringi Cove" },
    { code: "NNB", name: "Santa Ana" },
    { code: "SCZ", name: "Santa Cruz Is" },
    { code: "SVY", name: "Savo" },
    { code: "EGM", name: "Sege" },
    { code: "TAA", name: "Tarapaina" },
    { code: "TLG", name: "Tulagi Island" },
    { code: "RNA", name: "Ulawa Airport" },
    { code: "VIU", name: "Viru Harbour Airstrip" },
    { code: "XYA", name: "Yandina" },
    { code: "ALU", name: "Alula" },
    { code: "BIB", name: "Baidoa" },
    { code: "BSY", name: "Bardera" },
    { code: "BBO", name: "Berbera" },
    { code: "BXX", name: "Borama International" },
    { code: "BSA", name: "Bossaso" },
    { code: "BUO", name: "Burao" },
    { code: "CXN", name: "Candala" },
    { code: "HCM", name: "Eil" },
    { code: "ERA", name: "Erigavo" },
    { code: "GLK", name: "Galcaio" },
    { code: "GBM", name: "Garbaharey" },
    { code: "GSR", name: "Gardo" },
    { code: "GGR", name: "Garoe" },
    { code: "HGA", name: "Hargeisa" },
    { code: "KMU", name: "Kismayu" },
    { code: "LKR", name: "Las Khoreh" },
    { code: "LGX", name: "Lugh Ganane" },
    { code: "MGQ", name: "Mogadishu International Airport" },
    { code: "CMO", name: "Obbia" },
    { code: "CMS", name: "Scusciuban" },
    { code: "AGZ", name: "Aggeneys" },
    { code: "ADY", name: "Alldays" },
    { code: "HBL", name: "Babelegi Heliport" },
    { code: "BIY", name: "Bisho" },
    { code: "UTE", name: "Butterworth" },
    { code: "PHW", name: "Cape Town" },
    { code: "CPT", name: "Cape Town International" },
    { code: "HPR", name: "Central Hpr" },
    { code: "CDO", name: "Cradock" },
    { code: "DUK", name: "Dukuduk" },
    { code: "DUR", name: "Durban International" },
    { code: "ELS", name: "East London" },
    { code: "ELL", name: "Ellisras" },
    { code: "EMG", name: "Empangeni" },
    { code: "FCB", name: "Ficksburg Sentra Oes" },
    { code: "GRJ", name: "George" },
    { code: "GCJ", name: "Grand Central" },
    { code: "HRS", name: "Harrismith Airport" },
    { code: "HZV", name: "Hazyview" },
    { code: "HLW", name: "Hluhluwe" },
    { code: "HDS", name: "Hoedspruit Airport" },
    { code: "INY", name: "Inyati" },
    { code: "HIC", name: "Iscor Heliport" },
    { code: "KHO", name: "Khoka Moya" },
    { code: "KIM", name: "Kimberley" },
    { code: "KLZ", name: "Kleinzee" },
    { code: "KXE", name: "Klerksdorp" },
    { code: "KIG", name: "Koinghaas" },
    { code: "KOF", name: "Komatipoort" },
    { code: "ALJ", name: "Kortdoorn" },
    { code: "MQP", name: "Kruger Mpumalanga" },
    { code: "KMH", name: "Kuruman" },
    { code: "LAY", name: "Ladysmith" },
    { code: "SDB", name: "Langebaanweg" },
    { code: "HLA", name: "Lanseria" },
    { code: "LTA", name: "Letaba" },
    { code: "LMR", name: "Lime Acres" },
    { code: "LDZ", name: "Londolozi" },
    { code: "LCD", name: "Louis Trichardt" },
    { code: "LUJ", name: "Lusikisiki" },
    { code: "MBD", name: "Mafikeng International Airport" },
    { code: "AAM", name: "Mala Mala" },
    { code: "LLE", name: "Malelane" },
    { code: "MGH", name: "Margate" },
    { code: "MEZ", name: "Messina" },
    { code: "MBM", name: "Mkambati" },
    { code: "MZQ", name: "Mkuze" },
    { code: "MZY", name: "Mossel Bay" },
    { code: "MZF", name: "Mzamba" },
    { code: "NLP", name: "Nelspruit International" },
    { code: "NCS", name: "Newcastle" },
    { code: "NGL", name: "Ngala Airfield" },
    { code: "JNB", name: "Oliver Reginald Tambo International" },
    { code: "OUH", name: "Oudtshoorn" },
    { code: "OVG", name: "Overberg" },
    { code: "PZB", name: "Pietermaritzburg Oribi" },
    { code: "NTY", name: "Pilanesberg" },
    { code: "PBZ", name: "Plettenberg Bay" },
    { code: "PTG", name: "Polokwane" },
    { code: "AFD", name: "Port Alfred" },
    { code: "PLZ", name: "Port Elizabeth" },
    { code: "JOH", name: "Port Saint Johns" },
    { code: "PRK", name: "Prieska" },
    { code: "UTW", name: "Queenstown" },
    { code: "QRA", name: "Rand/Germiston" },
    { code: "HCS", name: "Randburg Heliport" },
    { code: "RVO", name: "Reivilo" },
    { code: "RCB", name: "Richards Bay" },
    { code: "ROD", name: "Robertson" },
    { code: "GSS", name: "Sabi Sabi" },
    { code: "ZEC", name: "Secunda" },
    { code: "SIS", name: "Sishen" },
    { code: "SZK", name: "Skukuza" },
    { code: "SBU", name: "Springbok" },
    { code: "TDT", name: "Tanda Tula" },
    { code: "TCU", name: "Thaba Nchu" },
    { code: "THY", name: "Thohoyandou" },
    { code: "TSD", name: "Tshipise" },
    { code: "ULD", name: "Ulundi" },
    { code: "ULX", name: "Ulusaba" },
    { code: "UTT", name: "Umtata" },
    { code: "UTN", name: "Upington" },
    { code: "VIR", name: "Virginia" },
    { code: "VRE", name: "Vredendal" },
    { code: "VRU", name: "Vryburg" },
    { code: "VYD", name: "Vryheid" },
    { code: "WKF", name: "Waterkloof AFB" },
    { code: "WEL", name: "Welkom" },
    { code: "PRY", name: "Wonderboom Apt." },
    { code: "PZL", name: "Zulu Inyala" },
    { code: "LEU", name: "Aeroport De La Seu" },
    { code: "RJL", name: "Agoncillo" },
    { code: "ALC", name: "Alicante El Altet" },
    { code: "LEI", name: "Almeria" },
    { code: "OVD", name: "Asturias" },
    { code: "MAD", name: "Barajas" },
    { code: "OZI", name: "Bobadilla" },
    { code: "ZRC", name: "Bus Station" },
    { code: "CDZ", name: "Cadiz" },
    { code: "JCU", name: "Ceuta Heliport" },
    { code: "CJI", name: "Ciudad Real - Don Quijote Airport" },
    { code: "ODB", name: "Cordoba" },
    { code: "EAS", name: "Donostia - San Sebastian" },
    { code: "BCN", name: "El Prat De Llobregat" },
    { code: "FGR", name: "Fuengirola" },
    { code: "FUE", name: "Fuerteventura" },
    { code: "GRO", name: "Girona-Costa Brava" },
    { code: "LPA", name: "Gran Canaria" },
    { code: "GRX", name: "Granada" },
    { code: "VDE", name: "Hierro" },
    { code: "HEV", name: "Huelva" },
    { code: "IBZ", name: "Ibiza" },
    { code: "XRY", name: "Jerez De La Frontera" },
    { code: "LCG", name: "La Coruna" },
    { code: "GMZ", name: "La Gomera" },
    { code: "SPC", name: "La Palma" },
    { code: "NDO", name: "La Palma Del Condado" },
    { code: "ACE", name: "Lanzarote" },
    { code: "LEN", name: "Leon" },
    { code: "ABC", name: "Los Llanos" },
    { code: "MAH", name: "Mahon" },
    { code: "SLM", name: "Matacan" },
    { code: "MLN", name: "Melilla" },
    { code: "OZU", name: "Montilla" },
    { code: "OZP", name: "Moron" },
    { code: "AGP", name: "Pablo Ruiz Picasso" },
    { code: "PNA", name: "Pamplona" },
    { code: "PXS", name: "Puerto De Santa Maria" },
    { code: "UER", name: "Puertollano" },
    { code: "REU", name: "Reus" },
    { code: "RRA", name: "Ronda" },
    { code: "FES", name: "San Fernando" },
    { code: "MJV", name: "San Javier" },
    { code: "SPO", name: "San Pablo" },
    { code: "SVQ", name: "San Pablo" },
    { code: "SDR", name: "Santander" },
    { code: "SCQ", name: "Santiago De Compostela" },
    { code: "PMI", name: "Son Sant Joan Airport" },
    { code: "BIO", name: "Sondica" },
    { code: "TFS", name: "Sur Reina Sofia" },
    { code: "BJZ", name: "Talaveral La Real" },
    { code: "TFN", name: "Tenerife Norte" },
    { code: "TOJ", name: "Torrejon Afb" },
    { code: "UTL", name: "Torremolinos" },
    { code: "VLC", name: "Valencia" },
    { code: "VLL", name: "Valladolid" },
    { code: "VGO", name: "Vigo" },
    { code: "VIT", name: "Vitoria." },
    { code: "ZAZ", name: "Zaragoza" },
    { code: "ADP", name: "Ampara" },
    { code: "GOY", name: "Amparai" },
    { code: "ACJ", name: "Anuradhapura" },
    { code: "CMB", name: "Bandaranayake" },
    { code: "BTC", name: "Batticaloa" },
    { code: "BJT", name: "Bentota River" },
    { code: "NUF", name: "Castlereigh Reservoir" },
    { code: "TRR", name: "China Bay" },
    { code: "DBU", name: "Dambulu Oya Tank" },
    { code: "HIM", name: "Hingurakgoda" },
    { code: "JAF", name: "Kankesanturai" },
    { code: "KTY", name: "Katukurunda Slaf Base" },
    { code: "KEZ", name: "Kelaniya River" },
    { code: "KCT", name: "Koggala" },
    { code: "AFK", name: "Kondavattavan Tank" },
    { code: "KDY", name: "Mahaweli" },
    { code: "DIW", name: "Mawella Lagoon" },
    { code: "MNH", name: "Minneriya" },
    { code: "KDZ", name: "Polgolla Reservoir" },
    { code: "GIU", name: "Sigiriya Slaf Base" },
    { code: "KDW", name: "Vic. Resevour Kandy" },
    { code: "WRZ", name: "Wirawila" },
    { code: "ASI", name: "Wideawake Field" },
    { code: "MQC", name: "Miquelon Aprt" },
    { code: "FSP", name: "Saint-pierre Pointe-blanche" },
    { code: "AAD", name: "Ad-Dabbah" },
    { code: "ATB", name: "Atbara" },
    { code: "KRT", name: "Civil" },
    { code: "DOG", name: "Dongola" },
    { code: "ELF", name: "El Fasher" },
    { code: "EBD", name: "El Obeid" },
    { code: "EDB", name: "Eldebba" },
    { code: "NUD", name: "En Nahud" },
    { code: "DNX", name: "Galegu" },
    { code: "GSU", name: "Gedaref" },
    { code: "EGN", name: "Geneina" },
    { code: "HGI", name: "Higlieg" },
    { code: "JUB", name: "Juba" },
    { code: "KSL", name: "Kassala" },
    { code: "GBU", name: "Khashm El Girba" },
    { code: "KST", name: "Kosti" },
    { code: "MAK", name: "Malakal" },
    { code: "MWE", name: "Merowe" },
    { code: "NHF", name: "New Halfa" },
    { code: "UYL", name: "Nyala" },
    { code: "PZU", name: "Port Sudan" },
    { code: "RSS", name: "Roseires" },
    { code: "DNI", name: "Wad Medani" },
    { code: "WHF", name: "Wadi Halfa" },
    { code: "WUU", name: "Wau" },
    { code: "ABN", name: "Albina" },
    { code: "BTO", name: "Botopasie" },
    { code: "AAJ", name: "Cayana Airstrip" },
    { code: "DOE", name: "Djoemoe" },
    { code: "LDO", name: "Ladouanie" },
    { code: "MOJ", name: "Moengo" },
    { code: "ICK", name: "Nieuw Nickerie" },
    { code: "SMZ", name: "Stoelmans Eiland" },
    { code: "AGI", name: "Wageningen" },
    { code: "WSO", name: "Washabo" },
    { code: "PBM", name: "Zanderij Intl" },
    { code: "ORG", name: "Zorg En Hoop" },
    { code: "MTS", name: "Matsapha Intl" },
    { code: "AGH", name: "Angelholm" },
    { code: "XYQ", name: "Angelholm Railway Svc." },
    { code: "ARN", name: "Arlanda" },
    { code: "AJR", name: "Arvidsjaur" },
    { code: "JKG", name: "Axamo" },
    { code: "XYB", name: "Borlange Railway Svc." },
    { code: "BMA", name: "Bromma" },
    { code: "BLE", name: "Dala Airport" },
    { code: "XWQ", name: "Enkoping" },
    { code: "EKT", name: "Eskilstuna" },
    { code: "XYM", name: "Falkenberg Rail" },
    { code: "XYI", name: "Flen" },
    { code: "GEV", name: "Gallivare" },
    { code: "GSE", name: "Gothenburg City Airport" },
    { code: "XWL", name: "Gothenburg Rail" },
    { code: "SQO", name: "Gunnarn" },
    { code: "HFS", name: "Hagfors" },
    { code: "HAD", name: "Halmstad" },
    { code: "XWP", name: "Hassleholm" },
    { code: "XXU", name: "Hedemora" },
    { code: "JHE", name: "Heliport" },
    { code: "JLD", name: "Heliport" },
    { code: "XYH", name: "Helsingborg Railway" },
    { code: "HMV", name: "Hemavan" },
    { code: "XYC", name: "Herrljunga" },
    { code: "LDK", name: "Hovby" },
    { code: "HUV", name: "Hudiksvall" },
    { code: "HLF", name: "Hultsfred - Vimmerby" },
    { code: "IDB", name: "Idre" },
    { code: "LLA", name: "Kallax" },
    { code: "RNB", name: "Kallinge" },
    { code: "KLR", name: "Kalmar" },
    { code: "XYO", name: "Karlshamn" },
    { code: "KSK", name: "Karlskoga" },
    { code: "XWK", name: "Karlskrona Rail Svc." },
    { code: "KSD", name: "Karlstad" },
    { code: "XXK", name: "Katrineholm" },
    { code: "KRN", name: "Kiruna" },
    { code: "KRF", name: "Kramfors" },
    { code: "KID", name: "Kristianstad" },
    { code: "XYN", name: "Kristinehamn Rail" },
    { code: "NRK", name: "Kungsangen" },
    { code: "GOT", name: "Landvetter" },
    { code: "LPI", name: "Linkoping" },
    { code: "LYC", name: "Lycksele" },
    { code: "JMM", name: "Malmo Harbour Heliport" },
    { code: "XFR", name: "Malmo South Railway" },
    { code: "MXX", name: "Mora" },
    { code: "XWZ", name: "Nykoping" },
    { code: "ORB", name: "Orebro-bofors" },
    { code: "OER", name: "Ornskoldsvik" },
    { code: "OSK", name: "Oskarshamn" },
    { code: "PJA", name: "Pajala" },
    { code: "XZQ", name: "Pass Rail" },
    { code: "XYX", name: "Sala" },
    { code: "GVX", name: "Sandviken" },
    { code: "NYO", name: "Skavsta" },
    { code: "SFT", name: "Skelleftea" },
    { code: "KVB", name: "Skovde" },
    { code: "VXO", name: "Smaland Airport" },
    { code: "SOO", name: "Soderhamn" },
    { code: "JSO", name: "Sodertalje Heliport" },
    { code: "XEZ", name: "Sodertalje S Railway" },
    { code: "XYU", name: "Solvesborg" },
    { code: "MMX", name: "Sturup" },
    { code: "SDL", name: "Sundsvall/harnosand" },
    { code: "EVG", name: "Sveg" },
    { code: "TYF", name: "Torsby Airport" },
    { code: "THN", name: "Trollhattan" },
    { code: "UME", name: "Umea" },
    { code: "VST", name: "Vasteras/Hasslo" },
    { code: "VVK", name: "Vastervik" },
    { code: "VHM", name: "Vilhelmina" },
    { code: "VBY", name: "Visby" },
    { code: "OSD", name: "Åre Östersund Airport" },
    { code: "ACH", name: "Altenrhein" },
    { code: "ACO", name: "Ascona" },
    { code: "ZBA", name: "Basel Bad - Railway" },
    { code: "BRN", name: "Belp" },
    { code: "EML", name: "Emmen" },
    { code: "BSL", name: "Euroairport Basel Mulhouse Freiburg" },
    { code: "GVA", name: "Geneve-cointrin" },
    { code: "LUG", name: "Lugano" },
    { code: "SMV", name: "Samedan" },
    { code: "SIR", name: "Sion" },
    { code: "ZRH", name: "Zürich-Kloten" },
    { code: "DEZ", name: "Al Jafrah" },
    { code: "SOR", name: "Al Thaurah" },
    { code: "DAM", name: "International" },
    { code: "KAC", name: "Kameshli" },
    { code: "LTK", name: "Latakia" },
    { code: "ALP", name: "Nejrab" },
    { code: "PMS", name: "Palmyra" },
    { code: "CMJ", name: "Chi Mei" },
    { code: "CYI", name: "Chiayi" },
    { code: "RMQ", name: "Chingchuankang" },
    { code: "GNI", name: "Green Island" },
    { code: "HCN", name: "Hengchun" },
    { code: "HSZ", name: "Hsinchu" },
    { code: "HUN", name: "Hualien" },
    { code: "KHH", name: "Kaoshiung International Airport" },
    { code: "LHN", name: "Lishan" },
    { code: "MZG", name: "Makung" },
    { code: "MFK", name: "Matsu" },
    { code: "LZN", name: "Nangan" },
    { code: "KYD", name: "Orchid Island" },
    { code: "PIF", name: "Pingtung" },
    { code: "KNH", name: "Shang-Yi" },
    { code: "SMT", name: "Sun Moon Lake" },
    { code: "TXG", name: "Taichung" },
    { code: "TNN", name: "Tainan" },
    { code: "TSA", name: "Taipei Songshan" },
    { code: "TTT", name: "Taitung" },
    { code: "TPE", name: "Taiwan Taoyuan International" },
    { code: "WOT", name: "Wonan" },
    { code: "DYU", name: "Dushanbe" },
    { code: "LBD", name: "Khudzhand" },
    { code: "ARK", name: "Arusha" },
    { code: "BKZ", name: "Bukoba" },
    { code: "DOD", name: "Dodoma" },
    { code: "GIT", name: "Geita" },
    { code: "DAR", name: "International" },
    { code: "TKQ", name: "Kigoma" },
    { code: "LDI", name: "Kikwetu" },
    { code: "JRO", name: "Kilimanjaro" },
    { code: "KIY", name: "Kilwa" },
    { code: "ZNZ", name: "Kisauni" },
    { code: "LKY", name: "Lake Manyara" },
    { code: "LUY", name: "Lushoto" },
    { code: "MFA", name: "Mafia" },
    { code: "XMI", name: "Masasi" },
    { code: "MBI", name: "Mbeya" },
    { code: "MYW", name: "Mtwara" },
    { code: "MUZ", name: "Musoma" },
    { code: "MWN", name: "Mwadui" },
    { code: "MWZ", name: "Mwanza" },
    { code: "NCH", name: "Nachingwea" },
    { code: "IRI", name: "Nduli" },
    { code: "JOM", name: "Njombe" },
    { code: "SEU", name: "Seronera" },
    { code: "SHY", name: "Shinyanga" },
    { code: "SGX", name: "Songea" },
    { code: "SUT", name: "Sumbawanga" },
    { code: "TBO", name: "Tabora" },
    { code: "TGT", name: "Tanga" },
    { code: "PMA", name: "Wawi" },
    { code: "GEE", name: "George Town" },
    { code: "BFV", name: "Buri Ram" },
    { code: "CNX", name: "Chiang Mai International" },
    { code: "CEI", name: "Chiang Rai" },
    { code: "CJM", name: "Chumphon Airport" },
    { code: "DMK", name: "Don Muang" },
    { code: "HDY", name: "Hat Yai" },
    { code: "HHQ", name: "Hua Hin Airport" },
    { code: "KDT", name: "Kamphangsaen" },
    { code: "KKC", name: "Khon Kaen" },
    { code: "USM", name: "Koh Samui" },
    { code: "KBV", name: "Krabi" },
    { code: "LPT", name: "Lampang" },
    { code: "LOE", name: "Loei" },
    { code: "KKM", name: "Lop Buri" },
    { code: "HGN", name: "Mae Hong Son" },
    { code: "MAQ", name: "Mae Sot" },
    { code: "UBP", name: "Muang Ubon" },
    { code: "KOP", name: "Nakhon Phanom" },
    { code: "NAK", name: "Nakhon Ratchasima" },
    { code: "TKH", name: "Nakhon Sawan" },
    { code: "NST", name: "Nakhon Si Thammarat" },
    { code: "NNT", name: "Nan" },
    { code: "NAW", name: "Narathiwat" },
    { code: "PBS", name: "Patong Beach" },
    { code: "PAN", name: "Pattani" },
    { code: "PYX", name: "Pattaya" },
    { code: "PMM", name: "Phanom Sarakham" },
    { code: "PHY", name: "Phetchabun" },
    { code: "PHZ", name: "Phi Phi Island" },
    { code: "PHS", name: "Phitsanulok" },
    { code: "PRH", name: "Phrae" },
    { code: "HKT", name: "Phuket International" },
    { code: "UNN", name: "Ranong" },
    { code: "ROI", name: "Roi Et Airport" },
    { code: "SNO", name: "Sakon Nakhon" },
    { code: "SGZ", name: "Songkhla" },
    { code: "THS", name: "Sukhothai" },
    { code: "URT", name: "Surat Thani" },
    { code: "PXR", name: "Surin" },
    { code: "BKK", name: "Suvarnabhumi International" },
    { code: "TKT", name: "Tak" },
    { code: "TST", name: "Trang" },
    { code: "TDX", name: "Trat" },
    { code: "UTH", name: "Udon Thani" },
    { code: "BAO", name: "Udorn" },
    { code: "UTP", name: "Utapao" },
    { code: "UTR", name: "Uttaradit" },
    { code: "LFW", name: "Lome" },
    { code: "LRL", name: "Niamtougou" },
    { code: "TBU", name: "Fua'amotu International" },
    { code: "EUA", name: "Kaufana" },
    { code: "NTT", name: "Kuini Lavenia" },
    { code: "TAB", name: "Crown Point Airport" },
    { code: "POS", name: "Piarco International" },
    { code: "TUN", name: "Carthage" },
    { code: "DJE", name: "Djerba-Zarzis" },
    { code: "EBM", name: "El Borma" },
    { code: "GAE", name: "Gabes" },
    { code: "GAF", name: "Gafsa" },
    { code: "MIR", name: "Habib Bourguiba" },
    { code: "SFA", name: "Sfax Thyna" },
    { code: "TBJ", name: "Tabarka" },
    { code: "TOE", name: "Tozeur" },
    { code: "AJI", name: "Ağri" },
    { code: "ADA", name: "Adana" },
    { code: "UAB", name: "Adana-Incirlik Airbase" },
    { code: "ADF", name: "Adiyaman" },
    { code: "ADB", name: "Adnan Menderes" },
    { code: "AFY", name: "Afyon" },
    { code: "AOE", name: "Anadolu University" },
    { code: "AYT", name: "Antalya" },
    { code: "IST", name: "Ataturk" },
    { code: "BZI", name: "Balikesir" },
    { code: "BDM", name: "Bandirma" },
    { code: "BAL", name: "Batman" },
    { code: "ERZ", name: "Budrum" },
    { code: "BTZ", name: "Bursa Airport" },
    { code: "CKZ", name: "Canakkale" },
    { code: "DNZ", name: "Cardak" },
    { code: "SZF", name: "Carsamba" },
    { code: "KCO", name: "Cengiz Topel" },
    { code: "IGL", name: "Cigli Military Arpt" },
    { code: "TEQ", name: "Corlu" },
    { code: "DLM", name: "Dalaman" },
    { code: "DIY", name: "Diyarbakir" },
    { code: "EDO", name: "Edremit/korfez" },
    { code: "EZS", name: "Elazig" },
    { code: "ASR", name: "Erkilet International Airport" },
    { code: "ERC", name: "Erzincan" },
    { code: "ESB", name: "Esenboga" },
    { code: "ESK", name: "Eskisehir" },
    { code: "ANK", name: "Etimesgut" },
    { code: "VAN", name: "Ferit Melen Airport" },
    { code: "GZT", name: "Gaziantep" },
    { code: "BXN", name: "Imsik Airport" },
    { code: "KCM", name: "Kahramanmaras" },
    { code: "KSY", name: "Kars" },
    { code: "KFS", name: "Kastamonu" },
    { code: "KYA", name: "Konya" },
    { code: "MLX", name: "Malatya" },
    { code: "MQM", name: "Mardin" },
    { code: "MQJ", name: "Merkez" },
    { code: "MZH", name: "Merzifon" },
    { code: "BJV", name: "Milas" },
    { code: "SAW", name: "Sabiha Gokcen" },
    { code: "SSX", name: "Samsun" },
    { code: "SFQ", name: "Sanliurfa" },
    { code: "SXZ", name: "Siirt" },
    { code: "SQD", name: "Sinop" },
    { code: "SIC", name: "Sinop Airport" },
    { code: "VAS", name: "Sivas" },
    { code: "TJK", name: "Tokat" },
    { code: "TZX", name: "Trabzon" },
    { code: "USQ", name: "Usak" },
    { code: "YEI", name: "Yenisehir" },
    { code: "ONQ", name: "Zonguldak" },
    { code: "ASB", name: "Ashgabat" },
    { code: "MYP", name: "Mary" },
    { code: "KRW", name: "Turkmanbashi" },
    { code: "CRZ", name: "Turkmenabad" },
    { code: "GDT", name: "Grand Turk Is" },
    { code: "PLS", name: "International" },
    { code: "XSC", name: "International" },
    { code: "MDS", name: "Middle Caicos" },
    { code: "NCA", name: "North Caicos" },
    { code: "PIC", name: "Pine Cay" },
    { code: "SLX", name: "Salt Cay" },
    { code: "FUN", name: "International" },
    { code: "RUA", name: "Arua" },
    { code: "EBB", name: "Entebbe" },
    { code: "ULU", name: "Gulu" },
    { code: "JIN", name: "Jinja" },
    { code: "KBG", name: "Kabalega Falls" },
    { code: "KSE", name: "Kasese" },
    { code: "KCU", name: "Masindi" },
    { code: "MBQ", name: "Mbarara" },
    { code: "OYG", name: "Moyo" },
    { code: "PAF", name: "Pakuba" },
    { code: "SRT", name: "Soroti" },
    { code: "TRY", name: "Tororo" },
    { code: "UKS", name: "Belbek" },
    { code: "ERD", name: "Berdyansk" },
    { code: "KBP", name: "Borispol" },
    { code: "CKC", name: "Cherkassy" },
    { code: "CEJ", name: "Chernigov" },
    { code: "CWC", name: "Chernovtsy" },
    { code: "QOF", name: "Darnitsa Bus Station" },
    { code: "DNK", name: "Dnepropetrovsk" },
    { code: "DOK", name: "Donetsk International Airport" },
    { code: "QOH", name: "Hotel Rus Bus Station" },
    { code: "IFO", name: "Ivano-Frankovsk" },
    { code: "KCP", name: "Kamenets-podolskiy" },
    { code: "KHC", name: "Kerch" },
    { code: "HRK", name: "Kharkov" },
    { code: "KHE", name: "Kherson" },
    { code: "HMJ", name: "Khmelnitskiy" },
    { code: "KGO", name: "Kirovograd" },
    { code: "KRQ", name: "Kramatorsk" },
    { code: "KHU", name: "Kremenchug" },
    { code: "KWG", name: "Krivoy Rog" },
    { code: "VSG", name: "Lugansk" },
    { code: "UCK", name: "Lutsk" },
    { code: "MPW", name: "Mariupol" },
    { code: "MXR", name: "Mirgorod" },
    { code: "NLV", name: "Nikolaev" },
    { code: "ODS", name: "Odessa International" },
    { code: "PLV", name: "Poltava" },
    { code: "RWN", name: "Rovno" },
    { code: "SEV", name: "Severodoneck" },
    { code: "SIP", name: "Simferopol" },
    { code: "LWO", name: "Snilow" },
    { code: "UMY", name: "Sumy" },
    { code: "TNL", name: "Ternopol" },
    { code: "UDJ", name: "Uzhgorod" },
    { code: "VIN", name: "Vinnica" },
    { code: "OZH", name: "Zaporozhye" },
    { code: "ZTR", name: "Zhitomir Airport" },
    { code: "IEV", name: "Zhulhany" },
    { code: "AUH", name: "Abu Dhabi International" },
    { code: "AAN", name: "Al Ain" },
    { code: "DHF", name: "Al Dhafra Military Apt" },
    { code: "AZI", name: "Bateen" },
    { code: "DXB", name: "Dubai" },
    { code: "FJR", name: "Fujairah Intl" },
    { code: "NHD", name: "Minhad AB" },
    { code: "RKT", name: "Ras Al Khaimah" },
    { code: "SHJ", name: "Sharjah" },
    { code: "BFS", name: "Aldergrove International Airport" },
    { code: "ADV", name: "Andover" },
    { code: "HLY", name: "Anglesey Airport" },
    { code: "BOL", name: "Bally Kelly" },
    { code: "UNT", name: "Baltasound" },
    { code: "BBP", name: "Bembridge" },
    { code: "BEB", name: "Benbecula" },
    { code: "BWY", name: "Bentwaters St" },
    { code: "GSY", name: "Binbrook" },
    { code: "BHX", name: "Birmingham International Airport" },
    { code: "BBS", name: "Blackbushe" },
    { code: "BLK", name: "Blackpool Airport" },
    { code: "BOH", name: "Bournemouth International" },
    { code: "BRF", name: "Bradford" },
    { code: "BSH", name: "Brighton" },
    { code: "BRS", name: "Bristol" },
    { code: "SKL", name: "Broadford" },
    { code: "BUT", name: "Burtonwood" },
    { code: "ZFC", name: "Bus Station" },
    { code: "CBG", name: "Cambridge" },
    { code: "CWL", name: "Cardiff" },
    { code: "CAX", name: "Carlisle" },
    { code: "CEG", name: "Chester" },
    { code: "ZBC", name: "Colmore Row Bus Stn" },
    { code: "OKH", name: "Cottesmor RAF" },
    { code: "CRN", name: "Cromarty" },
    { code: "DOC", name: "Dornoch" },
    { code: "DND", name: "Dundee" },
    { code: "MME", name: "Durham Tees Valley" },
    { code: "EMA", name: "East Midlands" },
    { code: "EOI", name: "Eday" },
    { code: "LDY", name: "Eglinton" },
    { code: "ENK", name: "Enniskillen St. Angelo" },
    { code: "EXT", name: "Exeter" },
    { code: "FIE", name: "Fair Isle" },
    { code: "SWS", name: "Fairwood Comm" },
    { code: "FAB", name: "Farnborough" },
    { code: "FEA", name: "Fetlar" },
    { code: "FZO", name: "Filton" },
    { code: "FLH", name: "Flotta" },
    { code: "FOA", name: "Foula" },
    { code: "BHD", name: "George Best Belfast City" },
    { code: "GLA", name: "Glasgow International" },
    { code: "ILY", name: "Glenegedale" },
    { code: "GLO", name: "Gloucestershire" },
    { code: "EWY", name: "Greenham RAF" },
    { code: "GCI", name: "Guernsey" },
    { code: "SWI", name: "Gypsy" },
    { code: "HTF", name: "Hatfield" },
    { code: "HAW", name: "Haverfordwest" },
    { code: "FWM", name: "Heliport" },
    { code: "LPH", name: "Heliport" },
    { code: "OHP", name: "Heliport" },
    { code: "RAY", name: "Heliport" },
    { code: "HEN", name: "Hendon" },
    { code: "HYC", name: "High Wycombe" },
    { code: "BEQ", name: "Honington" },
    { code: "HOY", name: "Hoy Island" },
    { code: "HUY", name: "Humberside International" },
    { code: "INV", name: "Inverness" },
    { code: "IPW", name: "Ipswich" },
    { code: "COL", name: "Isle Of Coll" },
    { code: "CSA", name: "Isle Of Colonsay" },
    { code: "JER", name: "Jersey" },
    { code: "MSE", name: "Kent International" },
    { code: "FSS", name: "Kinloss" },
    { code: "LKZ", name: "Lakenheath RAF" },
    { code: "LEQ", name: "Lands End" },
    { code: "LBA", name: "Leeds/bradford" },
    { code: "LWK", name: "Lerwick/Tingwall" },
    { code: "ADX", name: "Leuchars" },
    { code: "HRT", name: "Linton-On-Ouse" },
    { code: "LPL", name: "Liverpool John Lennon" },
    { code: "LCY", name: "London City Airport" },
    { code: "LGW", name: "London Gatwick" },
    { code: "LHR", name: "London Heathrow" },
    { code: "LTN", name: "London Luton" },
    { code: "SEN", name: "London Southend" },
    { code: "CAL", name: "Machrihanish" },
    { code: "MAN", name: "Manchester" },
    { code: "ZMP", name: "Manchester Bus Stn." },
    { code: "KNF", name: "Marham RAF" },
    { code: "MHZ", name: "Mildenhall Arpt" },
    { code: "KYN", name: "Milton Keynes" },
    { code: "ULL", name: "Mull" },
    { code: "GXH", name: "NAF" },
    { code: "NCL", name: "Newcastle Airport" },
    { code: "NQY", name: "Newquay Cornwall Airport" },
    { code: "BRR", name: "North Bay" },
    { code: "NRL", name: "North Ronaldsay" },
    { code: "ORM", name: "Northampton" },
    { code: "NHT", name: "Northolt" },
    { code: "NWI", name: "Norwich International Airport" },
    { code: "NQT", name: "Nottingham Airport" },
    { code: "OBN", name: "Oban Airport" },
    { code: "WUK", name: "Off Line Point" },
    { code: "KOI", name: "Orkney Island" },
    { code: "OXF", name: "Oxford" },
    { code: "PPW", name: "Papa Westray" },
    { code: "PZE", name: "Penzance" },
    { code: "PSL", name: "Perth" },
    { code: "PLH", name: "Plymouth" },
    { code: "PME", name: "Portsmouth" },
    { code: "PIK", name: "Prestwick" },
    { code: "BZZ", name: "Raf Brize Norton" },
    { code: "ABB", name: "RAF Station" },
    { code: "AYH", name: "RAF Station" },
    { code: "FFD", name: "RAF Station" },
    { code: "LMO", name: "RAF Station" },
    { code: "LYE", name: "RAF Station" },
    { code: "GQJ", name: "RAF Station" },
    { code: "ODH", name: "RAF Station" },
    { code: "SQZ", name: "RAF Station" },
    { code: "UHF", name: "RAF Station" },
    { code: "WTN", name: "RAF Station" },
    { code: "XQI", name: "Railway Station" },
    { code: "XQH", name: "Railway Station" },
    { code: "XWS", name: "Railway Station" },
    { code: "DSA", name: "Robin Hood" },
    { code: "RCS", name: "Rochester" },
    { code: "IOM", name: "Ronaldsway" },
    { code: "NDY", name: "Sanday" },
    { code: "SCS", name: "Scatsta" },
    { code: "ZDU", name: "ScotRail" },
    { code: "ZIV", name: "ScotRail" },
    { code: "ZXP", name: "ScotRail" },
    { code: "FKH", name: "Sculthorp RAF" },
    { code: "SZD", name: "Sheffield City Airport" },
    { code: "ESH", name: "Shoreham" },
    { code: "SOU", name: "Southampton Airport" },
    { code: "ISC", name: "St Marys" },
    { code: "STN", name: "Stansted" },
    { code: "SYY", name: "Stornoway" },
    { code: "SOY", name: "Stronsay" },
    { code: "LSI", name: "Sumburgh" },
    { code: "ACI", name: "The Blaye" },
    { code: "TRE", name: "Tiree" },
    { code: "TSO", name: "Tresco" },
    { code: "UPV", name: "Upavon" },
    { code: "BWF", name: "Walney Island" },
    { code: "WXF", name: "Weathersfield Raf" },
    { code: "WEM", name: "West Malling" },
    { code: "CVT", name: "West Midlands International" },
    { code: "WRY", name: "Westray" },
    { code: "WHS", name: "Whalsay" },
    { code: "WIC", name: "Wick" },
    { code: "WOB", name: "Woodbridge RAF" },
    { code: "WFD", name: "Woodford" },
    { code: "YEO", name: "Yeovilton" },
    { code: "OCH", name: "A L Mangham Jr. Regional" },
    { code: "AYE", name: "AAF Heliport" },
    { code: "ARA", name: "Acadiana Regional" },
    { code: "MFV", name: "Accomack County" },
    { code: "ADK", name: "Adak Island Ns" },
    { code: "LIT", name: "Adams Field Airport" },
    { code: "ADS", name: "Addison Airport" },
    { code: "SLK", name: "Adirondack" },
    { code: "INS", name: "Af Aux" },
    { code: "HST", name: "AFB" },
    { code: "MUO", name: "AFB" },
    { code: "OHC", name: "AFS" },
    { code: "PML", name: "AFS" },
    { code: "ANW", name: "Ainsworth" },
    { code: "HWD", name: "Air Terminal" },
    { code: "MDD", name: "Airpark" },
    { code: "VIK", name: "Airstrip" },
    { code: "AKK", name: "Akhiok SPB" },
    { code: "AKI", name: "Akiak" },
    { code: "CAK", name: "Akron/canton Regional" },
    { code: "KQA", name: "Akutan" },
    { code: "AUK", name: "Alakanuk" },
    { code: "CVO", name: "Albany" },
    { code: "ALB", name: "Albany International" },
    { code: "CHO", name: "Albemarle" },
    { code: "OAJ", name: "Albert J Ellis" },
    { code: "AEL", name: "Albert Lea" },
    { code: "FEP", name: "Albertus" },
    { code: "ABQ", name: "Albuquerque International" },
    { code: "WKK", name: "Aleknagik" },
    { code: "AED", name: "Aleneva" },
    { code: "ISW", name: "Alexander Field" },
    { code: "AXN", name: "Alexandria" },
    { code: "AXB", name: "Alexandria Bay" },
    { code: "AEX", name: "Alexandria International" },
    { code: "AXG", name: "Algona" },
    { code: "ALZ", name: "Alitak SPB" },
    { code: "AET", name: "Allakaket" },
    { code: "AGC", name: "Allegheny County" },
    { code: "AOH", name: "Allen County" },
    { code: "AIA", name: "Alliance" },
    { code: "APN", name: "Alpena County Regional" },
    { code: "ALE", name: "Alpine" },
    { code: "ALN", name: "Alton" },
    { code: "LTS", name: "Altus AFB" },
    { code: "AQY", name: "Alyeska" },
    { code: "ABL", name: "Ambler" },
    { code: "AHT", name: "Amchitka" },
    { code: "AHC", name: "Amedee AAF" },
    { code: "AMW", name: "Ames" },
    { code: "AOS", name: "Amook" },
    { code: "OTS", name: "Anacortes" },
    { code: "AKP", name: "Anaktuvuk" },
    { code: "AND", name: "Anderson" },
    { code: "AAP", name: "Andrau Airpark" },
    { code: "ADR", name: "Andrews" },
    { code: "ADW", name: "Andrews AFB" },
    { code: "NSF", name: "Andrews NAF" },
    { code: "AXX", name: "Angel Fire" },
    { code: "AGN", name: "Angoon" },
    { code: "ANI", name: "Aniak" },
    { code: "AMK", name: "Animas Airpark" },
    { code: "AIB", name: "Anita Bay" },
    { code: "ANN", name: "Annette Island" },
    { code: "ANB", name: "Anniston Metropolitan" },
    { code: "ANY", name: "Anthony" },
    { code: "ATE", name: "Antlers" },
    { code: "ACB", name: "Antrim County" },
    { code: "ANV", name: "Anvik" },
    { code: "CIB", name: "Ap In The Sky" },
    { code: "APV", name: "Apple Valley" },
    { code: "RKP", name: "Aransas County" },
    { code: "MGI", name: "Aransas National Wildlife Refuge" },
    { code: "APA", name: "Arapahoe Co" },
    { code: "ACV", name: "Arcata" },
    { code: "ARC", name: "Arctic Village" },
    { code: "ADM", name: "Ardmore Municipal Arpt" },
    { code: "WLD", name: "Arkansas City" },
    { code: "TUH", name: "Arnold AFS" },
    { code: "ATS", name: "Artesia" },
    { code: "ARX", name: "Asbury Park" },
    { code: "AVL", name: "Asheville Regional Airport" },
    { code: "ASX", name: "Ashland" },
    { code: "ASY", name: "Ashley" },
    { code: "JFN", name: "Ashtabula" },
    { code: "ASE", name: "Aspen" },
    { code: "AST", name: "Astoria" },
    { code: "AHN", name: "Athens" },
    { code: "AKB", name: "Atka" },
    { code: "NCQ", name: "Atlanta Nas" },
    { code: "ACY", name: "Atlantic City Intl" },
    { code: "ATT", name: "Atmautluak" },
    { code: "ATK", name: "Atqasuk" },
    { code: "OSX", name: "Attala County" },
    { code: "AUN", name: "Auburn" },
    { code: "LEW", name: "Auburn" },
    { code: "AUO", name: "Auburn-Opelika" },
    { code: "AUG", name: "Augusta" },
    { code: "AUM", name: "Austin" },
    { code: "ASQ", name: "Austin" },
    { code: "AUS", name: "Austin-bergstrom International" },
    { code: "GRB", name: "Austin-straubel Field" },
    { code: "NRC", name: "Aux Field" },
    { code: "SXC", name: "Avalo Vor/WP" },
    { code: "AVX", name: "Avalon Bay" },
    { code: "BCJ", name: "Baca Grande" },
    { code: "AIY", name: "Bader Field" },
    { code: "BGT", name: "Bagdad" },
    { code: "BKE", name: "Baker" },
    { code: "BAR", name: "Baker Aaf" },
    { code: "MLJ", name: "Baldwin County" },
    { code: "GBO", name: "Baltimore Greenbelt T" },
    { code: "ZBP", name: "Baltimore Rail" },
    { code: "BWI", name: "Baltimore/Washington International Thurgood Marshall" },
    { code: "BGR", name: "Bangor International Airport" },
    { code: "BNG", name: "Banning" },
    { code: "BHB", name: "Bar Harbor" },
    { code: "NAX", name: "Barbers Point" },
    { code: "NHX", name: "Barin Olf Osn" },
    { code: "BKH", name: "Barking Sands PMRF" },
    { code: "PAH", name: "Barkley Regional" },
    { code: "BNH", name: "Barnes" },
    { code: "HYA", name: "Barnstable" },
    { code: "BNL", name: "Barnwell Regional" },
    { code: "DAG", name: "Barstow-Daggett" },
    { code: "BTI", name: "Barter Island" },
    { code: "BVO", name: "Bartlesville" },
    { code: "BQV", name: "Bartlett SPB" },
    { code: "BSZ", name: "Bartletts" },
    { code: "BOW", name: "Bartow" },
    { code: "NBP", name: "Battery Pk City/N. Cov" },
    { code: "BDE", name: "Baudette" },
    { code: "BBC", name: "Bay City" },
    { code: "PFN", name: "Bay County" },
    { code: "HPY", name: "Baytown" },
    { code: "BCC", name: "Bear Creek" },
    { code: "BIE", name: "Beatrice" },
    { code: "BTY", name: "Beatty" },
    { code: "BMT", name: "Beaumont Muni" },
    { code: "DRI", name: "Beauregard Parish" },
    { code: "WBQ", name: "Beaver" },
    { code: "BFP", name: "Beaver Falls" },
    { code: "JAO", name: "Beaver Ruin" },
    { code: "BKW", name: "Beckley" },
    { code: "BEC", name: "Beech" },
    { code: "PSB", name: "Bellefonte" },
    { code: "BLV", name: "Belleville" },
    { code: "BLI", name: "Bellingham" },
    { code: "BLW", name: "Bellows Field" },
    { code: "BJI", name: "Bemidji" },
    { code: "CKB", name: "Benedum" },
    { code: "BTN", name: "Bennettsville" },
    { code: "JBK", name: "Berkeley" },
    { code: "BML", name: "Berlin Municipal Airport" },
    { code: "UDD", name: "Bermuda Dunes" },
    { code: "BTM", name: "Bert Mooney Airport" },
    { code: "UIZ", name: "Berz-Macomb" },
    { code: "BET", name: "Bethel Airport" },
    { code: "BTT", name: "Bettles" },
    { code: "BVY", name: "Beverly Municiple Airport" },
    { code: "BHC", name: "Bhurban Heliport" },
    { code: "BYS", name: "Bicycle Lake AAF" },
    { code: "RBF", name: "Big Bear City Arpt" },
    { code: "BIC", name: "Big Creek" },
    { code: "BGQ", name: "Big Lake" },
    { code: "BMX", name: "Big Mountain" },
    { code: "BPI", name: "Big Piney-marbleton" },
    { code: "WBR", name: "Big Rapids" },
    { code: "BIF", name: "Biggs Aaf" },
    { code: "BIL", name: "Billings" },
    { code: "KBC", name: "Birch Creek" },
    { code: "ESP", name: "Birchwood-Pocono" },
    { code: "BHM", name: "Birmingham" },
    { code: "BSQ", name: "Bisbee Municipal Arpt" },
    { code: "DUG", name: "Bisbee-Douglas Intl" },
    { code: "BIH", name: "Bishop" },
    { code: "FNT", name: "Bishop International Airport" },
    { code: "BIS", name: "Bismarck" },
    { code: "SPF", name: "Black Hills" },
    { code: "MBL", name: "Blacker" },
    { code: "BKT", name: "Blackstone AAF" },
    { code: "BWL", name: "Blackwell" },
    { code: "BWS", name: "Blaine" },
    { code: "BSI", name: "Blairsville" },
    { code: "BYW", name: "Blakely Island" },
    { code: "BDG", name: "Blanding" },
    { code: "BID", name: "Block Island" },
    { code: "BMG", name: "Bloomington" },
    { code: "CNK", name: "Blosser Municipal" },
    { code: "BLU", name: "Blue Canyon" },
    { code: "BFB", name: "Blue Fox Bay" },
    { code: "LEX", name: "Blue Grass" },
    { code: "LSD", name: "Blue Grass Station Army Heliport" },
    { code: "BLH", name: "Blythe" },
    { code: "BYH", name: "Blytheville AFB" },
    { code: "IAN", name: "Bob Barker Memorial" },
    { code: "BUR", name: "Bob Hope" },
    { code: "CEW", name: "Bob Sikes" },
    { code: "BFI", name: "Boeing Field/King County International Airport" },
    { code: "ASH", name: "Boire Field" },
    { code: "BOI", name: "Boise Air Terminal/Gowen Field" },
    { code: "BOF", name: "Bolling AFB" },
    { code: "SYI", name: "Bomar Field" },
    { code: "BNW", name: "Boone" },
    { code: "HRO", name: "Boone County" },
    { code: "BGD", name: "Borger" },
    { code: "RLU", name: "Bornite Upper" },
    { code: "BXS", name: "Borrego Springs" },
    { code: "JBC", name: "Boston City Heliport" },
    { code: "BSW", name: "Boswell Bay" },
    { code: "BLD", name: "Boulder City" },
    { code: "BYA", name: "Boundary" },
    { code: "JNU", name: "Boundary Bay" },
    { code: "HQM", name: "Bowerman" },
    { code: "ELN", name: "Bowers Field" },
    { code: "BWM", name: "Bowman" },
    { code: "LOU", name: "Bowman Field Airport" },
    { code: "BXC", name: "Boxborough" },
    { code: "POC", name: "Brackett Field" },
    { code: "BFD", name: "Bradford" },
    { code: "BDL", name: "Bradley International" },
    { code: "BSF", name: "Bradshaw AAF" },
    { code: "HFD", name: "Brainard" },
    { code: "BRD", name: "Brainerd Lakes Regional" },
    { code: "BWC", name: "Brawley" },
    { code: "LJN", name: "Brazoria County" },
    { code: "PWT", name: "Bremerton" },
    { code: "KTS", name: "Brevig Mission" },
    { code: "HDE", name: "Brewster Field" },
    { code: "BMC", name: "Brigham City" },
    { code: "BDX", name: "Broadus" },
    { code: "BBW", name: "Broken Bow" },
    { code: "WBI", name: "Broker Inn" },
    { code: "WSH", name: "Brookhaven" },
    { code: "BKX", name: "Brookings" },
    { code: "BOK", name: "Brookings State" },
    { code: "RBH", name: "Brooks Lodge" },
    { code: "SDM", name: "Brown Field Municipal" },
    { code: "EPG", name: "Browns" },
    { code: "BWD", name: "Brownwood" },
    { code: "NEA", name: "Brunswick Golden Isles" },
    { code: "FRN", name: "Bryant AAF" },
    { code: "BCE", name: "Bryce" },
    { code: "CCR", name: "Buchanan Fld" },
    { code: "BXK", name: "Buckeye Municipal" },
    { code: "BKC", name: "Buckland" },
    { code: "ZFZ", name: "Buffalo Depew Rr" },
    { code: "BFK", name: "Buffalo Municipal Airport" },
    { code: "BUF", name: "Buffalo Niagara International" },
    { code: "BFG", name: "Bullfrog Basin" },
    { code: "BKL", name: "Burke Lakefront" },
    { code: "BRL", name: "Burlington" },
    { code: "BBF", name: "Burlington" },
    { code: "LLY", name: "Burlington County" },
    { code: "BTV", name: "Burlington International" },
    { code: "BNO", name: "Burns" },
    { code: "ZSH", name: "Bus Station" },
    { code: "ZTL", name: "Bus Station" },
    { code: "AGS", name: "Bush Field" },
    { code: "BUM", name: "Butler" },
    { code: "BTP", name: "Butler County - Kenny Scholter Field" },
    { code: "FCS", name: "Butts AAF" },
    { code: "BZS", name: "Buzzards Pt S" },
    { code: "CBZ", name: "Cabin Creek" },
    { code: "CCB", name: "Cable Airport" },
    { code: "JUP", name: "Cable Heliport" },
    { code: "LGU", name: "Cache" },
    { code: "CAD", name: "Cadillac" },
    { code: "OZR", name: "Cairns AAF" },
    { code: "CIR", name: "Cairo" },
    { code: "CDW", name: "Caldwell Wright" },
    { code: "CLR", name: "Calipatria" },
    { code: "CWG", name: "Callaway Gardens" },
    { code: "LGC", name: "Calloway" },
    { code: "CEY", name: "Calloway County" },
    { code: "JST", name: "Cambria County" },
    { code: "CGE", name: "Cambridge" },
    { code: "APH", name: "Camp A P Hill" },
    { code: "HOP", name: "Campbell AAF" },
    { code: "GCC", name: "Campbell County" },
    { code: "CZZ", name: "Campo" },
    { code: "CDL", name: "Candle" },
    { code: "CVS", name: "Cannon AFB" },
    { code: "CNE", name: "Canon City" },
    { code: "CTK", name: "Canton" },
    { code: "CNY", name: "Canyonlands Field" },
    { code: "CGI", name: "Cape Girardeau" },
    { code: "LUR", name: "Cape Lisburne" },
    { code: "WWD", name: "Cape May County" },
    { code: "EHM", name: "Cape Newenham" },
    { code: "CZP", name: "Cape Pole" },
    { code: "CZF", name: "Cape Romanzof" },
    { code: "SPI", name: "Capital" },
    { code: "FFT", name: "Capital City" },
    { code: "HAR", name: "Capital City" },
    { code: "LAN", name: "Capital City" },
    { code: "PUC", name: "Carbon County" },
    { code: "SYN", name: "Carleton" },
    { code: "CNM", name: "Carlsbad" },
    { code: "CZT", name: "Carrizo Springs" },
    { code: "CIN", name: "Carroll" },
    { code: "CSN", name: "Carson City" },
    { code: "CZK", name: "Cascade Locks/Stevens" },
    { code: "ATU", name: "Casco Cove" },
    { code: "PVZ", name: "Casement" },
    { code: "MER", name: "Castle AFB" },
    { code: "SPQ", name: "Catalina SPB" },
    { code: "VQQ", name: "Cecil Field" },
    { code: "CDC", name: "Cedar City" },
    { code: "CID", name: "Cedar Rapids" },
    { code: "JOC", name: "Centerport Heliport" },
    { code: "CEM", name: "Central" },
    {
        code: "BMI",
        name: "Central Illinois Regional Airport at Bloomington-Normal",
    },
    { code: "JVI", name: "Central Jersey Regional Airport" },
    { code: "OWK", name: "Central Maine Airport of Norridgewock" },
    { code: "CWA", name: "Central Wisconsin" },
    { code: "CLS", name: "Centralia" },
    { code: "CEA", name: "Cessna Aircraft Field" },
    { code: "CDR", name: "Chadron" },
    { code: "CCA", name: "Chaffee AFB" },
    { code: "CIK", name: "Chalkyitsik" },
    { code: "CHL", name: "Challis" },
    { code: "YKN", name: "Chan Gurney" },
    { code: "WCR", name: "Chandalar" },
    { code: "CHS", name: "Charleston, AFB Municipal" },
    { code: "PGD", name: "Charlotte County" },
    { code: "CLT", name: "Charlotte Douglas" },
    { code: "CYM", name: "Chatham SPB" },
    { code: "CFK", name: "Chefornak" },
    { code: "CYF", name: "Chefornak SPB" },
    { code: "CEX", name: "Chena Hot Springs" },
    { code: "HCW", name: "Cheraw" },
    { code: "CKK", name: "Cherokee" },
    { code: "JKV", name: "Cherokee County" },
    { code: "TVC", name: "Cherry Capital Airport" },
    { code: "FKL", name: "Chess-Lambertin" },
    { code: "CTH", name: "Chestercounty Carlson" },
    { code: "VAK", name: "Chevak" },
    { code: "CYS", name: "Cheyenne" },
    { code: "CHI", name: "Chicago FSS" },
    { code: "NOH", name: "Chicago NAS" },
    { code: "ORD", name: "Chicago O'hare International" },
    { code: "CKX", name: "Chicken" },
    { code: "CIC", name: "Chico" },
    { code: "KCQ", name: "Chignik" },
    { code: "KBW", name: "Chignik Bay" },
    { code: "CDS", name: "Childress" },
    { code: "JCC", name: "China Basin Heliport" },
    { code: "CNO", name: "Chino" },
    { code: "CIU", name: "Chippewa County" },
    { code: "EAU", name: "Chippewa Valley Regional Airport" },
    { code: "CZN", name: "Chisana Field" },
    { code: "HIB", name: "Chisholm" },
    { code: "CZO", name: "Chistochina" },
    { code: "CXC", name: "Chitina Arpt" },
    { code: "CIV", name: "Chomley" },
    { code: "CHU", name: "Chuathbaluk" },
    { code: "LUK", name: "Cincinnati Municipal-Lunken Field" },
    { code: "ISZ", name: "Cincinnati-Blue Ash Airport" },
    { code: "CVG", name: "Cincinnati/northern Kentucky" },
    { code: "RCP", name: "Cinder River" },
    { code: "IRC", name: "Circle City" },
    { code: "CHP", name: "Circle Hot Springs" },
    { code: "CKU", name: "City" },
    { code: "HVR", name: "City County" },
    { code: "JBT", name: "City Landing" },
    { code: "JID", name: "City Of Industry H/P" },
    { code: "MDJ", name: "City-county" },
    { code: "EWK", name: "City-County" },
    { code: "SEP", name: "Clark Field" },
    { code: "CLP", name: "Clarks Point" },
    { code: "CAO", name: "Clayton" },
    { code: "CKE", name: "Clear Lake" },
    { code: "CWI", name: "Clinton" },
    { code: "PLB", name: "Clinton County" },
    { code: "ILN", name: "Clinton Field" },
    { code: "CLI", name: "Clintonville" },
    { code: "CLG", name: "Coalinga" },
    { code: "ERO", name: "Coast Guard" },
    { code: "LRK", name: "Coast Guard" },
    { code: "CSP", name: "Coast Guard Heliport" },
    { code: "PRT", name: "Coast Guard Heliport" },
    { code: "TRP", name: "Coast Guard Heliport" },
    { code: "CFA", name: "Coffee Point" },
    { code: "RCK", name: "Coffield" },
    { code: "KCC", name: "Coffman Cove SPB" },
    { code: "CDB", name: "Cold Bay" },
    { code: "CXF", name: "Coldfoot" },
    { code: "COM", name: "Coleman" },
    { code: "MTO", name: "Coles County Memorial" },
    { code: "CGS", name: "College Park" },
    { code: "KCR", name: "Colorado Creek" },
    { code: "AKO", name: "Colorado Plains Regional Airport" },
    { code: "COS", name: "Colorado Springs" },
    { code: "COA", name: "Columbia" },
    { code: "DLS", name: "Columbia Gorge Regional/The Dalles Municipal Airport" },
    { code: "COU", name: "Columbia Regional" },
    { code: "CBM", name: "Columbus AFB" },
    { code: "CLU", name: "Columbus Municipal" },
    { code: "OLU", name: "Columbus Municipal" },
    { code: "JBP", name: "Commerce Bus. Plaza" },
    { code: "CPM", name: "Compton" },
    { code: "CON", name: "Concord" },
    { code: "WSD", name: "Condron AAF" },
    { code: "JCE", name: "Convention Center H/P" },
    { code: "DGW", name: "Converse County" },
    { code: "COP", name: "Cooperstown - Westville Airport" },
    { code: "CZC", name: "Copper Centre" },
    { code: "LOZ", name: "Corbin-London" },
    { code: "CRO", name: "Corcoran" },
    { code: "CBA", name: "Corner Bay" },
    { code: "CRP", name: "Corpus Christi International Airport" },
    { code: "CRS", name: "Corsicana" },
    { code: "CTX", name: "Cortland" },
    { code: "CTW", name: "Cottonwood" },
    { code: "COT", name: "Cotulla" },
    { code: "CFD", name: "Coulter Field" },
    { code: "BFT", name: "County" },
    { code: "JAS", name: "County" },
    { code: "OBE", name: "County" },
    { code: "SIV", name: "County" },
    { code: "WSG", name: "County" },
    { code: "VCT", name: "County-Foster" },
    { code: "PRX", name: "Cox Field" },
    { code: "SEM", name: "Craig AFB" },
    { code: "CRG", name: "Craig Municipal" },
    { code: "CGA", name: "Craig SPB" },
    { code: "CIG", name: "Craig-Moffat" },
    { code: "CCG", name: "Crane County Arpt" },
    { code: "CKR", name: "Crane Island" },
    { code: "RTN", name: "Crews Field" },
    { code: "CKD", name: "Crooked Creek" },
    { code: "CTY", name: "Cross City" },
    { code: "STK", name: "Crosson Field" },
    { code: "MIC", name: "Crystal" },
    { code: "CYE", name: "Crystal Lake" },
    { code: "CUW", name: "Cube Cove" },
    { code: "VHN", name: "Culberson County" },
    { code: "WBN", name: "Cummings Park" },
    { code: "BBD", name: "Curtis Field" },
    { code: "ORV", name: "Curtis Memorial" },
    { code: "CTB", name: "Cut Bank" },
    { code: "CGF", name: "Cuyahoga County" },
    { code: "TNT", name: "Dade Collier" },
    { code: "DCK", name: "Dahl Creek Arpt" },
    { code: "DHT", name: "Dalhart" },
    { code: "DNE", name: "Dallas North Airport" },
    { code: "DFW", name: "Dallas/Fort Worth International" },
    { code: "DXR", name: "Danbury Municipal Airport" },
    { code: "MSN", name: "Dane County Regional" },
    { code: "DGB", name: "Danger Bay" },
    { code: "DNL", name: "Daniel Field" },
    { code: "MGM", name: "Dannelly Fld" },
    { code: "MEO", name: "Dare County Regional" },
    { code: "DVN", name: "Davenport" },
    { code: "DWH", name: "David Wayne Hooks" },
    { code: "OWB", name: "Daviess County" },
    { code: "MKO", name: "Davis Field" },
    { code: "DAA", name: "Davison AAF" },
    { code: "GDV", name: "Dawson Community" },
    { code: "MGY", name: "Dayton-Wright Brothers Airport" },
    { code: "DTH", name: "Death Valley" },
    { code: "DEC", name: "Decatur Arpt" },
    { code: "BGE", name: "Decatur County" },
    { code: "DCR", name: "Decatur Hi-Way" },
    { code: "DTR", name: "Decatur Island" },
    { code: "WDB", name: "Deep Bay" },
    { code: "DPK", name: "Deer Park" },
    { code: "DRG", name: "Deering" },
    { code: "PDK", name: "Dekalb-Peachtree" },
    { code: "MIE", name: "Delaware County" },
    { code: "DTA", name: "Delta" },
    { code: "ESC", name: "Delta County Arpt" },
    { code: "DJN", name: "Delta Junction" },
    { code: "DMN", name: "Deming" },
    { code: "DEN", name: "Denver International" },
    { code: "LOL", name: "Derby Field" },
    { code: "DSM", name: "Des Moines International" },
    { code: "DRA", name: "Desert Rock" },
    { code: "DSI", name: "Destin-Fort Walton Beach Airport" },
    { code: "DET", name: "Detroit City" },
    { code: "DTW", name: "Detroit Metropolitan Wayne County" },
    { code: "DVL", name: "Devils Lake" },
    { code: "GRM", name: "Devils Track" },
    { code: "LNI", name: "Dew Station" },
    { code: "PIZ", name: "Dew Station" },
    { code: "DIK", name: "Dickinson" },
    { code: "EEN", name: "Dillant-Hopkins" },
    { code: "HDH", name: "Dillingham Airfield" },
    { code: "DLN", name: "Dillon" },
    { code: "DLL", name: "Dillon" },
    { code: "DIO", name: "Diomede Island" },
    { code: "MGE", name: "Dobbins Afb" },
    { code: "DDC", name: "Dodge City Municipal" },
    { code: "UNU", name: "Dodge County" },
    { code: "DLO", name: "Dolomi" },
    { code: "SUE", name: "Door County" },
    { code: "DOF", name: "Dora Bay" },
    { code: "DHN", name: "Dothan Arpt" },
    { code: "ABY", name: "Dougherty County" },
    { code: "MEV", name: "Douglas County" },
    { code: "DGL", name: "Douglas Municipal" },
    { code: "DOV", name: "Dover AFB" },
    { code: "AHD", name: "Downtown" },
    { code: "EBR", name: "Downtown" },
    { code: "MKC", name: "Downtown" },
    { code: "STP", name: "Downtown" },
    { code: "DWN", name: "Downtown Airpark" },
    { code: "JDB", name: "Downtown Heliport" },
    { code: "JDM", name: "Downtown Heliport" },
    { code: "JDT", name: "Downtown Heliport" },
    { code: "DNT", name: "Downtown Heliport" },
    { code: "JRB", name: "Downtown Manhattan H/p" },
    { code: "DYL", name: "Doylestown" },
    { code: "HAI", name: "Dr Haines" },
    { code: "TPL", name: "Draughon-miller Central Texas Regional" },
    { code: "DRF", name: "Drift River" },
    { code: "DRU", name: "Drummond" },
    { code: "DRE", name: "Drummond Island" },
    { code: "DUJ", name: "Du Bois-Jefferson County" },
    { code: "DBS", name: "Dubois" },
    { code: "EGI", name: "Duke Field" },
    { code: "DLH", name: "Duluth International" },
    { code: "DKK", name: "Dunkirk" },
    { code: "DPA", name: "Dupage County" },
    { code: "POU", name: "Dutchess County" },
    { code: "DYS", name: "Dyess AFB" },
    { code: "EAA", name: "Eagle" },
    { code: "EGE", name: "Eagle County" },
    { code: "ELA", name: "Eagle Lake" },
    { code: "EGV", name: "Eagle River" },
    { code: "DUA", name: "Eaker" },
    { code: "POF", name: "Earl Fields Memorial" },
    { code: "TSS", name: "East 34th St Heliport" },
    { code: "NES", name: "East 34th St Landing" },
    { code: "JRE", name: "East 60th Street H/P" },
    { code: "HTO", name: "East Hampton" },
    { code: "MRB", name: "Eastern Wv Regional Airport/Shepherd Field" },
    { code: "CLL", name: "Easterwood Field" },
    { code: "ESN", name: "Easton" },
    { code: "OIC", name: "Eaton" },
    { code: "EDA", name: "Edna Bay" },
    { code: "MPV", name: "Edward F Knapp State" },
    { code: "GUF", name: "Edwards" },
    { code: "EDW", name: "Edwards AFB" },
    { code: "EEK", name: "Eek" },
    { code: "EGX", name: "Egegik" },
    { code: "VPS", name: "Eglin AFB" },
    { code: "EIL", name: "Eielson AFB" },
    { code: "EFB", name: "Eight Fathom Bight" },
    { code: "KKU", name: "Ekuk" },
    { code: "KEK", name: "Ekwok" },
    { code: "NJK", name: "El Centro NAF" },
    { code: "EDK", name: "El Dorado" },
    { code: "EMT", name: "El Monte" },
    { code: "ELP", name: "El Paso International Airport" },
    { code: "ELV", name: "Elfin Cove SPB" },
    { code: "ELI", name: "Elim" },
    { code: "ECG", name: "Elizabeth City" },
    { code: "FID", name: "Elizabeth Field" },
    { code: "EKX", name: "Elizabethtown" },
    { code: "EKN", name: "Elkins" },
    { code: "EKO", name: "Elko Regional" },
    { code: "ELW", name: "Ellamar" },
    { code: "EFD", name: "Ellington Field" },
    { code: "MYU", name: "Ellis Field" },
    { code: "RCA", name: "Ellsworth Afb" },
    { code: "NDP", name: "Ellyson NAS" },
    { code: "EDF", name: "Elmendorf Afb" },
    { code: "ELM", name: "Elmira Corning Regional" },
    { code: "LYU", name: "Ely" },
    { code: "EMB", name: "Embarkadero" },
    { code: "DUT", name: "Emergency Field" },
    { code: "PLN", name: "Emmet County" },
    { code: "ECA", name: "Emmet County Airport" },
    { code: "EMK", name: "Emmonak" },
    { code: "EMP", name: "Emporia" },
    { code: "WDG", name: "Enid Woodring Mun." },
    { code: "EPH", name: "Ephrata" },
    { code: "OMA", name: "Eppley Airfield" },
    { code: "ERR", name: "Errol" },
    { code: "ESF", name: "Esler Field" },
    { code: "ESO", name: "Espanola" },
    { code: "EUG", name: "Eugene" },
    { code: "UCE", name: "Eunice" },
    { code: "EUE", name: "Eureka" },
    { code: "EVW", name: "Evanston" },
    { code: "EVV", name: "Evansville Regional Airport" },
    { code: "EVM", name: "Eveleth" },
    { code: "UCY", name: "Everett-Stewart" },
    { code: "ZXS", name: "Exchangest Railway Svc" },
    { code: "CLW", name: "Executive" },
    { code: "FXE", name: "Executive" },
    { code: "SAC", name: "Executive" },
    { code: "FAI", name: "Fairbanks International Airport" },
    { code: "CLM", name: "Fairchild Intl" },
    { code: "KCK", name: "Fairfax Municipal" },
    { code: "FRM", name: "Fairmont" },
    { code: "MSC", name: "Falcon Field" },
    { code: "FAL", name: "Falcon State" },
    { code: "FLJ", name: "Falls Bay" },
    { code: "INL", name: "Falls Intl" },
    { code: "FAK", name: "False Island" },
    { code: "KFP", name: "False Pass" },
    { code: "IDA", name: "Fanning Field" },
    { code: "FWL", name: "Farewell" },
    { code: "KSR", name: "Federal No 1" },
    { code: "FAF", name: "Felker AAF" },
    { code: "SFF", name: "Felts Field" },
    { code: "FFM", name: "Fergus Falls" },
    { code: "FNK", name: "Fin Creek" },
    { code: "FDY", name: "Findlay" },
    { code: "FIC", name: "Fire Cove" },
    { code: "FCT", name: "Firing Center AAF" },
    { code: "FFA", name: "First Flight" },
    { code: "KCG", name: "Fisheries" },
    { code: "FIV", name: "Five Finger" },
    { code: "FMC", name: "Five Mile" },
    { code: "FLT", name: "Flat" },
    { code: "FXM", name: "Flaxman Island" },
    { code: "CKM", name: "Fletcher Field" },
    { code: "FLP", name: "Flippin" },
    { code: "FLO", name: "Florence" },
    { code: "FLU", name: "Flushing" },
    { code: "FCM", name: "Flying Cloud" },
    { code: "FLD", name: "Fond Du Lac" },
    { code: "FOE", name: "Forbes Field" },
    { code: "IMT", name: "Ford" },
    { code: "TBN", name: "Forney AAF" },
    { code: "FOB", name: "Fort Bragg" },
    { code: "FBR", name: "Fort Bridger" },
    { code: "QWF", name: "Fort Collins Bus Servi" },
    { code: "FOD", name: "Fort Dodge Regional" },
    { code: "HLR", name: "Fort Hood AAF" },
    { code: "RBN", name: "Fort Jefferson" },
    { code: "FLL", name: "Fort Lauderdale/hollywood International" },
    { code: "FLE", name: "Fort Lee AAF" },
    { code: "CMY", name: "Fort Mccoy" },
    { code: "FSU", name: "Fort Sumner" },
    { code: "AFW", name: "Fort Worth Alliance" },
    { code: "FWH", name: "Fort Worth NAS Jrb/Carswell Field" },
    { code: "FYU", name: "Fort Yukon" },
    { code: "FTL", name: "Fortuna Ledge" },
    { code: "FOX", name: "Fox" },
    { code: "LFN", name: "Franklin" },
    { code: "UOS", name: "Franklin County" },
    { code: "FDK", name: "Frederick Municipal" },
    { code: "SER", name: "Freeman Municipal" },
    { code: "RBK", name: "French Valley" },
    { code: "WFK", name: "Frenchville" },
    { code: "FRP", name: "Fresh Water Bay" },
    { code: "FAT", name: "Fresno Air Terminal Airport" },
    { code: "FCH", name: "Fresno-Chandler" },
    { code: "FRD", name: "Friday Harbor" },
    { code: "FBS", name: "Friday Harbor SPB" },
    { code: "FRR", name: "Front Royal-Warren County" },
    { code: "FRY", name: "Fryeburg" },
    { code: "FBK", name: "Ft Wainwright" },
    { code: "CWO", name: "Ft Wolter AAF" },
    { code: "FTY", name: "Fulton County" },
    { code: "RCR", name: "Fulton County" },
    { code: "AKC", name: "Fulton International" },
    { code: "GAB", name: "Gabbs" },
    { code: "GAG", name: "Gage" },
    { code: "GNV", name: "Gainesville Regional" },
    { code: "GAK", name: "Gakona" },
    { code: "GBH", name: "Galbraith Lake" },
    { code: "GAL", name: "Galena" },
    { code: "GBG", name: "Galesburg" },
    { code: "GQQ", name: "Galion" },
    { code: "BZN", name: "Gallatin Field" },
    { code: "JGL", name: "Galleria Heliport" },
    { code: "GAM", name: "Gambell" },
    { code: "GEK", name: "Ganes Creek" },
    { code: "GCK", name: "Garden City Municipal Airport" },
    { code: "PIM", name: "Garden Harris County" },
    { code: "RIL", name: "Garfield County" },
    { code: "UVA", name: "Garner Fld" },
    { code: "GYY", name: "Gary/Chicago International" },
    { code: "GKT", name: "Gatlinburg" },
    { code: "LAR", name: "General Brees Field" },
    { code: "MKE", name: "General Mitchell International" },
    { code: "IAH", name: "George Bush Intercontinental" },
    { code: "AIV", name: "George Downer" },
    { code: "BXA", name: "George R Carr" },
    { code: "GGE", name: "Georgetown" },
    { code: "GTY", name: "Gettysburg" },
    { code: "GIF", name: "Gilbert Field" },
    { code: "CJN", name: "Gillespie Field" },
    { code: "SEE", name: "Gillespie Field" },
    { code: "KGZ", name: "Glacier Creek" },
    { code: "FCA", name: "Glacier Park International" },
    { code: "GDW", name: "Gladwin" },
    { code: "GWV", name: "Glendale" },
    { code: "WLW", name: "Glenn County" },
    { code: "MTN", name: "Glenn L Martin" },
    { code: "GLQ", name: "Glennallen" },
    { code: "GWS", name: "Glenwood Springs" },
    { code: "BQK", name: "Glynco Jetport" },
    { code: "FTK", name: "Godman AAF" },
    { code: "IWD", name: "Gogebic County" },
    { code: "GDH", name: "Golden Horn Lodge SPB" },
    { code: "GTR", name: "Golden Triangle Reg." },
    { code: "GLV", name: "Golovin" },
    { code: "GOF", name: "Goodfellow AFB" },
    { code: "GNG", name: "Gooding" },
    { code: "GNU", name: "Goodnews Bay" },
    { code: "ELD", name: "Goodwin Field" },
    { code: "GRN", name: "Gordon" },
    { code: "GSH", name: "Goshen" },
    { code: "GFK", name: "Grand Forks" },
    { code: "RDR", name: "Grand Forks AFB" },
    { code: "GRI", name: "Grand Island" },
    { code: "GPZ", name: "Grand Rapids" },
    { code: "CRE", name: "Grand Strand Airport" },
    { code: "GMT", name: "Granite Mountain" },
    { code: "MWH", name: "Grant County" },
    { code: "PGC", name: "Grant County" },
    { code: "SVC", name: "Grant County" },
    { code: "AMN", name: "Gratiot Community" },
    { code: "GRK", name: "Gray Aaf" },
    { code: "GRF", name: "Gray Aaf" },
    { code: "KGX", name: "Grayling" },
    { code: "PNX", name: "Grayson County" },
    { code: "GBD", name: "Great Bend" },
    { code: "BGM", name: "Greater Binghamton" },
    { code: "IKK", name: "Greater Kankakee" },
    { code: "PIA", name: "Greater Peoria" },
    { code: "PMH", name: "Greater Portsmouth Regional" },
    { code: "ROC", name: "Greater Rochester International" },
    { code: "RFD", name: "Greater Rockford Airport" },
    { code: "ILG", name: "Greater Wilmington" },
    { code: "OTN", name: "Green" },
    { code: "RVR", name: "Green River" },
    { code: "SSU", name: "Greenbrier" },
    { code: "LWB", name: "Greenbrier Valley" },
    { code: "WAY", name: "Greene County" },
    { code: "GLH", name: "Greenville" },
    { code: "GMU", name: "Greenville Downtown" },
    { code: "GSP", name: "Greenville Spartanbur Int'l Airport" },
    { code: "GRD", name: "Greenwood" },
    { code: "PBF", name: "Grider Field" },
    { code: "SKY", name: "Griffing Sandusky" },
    { code: "RME", name: "Griffiss AFB" },
    { code: "GUS", name: "Grissom Afb" },
    { code: "MPE", name: "Griswold" },
    { code: "BPA", name: "Grumman" },
    { code: "GPT", name: "Gulfport-Biloxi International" },
    { code: "GKN", name: "Gulkana" },
    { code: "GUC", name: "Gunnison" },
    { code: "GUN", name: "Gunter AFB" },
    { code: "GST", name: "Gustavus Arpt" },
    { code: "GOK", name: "Guthrie" },
    { code: "GUY", name: "Guymon" },
    { code: "LZU", name: "Gwinnett County Airport" },
    { code: "JBS", name: "Hacienda Bus.Park H/P" },
    { code: "PVW", name: "Hale County" },
    { code: "FSN", name: "Haley AAF" },
    { code: "HAF", name: "Half Moon" },
    { code: "RZZ", name: "Halifax County" },
    { code: "DUC", name: "Halliburton Field" },
    { code: "HAO", name: "Hamilton" },
    { code: "SRF", name: "Hamilton Field" },
    { code: "HNM", name: "Hana" },
    { code: "HEY", name: "Hanchey Army Heliport" },
    { code: "BED", name: "Hanscom Field" },
    { code: "HEZ", name: "Hardy-Anders" },
    { code: "CDH", name: "Harrell Fld" },
    { code: "LWL", name: "Harriet Field" },
    { code: "MDT", name: "Harrisburg International" },
    { code: "ZUG", name: "Harrisburg Rail" },
    { code: "PHD", name: "Harry Clever" },
    { code: "ATL", name: "Hartsfield-jackson Atlanta International" },
    { code: "HSI", name: "Hastings" },
    { code: "HAX", name: "Hatbox Field" },
    { code: "HNC", name: "Hatteras" },
    { code: "PIB", name: "Hattiesburg-Laurel Regional" },
    { code: "HAE", name: "Havasupai" },
    { code: "HKS", name: "Hawkins Field" },
    { code: "HHR", name: "Hawthorne" },
    { code: "HTH", name: "Hawthorne" },
    { code: "HAY", name: "Haycock" },
    { code: "HZL", name: "Hazleton" },
    { code: "HKB", name: "Healy Lake" },
    { code: "FAR", name: "Hector Field" },
    { code: "HLN", name: "Helena" },
    { code: "JDY", name: "Heliport" },
    { code: "JEM", name: "Heliport" },
    { code: "JGX", name: "Heliport" },
    { code: "JGC", name: "Heliport" },
    { code: "JLB", name: "Heliport" },
    { code: "JNP", name: "Heliport" },
    { code: "ORQ", name: "Heliport" },
    { code: "JPD", name: "Heliport" },
    { code: "HSH", name: "Henderson Sky Harbor" },
    { code: "0A7", name: "Hendersonville Airport" },
    { code: "PHT", name: "Henry County" },
    { code: "FSI", name: "Henry Post AAF" },
    { code: "TMA", name: "Henry Tift Myers" },
    { code: "HED", name: "Herendeen" },
    { code: "LUL", name: "Hesler-Noble Field" },
    { code: "HIK", name: "Hickam AFB" },
    { code: "HKY", name: "Hickory" },
    { code: "HDA", name: "Hidden Falls" },
    { code: "HIF", name: "Hill Afb" },
    { code: "HLC", name: "Hill City" },
    { code: "HLB", name: "Hillenbrand" },
    { code: "ITO", name: "Hilo International" },
    { code: "HHH", name: "Hilton Head" },
    { code: "WHH", name: "Hiltons Har H" },
    { code: "BZT", name: "Hinkles Ferry" },
    { code: "HBR", name: "Hobart" },
    { code: "HBH", name: "Hobart Bay" },
    { code: "HGZ", name: "Hogatza" },
    { code: "HOL", name: "Holikachu" },
    { code: "HLI", name: "Hollister" },
    { code: "HMN", name: "Holloman AFB" },
    { code: "HCR", name: "Holy Cross" },
    { code: "HOM", name: "Homer" },
    { code: "HMS", name: "Homeshore" },
    { code: "HNL", name: "Honolulu International" },
    { code: "MWO", name: "Hook Field" },
    { code: "HNH", name: "Hoonah" },
    { code: "HPB", name: "Hooper Bay" },
    { code: "CLE", name: "Hopkins International" },
    { code: "RAC", name: "Horlick" },
    { code: "THP", name: "Hot Springs" },
    { code: "KBE", name: "Hot Springs SPB" },
    { code: "CMX", name: "Houghton County" },
    { code: "HCA", name: "Howard County" },
    { code: "HON", name: "Howes" },
    { code: "CVR", name: "Hughes" },
    { code: "HUJ", name: "Hugo" },
    { code: "HUF", name: "Hulman Field" },
    { code: "HUD", name: "Humboldt" },
    { code: "LND", name: "Hunt Field" },
    { code: "HGT", name: "Hunter AAF" },
    { code: "SVN", name: "Hunter AAF" },
    { code: "HSV", name: "Huntsville International - Carl T. Jones Field" },
    { code: "HSL", name: "Huslia" },
    { code: "HUT", name: "Hutchinson" },
    { code: "JHY", name: "Hyatt Regency H/P" },
    { code: "DEO", name: "Hyatt Regency H/P" },
    { code: "ICY", name: "Icy Bay" },
    { code: "IGG", name: "Igiugig" },
    { code: "BDR", name: "Igor I. Sikorsky Mem." },
    { code: "ILI", name: "Iliamna" },
    { code: "VYS", name: "Illinois Valley Regnl" },
    { code: "IMM", name: "Immokalee" },
    { code: "IML", name: "Imperial" },
    { code: "IPL", name: "Imperial County" },
    { code: "IDP", name: "Independence" },
    { code: "UTO", name: "Indian Mountain AFS" },
    { code: "IND", name: "Indianapolis International" },
    { code: "OTM", name: "Industrial" },
    { code: "ROW", name: "Industrial" },
    { code: "TIW", name: "Industrial" },
    { code: "HBB", name: "Industrial Airpark" },
    { code: "HSP", name: "Ingalls Field" },
    { code: "HVE", name: "Intermediate" },
    { code: "MDO", name: "Intermediate" },
    { code: "MHM", name: "Intermediate" },
    { code: "MOS", name: "Intermediate" },
    { code: "PMB", name: "Intermediate" },
    { code: "SKW", name: "Intermediate" },
    { code: "TSG", name: "Intermediate" },
    { code: "CYT", name: "Intermediate" },
    { code: "BIG", name: "Intermediate Field" },
    { code: "ALI", name: "International" },
    { code: "CXL", name: "International" },
    { code: "DRT", name: "International" },
    { code: "ERI", name: "International" },
    { code: "GGW", name: "International" },
    { code: "GTF", name: "International" },
    { code: "HUL", name: "International" },
    { code: "KTN", name: "International" },
    { code: "EYW", name: "International" },
    { code: "LRD", name: "International" },
    { code: "LBB", name: "International" },
    { code: "MOT", name: "International" },
    { code: "IAG", name: "International" },
    { code: "OLS", name: "International" },
    { code: "RST", name: "International" },
    { code: "OLF", name: "International" },
    { code: "YUM", name: "International" },
    { code: "JIO", name: "Intl Heliport" },
    { code: "PWM", name: "Intl Jetport" },
    { code: "IOW", name: "Iowa City" },
    { code: "IFA", name: "Iowa Falls" },
    { code: "ISL", name: "Isabel Pass" },
    { code: "JHC", name: "Island Heliport" },
    { code: "IVH", name: "Ivishak" },
    { code: "VLE", name: "J t Robidoux" },
    { code: "KPT", name: "Jackpot" },
    { code: "MJQ", name: "Jackson" },
    { code: "JAC", name: "Jackson Hole" },
    { code: "JAN", name: "Jackson-evers" },
    { code: "JAX", name: "Jacksonville," },
    { code: "JAR", name: "Jahrom" },
    { code: "DAY", name: "James Cox Dayton Intl" },
    { code: "JMS", name: "Jamestown" },
    { code: "JHW", name: "Jamestown" },
    { code: "BJC", name: "Jeffco" },
    { code: "BPT", name: "Jefferson County" },
    { code: "MDN", name: "Jefferson Proving Grd" },
    { code: "NLE", name: "Jerry Tyler Memorial" },
    { code: "FSD", name: "Joe Foss Field Airport" },
    { code: "JDA", name: "John Day" },
    { code: "JFK", name: "John F Kennedy Intl" },
    { code: "SNA", name: "John Wayne" },
    { code: "JCY", name: "Johnson" },
    { code: "OJC", name: "Johnson Executive" },
    { code: "JBR", name: "Jonesboro" },
    { code: "JLN", name: "Joplin Regional" },
    { code: "JDN", name: "Jordan" },
    { code: "TWF", name: "Joslin Field - Magic Valley Regional" },
    { code: "OGG", name: "Kahului" },
    { code: "KNK", name: "Kakhonak" },
    { code: "KKK", name: "Kalakaket AFS" },
    { code: "AZO", name: "Kalamazoo/Battle Creek Intl" },
    { code: "LUP", name: "Kalaupapa" },
    { code: "KAL", name: "Kaltag" },
    { code: "MUE", name: "Kamuela" },
    { code: "KUY", name: "Kamusi Airport" },
    { code: "KNB", name: "Kanab" },
    { code: "MCI", name: "Kansas City International Airport" },
    { code: "JHM", name: "Kapalua West Maui" },
    { code: "KYK", name: "Karluk" },
    { code: "KKL", name: "Karluk Lake SPB" },
    { code: "KXA", name: "Kasaan SPB" },
    { code: "KUK", name: "Kasigluk" },
    { code: "EAR", name: "Kearney" },
    { code: "BIX", name: "Keesler AFB" },
    { code: "CKA", name: "Kegelman Af" },
    { code: "SKF", name: "Kelly AFB" },
    { code: "KEU", name: "Kelly Bar" },
    { code: "KLP", name: "Kelp Bay" },
    { code: "EMM", name: "Kemerer" },
    { code: "ENA", name: "Kenai" },
    { code: "KEH", name: "Kenmore Air Harbor" },
    { code: "LKE", name: "Kenmore Air Harbor SPB" },
    { code: "ENW", name: "Kenosha Regional Airport" },
    { code: "GRR", name: "Kent County Intl" },
    { code: "KKT", name: "Kentland" },
    { code: "EOK", name: "Keokuk" },
    { code: "IYK", name: "Kern County" },
    { code: "MHV", name: "Kern County" },
    { code: "MIT", name: "Kern County" },
    { code: "TSP", name: "Kern County" },
    { code: "ERV", name: "Kerrville" },
    { code: "MEI", name: "Key Field" },
    { code: "KIP", name: "Kickapoo" },
    { code: "GGG", name: "Kilgore" },
    { code: "JCT", name: "Kimble County" },
    { code: "INR", name: "Kincheloe AFB" },
    { code: "KVC", name: "King Cove" },
    { code: "KPD", name: "King Of Prussia" },
    { code: "AKN", name: "King Salmon" },
    { code: "IGM", name: "Kingman" },
    { code: "LMT", name: "Kingsley Field" },
    { code: "KPN", name: "Kipnuk SPB" },
    { code: "IRS", name: "Kirsch Municipal" },
    { code: "ISM", name: "Kissimmee Gateway" },
    { code: "KVL", name: "Kivalina" },
    { code: "KZH", name: "Kizhuyak" },
    { code: "KBK", name: "Klag Bay" },
    { code: "KLW", name: "Klawock" },
    { code: "RKD", name: "Knox County Regional" },
    { code: "OBU", name: "Kobuk/Wien" },
    { code: "ADQ", name: "Kodiak Airport" },
    { code: "WDY", name: "Kodiak Fss" },
    { code: "OKK", name: "Kokomo" },
    { code: "KOA", name: "Kona International Airport" },
    { code: "KKH", name: "Kongiganak" },
    { code: "KOT", name: "Kotlik" },
    { code: "OTZ", name: "Kotzebue" },
    { code: "KKA", name: "Koyuk" },
    { code: "KYU", name: "Koyukuk" },
    { code: "LKK", name: "Kulik Lake" },
    { code: "UUK", name: "Kuparuk" },
    { code: "KWT", name: "Kwethluk" },
    { code: "KWK", name: "Kwigillingok" },
    { code: "KWN", name: "Kwinhagak" },
    { code: "LGD", name: "La Grande" },
    { code: "LGA", name: "La Guardia" },
    { code: "PMD", name: "La Palmdale Regional Airport" },
    { code: "DRO", name: "La Plata" },
    { code: "WLB", name: "Labouchere Bay" },
    { code: "LFT", name: "Lafayette Regional" },
    { code: "KCL", name: "Lagoon" },
    { code: "LGF", name: "Laguna AAF" },
    { code: "MFD", name: "Lahm Municipal" },
    { code: "BKF", name: "Lake Brooks SPB" },
    { code: "LKV", name: "Lake County" },
    { code: "LMA", name: "Lake Minchumina" },
    { code: "LKP", name: "Lake Placid" },
    { code: "NEW", name: "Lakefront" },
    { code: "LKS", name: "Lakeside" },
    { code: "LKI", name: "Lakeside USAF" },
    { code: "LAA", name: "Lamar Field" },
    { code: "STL", name: "Lambert-St. Louis International" },
    { code: "LNY", name: "Lanai City" },
    { code: "LNS", name: "Lancaster" },
    { code: "ZRL", name: "Lancaster PA Rail" },
    { code: "BAM", name: "Lander County" },
    { code: "EVA", name: "Landing Strip" },
    { code: "LFI", name: "Langley AFB" },
    { code: "KLN", name: "Larsen SPB" },
    { code: "TAD", name: "Las Animas" },
    { code: "LVS", name: "Las Vegas" },
    { code: "LTH", name: "Lathrop Wells" },
    { code: "DLF", name: "Laughlin Afb" },
    { code: "IFP", name: "Laughlin Bullhead Intl" },
    { code: "LWC", name: "Lawrence" },
    { code: "LWM", name: "Lawrence" },
    { code: "HTW", name: "Lawrence County Airpark" },
    { code: "MWC", name: "Lawrence J Timmerman" },
    { code: "LVL", name: "Lawrenceville" },
    { code: "LSF", name: "Lawson Aaf" },
    { code: "HOB", name: "Lea County" },
    { code: "LXV", name: "Leadville" },
    { code: "SLO", name: "Leckrone" },
    { code: "ANP", name: "Lee" },
    { code: "LBF", name: "Lee Bird Field" },
    { code: "AIZ", name: "Lee C Fine Memorial" },
    { code: "GVL", name: "Lee Gilmer Memorial" },
    { code: "LEE", name: "Leesburg" },
    { code: "GWO", name: "Leflore" },
    { code: "ABE", name: "Lehigh Valley International" },
    { code: "LEM", name: "Lemmon" },
    { code: "TUP", name: "Lemons Municipal" },
    { code: "NLC", name: "Lemoore NAS" },
    { code: "ADG", name: "Lenawee County" },
    { code: "MRN", name: "Lenoir" },
    { code: "KLL", name: "Levelock" },
    { code: "CDK", name: "Lewis" },
    { code: "MCN", name: "Lewis B Wilson" },
    { code: "LOT", name: "Lewis Lockport" },
    { code: "LXN", name: "Lexington" },
    { code: "LIH", name: "Lihue" },
    { code: "LVD", name: "Lime Village" },
    { code: "LNK", name: "Lincoln" },
    { code: "LHM", name: "Lincoln Regional Airport" },
    { code: "LDJ", name: "Linden" },
    { code: "GYR", name: "Litchfield" },
    { code: "WLN", name: "Little Naukati" },
    { code: "LPW", name: "Little Port Walter" },
    { code: "LRF", name: "Little Rock Afb" },
    { code: "LIV", name: "Livengood" },
    { code: "LVK", name: "Livermore" },
    { code: "BOS", name: "Logan International" },
    { code: "LPC", name: "Lompoc Airport" },
    { code: "LGB", name: "Long Beach Municipal" },
    { code: "LIJ", name: "Long Island" },
    { code: "ISP", name: "Long Island Mac Arthur" },
    { code: "KLS", name: "Longview" },
    { code: "LOG", name: "Longview" },
    { code: "LPS", name: "Lopez Island" },
    { code: "LSB", name: "Lordsburg" },
    { code: "WLR", name: "Loring" },
    { code: "LIZ", name: "Loring AFB" },
    { code: "LAM", name: "Los Alamos" },
    { code: "LAX", name: "Los Angeles International" },
    { code: "LSN", name: "Los Banos" },
    { code: "LHB", name: "Lost Harbor Sea Port" },
    { code: "LNN", name: "Lost Nation" },
    { code: "LSR", name: "Lost River" },
    { code: "MSY", name: "Louis Armstrong New Orléans International Airport" },
    { code: "SDF", name: "Louisville International" },
    { code: "DAL", name: "Love Field" },
    { code: "CHA", name: "Lovell Field" },
    { code: "LOR", name: "Lowe Army Heliport" },
    { code: "UBS", name: "Lowndes County" },
    { code: "LFK", name: "Lufkin Angelina County" },
    { code: "LUF", name: "Luke Afb" },
    { code: "LBT", name: "Lumberton" },
    { code: "IPT", name: "Lycoming County" },
    { code: "LLX", name: "Lyndonville" },
    { code: "PLK", name: "M Graham Clark" },
    { code: "MCF", name: "Mac Dill AFB" },
    { code: "RMC", name: "Machesney" },
    { code: "HFF", name: "Mackall AAF" },
    { code: "MCD", name: "Mackinac Island" },
    { code: "MAE", name: "Madera" },
    { code: "XMD", name: "Madison" },
    { code: "GVT", name: "Majors Field" },
    { code: "MLD", name: "Malad City" },
    { code: "MAW", name: "Malden" },
    { code: "GFA", name: "Malmstrom AFB" },
    { code: "MLK", name: "Malta" },
    { code: "MMH", name: "Mammoth Lakes" },
    { code: "MNZ", name: "Manassas" },
    { code: "MHT", name: "Manchester-boston Regional Airport" },
    { code: "MLY", name: "Manley Hot Springs" },
    { code: "KMO", name: "Manokotak SPB" },
    { code: "NTJ", name: "Manti-Ephraim" },
    { code: "MZJ", name: "Marana" },
    { code: "MTH", name: "Marathon Flight Strip" },
    { code: "MYH", name: "Marble Canyon" },
    { code: "RIV", name: "March ARB" },
    { code: "MRK", name: "Marco Island" },
    { code: "RTE", name: "Marguerite Bay" },
    { code: "JMC", name: "Marin County" },
    { code: "OAR", name: "Marina Municipal" },
    { code: "QNY", name: "Marine Air Terminal" },
    { code: "MZZ", name: "Marion" },
    { code: "HAB", name: "Marion County" },
    { code: "APT", name: "Marion County" },
    { code: "JMD", name: "Market Centre H/P" },
    { code: "MXG", name: "Marlborough" },
    { code: "JMH", name: "Marriott Heliport" },
    { code: "MLL", name: "Marshall" },
    { code: "FRI", name: "Marshall AAF" },
    { code: "CNU", name: "Martin Johnson" },
    { code: "MDA", name: "Martindale Army Heliport" },
    { code: "AOO", name: "Martinsburg" },
    { code: "MCW", name: "Mason City Municipal" },
    { code: "LDM", name: "Mason County" },
    { code: "SJT", name: "Mathis Fld" },
    { code: "MRC", name: "Maury County" },
    { code: "EGP", name: "Maverick Co" },
    { code: "OUN", name: "Max Westheimer" },
    { code: "MXE", name: "Maxton" },
    { code: "MYK", name: "May Creek" },
    { code: "MBS", name: "MBS International" },
    { code: "MLC", name: "Mc Alester" },
    { code: "MFE", name: "Mc Allen Miller International" },
    { code: "LAS", name: "Mc Carran Intl" },
    { code: "CLD", name: "Mc Clellan-Palomar Airport" },
    { code: "MMT", name: "Mc Entire ANG Base" },
    { code: "TYS", name: "Mc Ghee Tyson" },
    { code: "WRI", name: "Mc Guire Afb" },
    { code: "MKL", name: "Mc Kellar-Sipes Regional" },
    { code: "CEC", name: "Mc Namara Fld" },
    { code: "MYL", name: "Mccall" },
    { code: "MXY", name: "Mccarthy" },
    { code: "TCM", name: "McChord AFB" },
    { code: "MCC", name: "Mcclellan AFB" },
    { code: "MCK", name: "Mccook" },
    { code: "MCG", name: "Mcgrath" },
    { code: "MKF", name: "Mckenna AAF" },
    { code: "SSI", name: "Mckinnon" },
    { code: "MMI", name: "McMinn County" },
    { code: "SLE", name: "Mcnary Field" },
    { code: "MPR", name: "Mcpherson" },
    { code: "FTW", name: "Meacham Field" },
    { code: "BFL", name: "Meadows Field" },
    { code: "MEJ", name: "Meadville" },
    { code: "MDF", name: "Medford" },
    { code: "MDR", name: "Medfra" },
    { code: "TLR", name: "Mefford Field" },
    { code: "MLB", name: "Melbourne International Airport" },
    { code: "CIL", name: "Melsing Creek" },
    { code: "CSV", name: "Memorial" },
    { code: "DFI", name: "Memorial" },
    { code: "JEF", name: "Memorial" },
    { code: "OWD", name: "Memorial" },
    { code: "PUB", name: "Memorial" },
    { code: "SBM", name: "Memorial" },
    { code: "SIK", name: "Memorial" },
    { code: "TRX", name: "Memorial" },
    { code: "UGN", name: "Memorial" },
    { code: "HOT", name: "Memorial Field" },
    { code: "MEM", name: "Memphis International" },
    { code: "MNM", name: "Menominee" },
    { code: "MCE", name: "Merced Municipal Arpt" },
    { code: "CGX", name: "Merrill C Meigs" },
    { code: "MRI", name: "Merrill Field" },
    { code: "COI", name: "Merritt Island" },
    { code: "KIC", name: "Mesa Del Rey" },
    { code: "MTX", name: "Metro Field" },
    { code: "ZME", name: "Metropark Rail" },
    { code: "PMX", name: "Metropolitan" },
    { code: "CAE", name: "Metropolitan Airport" },
    { code: "CLC", name: "Metroport" },
    { code: "CEV", name: "Mettle Field" },
    { code: "MIO", name: "Miami" },
    { code: "MIA", name: "Miami International Airport" },
    { code: "OXD", name: "Miami University" },
    { code: "DPG", name: "Michael AAF" },
    { code: "MGC", name: "Michigan City Arpt" },
    { code: "ICT", name: "Mid-Continent" },
    { code: "MAF", name: "Midland International" },
    { code: "MDW", name: "Midway" },
    { code: "RED", name: "Mifflin County" },
    { code: "GNT", name: "Milan" },
    { code: "MLF", name: "Milford" },
    { code: "RCT", name: "Miller Field" },
    { code: "VTN", name: "Miller Field" },
    { code: "NQA", name: "Millington Regional Jetport" },
    { code: "MLT", name: "Millinocket" },
    { code: "MIV", name: "Millville" },
    { code: "ZML", name: "Milwaukee General Mitchell" },
    { code: "MWL", name: "Mineral Wells Arpt" },
    { code: "SJC", name: "Mineta San Jose International Airport" },
    { code: "MSP", name: "Minneapolis - St. Paul Intl" },
    { code: "MIB", name: "Minot AFB" },
    { code: "MNT", name: "Minto" },
    { code: "MMN", name: "Minute Man Airfield" },
    { code: "NKX", name: "Miramar MCAS" },
    { code: "LVM", name: "Mission Field" },
    { code: "MSO", name: "Missoula International" },
    { code: "BFM", name: "Mob Aerospace" },
    { code: "MBY", name: "Moberly" },
    { code: "MOB", name: "Mobile Regional Airport" },
    { code: "MBG", name: "Mobridge" },
    { code: "NUQ", name: "Moffett Field" },
    { code: "MKK", name: "Molokai" },
    { code: "ECS", name: "Mondell" },
    { code: "BLM", name: "Monmouth Executive Airport" },
    { code: "MVC", name: "Monroe - Walton County" },
    { code: "MTP", name: "Montauk Airport" },
    { code: "MRY", name: "Monterey Peninsula" },
    { code: "MVE", name: "Montevideo-Chippewa" },
    { code: "CEZ", name: "Montezuma County" },
    { code: "CXO", name: "Montgomery Co" },
    { code: "GAI", name: "Montgomery County" },
    { code: "MYF", name: "Montgomery Field" },
    { code: "MTJ", name: "Montrose Regional Airport" },
    { code: "QMV", name: "Montvale" },
    { code: "MVM", name: "Monument Valley" },
    { code: "GMV", name: "Monument Valley" },
    { code: "VAD", name: "Moody AFB" },
    { code: "MOR", name: "Moore-Murrell" },
    { code: "CFT", name: "Morenci" },
    { code: "MGW", name: "Morgantown" },
    { code: "MVL", name: "Morrisville-Stowe" },
    { code: "KMY", name: "Moser Bay" },
    { code: "MGR", name: "Moultrie Municipal" },
    { code: "MPS", name: "Mount Pleasant" },
    { code: "MUU", name: "Mount Union" },
    { code: "MWS", name: "Mount Wilson" },
    { code: "WMH", name: "Mountain Home" },
    { code: "MOU", name: "Mountain Village" },
    { code: "MCL", name: "Mt Mckinley" },
    { code: "MSD", name: "Mt Pleasant" },
    { code: "MPO", name: "Mt Pocono" },
    { code: "MVN", name: "Mt Vernon-Outland" },
    { code: "CDV", name: "Mudhole Smith" },
    { code: "MUI", name: "Muir AAF" },
    { code: "MHN", name: "Mullen" },
    { code: "ABR", name: "Municipal" },
    { code: "ABI", name: "Municipal" },
    { code: "AFO", name: "Municipal" },
    { code: "AIK", name: "Municipal" },
    { code: "ALM", name: "Municipal" },
    { code: "ALS", name: "Municipal" },
    { code: "AXS", name: "Municipal" },
    { code: "AHH", name: "Municipal" },
    { code: "AID", name: "Municipal" },
    { code: "ARB", name: "Municipal" },
    { code: "AAF", name: "Municipal" },
    { code: "AHF", name: "Municipal" },
    { code: "AIO", name: "Municipal" },
    { code: "AVO", name: "Municipal" },
    { code: "BVX", name: "Municipal" },
    { code: "BBB", name: "Municipal" },
    { code: "HKA", name: "Municipal" },
    { code: "WBU", name: "Municipal" },
    { code: "TTO", name: "Municipal" },
    { code: "BYG", name: "Municipal" },
    { code: "BUB", name: "Municipal" },
    { code: "CAR", name: "Municipal" },
    { code: "CGZ", name: "Municipal" },
    { code: "GHM", name: "Municipal" },
    { code: "ENL", name: "Municipal" },
    { code: "CCY", name: "Municipal" },
    { code: "CHK", name: "Municipal" },
    { code: "CNH", name: "Municipal" },
    { code: "ICL", name: "Municipal" },
    { code: "CLK", name: "Municipal" },
    { code: "CVN", name: "Municipal" },
    { code: "CFV", name: "Municipal" },
    { code: "CBK", name: "Municipal" },
    { code: "CUS", name: "Municipal" },
    { code: "CBF", name: "Municipal" },
    { code: "CSQ", name: "Municipal" },
    { code: "CKN", name: "Municipal" },
    { code: "CRT", name: "Municipal" },
    { code: "CUH", name: "Municipal" },
    { code: "DNN", name: "Municipal" },
    { code: "DAN", name: "Municipal" },
    { code: "DEH", name: "Municipal" },
    { code: "DNS", name: "Municipal" },
    { code: "DTL", name: "Municipal" },
    { code: "DLG", name: "Municipal" },
    { code: "DBN", name: "Municipal" },
    { code: "DBQ", name: "Municipal" },
    { code: "ETN", name: "Municipal" },
    { code: "EDE", name: "Municipal" },
    { code: "ELK", name: "Municipal" },
    { code: "EKI", name: "Municipal" },
    { code: "ETS", name: "Municipal" },
    { code: "EST", name: "Municipal" },
    { code: "FBY", name: "Municipal" },
    { code: "FFL", name: "Municipal" },
    { code: "FLX", name: "Municipal" },
    { code: "FBL", name: "Municipal" },
    { code: "FMN", name: "Municipal" },
    { code: "FAY", name: "Municipal" },
    { code: "FYM", name: "Municipal" },
    { code: "FIL", name: "Municipal" },
    { code: "FXY", name: "Municipal" },
    { code: "FCY", name: "Municipal" },
    { code: "FMS", name: "Municipal" },
    { code: "FSK", name: "Municipal" },
    { code: "FKN", name: "Municipal" },
    { code: "FDR", name: "Municipal" },
    { code: "FET", name: "Municipal" },
    { code: "FRH", name: "Municipal" },
    { code: "FUL", name: "Municipal" },
    { code: "GAD", name: "Municipal" },
    { code: "GLE", name: "Municipal" },
    { code: "GDM", name: "Municipal" },
    { code: "GLW", name: "Municipal" },
    { code: "GVE", name: "Municipal" },
    { code: "GTG", name: "Municipal" },
    { code: "GRE", name: "Municipal" },
    { code: "GCY", name: "Municipal" },
    { code: "HNS", name: "Municipal" },
    { code: "HPT", name: "Municipal" },
    { code: "HVS", name: "Municipal" },
    { code: "HYS", name: "Municipal" },
    { code: "HYR", name: "Municipal" },
    { code: "HUS", name: "Municipal" },
    { code: "HBO", name: "Municipal" },
    { code: "HNB", name: "Municipal" },
    { code: "IDG", name: "Municipal" },
    { code: "IRB", name: "Municipal" },
    { code: "IJX", name: "Municipal" },
    { code: "AFN", name: "Municipal" },
    { code: "EFW", name: "Municipal" },
    { code: "JOT", name: "Municipal" },
    { code: "KLG", name: "Municipal" },
    { code: "KNT", name: "Municipal" },
    { code: "ILE", name: "Municipal" },
    { code: "IRK", name: "Municipal" },
    { code: "KDK", name: "Municipal" },
    { code: "LSE", name: "Municipal" },
    { code: "LCI", name: "Municipal" },
    { code: "LCH", name: "Municipal" },
    { code: "XES", name: "Municipal" },
    { code: "HII", name: "Municipal" },
    { code: "LAL", name: "Municipal" },
    { code: "LPO", name: "Municipal" },
    { code: "LRU", name: "Municipal" },
    { code: "LWV", name: "Municipal" },
    { code: "LAW", name: "Municipal" },
    { code: "LRJ", name: "Municipal" },
    { code: "LWT", name: "Municipal" },
    { code: "LBL", name: "Municipal" },
    { code: "LIC", name: "Municipal" },
    { code: "MQB", name: "Municipal" },
    { code: "AGO", name: "Municipal" },
    { code: "MHK", name: "Municipal" },
    { code: "MXA", name: "Municipal" },
    { code: "MTW", name: "Municipal" },
    { code: "MKT", name: "Municipal" },
    { code: "MRF", name: "Municipal" },
    { code: "MNN", name: "Municipal" },
    { code: "MIW", name: "Municipal" },
    { code: "MFI", name: "Municipal" },
    { code: "RRL", name: "Municipal" },
    { code: "MLS", name: "Municipal" },
    { code: "MHE", name: "Municipal" },
    { code: "MOD", name: "Municipal" },
    { code: "MLU", name: "Municipal" },
    { code: "MXO", name: "Municipal" },
    { code: "MOX", name: "Municipal" },
    { code: "MMU", name: "Municipal" },
    { code: "MOP", name: "Municipal" },
    { code: "MPZ", name: "Municipal" },
    { code: "ENN", name: "Municipal" },
    { code: "RNH", name: "Municipal" },
    { code: "TNU", name: "Municipal" },
    { code: "OCE", name: "Municipal" },
    { code: "OCN", name: "Municipal" },
    { code: "OGD", name: "Municipal" },
    { code: "OLE", name: "Municipal" },
    { code: "OMK", name: "Municipal" },
    { code: "ONL", name: "Municipal" },
    { code: "ONH", name: "Municipal" },
    { code: "OGB", name: "Municipal" },
    { code: "OEO", name: "Municipal" },
    { code: "OOA", name: "Municipal" },
    { code: "PSP", name: "Municipal" },
    { code: "PAQ", name: "Municipal" },
    { code: "PGR", name: "Municipal" },
    { code: "PRO", name: "Municipal" },
    { code: "PTB", name: "Municipal" },
    { code: "PTS", name: "Municipal" },
    { code: "POH", name: "Municipal" },
    { code: "PCD", name: "Municipal" },
    { code: "PQI", name: "Municipal" },
    { code: "UIN", name: "Municipal" },
    { code: "RKW", name: "Municipal" },
    { code: "RBG", name: "Municipal" },
    { code: "RUI", name: "Municipal" },
    { code: "STC", name: "Municipal" },
    { code: "SGU", name: "Municipal" },
    { code: "SDP", name: "Municipal" },
    { code: "SBA", name: "Municipal" },
    { code: "SNL", name: "Municipal" },
    { code: "FHU", name: "Municipal" },
    { code: "SGY", name: "Municipal" },
    { code: "SPW", name: "Municipal" },
    { code: "SVH", name: "Municipal" },
    { code: "SLB", name: "Municipal" },
    { code: "SUM", name: "Municipal" },
    { code: "TVI", name: "Municipal" },
    { code: "TOR", name: "Municipal" },
    { code: "TOI", name: "Municipal" },
    { code: "TCS", name: "Municipal" },
    { code: "VDZ", name: "Municipal" },
    { code: "VRB", name: "Municipal" },
    { code: "VDI", name: "Municipal" },
    { code: "ACT", name: "Municipal" },
    { code: "RBW", name: "Municipal" },
    { code: "UKN", name: "Municipal" },
    { code: "AUW", name: "Municipal" },
    { code: "EBS", name: "Municipal" },
    { code: "ELZ", name: "Municipal" },
    { code: "AWM", name: "Municipal" },
    { code: "BRG", name: "Municipal" },
    { code: "WGO", name: "Municipal" },
    { code: "ONA", name: "Municipal" },
    { code: "FYV", name: "Municipal" },
    { code: "AUZ", name: "Municipal Airport" },
    { code: "FNL", name: "Municipal Airport" },
    { code: "JMN", name: "Municipal Heliport" },
    { code: "JRC", name: "Municipal Heliport" },
    { code: "JSK", name: "Municipal Heliport" },
    { code: "MML", name: "Municipal-Ryan Field" },
    { code: "FWA", name: "Municipal/Baer Field" },
    { code: "RDG", name: "Municipal/Spaatz Fld" },
    { code: "EKA", name: "Murray Field" },
    { code: "MUT", name: "Muscatine" },
    { code: "MKG", name: "Muskegon" },
    { code: "MUV", name: "Mustin Alf" },
    { code: "MYR", name: "Myrtle Beach Afb" },
    { code: "ZHC", name: "N Philadelphia Rail St" },
    { code: "NEL", name: "Naec" },
    { code: "DGN", name: "NAF" },
    { code: "NNK", name: "Naknek" },
    { code: "NOL", name: "Nakolik River" },
    { code: "ACK", name: "Nantucket Memorial" },
    { code: "KEB", name: "Nanwalek" },
    { code: "APC", name: "Napa County" },
    { code: "WNA", name: "Napakiak SPB" },
    { code: "APF", name: "Naples" },
    { code: "NGZ", name: "NAS" },
    { code: "NHZ", name: "NAS" },
    { code: "NFL", name: "NAS" },
    { code: "NBU", name: "NAS" },
    { code: "NRS", name: "NAS" },
    { code: "NQI", name: "NAS" },
    { code: "NTU", name: "NAS" },
    { code: "NGU", name: "Nas Chambers" },
    { code: "NBG", name: "NAS/Alvin Callendar" },
    { code: "BNA", name: "Nashville International" },
    { code: "RLA", name: "National" },
    { code: "GCN", name: "National Park" },
    { code: "CPR", name: "Natrona County International Airport" },
    { code: "NKI", name: "Naukiti" },
    { code: "IDI", name: "ndiana County Airport" },
    { code: "EED", name: "Needles" },
    { code: "AXV", name: "Neil Armstrong" },
    { code: "LSV", name: "Nellis AFB" },
    { code: "NLG", name: "Nelson Lagoon" },
    { code: "EOS", name: "Neosho" },
    { code: "NPH", name: "Nephi" },
    { code: "NVD", name: "Nevada" },
    { code: "EWB", name: "New Bedford" },
    { code: "ZRZ", name: "New Carrolton RR" },
    { code: "JCI", name: "New Century Aircenter Airport" },
    { code: "NCN", name: "New Chenega" },
    { code: "HVN", name: "New Haven" },
    { code: "ZVE", name: "New Haven Rail" },
    { code: "KGK", name: "New Koliganek" },
    { code: "GON", name: "New London" },
    { code: "ZGD", name: "New London Rail" },
    { code: "PSK", name: "New River Valley" },
    { code: "KNW", name: "New Stuyahok" },
    { code: "ULM", name: "New Ulm" },
    { code: "EWR", name: "Newark Liberty International" },
    { code: "ONP", name: "Newport" },
    { code: "EFK", name: "Newport" },
    { code: "ZWW", name: "Newport News Rail" },
    { code: "PHF", name: "Newport News/williamsb" },
    { code: "WWT", name: "Newtok" },
    { code: "LWS", name: "Nez Perce County Rgnl" },
    { code: "NIE", name: "Niblack" },
    { code: "NKV", name: "Nichen Cove" },
    { code: "NME", name: "Nightmute" },
    { code: "NIB", name: "Nikolai" },
    { code: "IKO", name: "Nikolski AFS" },
    { code: "NIN", name: "Ninilchik" },
    { code: "WTK", name: "Noatak" },
    { code: "ARV", name: "Noble F. Lee" },
    { code: "OME", name: "Nome" },
    { code: "NNL", name: "Nondalton" },
    { code: "ORF", name: "Norfolk International Airport" },
    { code: "VGT", name: "North Air Terminal" },
    { code: "OTH", name: "North Bend" },
    { code: "SFZ", name: "North Central" },
    { code: "WPO", name: "North Fork Valley" },
    { code: "NZY", name: "North Island Nas" },
    { code: "JNH", name: "North Park Inn H/P" },
    { code: "HWO", name: "North Perry" },
    { code: "PNE", name: "North Philadelphia" },
    { code: "NGC", name: "North Rim" },
    { code: "UMB", name: "North Shore" },
    { code: "ORT", name: "Northway" },
    { code: "XNA", name: "Northwest Arkansas Regional" },
    { code: "SBD", name: "Norton Afb" },
    { code: "NOT", name: "Novato" },
    { code: "NRB", name: "Ns" },
    { code: "NUI", name: "Nuiqsut" },
    { code: "NUL", name: "Nulato" },
    { code: "NUP", name: "Nunapitchuk" },
    { code: "ZNC", name: "Nyac" },
    { code: "ODW", name: "Oak Harbor" },
    { code: "OAK", name: "Oakland International Airport" },
    { code: "OBT", name: "Oakland/Coliseum Stat" },
    { code: "OCA", name: "Ocean Reef" },
    { code: "OCI", name: "Oceanic" },
    { code: "CEU", name: "Oconee County" },
    { code: "XZU", name: "Off line Point" },
    { code: "OGS", name: "Ogdensburg" },
    { code: "HLG", name: "Ohio County" },
    { code: "OSU", name: "Ohio State University" },
    { code: "ATO", name: "Ohio University" },
    { code: "OKM", name: "Okmulgee" },
    { code: "OLH", name: "Old Harbor SPB" },
    { code: "OLD", name: "Old Town" },
    { code: "NEN", name: "Olf Usn" },
    { code: "KOY", name: "Olga Bay SPB" },
    { code: "OLV", name: "Olive Branch Arpt" },
    { code: "ONY", name: "Olney" },
    { code: "OLY", name: "Olney-Noble" },
    { code: "OLM", name: "Olympia" },
    { code: "OEA", name: "Oneal" },
    { code: "RHI", name: "Oneida County" },
    { code: "UCA", name: "Oneida County" },
    { code: "ONN", name: "Onion Bay" },
    { code: "ONT", name: "Ontario International" },
    { code: "ONO", name: "Ontario Municipal Airport" },
    { code: "OPF", name: "Opa Locka" },
    { code: "MGJ", name: "Orange County" },
    { code: "ANA", name: "Orange County Steel Salvage Heliport" },
    { code: "ESD", name: "Orcas Island" },
    { code: "ORL", name: "Orlando Executive" },
    { code: "MCO", name: "Orlando International" },
    { code: "SFB", name: "Orlando Sanford Intl" },
    { code: "OVE", name: "Oroville" },
    { code: "OSB", name: "Osage Beach" },
    { code: "OKS", name: "Oshkosh" },
    { code: "FMH", name: "Otis AFB" },
    { code: "GLR", name: "Otsego County" },
    { code: "ATW", name: "Outagamie County" },
    { code: "CKV", name: "Outlaw Field" },
    { code: "KOZ", name: "Ouzinkie SPB" },
    { code: "OWA", name: "Owatonna" },
    { code: "CUB", name: "Owens Field" },
    { code: "SZP", name: "Oxnard" },
    { code: "OZA", name: "Ozona Municipal" },
    { code: "PFC", name: "Pacific City State" },
    { code: "PBK", name: "Pack Creek" },
    { code: "PFA", name: "Paf Warren" },
    { code: "PGA", name: "Page" },
    { code: "FMY", name: "Page Field" },
    { code: "PCE", name: "Painter Creek" },
    { code: "PWK", name: "Pal-Waukee" },
    { code: "PSX", name: "Palacios" },
    { code: "PSN", name: "Palestine Municipal Airport" },
    { code: "PHK", name: "Palm Beach Co Glades" },
    { code: "LNA", name: "Palm Beach County Park" },
    { code: "PBI", name: "Palm Beach International" },
    { code: "PAO", name: "Palo Alto" },
    { code: "EAT", name: "Pangborn Field" },
    { code: "PNU", name: "Panguitch" },
    { code: "ZRF", name: "Park & Ride Bus Svc" },
    { code: "PKF", name: "Park Falls" },
    { code: "PKD", name: "Park Rapids" },
    { code: "HLM", name: "Park Township" },
    { code: "WEA", name: "Parker County" },
    { code: "KPK", name: "Parks SPB" },
    { code: "NWH", name: "Parlin Field" },
    { code: "PRB", name: "Paso Robles" },
    { code: "FFO", name: "Patterson Afb" },
    { code: "NHK", name: "Patuxent River NAS" },
    { code: "KPH", name: "Pauloff Harbor SPB" },
    { code: "PJB", name: "Payson" },
    { code: "PGS", name: "Peach Springs" },
    { code: "PCU", name: "Pearl River County" },
    { code: "PSM", name: "Pease International" },
    { code: "CTO", name: "Peconic River" },
    { code: "PEQ", name: "Pecos City" },
    { code: "FST", name: "Pecos County" },
    { code: "PDB", name: "Pedro Bay" },
    { code: "PEC", name: "Pelican SPB" },
    { code: "PDT", name: "Pendleton" },
    { code: "SEG", name: "Penn Valley" },
    { code: "ZRP", name: "Pennsylvania Railroad Station" },
    { code: "JPN", name: "Pentagon Army" },
    { code: "JAJ", name: "Perimeter Mall" },
    { code: "PPA", name: "Perry Lefors Field" },
    { code: "PYL", name: "Perry SPB" },
    { code: "FPY", name: "Perry-Foley" },
    { code: "KPV", name: "Perryville SPB" },
    { code: "PSG", name: "Petersburg James A Johnson" },
    { code: "MPJ", name: "Petit Jean Park" },
    { code: "EAN", name: "Phifer Field" },
    { code: "PHL", name: "Philadelphia International" },
    { code: "PHP", name: "Philip" },
    { code: "TOP", name: "Philip Billard" },
    { code: "APG", name: "Phillips AAF" },
    { code: "PII", name: "Phillips Field" },
    { code: "DVT", name: "Phoenix Deer Valley Airport" },
    { code: "LQK", name: "Pickens" },
    { code: "GSO", name: "Piedmont Triad Intl" },
    { code: "NWS", name: "Pier 11/Wall St. SPB" },
    { code: "PIR", name: "Pierre" },
    { code: "MCB", name: "Pike County" },
    { code: "PIP", name: "Pilot Point" },
    { code: "PQS", name: "Pilot Station" },
    { code: "DUF", name: "Pine Island Airport" },
    { code: "XPR", name: "Pine Ridge" },
    { code: "SOP", name: "Pinehurst-S. Pines" },
    { code: "PGV", name: "Pitt-Greenville" },
    { code: "PIT", name: "Pittsburgh International" },
    { code: "PSF", name: "Pittsfield" },
    { code: "PVF", name: "Placerville" },
    { code: "PTU", name: "Platinum" },
    { code: "PBG", name: "Plattsburgh International" },
    { code: "PTR", name: "Pleasant Harbour" },
    { code: "PLY", name: "Plymouth" },
    { code: "PYM", name: "Plymouth" },
    { code: "PIH", name: "Pocatello" },
    { code: "KPB", name: "Point Baker SPB" },
    { code: "PBA", name: "Point Barrow" },
    { code: "PHO", name: "Point Hope" },
    { code: "NTD", name: "Point Mugu NAS" },
    { code: "PXL", name: "Polacca" },
    { code: "POE", name: "Polk AAF" },
    { code: "POQ", name: "Polk Inlet" },
    { code: "PPM", name: "Pompano Beach" },
    { code: "PNC", name: "Ponca City" },
    { code: "PTK", name: "Pontiac" },
    { code: "POB", name: "Pope AFB" },
    { code: "GFD", name: "Pope Field" },
    { code: "PVY", name: "Pope Vanoy" },
    { code: "PCK", name: "Porcupine Creek" },
    { code: "PTD", name: "Port Alexander" },
    { code: "PTC", name: "Port Alice" },
    { code: "PAK", name: "Port Allen" },
    { code: "PTA", name: "Port Alsworth" },
    { code: "PTL", name: "Port Armstrong" },
    { code: "KPY", name: "Port Bailey SPB" },
    { code: "KPC", name: "Port Clarence" },
    { code: "CMH", name: "Port Columbus Intl" },
    { code: "PFD", name: "Port Frederick" },
    { code: "PGM", name: "Port Graham" },
    { code: "PTH", name: "Port Heiden" },
    { code: "PRF", name: "Port Johnson" },
    { code: "KYL", name: "Port Largo" },
    { code: "ORI", name: "Port Lions SPB" },
    { code: "PRL", name: "Port Oceanic" },
    { code: "PPV", name: "Port Protection" },
    { code: "PJS", name: "Port San Juan" },
    { code: "TWD", name: "Port Townsend" },
    { code: "PWR", name: "Port Walter" },
    { code: "KPR", name: "Port Williams SPB" },
    { code: "PCA", name: "Portage Creek" },
    { code: "VPZ", name: "Porter County" },
    { code: "PTV", name: "Porterville" },
    { code: "HIO", name: "Portland" },
    { code: "PDX", name: "Portland International" },
    { code: "PTW", name: "Pottstown/Limerick" },
    { code: "PUL", name: "Poulsbo" },
    { code: "POY", name: "Powell" },
    { code: "PTT", name: "Pratt" },
    { code: "PRW", name: "Prentice" },
    { code: "PRC", name: "Prescott" },
    { code: "LYH", name: "Preston-Glenn Field" },
    { code: "PNN", name: "Princeton" },
    { code: "PCT", name: "Princeton" },
    { code: "BLF", name: "Princeton" },
    { code: "ZTJ", name: "Princeton JT Rail" },
    { code: "HPV", name: "Princeville" },
    { code: "PRZ", name: "Prineville" },
    { code: "PPC", name: "Prospect Creek" },
    { code: "ZRV", name: "Providence Rail" },
    { code: "PVC", name: "Provincetown" },
    { code: "PVU", name: "Provo" },
    { code: "PUO", name: "Prudhoe Bay" },
    { code: "SCC", name: "Prudhoe Bay/Deadhorse" },
    { code: "BCT", name: "Public" },
    { code: "SMX", name: "Public" },
    { code: "SME", name: "Pulaski County" },
    { code: "FLG", name: "Pulliam Field" },
    { code: "PUW", name: "Pullman-Moscow Regional Airport" },
    { code: "LAF", name: "Purdue University" },
    { code: "MLI", name: "Quad-City" },
    { code: "NYG", name: "Quantico Nas" },
    { code: "JLA", name: "Quartz Creek" },
    { code: "RZH", name: "Quartz Hill" },
    { code: "UQE", name: "Queen" },
    { code: "UIL", name: "Quillayute State" },
    { code: "MQI", name: "Quincy" },
    { code: "HSB", name: "Raleigh" },
    { code: "RDU", name: "Raleigh-durham International Airport" },
    { code: "TAL", name: "Ralph Calhoun" },
    { code: "RMP", name: "Rampart" },
    { code: "RND", name: "Randolph AFB" },
    { code: "RNG", name: "Rangely" },
    { code: "RGR", name: "Ranger Municipal" },
    { code: "RSP", name: "Raspberry Strait" },
    { code: "RWL", name: "Rawlins" },
    { code: "RBL", name: "Red Bluff Fss" },
    { code: "RDV", name: "Red Devil" },
    { code: "RBD", name: "Redbird" },
    { code: "RDD", name: "Redding" },
    { code: "RWF", name: "Redwood Falls Muni" },
    { code: "REE", name: "Reese AFB" },
    { code: "DAB", name: "Regional" },
    { code: "FAM", name: "Regional" },
    { code: "FSM", name: "Regional" },
    { code: "PNS", name: "Regional" },
    { code: "RAP", name: "Regional" },
    { code: "SHV", name: "Regional" },
    { code: "TVF", name: "Regional" },
    { code: "VLD", name: "Regional" },
    { code: "HIE", name: "Regional" },
    { code: "REH", name: "Rehoboth Beach" },
    { code: "RHV", name: "Reid-hillview" },
    { code: "GLD", name: "Renner Field" },
    { code: "RNO", name: "Reno-Tahoe International" },
    { code: "RNZ", name: "Rensselaer" },
    { code: "RNT", name: "Renton" },
    { code: "EHT", name: "Rentschler" },
    { code: "MHS", name: "Rep" },
    { code: "FRG", name: "Republic Field" },
    { code: "RIF", name: "Reynolds" },
    { code: "JXN", name: "Reynolds Municipal" },
    { code: "RIE", name: "Rice Lake" },
    { code: "RMG", name: "Richard B Russell" },
    { code: "SUW", name: "Richard I Bong Arpt" },
    { code: "MSS", name: "Richards Field" },
    { code: "GVW", name: "Richards-Gebaur" },
    { code: "RLD", name: "Richland" },
    { code: "SDY", name: "Richland Municipal" },
    { code: "RIC", name: "Richmond International Airport" },
    { code: "RID", name: "Richmond Municipal Airport" },
    { code: "ZRD", name: "Richmond VA Rail" },
    { code: "AMA", name: "Rick Husband Amarillo International" },
    { code: "LCK", name: "Rickenbacker" },
    { code: "BDF", name: "Rinkenberger" },
    { code: "RIR", name: "Riverside Fla-Bob" },
    { code: "RAL", name: "Riverside Municipal" },
    { code: "RIW", name: "Riverton Regional" },
    { code: "ROA", name: "Roanoke Regional Airport" },
    { code: "WVL", name: "Robert Lafleur" },
    { code: "RKR", name: "Robert S Kerr" },
    { code: "SYL", name: "Roberts AAF" },
    { code: "RDM", name: "Roberts Field" },
    { code: "WRB", name: "Robins AFB" },
    { code: "RCE", name: "Roche Harbor" },
    { code: "ZTE", name: "Rochester NY Rail" },
    { code: "JVL", name: "Rock County" },
    { code: "RKH", name: "Rock Hill" },
    { code: "RWI", name: "Rocky Mount-wilson" },
    { code: "ROG", name: "Rogers" },
    { code: "MFR", name: "Rogue Valley International - Medford Airport" },
    { code: "VIH", name: "Rolla National" },
    { code: "RFK", name: "Rollang Field" },
    { code: "DCA", name: "Ronald Reagan Washington National Airport" },
    { code: "RFG", name: "Rooke Field" },
    { code: "ROL", name: "Roosevelt" },
    { code: "RSJ", name: "Rosario SPB" },
    { code: "CRX", name: "Roscoe Turner" },
    { code: "HTL", name: "Roscommon County" },
    { code: "STJ", name: "Rosecrans Memorial" },
    { code: "BEH", name: "Ross Field" },
    { code: "RTD", name: "Rotunda" },
    { code: "RPX", name: "Roundup" },
    { code: "RSX", name: "Rouses Point" },
    { code: "RWB", name: "Rowan Bay" },
    { code: "SRW", name: "Rowan County" },
    { code: "MIF", name: "Roy Hurd Memorial" },
    { code: "RBY", name: "Ruby" },
    { code: "BYI", name: "Rupert" },
    { code: "RSL", name: "Russell" },
    { code: "RSH", name: "Russian SPB" },
    { code: "RSN", name: "Ruston" },
    { code: "RUT", name: "Rutland" },
    { code: "BTR", name: "Ryan" },
    { code: "HMT", name: "Ryan Field" },
    { code: "SMF", name: "Sacramento International" },
    { code: "MHR", name: "Sacramento Mather Airport" },
    { code: "SAD", name: "Safford" },
    { code: "SGW", name: "Saginaw Bay" },
    { code: "SAG", name: "Sagwon" },
    { code: "KSM", name: "Saint Marys" },
    { code: "SNP", name: "Saint Paul Island" },
    { code: "SLT", name: "Salida" },
    { code: "SLN", name: "Salina" },
    { code: "SBO", name: "Salina" },
    { code: "SNS", name: "Salinas" },
    { code: "SMN", name: "Salmon" },
    { code: "SLC", name: "Salt Lake City International" },
    { code: "BTF", name: "Salt Lake Skypark" },
    { code: "SAS", name: "Salton City" },
    { code: "CTZ", name: "Sampson County" },
    { code: "BRY", name: "Samuels Field" },
    { code: "SAT", name: "San Antonio International" },
    { code: "SQL", name: "San Carlos" },
    { code: "SAN", name: "San Diego International Airport" },
    { code: "SFO", name: "San Francisco International" },
    { code: "MXC", name: "San Juan County" },
    { code: "WSJ", name: "San Juan SPB" },
    { code: "SBP", name: "San Luis County Regional Airport" },
    { code: "JSG", name: "San Rafael H/P" },
    { code: "SHN", name: "Sanderson Field" },
    { code: "SFM", name: "Sanford" },
    { code: "SZN", name: "Santa Cruz Island" },
    { code: "SAF", name: "Santa Fe" },
    { code: "SMO", name: "Santa Monica" },
    { code: "SQA", name: "Santa Ynez" },
    { code: "SRQ", name: "Sarasota-Bradenton International Airport" },
    { code: "WSF", name: "Sarichef" },
    { code: "SAV", name: "Savannah/Hilton Head" },
    { code: "SVA", name: "Savoonga" },
    { code: "MQT", name: "Sawyer International" },
    { code: "SCH", name: "Schenectady County" },
    { code: "ZTD", name: "Schenectady Rail" },
    { code: "SUC", name: "Schloredt" },
    { code: "GLS", name: "Scholes Field" },
    { code: "BFF", name: "Scotts Bluff County" },
    { code: "SCF", name: "Scottsdale Municipal" },
    { code: "BVD", name: "Sea Port" },
    { code: "SYB", name: "Seal Bay" },
    { code: "SRC", name: "Searcy" },
    { code: "SWO", name: "Searcy Fld" },
    { code: "OGA", name: "Searle Field" },
    { code: "SEA", name: "Seattle-Tacoma International" },
    { code: "SEF", name: "Sebring Regional Airport" },
    { code: "DMO", name: "Sedalia" },
    { code: "SDX", name: "Sedona" },
    { code: "WLK", name: "Selawik" },
    { code: "SOV", name: "Seldovia" },
    { code: "SES", name: "Selfield" },
    { code: "GUP", name: "Senator Clark" },
    { code: "SQV", name: "Sequim Valley Arpt" },
    { code: "SWD", name: "Seward" },
    { code: "SHX", name: "Shageluk" },
    { code: "SKK", name: "Shaktoolik" },
    { code: "NRI", name: "Shangri-la" },
    { code: "LRO", name: "Sharpe AAF" },
    { code: "TGE", name: "Sharpe Field" },
    { code: "SSC", name: "Shaw AFB" },
    { code: "SMU", name: "Sheep Mountain" },
    { code: "MSL", name: "Sheffield" },
    { code: "SBX", name: "Shelby" },
    { code: "SXP", name: "Sheldon SPB" },
    { code: "SYA", name: "Shemya AFB" },
    { code: "SHD", name: "Shenandoah Valley" },
    { code: "SPS", name: "Sheppard AFB" },
    { code: "SHR", name: "Sheridan" },
    { code: "CSM", name: "Sherman" },
    { code: "FLV", name: "Sherman AAF" },
    { code: "PWD", name: "Sherwood" },
    { code: "SHH", name: "Shishmaref" },
    { code: "SAA", name: "Shively Field" },
    { code: "HCB", name: "Shoal Cove" },
    { code: "SOW", name: "Show Low" },
    { code: "DTN", name: "Shreveport Downtown" },
    { code: "SHG", name: "Shungnak" },
    { code: "SNY", name: "Sidney" },
    { code: "SXY", name: "Sidney" },
    { code: "JSD", name: "Sikorsky Heliport" },
    { code: "FBG", name: "Simmons AAF" },
    { code: "EWN", name: "Simmons Nott" },
    { code: "SUX", name: "Sioux Gateway" },
    { code: "SIY", name: "Siskiyou County" },
    { code: "SIT", name: "Sitka" },
    { code: "SKJ", name: "Sitkinak Cgs" },
    { code: "MVW", name: "Skagit Regional" },
    { code: "PHX", name: "Sky Harbor Intl" },
    { code: "SRU", name: "Skypark" },
    { code: "SLQ", name: "Sleetmute" },
    { code: "ISN", name: "Sloulin Field Intl" },
    { code: "MAC", name: "Smart" },
    { code: "SCJ", name: "Smith Cove" },
    { code: "SMD", name: "Smith Field" },
    { code: "SLG", name: "Smith Field" },
    { code: "INT", name: "Smith-Reynolds" },
    { code: "MQY", name: "Smyrna" },
    { code: "PAE", name: "Snohomish County" },
    { code: "ONM", name: "Socorro" },
    { code: "SXQ", name: "Soldotna" },
    { code: "SOL", name: "Solomon" },
    { code: "STS", name: "Sonoma County" },
    { code: "SBN", name: "South Bend Regional" },
    { code: "GEY", name: "South Big Horn County" },
    { code: "TVL", name: "South Lake Tahoe" },
    { code: "WSN", name: "South Naknek" },
    { code: "BRO", name: "South Padre Is. Intl" },
    { code: "NZW", name: "South Weymouth" },
    { code: "VCV", name: "Southern California Logistics" },
    { code: "MDH", name: "Southern Illinois" },
    { code: "BCS", name: "Southern Seaplane" },
    { code: "RSW", name: "Southwest Florida Reg" },
    { code: "SVW", name: "Sparrevohn AFS" },
    { code: "SAR", name: "Sparta Community" },
    { code: "SPA", name: "Spartanburg, Downtown Memorial" },
    { code: "KKI", name: "Spb" },
    { code: "KCN", name: "SPB" },
    { code: "EXI", name: "SPB" },
    { code: "FNR", name: "SPB" },
    { code: "HWI", name: "SPB" },
    { code: "HYL", name: "SPB" },
    { code: "HYG", name: "SPB" },
    { code: "WHD", name: "SPB" },
    { code: "KIB", name: "SPB" },
    { code: "KAE", name: "SPB" },
    { code: "KKB", name: "SPB" },
    { code: "MTM", name: "SPB" },
    { code: "WMK", name: "SPB" },
    { code: "MPB", name: "SPB" },
    { code: "PKA", name: "SPB" },
    { code: "PMU", name: "SPB" },
    { code: "PSQ", name: "SPB" },
    { code: "SCM", name: "SPB" },
    { code: "WSB", name: "SPB" },
    { code: "KTH", name: "SPB" },
    { code: "WYB", name: "SPB" },
    { code: "RTL", name: "Spirit Lake" },
    { code: "SUS", name: "Spirit Of St Louis" },
    { code: "OIL", name: "Splane Memorial" },
    { code: "GEG", name: "Spokane International" },
    { code: "SPZ", name: "Springdale Muni" },
    { code: "SGH", name: "Springfield" },
    { code: "ZSF", name: "Springfield MA RR" },
    { code: "SGF", name: "Springfield-Branson Rg" },
    { code: "UST", name: "St Augustine" },
    { code: "PLR", name: "St Clair County" },
    { code: "PHN", name: "St Clair County Intl" },
    { code: "STG", name: "St George Island" },
    { code: "SJN", name: "St Johns" },
    { code: "OPL", name: "St Landry Parish" },
    { code: "FPR", name: "St Lucie County" },
    { code: "XSM", name: "St Marys" },
    { code: "LTW", name: "St Marys County" },
    { code: "SMK", name: "St Michael" },
    { code: "CPS", name: "St. Louis Downtown Airport" },
    { code: "PIE", name: "St. Petersburg-Clearwater International" },
    { code: "ISO", name: "Stallings Field" },
    { code: "ZTF", name: "Stamford Rail STN" },
    { code: "BDY", name: "State" },
    { code: "CHZ", name: "State" },
    { code: "ESW", name: "State" },
    { code: "GOL", name: "State" },
    { code: "HES", name: "State" },
    { code: "NPT", name: "State" },
    { code: "REO", name: "State" },
    { code: "SCB", name: "State" },
    { code: "VSF", name: "State" },
    { code: "TBR", name: "Statesboro-Bulloch County Airport" },
    { code: "SBS", name: "Steamboat Springs" },
    { code: "WBB", name: "Stebbins" },
    { code: "JSL", name: "Steel Pier Hlpt" },
    { code: "OFK", name: "Stefan Field" },
    { code: "SLJ", name: "Stellar Air Park" },
    { code: "BKD", name: "Stephens County" },
    { code: "STE", name: "Stevens Point" },
    { code: "SVS", name: "Stevens Village" },
    { code: "SWF", name: "Stewart" },
    { code: "SSF", name: "Stinson Municipal" },
    { code: "SCK", name: "Stockton Metropolitan Airport" },
    { code: "VLO", name: "Stolport" },
    { code: "SRV", name: "Stony River" },
    { code: "SUD", name: "Stroud" },
    { code: "SSW", name: "Stuart Island" },
    { code: "SGT", name: "Stuttgart Municipal" },
    { code: "FOK", name: "Suffolk County" },
    { code: "SGR", name: "Sugar Land Regional" },
    { code: "MSV", name: "Sullivan County Intl" },
    { code: "SLR", name: "Sulphur Springs" },
    { code: "UMM", name: "Summit" },
    { code: "SUO", name: "Sun River" },
    { code: "SUN", name: "Sun Valley" },
    { code: "SVE", name: "Susanville" },
    { code: "GED", name: "Sussex County" },
    { code: "SWW", name: "Sweetwater" },
    { code: "RKS", name: "Sweetwater County" },
    { code: "SYV", name: "Sylvester" },
    { code: "SYR", name: "Syracuse Hancock International Airport" },
    { code: "PVD", name: "T. F. Green Airport" },
    { code: "HNE", name: "Tahneta Pass" },
    { code: "TCT", name: "Takotna" },
    { code: "TKL", name: "Taku SPB" },
    { code: "TKA", name: "Talkeetna" },
    { code: "ASN", name: "Talladega" },
    { code: "TLH", name: "Tallahassee Regional Airport" },
    { code: "TMB", name: "Tamiami" },
    { code: "TPA", name: "Tampa International" },
    { code: "TPO", name: "Tanalian Point" },
    { code: "TLJ", name: "Tatalina AFS" },
    { code: "TEK", name: "Tatitlek" },
    { code: "TWE", name: "Taylor" },
    { code: "TYZ", name: "Taylor" },
    { code: "OCF", name: "Taylor Field" },
    { code: "JAE", name: "Technology Park" },
    { code: "ANC", name: "Ted Stevens Anchorage International Airport" },
    { code: "MQW", name: "Telfair-Wheeler" },
    { code: "TLA", name: "Teller" },
    { code: "TEX", name: "Telluride" },
    { code: "TKE", name: "Tenakee SPB" },
    { code: "HUM", name: "Terrebonne" },
    { code: "TRL", name: "Terrell Field" },
    { code: "XSD", name: "Test Range" },
    { code: "TEB", name: "Teterboro" },
    { code: "TEH", name: "Tetlin" },
    { code: "JOR", name: "The City Heliport" },
    { code: "HBG", name: "The Hattiesburg-Bobby L. Chain Mun." },
    { code: "TRM", name: "Thermal" },
    { code: "ALX", name: "Thomas C Russell Fld" },
    { code: "HEE", name: "Thompson-Robbins" },
    { code: "THM", name: "Thompsonfield" },
    { code: "KTB", name: "Thorne Bay" },
    { code: "TNC", name: "Tin City Afs" },
    { code: "TIK", name: "Tinker AFB" },
    { code: "FME", name: "Tipton AAF" },
    { code: "TOC", name: "Toccoa" },
    { code: "GFB", name: "Togiak Fish" },
    { code: "TOG", name: "Togiak Village" },
    { code: "TKJ", name: "Tok" },
    { code: "TDZ", name: "Toledo" },
    { code: "TOL", name: "Toledo Express" },
    { code: "ITH", name: "Tompkins County" },
    { code: "TPH", name: "Tonopah Arpt" },
    { code: "KYO", name: "Topp Of Tampa" },
    { code: "TOA", name: "Torrance Airport" },
    { code: "TDW", name: "Tradewind" },
    { code: "SUU", name: "Travis AFB" },
    { code: "TRT", name: "Tremonton" },
    { code: "TTN", name: "Trenton Mercer Airport" },
    { code: "ZTN", name: "Trenton Rail STN" },
    { code: "LNR", name: "Tri County" },
    { code: "PSC", name: "Tri-cities" },
    { code: "TRI", name: "Tri-cities Regional" },
    { code: "PPF", name: "Tri-City" },
    { code: "SBT", name: "Tri-City" },
    { code: "ANQ", name: "Tri-State Steuben Cty" },
    { code: "HTS", name: "Tri-State/Milton" },
    { code: "TRH", name: "Trona" },
    { code: "TTD", name: "Troutdale" },
    { code: "TKF", name: "Truckee-Tahoe" },
    { code: "CNW", name: "TSTC Waco Airport" },
    { code: "TBC", name: "Tuba City" },
    { code: "TUS", name: "Tucson International Airport" },
    { code: "TCC", name: "Tucumcari" },
    { code: "THA", name: "Tullahoma/William Northern Field" },
    { code: "TUL", name: "Tulsa International" },
    { code: "TLT", name: "Tuluksak" },
    { code: "WTL", name: "Tuntutuliak" },
    { code: "TNK", name: "Tununak" },
    { code: "TNP", name: "Twentynine Palms" },
    { code: "TWA", name: "Twin Hills" },
    { code: "TWH", name: "Two Harbors" },
    { code: "TYR", name: "Tyler Pounds Regional Airport" },
    { code: "PAM", name: "Tyndall AFB" },
    { code: "UGI", name: "Uganik" },
    { code: "UGB", name: "Ugashik Bay" },
    { code: "UKI", name: "Ukiah" },
    { code: "UMT", name: "Umiat" },
    { code: "UNS", name: "Umnak" },
    { code: "UNK", name: "Unalakleet" },
    { code: "CMI", name: "University Of Illinois Willard" },
    { code: "SCE", name: "University Park" },
    { code: "UOX", name: "University-Oxford" },
    { code: "UPP", name: "Upolu Point" },
    { code: "UKT", name: "Upper Bucks" },
    { code: "JLH", name: "US Army Heliport" },
    { code: "AFF", name: "USAF Academy Airstrip" },
    { code: "WWS", name: "USAF Heliport" },
    { code: "NDV", name: "USN Heliport" },
    { code: "ZUA", name: "Utica NNY Rail" },
    { code: "HRL", name: "Valley International" },
    { code: "TCL", name: "Van De Graaf" },
    { code: "VNY", name: "Van Nuys" },
    { code: "QKB", name: "Van Service" },
    { code: "QCE", name: "Van Service" },
    { code: "QWP", name: "Van Service" },
    { code: "END", name: "Vance Afb" },
    { code: "VLA", name: "Vandalia" },
    { code: "ZRK", name: "VanGalder Bus Termina" },
    { code: "VEE", name: "Venetie" },
    { code: "VNC", name: "Venice Municipal" },
    { code: "OXR", name: "Ventura" },
    { code: "DNV", name: "Vermilion County" },
    { code: "VEL", name: "Vernal" },
    { code: "VRS", name: "Versailles" },
    { code: "VCB", name: "View Cove" },
    { code: "KWP", name: "Village SPB" },
    { code: "BFR", name: "Virgil I Grissom Muni" },
    { code: "VJI", name: "Virginia Highlands" },
    { code: "BCB", name: "Virginia Tech" },
    { code: "VIS", name: "Visalia" },
    { code: "VOK", name: "Volk Field" },
    { code: "OTO", name: "Vor" },
    { code: "LHV", name: "W T Piper Memorial" },
    { code: "WAH", name: "Wahpeton" },
    { code: "WKL", name: "Waikoloa Airport" },
    { code: "AIN", name: "Wainwright" },
    { code: "WAA", name: "Wales" },
    { code: "GJT", name: "Walker Field" },
    { code: "ALW", name: "Walla Walla" },
    { code: "WAL", name: "Wallops Flight Center" },
    { code: "ARG", name: "Walnut Ridge" },
    { code: "DWS", name: "Walt Disney World" },
    { code: "GBR", name: "Walter J. Koladza Airport" },
    { code: "WLM", name: "Waltham" },
    { code: "UWA", name: "Ware" },
    { code: "AYS", name: "Ware County" },
    { code: "BNF", name: "Warm Spring Bay SPB" },
    { code: "FEW", name: "Warren AFB" },
    { code: "BWG", name: "Warren County" },
    { code: "GFL", name: "Warren County" },
    { code: "RNC", name: "Warren County" },
    { code: "OCW", name: "Warren Field" },
    { code: "RRT", name: "Warroad" },
    { code: "HGR", name: "Wash. County Regional" },
    { code: "IAD", name: "Washington Dulles International" },
    { code: "WWA", name: "Wasilla" },
    { code: "OXC", name: "Waterbury-oxford" },
    { code: "KWF", name: "Waterfall SPB" },
    { code: "WFB", name: "Waterfront SPB" },
    { code: "ALO", name: "Waterloo" },
    { code: "ART", name: "Watertown" },
    { code: "ATY", name: "Watertown" },
    { code: "WVI", name: "Watsonville" },
    { code: "UES", name: "Waukesha" },
    { code: "BJJ", name: "Wayne County" },
    { code: "BGS", name: "Webb AFB" },
    { code: "EUF", name: "Weedon Field" },
    { code: "EDG", name: "Weide AAF" },
    { code: "GXY", name: "Weld County" },
    { code: "ENV", name: "Wendover" },
    { code: "JRA", name: "West 30th St Heliport" },
    { code: "ETB", name: "West Bend" },
    { code: "VKW", name: "West Kavik" },
    { code: "XPU", name: "West Kuparuk" },
    { code: "WWR", name: "West Woodward" },
    { code: "HPN", name: "Westchester County Apt" },
    { code: "WST", name: "Westerly State" },
    { code: "LBE", name: "Westmoreland County" },
    { code: "CEF", name: "Westover Metro Arpt" },
    { code: "WSX", name: "Westsound" },
    { code: "WWP", name: "Whale Pass" },
    { code: "WHT", name: "Wharton" },
    { code: "HHI", name: "Wheeler AFB" },
    { code: "NUW", name: "Whidbey Island NAS" },
    { code: "WMO", name: "White Mountain" },
    { code: "WTR", name: "White River" },
    { code: "LEB", name: "White River" },
    { code: "WHP", name: "Whiteman" },
    { code: "SZL", name: "Whiteman AFB" },
    { code: "SQI", name: "Whiteside County" },
    { code: "NSE", name: "Whiting Field NAS" },
    { code: "SBY", name: "Wicomico Regional" },
    { code: "EWD", name: "Wildman Lake" },
    { code: "CBE", name: "Wiley Ford" },
    { code: "PWA", name: "Wiley Post" },
    { code: "BRW", name: "Wiley Post/W.Rogers M" },
    { code: "QWG", name: "Wilgrove Air Park" },
    { code: "IKB", name: "Wilkes County" },
    { code: "AVP", name: "Wilkes-Barre/Scranton International Airport" },
    { code: "WBW", name: "Wilkes-Barre/Wyoming Valley" },
    { code: "OKC", name: "Will Rogers World Airport" },
    { code: "WJF", name: "William J Fox" },
    { code: "HOU", name: "William P Hobby" },
    { code: "CHD", name: "Williams Gateway Airport" },
    { code: "PTN", name: "Williams Memorial" },
    { code: "ZWB", name: "Williamsburg Rail" },
    { code: "MWA", name: "Williamson County" },
    { code: "ILL", name: "Willmar" },
    { code: "WOW", name: "Willow" },
    { code: "NXX", name: "Willow Grove NAS" },
    { code: "YIP", name: "Willow Run" },
    { code: "ILM", name: "Wilmington International" },
    { code: "ZWI", name: "Wilmington Rail" },
    { code: "WDR", name: "Winder" },
    { code: "MWM", name: "Windom Municipal" },
    { code: "BBX", name: "Wings Field" },
    { code: "INK", name: "Wink" },
    { code: "TDO", name: "Winlock" },
    { code: "WMC", name: "Winnemucca" },
    { code: "INW", name: "Winslow-Lindbergh" },
    { code: "LMS", name: "Winston County" },
    { code: "SNK", name: "Winston Field" },
    { code: "ISS", name: "Wiscasset" },
    { code: "LNP", name: "Wise" },
    { code: "WSM", name: "Wiseman" },
    { code: "SUA", name: "Witham Field" },
    { code: "OSH", name: "Wittman Field" },
    { code: "BTL", name: "WK Kellogg Regional" },
    { code: "PKB", name: "Wood County" },
    { code: "WOD", name: "Wood River" },
    { code: "WOO", name: "Woodchopper" },
    { code: "CDN", name: "Woodward Fld" },
    { code: "ORH", name: "Worcester Regional Airport" },
    { code: "WRL", name: "Worland" },
    { code: "WTC", name: "World Trade Center" },
    { code: "OTG", name: "Worthington" },
    { code: "WRG", name: "Wrangell SPB" },
    { code: "DWF", name: "Wright AFB" },
    { code: "OSC", name: "Wurtsmith AFB" },
    { code: "YKM", name: "Yakima Air Terminal" },
    { code: "YAK", name: "Yakutat" },
    { code: "HDN", name: "Yampa Valley" },
    { code: "CRW", name: "Yeager" },
    { code: "ELY", name: "Yelland" },
    { code: "WYS", name: "Yellowstone" },
    { code: "COD", name: "Yellowstone Regional" },
    { code: "EYR", name: "Yerington" },
    { code: "THV", name: "York Airport" },
    { code: "OYS", name: "Yosemite Ntl Park" },
    { code: "YNG", name: "Youngstown" },
    { code: "RKC", name: "Yreka" },
    { code: "MYV", name: "Yuba County" },
    { code: "KZB", name: "Zachar Bay SPB" },
    { code: "AYZ", name: "Zahns" },
    { code: "ZZV", name: "Zanesville" },
    { code: "ZPH", name: "Zephyrhills" },
    { code: "WAX", name: "Zwara" },
    { code: "DGF", name: "Bisbee-douglas International" },
    { code: "ATI", name: "Artigas" },
    { code: "BUV", name: "Bella Union" },
    { code: "PDP", name: "Capitan Corbeta CA Curbelo International Airport" },
    { code: "MVD", name: "Carrasco International" },
    { code: "CYR", name: "Laguna De Los Patos" },
    { code: "MLZ", name: "Melo" },
    { code: "PDU", name: "Paysandu" },
    { code: "RVY", name: "Rivera" },
    { code: "STY", name: "Salto" },
    { code: "DZO", name: "Santa Bernardina" },
    { code: "TAW", name: "Tacuarembo" },
    { code: "TYT", name: "Treinta-y-Tres" },
    { code: "VCH", name: "Vichadero" },
    { code: "JON", name: "Johnston Island" },
    { code: "MDY", name: "Sand Island Field" },
    { code: "AWK", name: "Wake Island" },
    { code: "AZN", name: "Andizhan" },
    { code: "BHK", name: "Bukhara" },
    { code: "FEG", name: "Fergana" },
    { code: "KSQ", name: "Karshi" },
    { code: "NMA", name: "Namangan" },
    { code: "NVI", name: "Navoi" },
    { code: "NCU", name: "Nukus" },
    { code: "SKD", name: "Samarkand" },
    { code: "TMJ", name: "Termez" },
    { code: "UGC", name: "Urgench" },
    { code: "TAS", name: "Yuzhny" },
    { code: "AFS", name: "Zarafshan" },
    { code: "AUY", name: "Aneityum" },
    { code: "AWD", name: "Aniwa" },
    { code: "VLI", name: "Bauerfield" },
    { code: "GBA", name: "Big Bay" },
    { code: "CCV", name: "Craig Cove" },
    { code: "DLY", name: "Dillons Bay" },
    { code: "EAE", name: "Emae" },
    { code: "EPI", name: "Epi" },
    { code: "FTA", name: "Futuna Airport" },
    { code: "ZGU", name: "Gaua" },
    { code: "IPA", name: "Ipota" },
    { code: "LPM", name: "Lamap" },
    { code: "LNB", name: "Lamen Bay" },
    { code: "LOD", name: "Longana" },
    { code: "LNE", name: "Lonorore" },
    { code: "MWF", name: "Maewo" },
    { code: "MTV", name: "Mota Lava" },
    { code: "NUS", name: "Norsup" },
    { code: "OLJ", name: "Olpoi" },
    { code: "PBJ", name: "Paama" },
    { code: "SON", name: "Pekoa" },
    { code: "UIQ", name: "Quine Hill" },
    { code: "RCL", name: "Redcliffe" },
    { code: "SSR", name: "Sara" },
    { code: "SLH", name: "Sola" },
    { code: "SWJ", name: "South West Bay" },
    { code: "TAH", name: "Tanna" },
    { code: "TGH", name: "Tongoa" },
    { code: "TOH", name: "Torres Airstrip" },
    { code: "ULB", name: "Ulei" },
    { code: "VLS", name: "Valesdir" },
    { code: "WLH", name: "Walaha" },
    { code: "MRD", name: "A Carnevalli" },
    { code: "AGV", name: "Acarigua" },
    { code: "AAO", name: "Anaco" },
    { code: "BNS", name: "Barinas" },
    { code: "BRM", name: "Barquisimeto" },
    { code: "CXA", name: "Caicara Del Orinoco" },
    { code: "CLZ", name: "Calabozo" },
    { code: "CAJ", name: "Canaima" },
    { code: "VCR", name: "Carora" },
    { code: "CUP", name: "Carupano" },
    { code: "VLV", name: "Carvajal" },
    { code: "CUV", name: "Casigua" },
    { code: "CBL", name: "Ciudad Bolivar" },
    { code: "CGU", name: "Ciudad Guayana" },
    { code: "CZE", name: "Coro" },
    { code: "CUM", name: "Cumana" },
    { code: "PMV", name: "Del Caribe International" },
    { code: "SOM", name: "Edmundo Barrios" },
    { code: "EOR", name: "El Dorado" },
    { code: "ELX", name: "El Tigre" },
    { code: "VIG", name: "El Vigia" },
    { code: "EOZ", name: "Elorza" },
    { code: "BLA", name: "Gen J A Anzoategui" },
    { code: "GUQ", name: "Guanare" },
    { code: "GUI", name: "Guiria" },
    { code: "ICA", name: "Icabaru" },
    { code: "LSP", name: "Josefa Camejo" },
    { code: "KTV", name: "Kamarata" },
    { code: "KAV", name: "Kavanayen" },
    { code: "STB", name: "L Delicias" },
    { code: "MAR", name: "La Chinita" },
    { code: "LFR", name: "La Fria" },
    { code: "LAG", name: "La Guaira" },
    { code: "LGY", name: "Lagunillas" },
    { code: "SFD", name: "Las Flecheras" },
    { code: "LRV", name: "Los Roques" },
    { code: "MYC", name: "Maracay" },
    { code: "STD", name: "Mayo Guerrero" },
    { code: "CBS", name: "Oro Negro" },
    { code: "PTM", name: "Palmarito" },
    { code: "PDZ", name: "Pedernales" },
    { code: "PPH", name: "Peraitepuy" },
    { code: "LPJ", name: "Pijiguaos" },
    { code: "PYH", name: "Puerto Ayacucho" },
    { code: "PBL", name: "Puerto Cabello" },
    { code: "UPC", name: "Puerto La Cruz" },
    { code: "PZO", name: "Puerto Ordaz" },
    { code: "PPZ", name: "Puerto Paez" },
    { code: "MUN", name: "Quiriquire" },
    { code: "SVZ", name: "San Antonio" },
    { code: "SCI", name: "San Cristobal" },
    { code: "SNF", name: "San Felipe" },
    { code: "SFX", name: "San Felix" },
    { code: "SVV", name: "San Salvador De" },
    { code: "SBB", name: "Santa Barbara Ba" },
    { code: "SNV", name: "Santa Elena" },
    { code: "MIQ", name: "Simón Bolívar International" },
    { code: "CCS", name: "Simon Bolivar International Airport" },
    { code: "TUV", name: "Tucupita" },
    { code: "TMO", name: "Tumeremo" },
    { code: "URM", name: "Uriman" },
    { code: "VLN", name: "Valencia" },
    { code: "VDP", name: "Valle De Pascua" },
    { code: "GDO", name: "Vare Maria" },
    { code: "WOK", name: "Wonken" },
    { code: "CAH", name: "Ca Mau" },
    { code: "VCA", name: "Can Tho" },
    { code: "CSJ", name: "Cape St Jacques" },
    { code: "HPH", name: "Catbi" },
    { code: "VCL", name: "Chulai" },
    { code: "VCS", name: "Coong" },
    { code: "DAD", name: "Da Nang" },
    { code: "DIN", name: "Dien Bien" },
    { code: "PQC", name: "Duong Dong" },
    { code: "HBN", name: "Flamingo" },
    { code: "KON", name: "Kontum" },
    { code: "DLI", name: "Lienkhang" },
    { code: "XLO", name: "Long Xuyen" },
    { code: "NHA", name: "Nha Trang" },
    { code: "CXR", name: "Nha Trang" },
    { code: "HOO", name: "Nhon Co" },
    { code: "HAN", name: "Noibai International" },
    { code: "PHA", name: "Phan Rang" },
    { code: "PHH", name: "Phan Thiet" },
    { code: "HUI", name: "Phu Bai" },
    { code: "PHU", name: "Phu Vinh" },
    { code: "BMV", name: "Phung-Duc" },
    { code: "VSO", name: "Phuoclong" },
    { code: "PXU", name: "Pleiku" },
    { code: "XNG", name: "Quang Ngai" },
    { code: "UIH", name: "Qui Nhon" },
    { code: "VKG", name: "Rach Gia" },
    { code: "SOA", name: "Soc Trang" },
    { code: "SGN", name: "Tan Son Nhat International" },
    { code: "TBB", name: "Tuy Hoa" },
    { code: "VII", name: "Vinh City" },
    { code: "XVL", name: "Vinh Long" },
    { code: "VTG", name: "Vung Tau" },
    { code: "NGD", name: "Anegada" },
    { code: "NSX", name: "Hovercraft/Launch Pt" },
    { code: "RAD", name: "Road Town" },
    { code: "TOV", name: "West End SPB" },
    { code: "STX", name: "Henry E Rohlsen" },
    { code: "SJF", name: "St John Island" },
    { code: "STT", name: "Cyril E King Airport" },
    { code: "FUT", name: "Futuna Island" },
    { code: "WLS", name: "Wallis Island" },
    { code: "EAB", name: "Abbse" },
    { code: "AAY", name: "Al Ghaydah" },
    { code: "TAI", name: "Al Janad" },
    { code: "BUK", name: "Albuq" },
    { code: "AJO", name: "Aljouf" },
    { code: "AXK", name: "Ataq" },
    { code: "BYD", name: "Beidah" },
    { code: "BHN", name: "Beihan" },
    { code: "DAH", name: "Dathina" },
    { code: "DHL", name: "Dhala" },
    { code: "DMR", name: "Dhamar" },
    { code: "SAH", name: "El Rahaba Airport" },
    { code: "HOD", name: "Hodeidah Arpt" },
    { code: "ADE", name: "International" },
    { code: "KAM", name: "Kamaran Is" },
    { code: "LDR", name: "Lodar" },
    { code: "MYN", name: "Mareb" },
    { code: "MKX", name: "Mukalla" },
    { code: "UKR", name: "Mukeiras" },
    { code: "IHN", name: "Qishn" },
    { code: "RXA", name: "Raudha" },
    { code: "RIY", name: "Riyan Mukalla" },
    { code: "SYE", name: "Sadah" },
    { code: "GXF", name: "Seiyun" },
    { code: "SCT", name: "Socotra" },
    { code: "WDA", name: "Wadi Ain" },
    { code: "ZBK", name: "Zabljak" },
    { code: "BSU", name: "Basankusu" },
    { code: "BAN", name: "Basongo" },
    { code: "BNC", name: "Beni" },
    { code: "BNB", name: "Boende" },
    { code: "BOA", name: "Boma" },
    { code: "BMB", name: "Bumba" },
    { code: "BUX", name: "Bunia" },
    { code: "BZU", name: "Buta" },
    { code: "GDJ", name: "Gandajika" },
    { code: "GMA", name: "Gemena" },
    { code: "IDF", name: "Idiofa" },
    { code: "PFR", name: "Ilebo" },
    { code: "KBO", name: "Kabalo" },
    { code: "KBN", name: "Kabinda" },
    { code: "FMI", name: "Kalemie" },
    { code: "KLY", name: "Kalima" },
    { code: "KMN", name: "Kamina" },
    { code: "KGA", name: "Kananga" },
    { code: "KNM", name: "Kaniama" },
    { code: "KAP", name: "Kapanga" },
    { code: "KEC", name: "Kasenga" },
    { code: "KGN", name: "Kasongo Lunda" },
    { code: "KKW", name: "Kikwit" },
    { code: "KIL", name: "Kilwa" },
    { code: "KND", name: "Kindu" },
    { code: "KRZ", name: "Kiri" },
    { code: "KOO", name: "Kongolo" },
    { code: "KLI", name: "Kota Koli" },
    { code: "LIE", name: "Libenge" },
    { code: "LIQ", name: "Lisala" },
    { code: "LZA", name: "Luiza" },
    { code: "LZI", name: "Luozi" },
    { code: "LBO", name: "Lusambo" },
    { code: "LUS", name: "Lusanga" },
    { code: "MSM", name: "Masi Manimba" },
    { code: "MAT", name: "Matadi" },
    { code: "IRP", name: "Matari" },
    { code: "MDK", name: "Mbandaka" },
    { code: "MJM", name: "Mbuji Mayi" },
    { code: "MNB", name: "Moanda" },
    { code: "BDV", name: "Moba" },
    { code: "MEW", name: "Mweka" },
    { code: "NIO", name: "Nioki" },
    { code: "NKL", name: "Nkolo" },
    { code: "PUN", name: "Punia" },
    { code: "PWO", name: "Pweto" },
    { code: "YAN", name: "Yangambi" },
    { code: "CGJ", name: "Chingola" },
    { code: "CIP", name: "Chipata" },
    { code: "KLB", name: "Kalabo" },
    { code: "KMZ", name: "Kaoma" },
    { code: "ZKB", name: "Kasaba Bay" },
    { code: "KAA", name: "Kasama" },
    { code: "ZKP", name: "Kasompe" },
    { code: "LVI", name: "Livingstone" },
    { code: "LXU", name: "Lukulu" },
    { code: "LUN", name: "Lusaka" },
    { code: "MNS", name: "Mansa" },
    { code: "MMQ", name: "Mbala" },
    { code: "MFU", name: "Mfuwe" },
    { code: "MNR", name: "Mongu" },
    { code: "NLA", name: "Ndola" },
    { code: "ZGM", name: "Ngoma" },
    { code: "SXG", name: "Senanga" },
    { code: "SJQ", name: "Sesheke" },
    { code: "SLI", name: "Solwezi" },
    { code: "KIW", name: "Southdowns" },
    { code: "BBZ", name: "Zambezi" },
    { code: "BFO", name: "Buffalo Range" },
    { code: "BUQ", name: "Bulawayo" },
    { code: "BZH", name: "Bumi Hills" },
    { code: "CHJ", name: "Chipinge" },
    { code: "GWE", name: "Gweru" },
    { code: "HRE", name: "Harare" },
    { code: "WKI", name: "Hwange" },
    { code: "HWN", name: "Hwange Nat Park" },
    { code: "KAB", name: "Kariba" },
    { code: "MJW", name: "Mahenye" },
    { code: "MVZ", name: "Masvingo" },
    { code: "UTA", name: "Mutare" },
    { code: "VFA", name: "Victoria Falls" },
];
export var airportsOptions = airports.map(function (airport) {
    return {
        label: airport.code + " - " + airport.name,
        value: airport.code,
    };
});
