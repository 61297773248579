import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const eventPersonnelListQueryOptions = (eventId: string) =>
  queryOptions({
    queryKey: ["eventPersonnelList", eventId],
    queryFn: async () => {
      const response =
        await API.events.eventsControllerFindAllPersonnel(eventId);
      return response.data;
    },
    enabled: !!eventId,
  });

export const useEventPersonnelList = (eventId: string) => {
  return useQuery(eventPersonnelListQueryOptions(eventId));
};

export const useEventPersonnelListSuspense = (eventId: string) => {
  return useSuspenseQuery(eventPersonnelListQueryOptions(eventId));
};
