import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { useTourPersonnel } from "@/api/queries/useTourPersonnel";
import { ArtiSelect } from "@/components/ArtiSelect/ArtiSelect";
import { SelectedBadge } from "@/components/SelectedBadge/SelectedBadge";
import { cn } from "@/lib/utils";
import { useEventPersonnelRoles } from "@/api/queries/useEventPersonnelRoles";
import { useTourGroups } from "@/api/queries/useTourGroups";
import { useEffect, useState } from "react";
import { TourEventRole, TourEventRoles } from "@articulate/shared";
import { getGenericFormSchema } from "@/modules/Tours/Events/eventsSchemas";
import { getUserName } from "@/lib/getUserName";
import { useSearchParams } from "react-router-dom";

type Options = {
  label: string;
  value: string;
}[];

type ManipulateOptionsHandler = {
  userEmail?: string;
  groupId?: string;
};

type Props = {
  tourId: string;
  role: TourEventRole;
};

const options = {
  shouldValidate: true,
  shouldDirty: true,
};
const emailSchema = z.string().email();

export function PersonnelPicker({ tourId, role }: Props) {
  const { t } = useTranslation(["common", "dashboard", "forms"]);
  const [searchParams] = useSearchParams();
  const genericSchema = getGenericFormSchema(t);
  const { setValue, watch } = useFormContext<z.infer<typeof genericSchema>>();

  const { data: tourPersonnel } = useTourPersonnel(
    tourId || searchParams.get("tourId")!
  );
  const { data: eventPersonnelRoles } = useEventPersonnelRoles();
  const { data: tourGroups } = useTourGroups(
    tourId || searchParams.get("tourId")!
  );

  const [membersOptions, setMembersOptions] = useState<Options>([]);
  const [groupsOptions, setGroupsOptions] = useState<Options>([]);

  const isCrew = role === TourEventRoles.CREW;
  const formName = `${isCrew ? "membersAndGroups" : "spectators"}` as const;
  const fieldValues = watch(formName);

  const removeOption = ({ userEmail, groupId }: ManipulateOptionsHandler) => {
    if (userEmail) {
      setMembersOptions((prev) =>
        prev?.filter((member) => member.value !== userEmail)
      );
      return;
    }

    if (groupId) {
      setGroupsOptions((prev) =>
        prev?.filter((group) => group.value !== groupId)
      );
    }
  };

  const addOption = ({
    userEmail,
    userName,
    groupId,
  }: ManipulateOptionsHandler & { userName?: string }) => {
    if (userEmail && userName) {
      setMembersOptions((prev) => [
        ...prev,
        { label: userName, value: userEmail },
      ]);
      return;
    }

    if (groupId) {
      setGroupsOptions((prev) => [...prev, { label: groupId, value: groupId }]);
    }
  };

  const onSelect = (value: string) => {
    const email = emailSchema.safeParse(value);
    const roleName = isCrew ? TourEventRoles.CREW : TourEventRoles.SPECTATOR;
    const role = eventPersonnelRoles?.eventPersonnelRoles.find(
      (personnelRole) => personnelRole.role === roleName
    );

    if (email.success) {
      if (fieldValues.find((item) => item.email === value)) return;

      const user = tourPersonnel?.tourPersonnel.find(
        (user) => user.user.email === value
      );

      if (!user?.id || !user?.user.id || !role?.id) return;

      setValue(
        formName,
        [
          ...fieldValues,
          {
            email: email.data,
            roleId: role.id,
            id: user.id,
            userId: user.user.id,
          },
        ],
        options
      );
      removeOption({ userEmail: email.data });
      return;
    }

    if (fieldValues.find((item) => item.id === value) || !role?.id) return;

    setValue(
      formName,
      [
        ...fieldValues,
        {
          email: "",
          roleId: role.id,
          id: value,
          userId: "",
        },
      ],
      options
    );
    removeOption({ groupId: value });
  };

  const onUnselect = (value: string) => {
    const email = emailSchema.safeParse(value);
    const targetUser = tourPersonnel?.tourPersonnel.find(
      (person) => person.user.email === email.data
    );

    setValue(
      formName,
      email.success
        ? fieldValues.filter((item) => item.email !== email.data)
        : fieldValues.filter((item) => item.id !== value),
      options
    );
    addOption(
      email.success
        ? {
            userEmail: email.data,
            userName: getUserName(
              targetUser?.user.firstName,
              targetUser?.user.lastName,
              targetUser?.user.email
            ),
          }
        : { groupId: value }
    );
  };

  const crews = watch("membersAndGroups");
  const spectators = watch("spectators");

  useEffect(() => {
    const crewsAndSpectators = [...crews, ...spectators];

    setMembersOptions(
      tourPersonnel?.tourPersonnel
        .map((item) => ({
          label: getUserName(
            item.user.firstName,
            item.user.lastName,
            item.user.email
          ),
          value: item.user.email,
        }))
        .filter(
          (option) =>
            !crewsAndSpectators.find((item) => item.email === option.value)
        ) || []
    );

    setGroupsOptions(
      tourGroups?.groups
        .map((group) => ({
          label: group.name,
          value: group.id,
        }))
        .filter(
          (option) =>
            !crewsAndSpectators.find((item) => item.id === option.value)
        ) || []
    );
  }, [tourPersonnel, tourGroups, crews, spectators]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-4">
        <ArtiSelect
          items={[
            ...groupsOptions.sort((a, b) => a.label.localeCompare(b.label)),
            ...membersOptions.sort((a, b) => a.label.localeCompare(b.label)),
          ]}
          value=""
          placeholder={t("dashboard:events.assignUsers")}
          label={t("dashboard:events.assignUsers")}
          onChange={onSelect}
        />
        <ul
          className={cn("flex flex-wrap gap-4", {
            hidden: !fieldValues?.length,
          })}>
          {fieldValues?.map((memberOrGroup) => {
            const targetUser = tourPersonnel?.tourPersonnel.find(
              (person) => person.user.email === memberOrGroup.email
            )?.user;

            const userName = getUserName(
              targetUser?.firstName,
              targetUser?.lastName,
              targetUser?.email
            );

            return (
              <SelectedBadge
                value={
                  userName ||
                  tourGroups?.groups.find(
                    (group) => group.id === memberOrGroup.id
                  )?.name ||
                  ""
                }
                onDelete={() =>
                  onUnselect(memberOrGroup.email || memberOrGroup.id)
                }
                className="h-6"
                key={memberOrGroup.id}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}
