export var riderTypes = [
    "ARENA",
    "CLUB",
    "FESTIVAL",
    "SPECIAL_EVENT",
];
export var RiderTypes = {
    ARENA: "ARENA",
    CLUB: "CLUB",
    FESTIVAL: "FESTIVAL",
    SPECIAL_EVENT: "SPECIAL_EVENT",
};
var getFormatedRiderType = function (type) {
    switch (type) {
        case "ARENA":
            return "Arena/Amp/Stadium";
        case "CLUB":
            return "Club/Theatre";
        case "FESTIVAL":
            return "Festival";
        case "SPECIAL_EVENT":
            return "Special Event/Custom";
    }
};
export var riderTypesOptions = riderTypes.map(function (type) { return ({
    label: getFormatedRiderType(type),
    value: type,
}); });
export var riderLaborsTypes = [
    "FORKLIFT",
    "OPERATOR",
    "RIGGER",
    "CLIMBER",
];
export var RiderLaborsTypes = {
    FORKLIFT: "FORKLIFT",
    OPERATOR: "OPERATOR",
    RIGGER: "RIGGER",
    CLIMBER: "CLIMBER",
};
export var riderLaborsTypesOptions = riderLaborsTypes.map(function (type) { return ({
    label: type.charAt(0) + type.substring(1).toLowerCase(),
    value: type,
}); });
