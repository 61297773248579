import React from "react";
import { ReactNode } from "react";
import { Control, Path } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Checkbox } from "../ui/checkbox";

type FormFieldControllerProps<T extends Record<string, unknown>> = {
  name: Path<T>;
  label: string;
  children: ReactNode;
  control: Control<T>;
  omitErrorMessage?: boolean;
  withNotApplicable?: boolean;
};

export function FormFieldController<T extends Record<string, unknown>>(
  props: FormFieldControllerProps<T>
) {
  const Child = React.Children.only(props.children);

  if (!React.isValidElement(Child)) return null;

  return (
    <FormField
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormItem>
          {props.label && (
            <FormLabel className="cursor-pointer" htmlFor={props.label}>
              {props.label}
            </FormLabel>
          )}
          <FormControl>
            {React.cloneElement(Child, {
              ...field,
              //@ts-expect-error - we only use inputs in here so they get disabled
              disabled: props.withNotApplicable && field.value === "N/A",
              id: props.label,
            })}
          </FormControl>
          {props.withNotApplicable && (
            <div className="flex items-center gap-2 pt-2">
              <Checkbox
                id={`${props.name}-checkbox-na`}
                checked={field.value === "N/A"}
                onCheckedChange={(checked) => {
                  field.onChange(checked ? "N/A" : "");
                }}
              />
              <FormLabel
                htmlFor={`${props.name}-checkbox-na`}
                className="w-full cursor-pointer">
                N/A
              </FormLabel>
            </div>
          )}
          {!props.omitErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
