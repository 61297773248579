import { useMutation } from "@tanstack/react-query";
import { API } from "../api";

export type RemovePersonalEventArgs = {
  eventId: string;
};

export const useRemovePersonalEvent = () => {
  return useMutation({
    mutationKey: ["removePersonalEvent"],
    mutationFn: async ({ eventId }: RemovePersonalEventArgs) => {
      const response =
        await API.personalEvents.personalEventsControllerRemove(eventId);

      return response.data;
    },
  });
};
