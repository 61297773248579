import { useMutation } from "@tanstack/react-query";
import { API } from "../api";
import { CreatePersonalEventDto } from "../base-api";

export type CreatePersonalEventArgs = {
  data: CreatePersonalEventDto;
};

export const useCreatePersonalEvent = () => {
  return useMutation({
    mutationKey: ["createPersonalEvent"],
    mutationFn: async ({ data }: CreatePersonalEventArgs) => {
      const response =
        await API.personalEvents.personalEventsControllerCreate(data);

      return response.data;
    },
  });
};
