import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";
import { EventsDto } from "../base-api";
import { toZonedTime } from "date-fns-tz";
import { getDaysForInterval, toApiDateFormat } from "@/lib/dates";
import { getEventTimezones } from "@/modules/Tours/TourEvents";

const SIXTY_SECONDS = 60000;

export const tourEventsQueryOptions = (tourId: string) =>
  queryOptions({
    queryKey: ["tourEvents", tourId],
    queryFn: async () => {
      const response = await API.events.eventsControllerFindAllByTour(tourId);
      return response.data;
    },
    select(data) {
      const allEventsValuesNested = Object.values(data.events).flat();

      let returnObject: EventsDto = { events: {} };
      const usedEvents = new Set<string>();

      allEventsValuesNested.forEach((event) => {
        if (usedEvents.has(event.id)) {
          return;
        }
        const isTravel = !!event.additionalInformation?.travel?.travelType;
        const { startTimezone, endTimezone } = getEventTimezones({
          isTravel,
          event,
        });
        usedEvents.add(event.id);
        const date = toZonedTime(event.startDate!, startTimezone!);
        const endDate = toZonedTime(event.endDate!, endTimezone!);
        const formattedStartDate = toApiDateFormat(date);

        const days = getDaysForInterval(
          date.toISOString(),
          endDate.toISOString()
        );
        const daysFormatted = event.additionalInformation?.hotel
          ? days.map((day) => toApiDateFormat(day))
          : [formattedStartDate];

        daysFormatted.forEach((date) => {
          returnObject = {
            events: {
              ...returnObject.events,
              [date]: returnObject.events[date]
                ? [...returnObject.events[date], event]
                : [event],
            },
          };
        });
      });

      return returnObject;
    },
    enabled: !!tourId,
    refetchInterval: SIXTY_SECONDS,
  });

export const useTourEvents = (tourId: string) => {
  return useQuery(tourEventsQueryOptions(tourId));
};

export const useTourEventsSuspense = (tourId: string) => {
  return useSuspenseQuery(tourEventsQueryOptions(tourId));
};
